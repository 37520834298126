import { SectionService } from './../../Services/section.service';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Sectioncl} from '../../Models/sectioncl.model';
import {ComiteService} from '../../Services/comite.service';
import {Comitecl} from '../../Models/comitecl.model';
import { ToastrService } from 'ngx-toastr';
import {Sousprefecturecl} from '../../Models/sousprefecturecl.model';
import {SousprefectureService} from '../../Services/sousprefecture.service';

@Component({
  selector: 'app-sectionmodal',
  templateUrl: './sectionmodal.component.html',
  styleUrls: ['./sectionmodal.component.css']
})
export class SectionmodalComponent implements OnInit {
  public lstCommune: Sousprefecturecl[];
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: Sectioncl, private toastr: ToastrService, private svceSection: SectionService, public svceCommune: SousprefectureService, public dialogRef: MatDialogRef<SectionmodalComponent>) { }

  ngOnInit(): void {
    this.getAllCommunes();
  }
  getAllCommunes = () => {
    this.svceCommune.getAllCommuneSp().subscribe(
      (result) => {
        this.lstCommune = result['donnees'];
        console.log(result);
    },
    (error) => {
      console.log(error);
    }
    );
  }
  close = () => {
    this.dialogRef.close(false);
  }
  crudSection = (section: Sectioncl, type: string) => {
    this.svceSection.addSection(section).subscribe(
      (res) => {
        console.log(res);
        switch (type) {
          case 'add':
            this.toastr.success('Section insérée avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
          case 'update':
            this.toastr.success('Section modifiée avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
        }
        this.dialogRef.close(true);
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  onAdd = (section: Sectioncl) => {
    switch (section.actionType){
      case 'add':
        this.crudSection(section, section.actionType);
        break;
      case 'update':
        this.crudSection(section, section.actionType);
        break;
      default:
        break;
    }
  }
}
