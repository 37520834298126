import { SousprefectureService } from './../../Services/sousprefecture.service';
import { Sousprefecturecl } from './../../Models/sousprefecturecl.model';
import { ActivatedRoute } from '@angular/router';
import { Membrecl } from './../../Models/membrecl.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-newmembre',
  templateUrl: './newmembre.component.html',
  styleUrls: ['./newmembre.component.css']
})
export class NewmembreComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  private sub: any;
  public newMembre = new Membrecl();
  public lstCommune: Sousprefecturecl[];
  constructor(private route: ActivatedRoute, public svceCommune: SousprefectureService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      // this.newMembre =  params['millitant'] as Membrecl; // (+) converts string 'id' to a number
    });
    const nouveauMembre = localStorage.getItem("mewMillitant");
    this.newMembre = JSON.parse(nouveauMembre);
    this.getAllCommunes();
  }
  getAllCommunes = () => {
    this.svceCommune.getAllCommuneSp().subscribe(
      (result) => {
        this.lstCommune = result['donnees'];
        console.log(result);
    },
    (error) => {
      console.log(error);
    }
    );
  }
  addNewMembre = (membre: Membrecl) => {

  }
}
