<section class="content home">
  <div class="block-header">
    <div class="row">
      <div class="col-lg-5 col-md-5 col-sm-12">
        <h2>Coordinateurs<small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-12 text-right">

        <!-- <button (click)="openModal(service)" class="btn btn-white btn-icon btn-round d-none d-md-inline-block float-right m-l-10" type="button">
            <i class="zmdi zmdi-plus"></i>
        </button> -->

        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
          <li class="breadcrumb-item">Accueil</li>
          <li class="breadcrumb-item active">Coordinateurs</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="body">
            <div class="row filtrepan">
              <div class="col-lg-6">
                <!-- <label><small>Régions</small></label> -->
                <div class="form-group">
                  <mat-form-field>
                    <mat-select  (selectionChange)="getAllTrieDep(singleSelect.value)" placeholder="Régions" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of lstRegions.selected" [value]="item.regionId">
                        {{item.libelle}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- <label><small>Departements</small></label> -->
                <div class="form-group">
                  <mat-form-field>
                    <mat-select  (selectionChange)="getAllTrieCommune(singleDepSelect.value)" placeholder="Departements" #singleDepSelect>
                      <mat-option>
                        <ngx-mat-select-search ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of lstDepsTrie.selected" [value]="item.departementId">
                        {{item.libelle}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="col-lg-4">
                 <label><small>Sous-préfectures</small></label>
                <div class="form-group">
                  <mat-form-field>
                    <mat-select  (selectionChange)="getAllCordinateursBy(singleCommuneSelect.value)" placeholder="Communes / SP" #singleCommuneSelect>
                      <mat-option>
                        <ngx-mat-select-search ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of lstCommuneSPTrie.selected" [value]="item.sousPrefectureId">
                        {{item.libelle}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div> -->
              <!-- <div class="col-lg-3">
                <div class="form-group">
                  <mat-form-field>
                    <mat-select [formControl]="selectControlSection" placeholder="Sections" #singleSectionSelect>
                      <mat-option>
                        <ngx-mat-select-search [formControl]="selectFilterCtrlSection"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of lstSectionsTrie.selected" [value]="item.sectionId">
                        {{item.libelle}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="body" style="min-height: 200px;">


            <div class="col-sm-12 col-lg-12">
              <div class="row">
                <div class="col-sm-10 col-lg-10">
                  <div class="input-group search">
                    <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                    <!-- <span class="input-group-addon">
                        <i class="zmdi zmdi-search"></i>
                    </span> -->
                  </div>
                </div>
                <div class="col-sm-2 col-lg-2  text-right processBtn">
                  <button [ngClass]="{'hide': (lstCordinateursCheck.isEmpty())}"  mat-icon-button class="btn btn-icon btn-neutral btn-icon-mini">
                    <i class="material-icons add">add</i>
                  </button>
                  <button [ngClass]="{'hide': (lstCordinateursCheck.isEmpty())}" mat-icon-button class="btn btn-icon btn-neutral btn-icon-mini">
                    <i class="material-icons delete">delete_outline</i>
                  </button>
                </div>
                <div class="table-responsive">
                  <div class="col-sm-12 col-lg-12">
                    <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                      <ng-container matColumnDef="check">
                        <th class="center" mat-header-cell *matHeaderCellDef>
                          <mat-checkbox (change)="$event ? masterToggle() : null"
                                        [checked]="lstCordinateursCheck.hasValue() && isAllSelected()"
                                        [indeterminate]="lstCordinateursCheck.hasValue() && !isAllSelected()">
                          </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? lstCordinateursCheck.toggle(element) : null"
                                        [checked]="lstCordinateursCheck.isSelected(element)">
                          </mat-checkbox>
                        </td>
                      </ng-container>
                      <!-- <ng-container matColumnDef="Id">
                        <th class="center" mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Login">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Code Electeur </th>
                        <td mat-cell *matCellDef="let element"> {{element.numeroElecteur !== null ? element.numeroElecteur : 'Non inscrit'}} </td>
                      </ng-container>-->
                      <ng-container matColumnDef="Nom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
                        <td mat-cell *matCellDef="let element"> <a (click)="redirectToDetails(element.membreId)" >{{element.nom}}</a>  </td>
                      </ng-container>
                      <ng-container matColumnDef="Prenoms">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom(s) </th>
                        <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Gender">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sexe </th>
                        <td mat-cell *matCellDef="let element"> {{element.sex == 'M' ? 'Masculin' : element.sex == 'F' ? 'Fénimin' : ''}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Commune">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Communes </th>
                        <td mat-cell *matCellDef="let element"> {{element.libelle}}</td>
                      </ng-container>
                      <ng-container matColumnDef="Section">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header># Sections </th>
                        <td mat-cell *matCellDef="let element"><span *ngIf="element.NbreSEC > 0" class="badge badge-pill">{{element.NbreSEC}} SECTION(S)</span> <span *ngIf="element.NbreSEC == 0" class="badge badge-primary">AUCUNE SECTION</span></td>
                      </ng-container>
                      <ng-container matColumnDef="Nbre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header># Missions </th>
                        <td mat-cell *matCellDef="let element"><span *ngIf="element.NbreM > 0" class="badge badge-pill">{{element.NbreM}} MISSION(S)</span> <span *ngIf="element.NbreM == 0" class="badge badge-primary">AUCUNE MISSION</span></td>
                      </ng-container>
                      <ng-container matColumnDef="NbreCB">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header># Comités </th>
                        <td mat-cell *matCellDef="let element"><span *ngIf="element.NbreCB > 0" class="badge badge-pill">{{element.NbreCB}} CB(S)</span> <span *ngIf="element.NbreCB == 0 || element.NbreCB == null" class="badge badge-primary">AUCUN CB</span></td>
                      </ng-container>
                      <ng-container matColumnDef="NbreML">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header># Militants </th>
                        <td mat-cell *matCellDef="let element"><span *ngIf="element.NbreML > 0" class="badge badge-pill">{{element.NbreML}} MILITANT(S)</span> <span *ngIf="element.NbreML == 0 || element.NbreML == null" class="badge badge-primary">AUCUN MILITANT</span></td>
                      </ng-container>
                      <ng-container matColumnDef="Phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Contacts </th>
                        <td mat-cell *matCellDef="let element"><span class="phone"  ><i *ngIf=" element.contact !== '' && element.contact !== null" class="zmdi zmdi-phone m-r-5"> </i>{{ element.contact === '' || element.contact === null ?  (element.contact | mask: '(00) 00-00-00') :  (element.contact | mask: '(00) 00-00-00') }}</span> </td>
                      </ng-container>
                      <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef style="width: 140px;text-align: center"> Actions </th>
                        <td mat-cell *matCellDef=" let element">
                          <button [disabled]="element.NbreM == 0 ? true : false" mat-icon-button class="btn btn-icon btn-neutral  btn-icon-mini" (click)="openDetailsMission(element)">
                            <i class="material-icons">visibility</i>
                          </button>
                          <button mat-icon-button class="btn btn-icon btn-neutral  btn-icon-mini" (click)="openDetailsMembre(element)">
                            <i class="material-icons">format_list_bulleted</i>
                          </button>
                          <button mat-icon-button class="btn btn-icon btn-neutral  btn-icon-mini" (click)="openAffectaionMission(element.membreId, element.blocId)">
                            <i class="material-icons">done_all</i>
                          </button>
                          <button mat-icon-button class="btn btn-icon btn-neutral btn-icon-mini" (click)="redirectToDelete(element.affectationId, ('le Coordinateur '+element.nom+' '+element.prenom+ ' de la section '+ element.libelle), element.sousPrefectureId)">
                            <i class="material-icons delete">delete_outline</i>
                          </button>
                          <button mat-icon-button [disabled]="element.nom !== null ? element.AccessActif == 1 ? true : false : true" class="btn btn-icon btn-neutral btn-icon-mini" (click)="redirectToAddAccess(element)">
                            <i class="material-icons delete">vpn_key</i>
                          </button>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Loading">
                        <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                          Chargement en cour....
                        </th>
                      </ng-container>
                      <ng-container matColumnDef="NoData">
                        <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                          Aucunes données trouvées....
                        </th>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                      <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[15, 30, 60]" [pageSize]="15"  showFirstLastButtons></mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
