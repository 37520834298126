import { SectionService } from './../../Services/section.service';
import { ToastrService } from 'ngx-toastr';
import { ComiteService } from './../../Services/comite.service';
import { SousprefectureService } from './../../Services/sousprefecture.service';
import { DepartementService } from './../../Services/departement.service';
import { RegionService } from './../../Services/region.service';
import { UtilisateursService } from './../../Services/utilisateurs.service';
import { FormControl } from '@angular/forms';
import { Comitecl } from './../../Models/comitecl.model';
import { Sectioncl } from './../../Models/sectioncl.model';
import { Sousprefecturecl } from './../../Models/sousprefecturecl.model';
import { Departementcl } from './../../Models/departementcl.model';
import { Regioncl } from './../../Models/regioncl.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import { ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label, MultiDataSet, PluginServiceGlobalRegistrationAndOptions } from 'ng2-charts';
import {DashboardService} from '../../Services/dashboard.service';
import {Dashboradcl} from '../../Models/dashboradcl.model';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {Animateur} from '../../Models/animateur.model';
@Component({
  selector: 'app-tableaubord',
  templateUrl: './tableaubord.component.html',
  styleUrls: ['./tableaubord.component.css']
})
export class TableaubordComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  /*Diagrame en ligne */
  lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 11, 56, 15, 40], label: 'Evolution Missions' },
    { data: [12, 44, 24, 11, 20, 12, 60], label: 'Evoluttion Militants' },
    { data: [18, 48, 77, 19, 10, 27, 40], label: 'Evolution Sections' },
  ];
  lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  lineChartOptions = {
    responsive: true,
  };
  lineChartColors: Color[] = [
    {
      borderColor: 'rgba(148,159,177,1)',
      backgroundColor: 'rgba(148,159,177,0.2)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  totalMission: number = 0;
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  /* Fin diagrame en line  */
  /*Pie diagram*/
  doughnutChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  doughnutChartLabels: Label[] = [['En Cours'], ['Terminées'], ['Pas débutées']];
  doughnutChartData: number[] = [300, 500, 200];
  doughnutChartType: ChartType = 'doughnut';
  //doughnutChartPlugins = [];
  doughnutChartLegend = false;
  doughnutChartColors = [
    {
      backgroundColor: ['rgba(30, 130, 76, 1)', 'rgba(249, 105, 14, 1)', 'rgba(242, 38, 19, 1)'],
    },
  ];
  doughnutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{
    // tslint:disable-next-line:typedef
    beforeDraw(chart) {
      const ctx = chart.ctx;
      const txt = 'Center Text';

      // Get options from the center object in options
      const sidePadding = 60;
      // const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      const stringWidth = ctx.measureText(txt).width;
      // const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      // const widthRatio = elementWidth / stringWidth;
      // const newFontSize = Math.floor(30 * widthRatio);
      // const elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      // const fontSizeToUse = Math.min(newFontSize, elementHeight);

      // ctx.font = fontSizeToUse + 'px Arial';
      ctx.font = 25 + 'px Arial';
      ctx.fillStyle = 'grey';

      // Draw text in center
      ctx.fillText('Missions', centerX, centerY);
    }
  }];
  /* End Pie fdiagram*/
  /*Jauge charts*/
  gaugeType = 'arch';
  gaugeValue = 0;
  gaugeLabel = 'Evolution';
  gaugeAppendText = '%';
  foregroundColor = ['rgba(0, 150, 136, 1)'];
  /**
   * Prise compte des parametres de filtre
   * @param router
   */
  totalMissionEnCours: number;
  totalMissionNotStart: number;
  totalMissionTerminees: number;
  totalMaxElecteur: number;
  redColor = "#FF0000";
  greenColor = "#008000";
  yellowColor = "#FFFF00";
  gaugeForegroundColorElecteur = this.redColor;
  gaugeValueElecteur: number;
  gaugeValueSection: number;
  gaugeForegroundColorMission = this.redColor;
  gaugeValueMission: number;
  tableDataSource = new MatTableDataSource();
  dashboard = new Dashboradcl();
  lstMissions: Dashboradcl[];
  maxGood: number = myGlobals.TAUX_CONFORME_GOOD;
  maxNotBad: number = myGlobals.TAUX_CONFORME_NOTBAD;
  userConnected: {};
  paramMaxMembreNumber: number;
  addNewMembreActivated: boolean;
  addNewMembreVisible: boolean;
  searchBarActivated: boolean;
  selectBoxRegionActivated: boolean;
  selectBoxDepartementActivated: boolean;
  selectBoxCommuneActivated: boolean;
  selectBoxSectionActivated: boolean;
  selectBoxComiteActivated: boolean;
  private dialogConfig;
  public displayedColumns = ['Login', 'Nom', 'Prenoms', 'Gender', 'Email', 'Phone', 'Commune', 'Comite', 'Role', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  lstComites = new SelectionModel<Comitecl>(true, []);
  lstComitesTrie = new SelectionModel<Comitecl>(true, []);
  /** Comites */
  public selectControlComite: FormControl = new FormControl();
  public selectFilterCtrlComite: FormControl = new FormControl();
  constructor(private dialog: MatDialog, private svceUser: UtilisateursService, private router: Router,  private svceSection: SectionService, private svceComite: ComiteService, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
    this.paramMaxMembreNumber = myGlobals.NBRE_MAX_ML;
    this.getAllComite();
    this.loadData();
  }
  loadData = () => {
    switch (this.userConnected['typemembre']) {
      case 'Animateur': {
        this.getAllNumbersByComite(this.userConnected['comiteBaseId']);
        this.getAllMissionsByComite(this.userConnected['comiteBaseId']);
        this.searchBarActivated = false;
        this.addNewMembreVisible = true;
        break;
      }
      case 'Secrétaire': {
        this.getAllNumbersBySection(this.userConnected['sectionId']);
        this.getAllMissionsBySection(this.userConnected['sectionId']);
        this.searchBarActivated = false;
        this.addNewMembreVisible = false;
        break;
      }
      default: {
        // this.getAllMembres();
        break;
      }
    }
  }
  detailsMission = () => {
    this.router.navigate(['dashboard/detailsMision']) ;
  }
  /** Fin Section */
  /** Comité de base */
  getAllComite = () => {
    if (this.userConnected['typemembre'] === 'Secrétaire'){
      this.svceComite.getAllComiteBy(this.userConnected['sectionId']).subscribe(
        (res) => {
          if (res !== null && !res['status']){
            this.lstComites.clear();
            this.lstComites = res['donnees'];
            res['donnees'].forEach(element => {
              this.lstComitesTrie.select(element);
            });
          }
          const leng = Object.keys(this.lstComites).length;
          console.log(leng);
          this.paramMaxMembreNumber = myGlobals.NBRE_MAX_ML * leng;
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.svceComite.getAllComiteBases().subscribe(
        (res) => {
          if (res !== null && !res['status']){
            this.lstComites.clear();
            console.log(res['donnees']);
            res['donnees'].forEach(element => {
              this.lstComites.select(element);
              this.lstComitesTrie.select(element);
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  getgaugeColorElecteur = (value: number) => {
    // tslint:disable-next-line:triple-equals
    if (!isNaN(value)){
      if (value >= myGlobals.TAUX_CONFORME_NOTBAD && value <= myGlobals.TAUX_CONFORME_GOOD){
        this.gaugeForegroundColorElecteur = this.yellowColor;
      }
      if (value >= myGlobals.TAUX_CONFORME_GOOD){
        this.gaugeForegroundColorElecteur = this.greenColor;
      }
      if (value < myGlobals.TAUX_CONFORME_NOTBAD){
        this.gaugeForegroundColorElecteur = this.redColor;
      }
    } else {
      this.gaugeValueElecteur = 0;
    }
  }
  getgaugeColorMission = (value: number) => {
    if (!isNaN(value)) {
      if (value >= myGlobals.TAUX_CONFORME_NOTBAD && value <= myGlobals.TAUX_CONFORME_GOOD) {
        this.gaugeForegroundColorMission = this.yellowColor;
      }
      if (value >= myGlobals.TAUX_CONFORME_GOOD) {
        this.gaugeForegroundColorMission = this.greenColor;
      }
      if (value < myGlobals.TAUX_CONFORME_NOTBAD) {
        this.gaugeForegroundColorMission = this.redColor;
      }
    } else {
      this.gaugeValueMission = 0;
    }
  }
  loadCharts = () => {
    this.doughnutChartData = [this.totalMissionTerminees, this.totalMissionEnCours, this.totalMissionNotStart];
  }
  /** Fin Comites */
  getAllNumbersByComite = (comiteBaseId: string) => {
    this.svceComite.getAllNumbersByComite(comiteBaseId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.dashboard = res['donnees'][0] as Dashboradcl;
          this.totalMaxElecteur = this.dashboard.nbreML;
          this.totalMission = this.dashboard.totalMission;
          this.totalMissionEnCours = this.dashboard.totalMissionEnCours;
          this.totalMissionNotStart = this.dashboard.totalMissionNotStart;
          this.totalMissionTerminees = this.dashboard.totalMissionTerminees;
          this.loadCharts();
          this.gaugeValueElecteur = Number(((this.dashboard.nbreML / this.paramMaxMembreNumber) * 100).toFixed(2));
          this.getgaugeColorElecteur(this.gaugeValueElecteur);
          this.gaugeValueMission = Number(((this.dashboard.totalMissionEffectuee / this.dashboard.totalMission) * 100).toFixed(2));
          this.getgaugeColorMission(this.gaugeValueMission);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMissionsByComite = (comiteBaseId: string) => {
    this.svceComite.getAllMissionsByComite(comiteBaseId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMissions = res['donnees'];
        } else {
          this.lstMissions = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllNumbersBySection = (sectionId: string) => {
    this.svceSection.getAllNumbersBySection(sectionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.dashboard = res['donnees'][0] as Dashboradcl;
          this.totalMaxElecteur = this.dashboard.nbreML;
          this.totalMission = this.dashboard.totalMission;
          this.totalMissionEnCours = this.dashboard.totalMissionEnCours;
          this.totalMissionNotStart = this.dashboard.totalMissionNotStart;
          this.totalMissionTerminees = this.dashboard.totalMissionTerminees;
          this.loadCharts();
          this.gaugeValueElecteur = Number(((this.dashboard.nbreML / this.paramMaxMembreNumber) * 100).toFixed(2));
          this.getgaugeColorElecteur(this.gaugeValueElecteur);
          this.gaugeValueMission = Number(((this.dashboard.totalMissionEffectuee / this.dashboard.totalMission) * 100).toFixed(2));
          this.getgaugeColorMission(this.gaugeValueMission);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMissionsBySection = (sectionId: string) => {
    this.svceSection.getAllMissionsBySection(sectionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMissions = res['donnees'];
        } else {
          this.lstMissions = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
