<section class="content home">
  <div class="block-header">
      <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12">
              <h2>Details membre <small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 text-right">
              <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
                <li class="breadcrumb-item">Accueil</li>
                  <li class="breadcrumb-item active">Details membre</li>
              </ul>
          </div>
      </div>
  </div>
  <div class="container-fluid">
      <div class="row clearfix">
        <!-- <form id="infoMembre" #infoMembre="ngForm" action="post"> -->

        <div class="col-sm-6">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="header">
                    <h2><strong>Electeurs</strong></h2>
                    <ul class="header-dropdown">
                        <li class="remove">
                            <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                        </li>
                    </ul>
                </div>
                <div class="body">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <mat-form-field class="example-full-width">
                          <mat-label>Carte CNI</mat-label>
                          <input matInput [(ngModel)]="membre.numeroCNI" name="numeroCNI" placeholder="Carte CNI"  #cni="ngModel">
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <mat-form-field>
                          <mat-select  [disabled] = disabledForm [(ngModel)] = "membre.communeId" name="communeId" placeholder="Communes / SP" #singleCommuneSelect>
                            <mat-option>
                              <ngx-mat-select-search ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of lstCommuneSP.selected" [value]="item.sousPrefectureId">
                              {{item.libelle}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <mat-form-field class="example-full-width">
                          <mat-label>Lieu de vote</mat-label>
                          <input matInput [disabled] = disabledForm [(ngModel)]="membre.lieuVoteId" name="lieuVoteId" placeholder="Lieu de vote" required>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <mat-form-field class="example-full-width">
                          <mat-label>Numéro électeur</mat-label>
                          <input matInput [disabled] = disabledForm [(ngModel)]="membre.numeroElecteur" name="numeroElecteur" placeholder="Numéro électeur" required>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
                <div class="card">
                  <div class="header">
                      <h2><strong>Parents</strong></h2>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-lg-12">
                        <fieldset>
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Nom du père</mat-label>
                                  <input matInput [disabled] = disabledForm [(ngModel)]="membre.nomPere" name="nomPere" placeholder="Nom du père" required>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Prénom(s) du père</mat-label>
                                  <input matInput [disabled] = disabledForm [(ngModel)]="membre.prenomPere" name="prenomPere" placeholder="Prénom(s) du père" required>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Date de naissance du père</mat-label>
                                  <input matInput [disabled] = disabledForm [(ngModel)]="membre.dateNaissancePere" name="dateNaissancePere" placeholder="Date de naissance du père" required>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Lieu de naissance du père</mat-label>
                                  <input matInput [disabled] = disabledForm [(ngModel)]="membre.lieuNaissancePere" name="lieuNaissancePere" placeholder="Lieu de naissance du père" required>
                                </mat-form-field>
                              </div>
                            </div>
                            <hr/>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Nom de la mère</mat-label>
                                  <input matInput [disabled] = disabledForm [(ngModel)]="membre.nomMere" name="nomMere" placeholder="Nom de la mère" required>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Prénom(s) de la mère</mat-label>
                                  <input matInput [disabled] = disabledForm [(ngModel)]="membre.prenomMere" name="prenomMere" placeholder="Prénom(s) de la mère" required>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Date de naissance</mat-label>
                                  <input matInput [disabled] = disabledForm [(ngModel)]="membre.dateNaissanceMere" name="dateNaissanceMere" placeholder="Date de naissance" required>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Lieu de naissance</mat-label>
                                  <input matInput [disabled] = disabledForm [(ngModel)]="membre.lieuNaissanceMere" name="lieuNaissanceMere" placeholder="Lieu de naissance" required>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="header">
              <h2><strong>Membre</strong></h2>
              <ul class="header-dropdown">
                  <li class="remove">
                      <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                  </li>
              </ul>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <mat-form-field class="example-full-width">
                    <mat-label>Nom</mat-label>
                    <input matInput [disabled] = disabledForm [(ngModel)]="membre.nom" name="nom" placeholder="Nom" required>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <mat-form-field class="example-full-width">
                    <mat-label>Prénom(s)</mat-label>
                    <input matInput [disabled] = disabledForm [(ngModel)]="membre.prenom" name="prenom" placeholder="Prénom(s)" required>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <mat-form-field class="example-full-width">
                    <mat-label>E-mail</mat-label>
                    <input matInput [(ngModel)]="membre.email" name="email" placeholder="E-mail"  #email="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <mat-form-field class="example-full-width">
                    <mat-label>Contact</mat-label>
                    <input matInput mask="00-00-00-00-00" [showMaskTyped]="true" [dropSpecialCharacters]="true" [(ngModel)]="membre.contact" name="Contact" placeholder="Contact" required #contact="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <mat-form-field>
                    <mat-select  [disabled] = disabledForm [(ngModel)] = "membre.sex" name="sex" placeholder="Sexe" #singleCommuneSelect>
                      <mat-option>
                        <ngx-mat-select-search ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option value="M">Masculin</mat-option>
                      <mat-option value="F">Feminin</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <mat-form-field class="example-full-width">
                    <mat-label>Lieu de naissancee</mat-label>
                    <input matInput [disabled] = disabledForm [(ngModel)]="membre.lieuNaissance" name="lieuNaissance" placeholder="Lieu de naissance" required>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <mat-form-field class="example-full-width">
                    <mat-label>Date de naissance</mat-label>
                    <input matInput [disabled] = disabledForm [(ngModel)]="membre.dateNaissance" name="dateNaissance" placeholder="Date de naissance" required>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <mat-form-field class="example-full-width">
                    <mat-label>Profession</mat-label>
                    <input matInput [disabled] = disabledForm [(ngModel)]="membre.profession" name="Profession" placeholder="Profession" required>
                  </mat-form-field>
                </div>
              </div>
             <!-- <div class="col-sm-6">
                <div class="form-group">
                  <mat-form-field class="example-full-width">
                    <mat-label>Lieu de naissance</mat-label>
                    <input matInput [disabled] = disabledForm [(ngModel)]="membre.lieuNaissance" name="lieuNaissance" placeholder="Lieu de naissancee" required>
                  </mat-form-field>
                </div>
              </div>-->
              <div class="col-sm-6">
                <div class="form-group">
                  <mat-form-field class="example-full-width">
                    <mat-label>Adresse postale</mat-label>
                    <input matInput [disabled] = disabledForm [(ngModel)]="membre.adressePostale" name="adressePostale" placeholder="Adresse postale" required>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-12">
                <label><small>Autres informations</small></label>
                <div class="form-group">
                  <textarea class="form-control"></textarea>
                </div>
              </div>
              <div class="col-sm-12 text-left">
                <div class="footer text-right">
                  <button class="btn btn-default waves-effect m-r-20">ANNULER</button>
                  <button mat-button [disabled] = "contact.invalid || email.invalid || cni.invalid ? true: false" (click)="onAddMembre(membre)" class="btn btn-primary waves-effect m-r-20">VALIDER</button>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
        <!-- </form> -->
      </div>
  </div>
</section>
