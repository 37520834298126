import { DetailsmembresectionComponent } from '../../Modals/detailsmembresection/detailsmembresection.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {Cordinateurcl} from '../../Models/cordinateurcl.model';
import {SelectionModel} from '@angular/cdk/collections';
import {Regioncl} from '../../Models/regioncl.model';
import {Departementcl} from '../../Models/departementcl.model';
import {Sousprefecturecl} from '../../Models/sousprefecturecl.model';
import {Sectioncl} from '../../Models/sectioncl.model';
import {FormControl} from '@angular/forms';
import {RegionService} from '../../Services/region.service';
import {DepartementService} from '../../Services/departement.service';
import {Router} from '@angular/router';
import {SousprefectureService} from '../../Services/sousprefecture.service';
import {SectionService} from '../../Services/section.service';
import {CordinateurService} from '../../Services/cordinateur.service';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {DetailsmissionmodalComponent} from '../../Modals/detailsmissionmodal/detailsmissionmodal.component';
import {Comitecl} from '../../Models/comitecl.model';
import {ServiceComponent} from '../../Modals/service/service.component';
import {DetailscomitemodalComponent} from '../../Modals/detailscomitemodal/detailscomitemodal.component';
import {SectionmodalComponent} from '../../Modals/sectionmodal/sectionmodal.component';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {InformationmodalComponent} from '../../Modals/informationmodal/informationmodal.component';
import {DetailsmilitantsmodalComponent} from '../../Modals/detailsmilitantsmodal/detailsmilitantsmodal.component';
import {ConfirmationmodalComponent} from '../../Modals/confirmationmodal/confirmationmodal.component';
import {ComiteService} from '../../Services/comite.service';
import {StaffMemberModalComponent} from '../../Modals/staff-member-modal/staff-member-modal.component';

@Component({
  selector: 'app-liste-sectionfull',
  templateUrl: './liste-sectionfull.component.html',
  styleUrls: ['./liste-sectionfull.component.css']
})
export class ListeSectionfullComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  comite = new Comitecl();
  public lstComite: Comitecl[];
  private dialogConfig;
  private index: number;
  public ELEMENT_DATA: Cordinateurcl[] = [];
  tableDataSource = new MatTableDataSource<Cordinateurcl>();
  public displayedColumns = ['Section', 'Commune', 'Coordinateur', 'Phone', 'NbreCB' , 'NbreML' , 'NbreMI',  'Actions' ];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  lstCordinateursCheck = new SelectionModel<Cordinateurcl>(true, []);
  /*Choix des listes contenant les filtres*/
  lstRegions = new SelectionModel<Regioncl>(true, []);
  lstDeps = new SelectionModel<Departementcl>(true, []);
  lstDepsTrie = new SelectionModel<Departementcl>(true, []);
  lstCommuneSP = new SelectionModel<Sousprefecturecl>(true, []);
  lstCommuneSPTrie = new SelectionModel<Sousprefecturecl>(true, []);
  lstSections = new SelectionModel<Sectioncl>(true, []);
  lstSectionsTrie = new SelectionModel<Sectioncl>(true, []);
  /* Gestion des filtres */
  public selectControlRegion: FormControl = new FormControl();
  public selectFilterCtrlRegion: FormControl = new FormControl();
  /** Départements */
  public selectControlDep: FormControl = new FormControl();
  public selectFilterCtrlDep: FormControl = new FormControl();
  /** Sections */
  public selectControlCommune: FormControl = new FormControl();
  public selectFilterCtrlCommune: FormControl = new FormControl();
  /** Sections */
  public selectControlSection: FormControl = new FormControl();
  public selectFilterCtrlSection: FormControl = new FormControl();
  section = new Sectioncl();
  constructor(private svceRegion: RegionService, private svceDep: DepartementService, private router: Router, private svceSp: SousprefectureService, private svceSection: SectionService, private svceComite: ComiteService, private svceCordi: CordinateurService, private dialog: MatDialog, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.lstComite = [];
    this.getAllCordinateurs();
    this.getAllRegion();
    this.getAllDep();
    this.getAllComSp();
    this.getAllSec();
    this.getAllComites();
  }
  /**Récuperation des cordinateurs du système */
  getAllCordinateurs = () => {
    this.svceCordi.getAllCordinateurs().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**
   * Récuperation des cordinateurs du système selon la Sp
   * @param sousprefectureId
   */
  getAllCordinateursBy = (sousprefectureId: string) => {
    this.svceCordi.getAllCordinateursBy(sousprefectureId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected = () => {
    const numSelected = this.lstCordinateursCheck.selected.length;
    const numRows = this.tableDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle = () => {
    this.isAllSelected() ?
      this.lstCordinateursCheck.clear() :
      this.tableDataSource.data.forEach(row => this.lstCordinateursCheck.select(row as Cordinateurcl));
  }

  /** Gestion des listes pour le filtre
   /**Regions */
  getAllRegion = () => {
    this.svceRegion.getAllRegion().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstRegions.clear();
          res['donnees'].forEach(element => {
            this.lstRegions.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**Fin Region */
  /**Departement */
  getAllDep = () => {
    this.svceDep.getAllDepartement().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstDeps.clear();
          res['donnees'].forEach(element => {
            this.lstDeps.select(element);
            this.lstDepsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieDep = (RegionId: string) => {
    this.lstDepsTrie.clear();
    this.lstDeps.selected.forEach(element => {
      if (element.regionId === RegionId)
      {
        this.lstDepsTrie.select(element);
      }
    });

    if (this.lstDepsTrie.selected.length <= 0)
    {
      this.lstDeps.selected.forEach(element => {
        this.lstDepsTrie.select(element);
      });
    }
  }
  /**Fin departement */
  /**Commune */
  getAllComSp = () => {
    this.svceSp.getAllCommuneSp().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstCommuneSP.clear();
          res['donnees'].forEach(element => {
            this.lstCommuneSP.select(element);
            this.lstCommuneSPTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieCommune = (DepartementId: string) => {
    this.lstCommuneSPTrie.clear();
    this.lstCommuneSP.selected.forEach(element => {
      if (element.departementId === DepartementId)
      {
        this.lstCommuneSPTrie.select(element);
      }
    });

    if (this.lstCommuneSPTrie.selected.length <= 0)
    {
      this.lstCommuneSP.selected.forEach(element => {
        this.lstCommuneSPTrie.select(element);
      });
    }
  }
  /** Fin Commune */
  /** Section */
  getAllSec = () => {
    this.svceSection.getAllSections().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstSections.clear();
          res['donnees'].forEach(element => {
            this.lstSections.select(element);
            this.lstSectionsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieSection = (sousprefectureId: string) => {
    this.lstSectionsTrie.clear();
    this.lstSections.selected.forEach(element => {
      if (element.communeId === sousprefectureId)
      {
        this.lstSectionsTrie.select(element);
      }
    });

    if (this.lstSectionsTrie.selected.length <= 0)
    {
      this.lstSections.selected.forEach(element => {
        this.lstSectionsTrie.select(element);
      });
    }
  }
  cleanFielsd = () => {
    this.section = new Sectioncl();
    this.section.actionType = 'add';
    this.section.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.section.code = '';
    this.section.libelle = '';
    this.section.sectionId = '';
  }
  openModalSection = (section: Sectioncl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        section
      }
    };
    const dialogRef = this.dialog.open(SectionmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.getAllCordinateurs();
      }
    });
  }
  openModalComite = (comite: Comitecl, sousPrefectureId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        comite
      }
    };
    const dialogRef = this.dialog.open(ServiceComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.getAllComites();
        this.getAllCordinateurs();
        this.openConfirmationDialog(comite.comiteBaseId, comite.libelle);
      }
    });
  }
  openConfirmationDialog = (blocId: string, libelle: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': ' Voulez-vous enregistrer des militants pour ce comité de base ?'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        /*console.log('Good');
        console.log('Lenght : ' + Object.keys(this.lstComite).length);
        // console.log(' Last Comite de Base ID : ' + this.lstComite[Object.keys(this.lstComite).length].comiteBaseId);
        this.index = Object.keys(this.lstComite).length;
        console.log(this.index);*/
        // console.log(this.lstComite.pop());
        this.getAllComites();
        const cmt: Comitecl = this.lstComite.slice(-1)[0];
        const lastComiteBaseId: string = cmt.comiteBaseId;
        console.log(' Last Comite de Base ID : ' + lastComiteBaseId);
        this.openListMilitant(lastComiteBaseId, libelle);
      }
      else {}
    });
  }
  openListMilitant = (blocId: string, libelle: string) => {
    console.log('Bloc' + blocId);
    console.log('Com' + libelle);
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        'blocId': blocId,
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DetailsmilitantsmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      } else {
        this.getAllCordinateurs();
        console.log('load data');
      }
    });
  }
  addComiteBase = (blocId: string, sousPrefectureId: string, NbreCB: number) => {
    if (NbreCB >= myGlobals.NBRE_MAX_CB)
    {
      console.log('Voir Admin');
      this.dialogConfig = {
        width: '500px',
        disableClose: true,
        position: {top: '10%'},
        data: {
          // tslint:disable-next-line:max-line-length
          'libelle': 'Merci de bien vouloir contacter l\'administrateur car vous avez atteint le nombre maximal de comité de base (' + myGlobals.NBRE_MAX_CB + ')'
        }
      };
      const dialogRef = this.dialog.open(InformationmodalComponent,
        this.dialogConfig
      );
      dialogRef.afterClosed().subscribe(result =>
      {
        if (result)
        {
          console.log('Good');
        }
      });
    } else {
      console.log(blocId);
      this.comite.sectionId = blocId;
      this.comite.actionType = 'add';
      this.comite.code = '';
      this.comite.libelle = '';
      this.comite.comiteBaseId = '';
      this.comite.NbreCB = NbreCB;
      this.comite.createdBy = localStorage.getItem('USER_CONNECTED_ID');
      this.openModalComite(this.comite, sousPrefectureId);
    }
  }
  openDetailsComite = (acteur: Cordinateurcl) => {
    console.log(acteur);
    this.dialogConfig = {
      width: '800px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        acteur
      }
    };
    const dialogRef = this.dialog.open(DetailscomitemodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
    });
  }
  openDetailsMission = (acteur: Cordinateurcl) => {
    console.log(acteur);
    this.dialogConfig = {
      width: '800px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        acteur
      }
    };
    const dialogRef = this.dialog.open(DetailsmissionmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
    });
  }
  /**Fin affectaion */
  openDetailsMembre = (section: Sectioncl) => {
    // console.log(section);
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        section
      }
    };
    const dialogRef = this.dialog.open(DetailsmembresectionComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      } else {
        this.getAllCordinateurs();
      }
    });
  }
  getAllComites = () => {
    this.svceComite.getAllComiteBases().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstComite = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getListStaff = (blocId: string, libelle: string) => {
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        'blocId': blocId,
        'libelle': 'Région ' + libelle
      }
    };
    const dialogRef = this.dialog.open(StaffMemberModalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result) {
        this.getAllCordinateurs();
      }
      else {
        // this.loadData();
      }
    });
  }
}
