import { Membrecl } from './../../Models/membrecl.model';
import { ToastrService } from 'ngx-toastr';
import { UtilisateursService } from './../../Services/utilisateurs.service';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Affectationmembrecl} from '../../Models/affectationmembrecl.model';

@Component({
  selector: 'app-founddetailsmilitant',
  templateUrl: './founddetailsmilitant.component.html',
  styleUrls: ['./founddetailsmilitant.component.css']
})
export class FounddetailsmilitantComponent implements OnInit {
  public membre = new Membrecl();
  public affectationMembre = new Affectationmembrecl();
  constructor(@Inject(MAT_DIALOG_DATA) public data: Membrecl, private toastr: ToastrService, public svceMembre: UtilisateursService, public dialogRef: MatDialogRef<FounddetailsmilitantComponent>) { }

  ngOnInit(): void {
  }
  close = (value: boolean) => {
    this.dialogRef.close(value);
  }
  onAddMembre = (membre: Membrecl) => {
    if (membre.actionType === 'parrain')
    {
      // repositionne l'action à ajouter pour l'ajout du nouveau membre mais qui n'est pas lié à un comité de base
      membre.actionType = 'add';
      this.addMembre(membre);
    } else {
      if (membre.MembreExisted > 0)
      {
        // Affectation seulement d'un membre à un comité de base
        this.affectationMembre.affectationMembreId = '';
        this.affectationMembre.comiteBaseId = membre.comiteBaseId;
        this.affectationMembre.membreId = membre.membreId;
        this.affectationMembre.actionType = 'add';
        this.affectationMembre.createdBy = localStorage.getItem('USER_CONNECTED_ID');
        console.log(this.affectationMembre);
        this.svceMembre.addAffectaionMembre(this.affectationMembre).subscribe(
          (res) => {
            console.log(res);
            this.toastr.success('Militant affecté avec succès', 'Message', {
              timeOut: 3000,
            });
            this.close(true);
          },
          (error) => {
            console.log(error);
            this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
              timeOut: 3000,
            });
          }
        );
      } else {
        // Ajout d'un nouvel utilisateur et affectation à un comité de base
        this.addMembre(membre);
      }
    }
  }
  addMembre = (membre: Membrecl) => {
    this.svceMembre.addMembre(membre).subscribe(
      (res) => {
        console.log(res);
        this.toastr.success('Militant ajouté avec succès', 'Message', {
          timeOut: 3000,
        });
        this.close(true);
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
}
