import {Component, OnInit, ViewChild} from '@angular/core';
import {Service} from '../../Models/service.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {Missioncl} from '../../Models/missioncl.model';
import {MissionService} from '../../Services/mission.service';
import {DeletealertComponent} from '../../Modals/deletealert/deletealert.component';
import {MissionmodalComponent} from '../../Modals/missionmodal/missionmodal.component';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.css']
})
export class MissionComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  private dialogConfig;
  mission = new Missioncl();
  service: Service;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Id', 'Libelle', 'checkList', 'comment', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private dialog: MatDialog, private svceMission: MissionService,  private toastr: ToastrService) { }

  ngOnInit(): void {
    this.mission.actionType = 'add';
    this.mission.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.getAllMissions();
  }
  getAllMissions = () => {
    this.svceMission.getAllMission().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  openModal = (mission: Missioncl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        mission
      }
    };
    const dialogRef = this.dialog.open(MissionmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.getAllMissions();
      }
    });
  }
  cleanFielsd = (mission: Missioncl) => {
    this.mission.actionType = 'add';
    this.mission.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.mission.checkList = 0;
    this.mission.commentActif = 0;
    this.mission.libelle = '';
    this.mission.missionId = '';
  }
  redirectToUpdate = (mission: Missioncl) => {
    mission.actionType = 'update';
    this.openModal(mission);
  }
  redirectToDelete = (missionId: string, libelle: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': missionId,
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DeletealertComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceMission.deleteMission(missionId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Mission supprimée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.getAllMissions();
            }
          }
        );
      }
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
}
