<h2 mat-dialog-title>SECTION</h2>
<mat-dialog-content class="mat-typography">
  <form id="sectionForm" #sectionForm="ngForm" action="post">
    <div class="row clearfix">
      <div class="col-sm-12">
        <div class="form-group">
          <mat-form-field appearance="fill">
            <!--<mat-label>Select me</mat-label>-->
            <mat-select class="form-control" required>
                <mat-option value="F">Féminin</mat-option>
                <mat-option value="M">Masculin</mat-option>
            </mat-select>
            <mat-hint align="end">Faire le choix du Comité</mat-hint>
        </mat-form-field>
        </div>
      </div>
      <div class="col-sm-12">
          <div class="form-group">
              <input type="text" class="form-control" placeholder="Libelle" required>
          </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Code" required>
        </div>
    </div>
      <div class="col-sm-12" align="end">
          <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>ANNULER</button>
          <button mat-button [disabled]="!sectionForm.form.valid" class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>VALIDER</button>
      </div>
  </div>
  </form>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end">
  <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>Cancel</button>
  <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
