<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Affectation Objectif</strong> </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
  <form id="affectationObjectifForm"  #affectationObjectifForm="ngForm" action="post">
    <div class="body">
    <div class="row clearfix">
      <div class="col-sm-12">
        <mat-form-field class="example-full-width">
          <mat-label>Libelle</mat-label>
          <input matInput value="{{data['libelleObjectif']}}" disabled name="objectifId" type="text" required>
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <mat-form-field>
            <mat-select [(ngModel)]="data['affectationObjectif'].departementId" name="departementId" class="" required placeholder="Departements" #singleSelect>
              <mat-option>
                <ngx-mat-select-search ></ngx-mat-select-search>
              </mat-option>
              <mat-option  *ngFor="let departement of lstDepartement" [value]="departement.departementId">
                {{departement.libelle}}
              </mat-option>
            </mat-select>
          </mat-form-field>
         <!-- <mat-form-field appearance="fill">
            <mat-label>Communes / Sous-préfectures</mat-label>
            <mat-select [(ngModel)]="data['affectationObjectif'].sousPrefectureId" name="sousPrefectureId" class="form-control" required>
              <mat-option *ngFor="let commune of lstSousPrefecture" value="{{commune.sousPrefectureId}}">{{commune.libelle}}</mat-option>
            </mat-select>
          </mat-form-field>-->
        </div>
      </div>
      <div class="col-sm-12">
        <mat-form-field class="example-full-width">
          <mat-label>Valeur</mat-label>
          <input matInput [(ngModel)]="data['affectationObjectif'].valeur" name="valeur" type="number"  required>
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <mat-form-field>
          <input matInput [matDatepicker] = "picker" [(ngModel)]="data['affectationObjectif'].deadline" [min]="this.minDate"  name="deadline" placeholder = "Date Limite" required>
          <mat-datepicker-toggle matSuffix [for] = "picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
       <!-- <div class="form-group">
          <input  [(ngModel)]="data['affectationObjectif'].deadline" name="deadline" type="date" class="form-control" placeholder="Date Limite" required>
        </div>-->
      </div>
      <div class="col-sm-12">
        <label><small>Commentaires</small></label>
        <div class="form-group">
          <textarea [(ngModel)]="data['affectationObjectif'].comment" name="comment" class="form-control"></textarea>
        </div>
      </div>
      <div class="col-sm-12" align="end">
        <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close()" >ANNULER</button>
        <button mat-button [disabled]="!affectationObjectifForm.form.valid" class="btn btn-primary waves-effect m-r-20" (click)="onAdd(data['affectationObjectif']); this.affectationObjectifForm.form.reset();" cdkFocusInitial>VALIDER</button>
      </div>
    </div>
    </div>
  </form>
    </div>
  </div>
</div>

<!-- <mat-dialog-actions align="end">
  <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>Cancel</button>
  <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
