<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Mission</strong> </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
  <form id="missionForm" #missionForm="ngForm" action="post">
    <div class="body">
    <div class="row clearfix">
      <div class="col-sm-12">
        <mat-form-field class="example-full-width">
          <mat-label>Libelle</mat-label>
          <input matInput [(ngModel)]="data['mission'].libelle" name="libelle" placeholder="Libelle" required>
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <mat-checkbox class=""   [checked]="data['mission'].checkList == 1 ? true : false" [value]="data['mission'].checkList " (change)="onCheckboxChange($event)" name="checkList">Cette mission necessite une liste des tâches après exécution ?</mat-checkbox>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <mat-checkbox class=""  [checked]="data['mission'].commentActif == 1 ? true : false" [value]="data['mission'].commentActif " (change)="onCommentChange($event)" name="comment">Cette mission necessite des commentaires ?</mat-checkbox>
        </div>
      </div>
      <div class="col-sm-12" align="end">
        <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close()" >ANNULER</button>
        <button mat-button [disabled]="!missionForm.form.valid" class="btn btn-primary waves-effect m-r-20" (click)="onAdd(data['mission'])" cdkFocusInitial>VALIDER</button>
      </div>
    </div>
    </div>
  </form>
    </div>
  </div>
</div>
<!-- <mat-dialog-actions align="end">
  <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>Cancel</button>
  <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
