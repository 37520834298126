import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { RoleEquipeCl } from '../Models/roleequipe.model';

@Injectable({
  providedIn: 'root'
})
export class RoleequipeService {

  URL_API_LOCAL = myGlobals.API_REST_URL + 'coRoleequipe.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  getAllRoleEquipe = (): Observable<RoleEquipeCl[]> => {
    return this.httpQuery.get<RoleEquipeCl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION, { headers: this.headers })
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllRoleEquipeBy = (blocId): Observable<RoleEquipeCl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('roleequipeId', blocId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.FILTRE_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  addRoleEquipe = (roleequipe: RoleEquipeCl): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('roleequipeId', roleequipe.roleequipeId);
    FORMDATA.append('libelle', roleequipe.libelle);
    FORMDATA.append('code', roleequipe.code);
    FORMDATA.append('description', roleequipe.description);
    FORMDATA.append('createdBy', roleequipe.createdBy);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + (roleequipe.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION), FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteRoleEquipe = (RoleEquipeId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('roleequipeId', RoleEquipeId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.DELETEBY_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
}
