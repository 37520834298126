import { AffectationmissionService } from './../../Services/affectationmission.service';
import { AddactiviteComponent } from './../addactivite/addactivite.component';
import { Affectationmissioncl } from './../../Models/affectationmissioncl';
import { Missioncl } from './../../Models/missioncl.model';
import { MissionService } from './../../Services/mission.service';
import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {ComiteService} from '../../Services/comite.service';
import {SectionService} from '../../Services/section.service';

@Component({
  selector: 'app-affectationmision',
  templateUrl: './affectationmision.component.html',
  styleUrls: ['./affectationmision.component.css']
})
export class AffectationmisionComponent implements OnInit {
  /* Gestion des filtres */
  lstMissions = new SelectionModel<Missioncl>(true, []);
  minDate: Date = new Date();
  public selectControlMission: FormControl = new FormControl();
  public selectFilterCtrlMission: FormControl = new FormControl();
  public affectationmission = new Affectationmissioncl();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toastr: ToastrService, private svceMission: MissionService, private svceSection: SectionService,  private svceComiteBase: ComiteService,  private svceAffMission: AffectationmissionService, public dialogRef: MatDialogRef<AffectationmisionComponent>) { }

  ngOnInit(): void {
    this.affectationmission.type = this.data.type;
    this.affectationmission.blocId = this.data.blocId;
    this.affectationmission.etat = 0;
    this.affectationmission.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.affectationmission.campagneId = localStorage.getItem('USER_CAMPAGNE_ID');
    this.refresh();
  }
  refresh = () => {
    if (this.data.type == 4){
      this.getAllMissionBySection();
    } else {
      this.getAllMissionByComite();
    }
  }
  getAllMissionBySection = () => {
    this.svceSection.getAllMissionBySection(this.data.blocId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMissions.clear();
          res['donnees'].forEach(element => {
            this.lstMissions.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMissionByComite = () => {
    this.svceComiteBase.getAllMissionByComite(this.data.blocId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMissions.clear();
          res['donnees'].forEach(element => {
            this.lstMissions.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMission = () => {
    this.svceMission.getAllMission().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMissions.clear();
          res['donnees'].forEach(element => {
            this.lstMissions.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  close = () => {
    this.dialogRef.close(true);
  }
  onAdd = () => {
    this.svceAffMission.addAffectation(this.affectationmission).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.toastr.success('Mission affectée avec succès', 'Message', {
            timeOut: 3000,
          });
          this.dialogRef.close(true);
        }
        else{
          if (res !== null && res['status']){
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
}
