import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Missioncl} from '../../Models/missioncl.model';
import {ToastrService} from 'ngx-toastr';
import {MissionService} from '../../Services/mission.service';
import {Particl} from '../../Models/particl.model';
import {PartiService} from '../../Services/parti.service';

@Component({
  selector: 'app-partimodal',
  templateUrl: './partimodal.component.html',
  styleUrls: ['./partimodal.component.css']
})
export class PartimodalComponent implements OnInit {
  private checkMutipleCandidat: number;
  private checkPrincipal: number;
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: Particl, private toastr: ToastrService, public svceParti: PartiService, public dialogRef: MatDialogRef<PartimodalComponent>) { }

  ngOnInit(): void {
  }
  close = () => {
    this.dialogRef.close(false);
  }
  crudSection = (parti: Particl, type: string) => {
    this.svceParti.addParti(parti).subscribe(
      (res) => {
        switch (type) {
          case 'add':
            this.toastr.success('Parti inséré avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
          case 'update':
            this.toastr.success('Parti modifié avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
        }
        this.dialogRef.close(true);
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  onMutipleCandidatChange = (e) => {
    if (e.checked){
      this.checkMutipleCandidat = 1;
    } else {
      this.checkMutipleCandidat = 0;
    }
  }
  onPrincipalChange = (e) => {
    if (e.checked){
      this.checkPrincipal = 1;
    } else {
      this.checkPrincipal = 0;
    }
  }
  onAdd = (parti: Particl) => {
    if (this.checkMutipleCandidat != null){
      parti.mutipleCandidat = this.checkMutipleCandidat;
    }
    if (this.checkPrincipal != null){
      parti.principal = this.checkPrincipal;
    }
    switch (parti.actionType){
      case 'add':
        this.crudSection(parti, parti.actionType);
        break;
      case 'update':
        this.crudSection(parti, parti.actionType);
        break;
      default:
        break;
    }
  }
}
