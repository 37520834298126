import { AddactiviteComponent } from './../../Modals/addactivite/addactivite.component';
import { Activite } from './../../Models/activite.model';
import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-activite',
  templateUrl: './activite.component.html',
  styleUrls: ['./activite.component.css']
})
export class ActiviteComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  private dialogConfig;
  activite = new Activite();
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }
  openModal = (activite: any) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        activite
      }
    };
    const dialogRef = this.dialog.open(AddactiviteComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
      {
          if (result)
          {
              switch (result.ActionType){
                  case 'add':
                      this.onAdd(result);
                      break;
                  case 'update':
                      this.onUpdate(result);
                      break;
                  default:
                      break;
              }
          }
      });
  }
  onAdd = (activite: any) => {};
  onUpdate = (activite: any) => {};
  onDelete = () => {};
}
