import { ISlimScrollOptions } from './Models/islim-scroll-options.model';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import {HttpClient, HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxGaugeModule } from 'ngx-gauge';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { MatTableModule  } from '@angular/material/table';
import { MatSortModule  } from '@angular/material/sort';
import { MatPaginatorModule  } from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';

import { CookieService } from 'ngx-cookie-service';
import {MatFormFieldModule} from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CountdownModule } from 'ngx-countdown';


import { LoginComponent } from './Auth/login/login.component';
import { ForgotComponent } from './Auth/forgot/forgot.component';
import { SingupComponent } from './Auth/singup/singup.component';
import { MasterComponent } from './Layout/master/master.component';
import { LeftnavComponent } from './Layout/leftnav/leftnav.component';
import { NavComponent } from './Layout/nav/nav.component';
import { FooterComponent } from './Layout/footer/footer.component';
import { DashboardComponent } from './Views/dashboard/dashboard.component';
import { UsersComponent } from './Views/users/users.component';
import { StaffComponent } from './Views/staff/staff.component';
import { DepartementsComponent } from './Views/departements/departements.component';
import { PrestationsComponent } from './Views/prestations/prestations.component';
import { NotfoundComponent } from './404/notfound/notfound.component';
import { ActiviteComponent } from './Views/activite/activite.component';
import { UtilisateurComponent } from './Modals/utilisateur/utilisateur.component';
import { ServiceComponent } from './Modals/service/service.component';
import { AddactiviteComponent } from './Modals/addactivite/addactivite.component';
import { AffectationComponent } from './Views/affectation/affectation.component';
import { ProfileComponent } from './Views/profile/profile.component';
import { AnimateurComponent } from './Views/animateur/animateur.component';
import { CordinateurComponent } from './Views/cordinateur/cordinateur.component';
import { DetailsanimateurComponent } from './Modals/detailsanimateur/detailsanimateur.component';
import { SectionComponent } from './Views/section/section.component';
import { SectionmodalComponent } from './Modals/sectionmodal/sectionmodal.component';
import { StudentComponent } from './Layout/student/student.component';
import { DeletealertComponent } from './Modals/deletealert/deletealert.component';
import { MissionComponent } from './Views/mission/mission.component';
import { MissionmodalComponent } from './Modals/missionmodal/missionmodal.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { DetailsmembreComponent } from './Views/detailsmembre/detailsmembre.component';
import { NewmembreComponent } from './Views/newmembre/newmembre.component';
import { ListemembreComponent } from './Views/listemembre/listemembre.component';
import { ObjectifComponent } from './Views/objectif/objectif.component';
import { ObjectifmodalComponent } from './Modals/objectifmodal/objectifmodal.component';
import { CampagneComponent } from './Views/campagne/campagne.component';
import { CampagnemodalComponent } from './Modals/campagnemodal/campagnemodal.component';
import { ManagementdashComponent } from './Views/managementdash/managementdash.component';
import { DetailsmissionComponent } from './Views/detailsmission/detailsmission.component';
import { AffectationmissionComponent } from './Modals/affectationmission/affectationmission.component';
import { ListeSectionfullComponent } from './Views/liste-sectionfull/liste-sectionfull.component';
import { ListecomitefullComponent } from './Views/listecomitefull/listecomitefull.component';
import { AffectationmisionComponent } from './Modals/affectationmision/affectationmision.component';
import { AffectationobjectifmodalComponent } from './Modals/affectationobjectifmodal/affectationobjectifmodal.component';
import { DetailsmissionmodalComponent } from './Modals/detailsmissionmodal/detailsmissionmodal.component';
import { RechercheMembreComponent } from './Modals/recherche-membre/recherche-membre.component';
import { DetailsmilitantsmodalComponent } from './Modals/detailsmilitantsmodal/detailsmilitantsmodal.component';
import { DeconexionComponent } from './Modals/deconexion/deconexion.component';
import { DetailscomitemodalComponent } from './Modals/detailscomitemodal/detailscomitemodal.component';
import { FounddetailsmilitantComponent } from './Modals/founddetailsmilitant/founddetailsmilitant.component';
import { AffectationconfirmationComponent } from './Modals/affectationconfirmation/affectationconfirmation.component';
import { DetailsmembresectionComponent } from './Modals/detailsmembresection/detailsmembresection.component';
import { DetailsobjectifsperdepartementComponent } from './Modals/detailsobjectifsperdepartement/detailsobjectifsperdepartement.component';
import { AnimateurleftnavComponent } from './Layout/animateurleftnav/animateurleftnav.component';
import { CordinateurleftbarComponent } from './Layout/cordinateurleftbar/cordinateurleftbar.component';
import { ConfirmationmodalComponent } from './Modals/confirmationmodal/confirmationmodal.component';
import { InformationmodalComponent } from './Modals/informationmodal/informationmodal.component';
import { ChecklistmodalComponent } from './Modals/checklistmodal/checklistmodal.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ListeregionsComponent } from './Views/listeregions/listeregions.component';
import { ListedepartementsComponent } from './Views/listedepartements/listedepartements.component';
import { ListecommunesComponent } from './Views/listecommunes/listecommunes.component';
import { RecherchemembredateComponent } from './Modals/recherchemembredate/recherchemembredate.component';
import { GetmilitantaffectionComponent } from './Modals/getmilitantaffection/getmilitantaffection.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AddcommentmodalComponent } from './Modals/addcommentmodal/addcommentmodal.component';
import { DetailsfilleulemodalComponent } from './Modals/detailsfilleulemodal/detailsfilleulemodal.component';
import { ListemilitantwocomiteComponent } from './Views/listemilitantwocomite/listemilitantwocomite.component';
import { ListemissionsComponent } from './Views/listemissions/listemissions.component';
import { TableaubordComponent } from './Views/tableaubord/tableaubord.component';
import { PartiComponent } from './Views/parti/parti.component';
import { PartimodalComponent } from './Modals/partimodal/partimodal.component';
import { AccesscampagnemodalComponent } from './Modals/accesscampagnemodal/accesscampagnemodal.component';
import { CheckpatiscampagneComponent } from './Modals/checkpatiscampagne/checkpatiscampagne.component';
import { ChoixcampagneComponent } from './Modals/choixcampagne/choixcampagne.component';
import { LeftnavRefontComponent } from './Layout/leftnav-refont/leftnav-refont.component';
import { RecensementComponent } from './Views/recensement/recensement.component';
import { MethodusedtoaddmembermodalComponent } from './Modals/methodusedtoaddmembermodal/methodusedtoaddmembermodal.component';
import { DetailsmembreregionComponent } from './Modals/detailsmembreregion/detailsmembreregion.component';
import { DetailsmembredepartementmodalComponent } from './Modals/detailsmembredepartementmodal/detailsmembredepartementmodal.component';
import { RoleequipeComponent } from './Views/roleequipe/roleequipe.component';
import { RoleEquipeModalComponent } from './Modals/role-equipe-modal/role-equipe-modal.component';
import { ListPostStaffModalComponent } from './Modals/list-post-staff-modal/list-post-staff-modal.component';
import { StaffMemberModalComponent } from './Modals/staff-member-modal/staff-member-modal.component';
import { ListesecretaireCommuneComponent } from './Views/listesecretaire-commune/listesecretaire-commune.component';
import { DetailsmembreCommuneComponent } from './Modals/detailsmembre-commune/detailsmembre-commune.component';
import { ListdistrictfullComponent } from './Views/listdistrictfull/listdistrictfull.component';
import { DetailsmembreDistrictComponent } from './Modals/detailsmembre-district/detailsmembre-district.component';
import {InterceptorService} from './Layout/loader/interceptor.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
const MaterialModule = [
  HttpClientModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  FormsModule,
  ReactiveFormsModule,
  MatDialogModule,
  MatSelectModule,
  MatFormFieldModule,
  ChartsModule,
  NgxMatSelectSearchModule,
  MatDatepickerModule,
  MatInputModule,
  MatNativeDateModule,
  NgSlimScrollModule,
  MatProgressSpinnerModule
];
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotComponent,
    SingupComponent,
    MasterComponent,
    LeftnavComponent,
    NavComponent,
    FooterComponent,
    DashboardComponent,
    UsersComponent,
    StaffComponent,
    DepartementsComponent,
    PrestationsComponent,
    NotfoundComponent,
    ActiviteComponent,
    UtilisateurComponent,
    ServiceComponent,
    AddactiviteComponent,
    AffectationComponent,
    ProfileComponent,
    AnimateurComponent,
    CordinateurComponent,
    DetailsanimateurComponent,
    SectionComponent,
    SectionmodalComponent,
    StudentComponent,
    DeletealertComponent,
    MissionComponent,
    MissionmodalComponent,
    DetailsmembreComponent,
    NewmembreComponent,
    ListemembreComponent,
    ObjectifComponent,
    ObjectifmodalComponent,
    CampagneComponent,
    CampagnemodalComponent,
    ManagementdashComponent,
    DetailsmissionComponent,
    AffectationmissionComponent,
    ListeSectionfullComponent,
    ListecomitefullComponent,
    AffectationmisionComponent,
    AffectationobjectifmodalComponent,
    DetailsmissionmodalComponent,
    RechercheMembreComponent,
    DetailsmilitantsmodalComponent,
    DeconexionComponent,
    DetailscomitemodalComponent,
    FounddetailsmilitantComponent,
    AffectationconfirmationComponent,
    DetailsmembresectionComponent,
    DetailsobjectifsperdepartementComponent,
    AnimateurleftnavComponent,
    CordinateurleftbarComponent,
    ConfirmationmodalComponent,
    InformationmodalComponent,
    ChecklistmodalComponent,
    ListeregionsComponent,
    ListedepartementsComponent,
    ListecommunesComponent,
    RecherchemembredateComponent,
    GetmilitantaffectionComponent,
    AddcommentmodalComponent,
    DetailsfilleulemodalComponent,
    ListemilitantwocomiteComponent,
    ListemissionsComponent,
    TableaubordComponent,
    PartiComponent,
    PartimodalComponent,
    AccesscampagnemodalComponent,
    CheckpatiscampagneComponent,
    ChoixcampagneComponent,
    LeftnavRefontComponent,
    RecensementComponent,
    MethodusedtoaddmembermodalComponent,
    DetailsmembreregionComponent,
    DetailsmembredepartementmodalComponent,
    RoleequipeComponent,
    RoleEquipeModalComponent,
    ListPostStaffModalComponent,
    StaffMemberModalComponent,
    ListesecretaireCommuneComponent,
    DetailsmembreCommuneComponent,
    ListdistrictfullComponent,
    DetailsmembreDistrictComponent,
  ],
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      ToastrModule.forRoot(),
      MDBBootstrapModule.forRoot(),
      MaterialModule,
      NgxGaugeModule,
      MatCheckboxModule,
      MatSlideToggleModule,
      NgxMaskModule.forRoot(maskConfigFunction),
      CountdownModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ CookieService, MatDatepickerModule,
    MatNativeDateModule,
    {
      provide: SLIMSCROLL_DEFAULTS,
      useValue: {
        alwaysVisible: false,
        gridOpacity: '0.2',
        barOpacity: '0.5',
        gridBackground: '#c2c2c2',
        gridWidth: '6',
        gridMargin: '2px 2px',
        barBackground: '#2C3E50',
        barWidth: '6',
        barMargin: '2px 2px'
      } as ISlimScrollOptions
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
