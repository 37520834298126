import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addactivite',
  templateUrl: './addactivite.component.html',
  styleUrls: ['./addactivite.component.css']
})
export class AddactiviteComponent implements OnInit {
  foodes = new Array<string>('Banane', 'Ananas', 'Citron', 'MAngue', 'Ble', 'Orange');
  foods = {
    'value': 'Aboubacar',
    'viewValue': 'Force'
  };
  constructor() { }

  ngOnInit(): void {
  }

}
