import { AffectationService } from './../../Services/affectation.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-deletealert',
  templateUrl: './deletealert.component.html',
  styleUrls: ['./deletealert.component.css']
})
export class DeletealertComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogRef: MatDialogRef<DeletealertComponent>) { }

  ngOnInit(): void {
    console.log(this.data);
  }
  close = (param: boolean) => {
    this.dialogRef.close(param);
  }

}
