import {Component, OnInit, ViewChild} from '@angular/core';
import {Sectioncl} from '../../Models/sectioncl.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {SectionService} from '../../Services/section.service';
import {ToastrService} from 'ngx-toastr';
import {SectionmodalComponent} from '../../Modals/sectionmodal/sectionmodal.component';
import {DeletealertComponent} from '../../Modals/deletealert/deletealert.component';
import {Objectifcl} from '../../Models/objectifcl.model';
import {ObjectifService} from '../../Services/objectif.service';
import {ObjectifmodalComponent} from '../../Modals/objectifmodal/objectifmodal.component';
import {AffectationobjectifmodalComponent} from '../../Modals/affectationobjectifmodal/affectationobjectifmodal.component';
import {AffectationObjectifcl} from '../../Models/affectation-objectifcl.model';
// tslint:disable-next-line:max-line-length
import {DetailsobjectifsperdepartementComponent} from '../../Modals/detailsobjectifsperdepartement/detailsobjectifsperdepartement.component';
import {AffectationobjectifService} from '../../Services/affectationobjectif.service';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-objectif',
  templateUrl: './objectif.component.html',
  styleUrls: ['./objectif.component.css']
})
export class ObjectifComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  objectif = new Objectifcl();
  private dialogConfig;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Id', 'Libelle', 'Code', 'Nbre', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private dialog: MatDialog, private svceObjectif: ObjectifService, private svceAffectationObjectif: AffectationobjectifService , private toastr: ToastrService) { }

  ngOnInit(): void {
    this.objectif.actionType = 'add';
    this.objectif.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.getAllObjectifs();
  }
  getAllObjectifs = () => {
    this.svceObjectif.getAllObjectif().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  openModal = (objectif: Objectifcl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        objectif
      }
    };
    const dialogRef = this.dialog.open(ObjectifmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.getAllObjectifs();
      }
    });
  }
  cleanFielsd = (objectif: Objectifcl) => {
    this.objectif = new Objectifcl();
    this.objectif.objectifId = '';
    this.objectif.actionType = 'add';
    this.objectif.createdBy = localStorage.getItem('USER_CONNECTED_ID');
  }
  redirectToUpdate = (objectif: Objectifcl) => {
    console.log(objectif);
    // this.objectif.actionType = 'update';
    objectif.actionType = 'update';
    this.openModal(objectif);
  }
  redirectToAffectationObjectif = (affectationObjectif: AffectationObjectifcl, objectif: Objectifcl) => {
    affectationObjectif = new AffectationObjectifcl();
    affectationObjectif.objectifId = objectif.objectifId;
    affectationObjectif.actionType = 'add';
    affectationObjectif.affectationobjectifId = '';
    affectationObjectif.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    affectationObjectif.campagneId = localStorage.getItem('USER_CAMPAGNE_ID');
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'affectationObjectif': affectationObjectif,
        'libelleObjectif': objectif.libelle
      }
    };
    const dialogRef = this.dialog.open(AffectationobjectifmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
      } else {
        this.getAllObjectifs();
      }
    });
  }
  redirectToDelete = (objectifId: string, libelle: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': objectifId,
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DeletealertComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceObjectif.deleteObjectif(objectifId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Objectif supprimé avec succès', 'Message', {
                timeOut: 3000,
              });
              this.getAllObjectifs();
            }
          }
        );
      }
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  openDetailsDepartement = (affectationObjectif: AffectationObjectifcl) => {
    console.log(affectationObjectif);
    this.dialogConfig = {
      width: '1000px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        affectationObjectif
      }
    };
    const dialogRef = this.dialog.open(DetailsobjectifsperdepartementComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
    });
  }
}
