<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Campagne</strong> </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
  <form id="campagneForm" #campagneForm="ngForm" action="post">
    <div class="body">
    <div class="row clearfix">
      <div class="col-sm-12">
        <div class="form-group">
          <!-- <input [(ngModel)]="data['section'].code" name="code" type="text" class="form-control" placeholder="Code" required> -->
          <mat-form-field class="example-full-width">
            <mat-label>Libelle</mat-label>
            <input matInput [(ngModel)]="data['campagne'].libelle" name="libelle" placeholder="Libelle" required>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="data['campagne'].scrutin" [matDatepicker] = "picker1" [min]="this.minDateScrutin"  name="scrutin" placeholder = "Date du scrutin" required>
            <mat-datepicker-toggle matSuffix [for] = "picker1"></mat-datepicker-toggle>
            <mat-datepicker  #picker1></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="data['campagne'].lancement" [matDatepicker] = "picker2" [min]="this.minDateScrutin" name="lancement" placeholder = "Date de lancement" required>
            <mat-datepicker-toggle matSuffix [for] = "picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <!-- <input [(ngModel)]="data['section'].code" name="code" type="text" class="form-control" placeholder="Code" required> -->
          <mat-form-field class="example-full-width">
            <mat-label>Slogan</mat-label>
            <textarea matInput [(ngModel)]="data['campagne'].slogan" placeholder="Ex. Le meilleur pour un avenir meilleur..." name="slogan"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-12" align="end">
        <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close()" >ANNULER</button>
        <button mat-button [disabled]="!campagneForm.form.valid" class="btn btn-primary waves-effect m-r-20" (click)="onAdd(data['campagne'])" cdkFocusInitial>VALIDER</button>
      </div>
    </div>
    </div>
  </form>
  </div>
  </div>
  </div>
<!-- <mat-dialog-actions align="end">
  <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>Cancel</button>
  <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
