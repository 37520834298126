<div class="container-fluid">
  <div class="row clearfix">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="card">
        <div class="header">
          <h2><strong>Recherche</strong> Membre pour ajout</h2>
          <ul class="header-dropdown">
            <li class="remove">
              <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
            </li>
          </ul>
        </div>

        <div class="body">
          <div class="row clearfix">
            <div class="col-sm-12">
              <!-- Nav tabs -->
              <ul class="nav nav-tabs">
                <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#profile">INFOS PERSO</a></li>
                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#home">NUMERO ELECTEUR</a></li>
              </ul>
              <!-- Tab panes -->
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane in active" id="profile"> <b>Infos personnelles</b>
                  <form id="infoPerso" #infoPerso="ngForm" action="post">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <mat-form-field class="example-full-width">
                            <mat-label>Nom </mat-label>
                            <input matInput placeholder="Nom"   [(ngModel)] ="membre.nom"  name="Nom" required maxlength="50" minlength="2" #inputNom>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <mat-form-field class="example-full-width">
                            <mat-label>Prénom(s) </mat-label>
                            <input matInput placeholder="Prénom(s)"  [(ngModel)] ="membre.prenom"  name="prenom" required maxlength="100" minlength="5" #inputPrenom>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <mat-form-field class="example-full-width">
                            <mat-label>Date de naissance </mat-label>
                            <input matInput  mask="00/00/0000" [showMaskTyped]="true" [dropSpecialCharacters]="false" placeHolderCharacter="0" [(ngModel)] ="membre.dateNaissance" name="dateNaissance"  required #dateNaissance placeholder = "Date de naissance" minlength="5">
                          </mat-form-field>
                          <!-- <mat-form-field>
                              <input matInput [matDatepicker] = "picker"  [(ngModel)] ="membre.dateNaissance" name="dateNaissance"  required #dateNaissance placeholder = "Date de naissance">
                              <mat-datepicker-toggle matSuffix [for] = "picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field> -->
                        </div>
                      </div>
                      <div class="col-sm-12 text-right">
                        <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close(false)" >ANNULER</button>
                        <button mat-button [disabled]="!infoPerso.form.valid" class="btn btn-primary waves-effect m-r-20" (click) ="onRechercher()" cdkFocusInitial>RETROUVER</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div role="tabpanel" class="tab-pane show" id="home"> <b>Infos électeur</b>
                  <form id="nummeroElecteur" #nummeroElecteur="ngForm" action="post">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <mat-form-field class="example-full-width">
                            <mat-label>Code Electeur / CNI </mat-label>
                            <input matInput placeholder="Code Electeur"  [(ngModel)] ="membre.numeroElecteur" name="numeroElecteur" required maxlength="14" minlength="14" #inpuCodeEl>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-sm-12 text-right">
                        <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close(false)" >ANNULER</button>
                        <button mat-button [disabled]="!nummeroElecteur.form.valid" (click) ="onRechercher()" class="btn btn-primary waves-effect m-r-20" cdkFocusInitial>RETROUVER</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
