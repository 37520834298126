import {Component, OnInit, ViewChild} from '@angular/core';
import {Membrecl} from '../../Models/membrecl.model';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {Regioncl} from '../../Models/regioncl.model';
import {Departementcl} from '../../Models/departementcl.model';
import {Sousprefecturecl} from '../../Models/sousprefecturecl.model';
import {Sectioncl} from '../../Models/sectioncl.model';
import {Comitecl} from '../../Models/comitecl.model';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import {Router} from '@angular/router';
import {RegionService} from '../../Services/region.service';
import {DepartementService} from '../../Services/departement.service';
import {SousprefectureService} from '../../Services/sousprefecture.service';
import {SectionService} from '../../Services/section.service';
import {ComiteService} from '../../Services/comite.service';
import {ToastrService} from 'ngx-toastr';
import {DeletealertComponent} from '../../Modals/deletealert/deletealert.component';
import {RechercheMembreComponent} from '../../Modals/recherche-membre/recherche-membre.component';
import {InformationmodalComponent} from '../../Modals/informationmodal/informationmodal.component';
import {AffectationmissionComponent} from '../../Modals/affectationmission/affectationmission.component';
import {ConfirmationmodalComponent} from '../../Modals/confirmationmodal/confirmationmodal.component';
import {DetailsfilleulemodalComponent} from '../../Modals/detailsfilleulemodal/detailsfilleulemodal.component';
import {MethodusedtoaddmembermodalComponent} from '../../Modals/methodusedtoaddmembermodal/methodusedtoaddmembermodal.component';

@Component({
  selector: 'app-recensement',
  templateUrl: './recensement.component.html',
  styleUrls: ['./recensement.component.css']
})
export class RecensementComponent implements OnInit {
  membre = new Membrecl();
  appName: string = myGlobals.APP_NAME;
  tableDataSource = new MatTableDataSource();
  private dialogConfig;
  public displayedColumns = ['Login', 'Nom', 'Prenoms', 'Gender', 'Email', 'Phone', 'Commune', 'Comite', 'Role', 'Filleule', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  lstRegions = new SelectionModel<Regioncl>(true, []);
  lstRegionTrie = new SelectionModel<Regioncl>(true, []);
  lstDeps = new SelectionModel<Departementcl>(true, []);
  lstDepsTrie = new SelectionModel<Departementcl>(true, []);
  lstCommuneSP = new SelectionModel<Sousprefecturecl>(true, []);
  lstCommuneSPTrie = new SelectionModel<Sousprefecturecl>(true, []);
  lstSections = new SelectionModel<Sectioncl>(true, []);
  lstSectionsTrie = new SelectionModel<Sectioncl>(true, []);
  lstComites = new SelectionModel<Comitecl>(true, []);
  lstComitesTrie = new SelectionModel<Comitecl>(true, []);
  /* Gestion des filtres */
  public selectControlRegion: FormControl = new FormControl();
  public selectFilterCtrlRegion: FormControl = new FormControl();
  /** Départements */
  public selectControlDep: FormControl = new FormControl();
  public selectFilterCtrlDep: FormControl = new FormControl();
  /** Sous prefectures / Commune */
  public selectControlCommune: FormControl = new FormControl();
  public selectFilterCtrlCommune: FormControl = new FormControl();
  /** Sections */
  public selectControlSection: FormControl = new FormControl();
  public selectFilterCtrlSection: FormControl = new FormControl();
  /** Comites */
  public selectControlComite: FormControl = new FormControl();
  public selectFilterCtrlComite: FormControl = new FormControl();
  maxMembreNbre: number;
  paramMaxMembreNumber: number;
  userConnected: {};
  addNewMembreActivated: boolean;
  addNewMembreVisible: boolean;
  searchBarActivated: boolean;
  selectBoxRegionActivated: boolean;
  selectBoxDepartementActivated: boolean;
  selectBoxCommuneActivated: boolean;
  selectBoxSectionActivated: boolean;
  selectBoxComiteActivated: boolean;
  constructor(private dialog: MatDialog, private svceUser: UtilisateursService, private router: Router, private svceRegion: RegionService, private svceDep: DepartementService, private svceSp: SousprefectureService, private svceSection: SectionService, private svceComite: ComiteService, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.paramMaxMembreNumber = myGlobals.NBRE_MAX_ML;
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
    this.loadData();
  }
  loadData = () => {
    switch (this.userConnected['typemembre']) {
      case 'Animateur': {
        this.getAllMillitantsBy(this.userConnected['comiteBaseId']);
        this.searchBarActivated = false;
        this.addNewMembreVisible = true;
        this.selectBoxRegionActivated = false;
        this.selectBoxDepartementActivated = false;
        this.selectBoxCommuneActivated = false;
        this.selectBoxSectionActivated = false;
        this.selectBoxComiteActivated = false;
        break;
      }
      case 'Secrétaire': {
        this.getAllMillitantsBySection(this.userConnected['sectionId']);
        this.getAllComiteBySection(this.userConnected['sectionId']);
        this.searchBarActivated = true;
        this.addNewMembreVisible = false;
        this.selectBoxRegionActivated = false;
        this.selectBoxDepartementActivated = false;
        this.selectBoxCommuneActivated = false;
        this.selectBoxSectionActivated = false;
        this.selectBoxComiteActivated = true;
        break;
      }
      default: {
        this.getAllMembres();
        this.getAllRegion();
        this.getAllDep();
        this.getAllComSp();
        this.getAllSec();
        this.getAllComite();
        this.searchBarActivated = true;
        this.addNewMembreVisible = true;
        this.selectBoxRegionActivated = true;
        this.selectBoxDepartementActivated = true;
        this.selectBoxCommuneActivated = true;
        this.selectBoxSectionActivated = true;
        this.selectBoxComiteActivated = true;
        break;
      }
    }
  }
  getAllRegion = () => {
    this.svceRegion.getAllRegion().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstRegions.clear();
          res['donnees'].forEach(element => {
            this.lstRegions.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**Departement */
  getAllDep = () => {
    this.svceDep.getAllDepartement().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstDeps.clear();
          res['donnees'].forEach(element => {
            this.lstDeps.select(element);
            this.lstDepsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieDep = (RegionId: string) => {
    this.lstDepsTrie.clear();
    this.lstDeps.selected.forEach(element => {
      if (element.regionId === RegionId)
      {
        this.lstDepsTrie.select(element);
      }
    });

    if (this.lstDepsTrie.selected.length <= 0)
    {
      this.lstDeps.selected.forEach(element => {
        this.lstDepsTrie.select(element);
      });
    }
  }
  /** Fin departement */
  /** Commune */
  getAllComSp = () => {
    this.svceSp.getAllCommuneSp().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstCommuneSP.clear();
          res['donnees'].forEach(element => {
            this.lstCommuneSP.select(element);
            this.lstCommuneSPTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieCommune = (DepartementId: string) => {
    this.lstCommuneSPTrie.clear();
    this.lstCommuneSP.selected.forEach(element => {
      if (element.departementId === DepartementId)
      {
        this.lstCommuneSPTrie.select(element);
      }
    });

    if (this.lstCommuneSPTrie.selected.length <= 0)
    {
      this.lstCommuneSP.selected.forEach(element => {
        this.lstCommuneSPTrie.select(element);
      });
    }
  }
  /** Fin Commune */
  /** Section */
  getAllSec = () => {
    this.svceSection.getAllSections().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstSections.clear();
          res['donnees'].forEach(element => {
            this.lstSections.select(element);
            this.lstSectionsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieSection = (sousprefectureId: string) => {
    this.lstSectionsTrie.clear();
    this.lstSections.selected.forEach(element => {
      if (element.communeId === sousprefectureId)
      {
        this.lstSectionsTrie.select(element);
      }
    });

    if (this.lstSectionsTrie.selected.length <= 0)
    {
      this.lstSections.selected.forEach(element => {
        this.lstSectionsTrie.select(element);
      });
    }
  }
  /** Fin Section */
  /** Comité de base */
  getAllComite = () => {
    this.svceComite.getAllComiteBases().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstComites.clear();
          res['donnees'].forEach(element => {
            this.lstComites.select(element);
            this.lstComitesTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllComiteBySection = (sectionId: string) => {
    this.svceComite.getAllComiteBy(sectionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstComites.clear();
          res['donnees'].forEach(element => {
            this.lstComites.select(element);
            this.lstComitesTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieComite = (sectionId: string) => {
    this.lstComitesTrie.clear();
    this.lstComites.selected.forEach(element => {
      if (element.sectionId === sectionId)
      {
        this.lstComitesTrie.select(element);
      }
    });
    console.log(this.lstComitesTrie.selected);
    if (this.lstComitesTrie.selected.length <= 0)
    {
      this.lstComites.selected.forEach(element => {
        this.lstComitesTrie.select(element);
      });
    }
  }
  /** Fin Comites */
  getAllMembres = () => {
    this.svceUser.getAllMembres().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMillitantsBy = (comiteBaseId: string) => {
    this.svceUser.getAllMembresBy(comiteBaseId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.maxMembreNbre = Object.keys(this.tableDataSource.data).length;
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
          if (this.maxMembreNbre >= myGlobals.NBRE_MAX_ML) {
            this.addNewMembreActivated = true;
          } else {
            this.addNewMembreActivated = false;
          }
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMillitantsBySection = (sectionId: string) => {
    this.svceUser.getAllMembresBySection(sectionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.maxMembreNbre = Object.keys(this.tableDataSource.data).length;
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
          if (this.maxMembreNbre >= myGlobals.NBRE_MAX_ML) {
            this.addNewMembreActivated = true;
          } else {
            this.addNewMembreActivated = false;
          }
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  redirectToDetails = (membre: Membrecl) => {
    this.router.navigate(['dashboard/membreDetails', membre.membreId]) ;
  }
  redirectToUpdate = (membre: Membrecl) => {
    this.router.navigate(['dashboard/membreDetails', membre.membreId]) ;
  }
  redirectToDelete = (membreId: string, libelle: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DeletealertComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceUser.deleteMembre(membreId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success(res['message'], 'Message', {
                timeOut: 3000,
              });
              this.loadData();
            }
            else{
              if (res !== null && res['status']){
                this.tableDataSource.data = [];
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  addMembre = (membre: Membrecl) => {
    membre.comiteBaseId = this.userConnected['comiteBaseId'];
    membre.actionType = 'add';
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        membre
      }
    };
    const dialogRef = this.dialog.open(RechercheMembreComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log('Good');
        this.loadData();
      }
      else{
        console.log(result);
        dialogRef.close();
      }
    });
    /* this.dialogConfig = {
       width: '500px',
       disableClose: true,
       position: {top: '10%'},
       data: {
         // tslint:disable-next-line:max-line-length
         membre
       }
     };
     const dialogRef = this.dialog.open(MethodusedtoaddmembermodalComponent,
       this.dialogConfig
     );
     dialogRef.afterClosed().subscribe(result =>
     {
       if (result)
       {
         console.log('Good');
     }
     });
     /*if (this.addNewMembreActivated === false) {
       membre.comiteBaseId = this.userConnected['comiteBaseId'];
       membre.actionType = 'add';
       this.dialogConfig = {
         width: '500px',
         disableClose: true,
         position: {top: '10%'},
         data: {
           membre
         }
       };
       const dialogRef = this.dialog.open(RechercheMembreComponent,
         this.dialogConfig
       );
       dialogRef.afterClosed().subscribe(result =>
       {
         if (result)
         {
           console.log('Good');
           this.loadData();
         }
         else{
           console.log(result);
           dialogRef.close();
         }
       });
     } else {
       console.log('Voir Admin');
       this.dialogConfig = {
         width: '500px',
         disableClose: true,
         position: {top: '10%'},
         data: {
           // tslint:disable-next-line:max-line-length
           'libelle': 'Merci de bien vouloir contacter l\'administrateur car vous avez atteint le nombre maximal de militant dans un comité de base (' + myGlobals.NBRE_MAX_ML + ')'
         }
       };
       const dialogRef = this.dialog.open(InformationmodalComponent,
         this.dialogConfig
       );
       dialogRef.afterClosed().subscribe(result =>
       {
         if (result)
         {
           console.log('Good');
         }
       });
     }*/
    // this.router.navigate(['dashboard/nouveauMembre']) ;
  }
  affectationOpenModal = (membreId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        membreId
      }
    };
    const dialogRef = this.dialog.open(AffectationmissionComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
    });
  }
  deleteAffectationMembre = (affectationMembreId: string, membre: Membrecl, libelleCB: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '20%'},
      data: {
        'libelle': ' Vous êtes sur le point de retirer ' + (membre.sex === 'M' ? 'M. ' : 'Mme. ' + membre.nom + ' ' + membre.prenom )+ ' du comité de base ' + libelleCB + '. Etes-vous sûr ?'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceUser.deleteAffectaionMembre(affectationMembreId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success(res['message'], 'Message', {
                timeOut: 3000,
              });
              this.loadData();
            }
            else{
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
  addAffectation = (membre: Membrecl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '20%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': ' Vous allez être rédirigé sur la liste des comités de base afin de réaffecter ' + (membre.sex === 'M' ? 'M. ' : 'Mme. ' ) + ( membre.nom + ' ' + membre.prenom ) + ' à son nouveau comité de base.'
      }
    };
    const dialogRef = this.dialog.open(InformationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        localStorage.setItem('numeroElecteur', membre.numeroElecteur);
        if (this.userConnected['typemembre'] === 'Animateur'){
          this.router.navigate(['dashboard/listemilitantwocomite']) ;
        } else {
          this.router.navigate(['dashboard/listeComites']) ;
        }
      }
    });
  }
  addParrainage = (membre: Membrecl, parrainId: string) => {
    membre.actionType = 'parrain';
    membre.parrainId = parrainId;
    membre.comiteBaseId = parrainId;
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        membre
      }
    };
    const dialogRef = this.dialog.open(RechercheMembreComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log('Good');
        this.loadData();
      }
      else{
        console.log(result);
        dialogRef.close();
      }
    });
  }
  openListFilleule = (parrainId: string, libelle: string) => {
    console.log(parrainId);
    console.log(libelle);
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        'parrainId': parrainId,
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DetailsfilleulemodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      } else {
      }
    });
  }
}
