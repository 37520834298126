import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Comitecl} from '../Models/comitecl.model';
import {catchError, retry} from 'rxjs/operators';
import {Particl} from '../Models/particl.model';
import {AffectationObjectifcl} from '../Models/affectation-objectifcl.model';
import {Departementcl} from '../Models/departementcl.model';

@Injectable({
  providedIn: 'root'
})
export class AffectationobjectifService {
  URL_API_LOCAL = myGlobals.API_REST_URL + 'coAffectationObjectif.php';
  /*Headers*/
    headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  getAllAffectationObjectif = (): Observable<AffectationObjectifcl[]> => {
    /*this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coComiteBase.php';*/
    return this.httpQuery.get<AffectationObjectifcl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION , {headers: this.headers })
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllAffectationObjectifBy = (objectifId: string): Observable<AffectationObjectifcl[]> => {
    /*this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coComiteBase.php';*/
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', objectifId);
    return this.httpQuery.post<AffectationObjectifcl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALLBY_ACTION , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllDepartementByObjectif = (objectifId: string): Observable<Departementcl[]> => {
    /*this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coComiteBase.php';*/
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', objectifId);
    return this.httpQuery.post<Departementcl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.FILTRE_ACTION , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  addAffectationObjectif = (affectationObjectif: AffectationObjectifcl): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('affectationObjectifId', affectationObjectif.affectationobjectifId);
    FORMDATA.append('objectifId', affectationObjectif.objectifId);
    FORMDATA.append('departementId', affectationObjectif.departementId);
    FORMDATA.append('valeur', affectationObjectif.valeur);
    FORMDATA.append('deadline', this.getMMDDYYYY(affectationObjectif.deadline));
    FORMDATA.append('comment', affectationObjectif.comment);
    FORMDATA.append('campagneId', affectationObjectif.campagneId);
    FORMDATA.append('createdBy', affectationObjectif.createdBy);
    console.log(affectationObjectif);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${affectationObjectif.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteComite = (affectationObjectifId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('affectationObjectifId', affectationObjectifId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.DELETEBY_ACTION  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getMMDDYYYY = (date) => {
    let m: string;
    let d: string;
    const today = new Date(date);
    const dd: number = today.getDate();
    const mm: number = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      d = '0' + dd;
    } else {
      d = dd + '';
    }
    if (mm < 10) {
      m = '0' + mm;
    } else {
      m = mm + '';
    }
    return m + '/' + d + '/' + yyyy;
  }
}
