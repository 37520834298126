import { Sousprefecturecl } from '../../Models/sousprefecturecl.model';
import { SelectionModel } from '@angular/cdk/collections';
import { SousprefectureService } from 'src/app/Services/sousprefecture.service';
import { Membrecl } from '../../Models/membrecl.model';
import { UtilisateursService } from '../../Services/utilisateurs.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-detailsmembre',
  templateUrl: './detailsmembre.component.html',
  styleUrls: ['./detailsmembre.component.css']
})
export class DetailsmembreComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  userConnected: {};
  membreId: string;
  disabledForm: boolean;
  private sub: any;
  membre = new Membrecl();
  lstCommuneSP = new SelectionModel<Sousprefecturecl>(true, []);
  constructor(private route: ActivatedRoute, private svceMemmbre: UtilisateursService, private svceSp: SousprefectureService, private toastr: ToastrService, public svceMembre: UtilisateursService, private router: Router) { }

  ngOnInit(): void {
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
    this.getAllComSp();
    this.sub = this.route.params.subscribe(params => {
      this.membreId =  params['id']; // (+) converts string 'id' to a number
    });
    if (this.membreId !== ''){
      this.getOneMembre(this.membreId);
      this.disabledForm = true;
    }
    else{
      this.disabledForm = false;
      this.membre.membreId = '';
      this.membre.numeroElecteur = '';
      this.membre.numeroCNI = '';
      this.membre.nom = '';
      this.membre.prenom = '';
      this.membre.email = '';
      this.membre.contact = '';
      this.membre.sex = '';
      this.membre.lieuVoteId = '';
      this.membre.dateNaissance = '';
      this.membre.lieuNaissance = '';
      this.membre.adressePhysique = '';
      this.membre.adressePostale = '';
      this.membre.profession = '';
      this.membre.nomPere = '';
      this.membre.prenomPere = '';
      this.membre.dateNaissancePere = '';
      this.membre.lieuNaissancePere = '';
      this.membre.nomMere = '';
      this.membre.prenomMere = '';
      this.membre.dateNaissanceMere = '';
      this.membre.lieuNaissanceMere = '';
      this.membre.autreInfos = '';
    }
  }
  ngOnDestroy = () => {
    this.sub.unsubscribe();
  }
  getOneMembre = (membreId: string) => {
    this.svceMemmbre.getOneMembres(membreId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.membre = res['donnees'];
          this.membre.numeroCNI = this.membre.numeroCNI !== 'undefined' ? this.membre.numeroCNI  : '';
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /** Commune */
  getAllComSp = () => {
    this.svceSp.getAllCommuneSp().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstCommuneSP.clear();
          res['donnees'].forEach(element => {
            this.lstCommuneSP.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onAddMembre = (membre: Membrecl) => {
    // membre.actionType = 'update';
    if (this.membreId === '') {
      membre.actionType = 'add';
      membre.comiteBaseId = this.userConnected['comiteBaseId'];
      membre.createdBy = this.userConnected['membreId'];
      membre.communeId = this.userConnected['communeId'];
    }
    else{
      membre.actionType = 'update';
    }
    console.log(membre);
    this.svceMembre.addMembre(membre).subscribe(
      (res) => {
        console.log(res);
        this.toastr.success('Les informations mises à jour avec succès.', 'Message', {
          timeOut: 3000,
        });
        this.redirectToMilitant();
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  redirectToMilitant = () => {
    this.router.navigate(['dashboard/listeMembres']) ;
  }
}
