import { UtilisateursService } from './../../Services/utilisateurs.service';
import { GetmilitantaffectionComponent } from './../getmilitantaffection/getmilitantaffection.component';
import { Membrecl } from './../../Models/membrecl.model';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-recherchemembredate',
  templateUrl: './recherchemembredate.component.html',
  styleUrls: ['./recherchemembredate.component.css']
})
export class RecherchemembredateComponent implements OnInit {
  private dialogConfig;
  membre = new Membrecl();
  // public customPatterns = { '0': { pattern: new RegExp('\[a-zA-Z\]')} };
  public customPatterns = { '0': { pattern: new RegExp('\[0-9\]')} };
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private toastr: ToastrService, public dialogRef: MatDialogRef<RecherchemembredateComponent>, private svceMembre: UtilisateursService) { }

  ngOnInit(): void {
    this.membre.numeroElecteur = '';
    //this.openConfirmationDetailsMembre(this.membre);
  }
  close = () => {
    this.dialogRef.close(false);
  }
  onValidateElect = (fomulaire: NgForm) => {
    this.onRechercher();
  }
  onValidateInfo = (fomulaire: NgForm) => {
    this.onRechercher();
  }
  valuechange = (newValue: string) => {
    console.log(newValue);
    this.membre.dateNaissance = newValue ;
  }
  onRechercher = () => {

    this.svceMembre.SearchAllMillitantForAff(this.membre).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          if ( parseInt(res['donnees'][0]['Nbre']) === 0)
          {
            this.toastr.success('Electeur trouvé sur la liste electorale.', 'Message', {
              timeOut: 3000,
            });
            const newMembre = res['donnees'][0] as Membrecl ;
            newMembre.comiteBaseId = this.data['blocId'];
            // newMembre.membreId = '';
            newMembre.actionType = 'add';
            newMembre.createdBy = localStorage.getItem('USER_CONNECTED_ID');
            this.openConfirmationDetailsMembre(newMembre);
          }
          else{

            this.toastr.warning('Electeur trouvé sur la liste electorale mais dejà affecté à un poste de responsabilité.', 'Message', {
              timeOut: 3000,
            });
          }
        }
        else{
          if (res !== null && res['status']){
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        this.toastr.warning('Erreur lors de la recherche. Veuillez réessayer svp et si cela persiste contacter l\'administrateur.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  openConfirmationDetailsMembre = (membre: Membrecl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '2%'},
      data: {
        'type': this.data['type'],
        'membre': membre,
        'blocId': this.data['blocId']
      }
    };
    const dialogRef = this.dialog.open(GetmilitantaffectionComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
        this.dialogRef.close(true);
      }
    });
    // this.router.navigate(['dashboard/nouveauMembre']) ;
  }
}
