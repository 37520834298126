import {Actioncl} from './actioncl.model';

export class Accesscampagne extends Actioncl{
  id: string;
  accessCampagneId: string;
  accessId: string;
  campagneId: string;
  etat: number;
  status: number;
  createdBy: string;
  createdOn: string;
}
