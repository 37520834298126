import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Missioncl} from '../../Models/missioncl.model';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MissionService} from '../../Services/mission.service';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import {Membrecl} from '../../Models/membrecl.model';
import {Router} from '@angular/router';
import {RechercheMembreComponent} from '../recherche-membre/recherche-membre.component';
import {AffectationService} from '../../Services/affectation.service';
import {DeletealertComponent} from '../deletealert/deletealert.component';
import {ToastrService} from 'ngx-toastr';
import {Affectation} from '../../Models/affectation.model';
import {AffectationconfirmationComponent} from '../affectationconfirmation/affectationconfirmation.component';
import {AnimateurService} from '../../Services/animateur.service';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {InformationmodalComponent} from '../informationmodal/informationmodal.component';

@Component({
  selector: 'app-detailsmilitantsmodal',
  templateUrl: './detailsmilitantsmodal.component.html',
  styleUrls: ['./detailsmilitantsmodal.component.css']
})
export class DetailsmilitantsmodalComponent implements OnInit {
  private dialogConfig;
  membre = new Membrecl();
  maxMembreNbre: number;
  addNewMembreActivated: boolean;
  affectation = new Affectation();
  tableDataSource = new MatTableDataSource<Missioncl>();
  public displayedColumns = [ 'Electeur', 'Nom', 'Prenoms', 'Gender', 'Email', 'Phone', 'Rôle', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private svceUser: UtilisateursService, private dialog: MatDialog, public svceAnimateur: AnimateurService, public svceAffectation: AffectationService, private toastr: ToastrService, public svceMembre: UtilisateursService, private router: Router, public dialogRef: MatDialogRef<DetailsmilitantsmodalComponent>) { }

  ngOnInit(): void {
    this.getAllMembresBy(this.data['blocId']);
    this.membre.comiteBaseId = this.data['blocId'];
    this.addNewMembreActivated = false;
    console.log(' Militant Liste : ' + this.data['blocId']);
  }
  getAllMembresBy = (comiteBaseId: string) => {
    this.svceMembre.getAllMembresBy(comiteBaseId).subscribe(
      (res) => {
        if (res !== null && !res.status){
          this.tableDataSource.data = res['donnees'];
          console.log(this.tableDataSource.data);
          // console.log(Object.keys(this.tableDataSource.data).length);
          this.maxMembreNbre = Object.keys(this.tableDataSource.data).length;
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
          if (this.maxMembreNbre >= myGlobals.NBRE_MAX_ML) {
            this.addNewMembreActivated = true;
          } else {
            this.addNewMembreActivated = false;
          }
          console.log(this.addNewMembreActivated);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
    this.dialogRef.close(true);
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  addMembre = (membre: Membrecl) => {
    if (this.addNewMembreActivated === false) {
      membre.actionType = 'add';
      this.dialogConfig = {
        width: '500px',
        disableClose: true,
        position: {top: '10%'},
        data: {
          membre
        }
      };
      const dialogRef = this.dialog.open(RechercheMembreComponent,
        this.dialogConfig
      );
      dialogRef.afterClosed().subscribe(result =>
      {
        if (result)
        {
          console.log('Good');
          this.getAllMembresBy(membre.comiteBaseId);
        }
        else{
          console.log(result);
          dialogRef.close();
        }
      });
    } else {
      console.log('Voir Admin');
      this.dialogConfig = {
        width: '500px',
        disableClose: true,
        position: {top: '10%'},
        data: {
          // tslint:disable-next-line:max-line-length
          'libelle': 'Merci de bien vouloir contacter l\'administrateur car vous avez atteint le nombre maximal de militant dans un comité de base (' + myGlobals.NBRE_MAX_ML + ')'
        }
      };
      const dialogRef = this.dialog.open(InformationmodalComponent,
        this.dialogConfig
      );
      dialogRef.afterClosed().subscribe(result =>
      {
        if (result)
        {
          console.log('Good');
        }
      });
    }
    // this.router.navigate(['dashboard/nouveauMembre']) ;
  }
  addAffectation = (membreId: string, libelle: string, type: number, affectationId: string) => {
    console.log(membreId + ' ' + this.data['blocId']);
    this.affectation.blocId = this.data['blocId'];
    this.affectation.acteurId = membreId;
    this.affectation.affectationId = '';
    this.affectation.status = 1;
    this.affectation.actionType = 'add';
    this.affectation.type = 5;
    this.affectation.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': membreId,
        'libelle': libelle + ' en tant que Animateur',
        'type': type
      }
    };
    const dialogRef = this.dialog.open(AffectationconfirmationComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        // tslint:disable-next-line:triple-equals
        if (type == 0){
          console.log('Ajouter' + affectationId);
          this.svceAffectation.addAffectation(this.affectation).subscribe(
            (res) => {
              if (res !== null && !res['status']){
                this.toastr.success('Animateur nommé avec succès', 'Message', {
                  timeOut: 3000,
                });
                this.getAllMembresBy(this.data['blocId']);
              }
              else {
                if (res !== null && res['status']){
                  this.toastr.warning(res['message'], 'Message', {
                    timeOut: 3000,
                  });
                } else{
                  console.log(res);
                }
              }
            }
          );
        } else {
          console.log('retirer ' + affectationId + ' ' + type);
          this.svceAnimateur.deleteAnimateur(affectationId).subscribe(
            (res) => {
              if (res !== null && !res['status']){
                this.toastr.success('Affectation supprimée avec succès', 'Message', {
                  timeOut: 3000,
                });
                this.getAllMembresBy(this.data['blocId']);
              }
              else {
                if (res !== null && res['status']){
                  this.toastr.warning(res['message'], 'Message', {
                    timeOut: 3000,
                  });
                }
                else{
                  console.log(res);
                }
              }
            },
            (error) => {}
          );
        }
      }
    });
  }
  redirectToDelete = (affectationMembreId: string, libelle: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DeletealertComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceUser.deleteAffectaionMembre(affectationMembreId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success(res['message'], 'Message', {
                timeOut: 3000,
              });
              this.getAllMembresBy(this.data['blocId']);
            }
            else{
              if (res !== null && res['status']){
                this.tableDataSource.data = [];
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
}
