import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {AffectationObjectifcl} from '../../Models/affectation-objectifcl.model';
import {ChecklistService} from '../../Services/checklist.service';
import {Checklistcl} from '../../Models/checklistcl.model';

@Component({
  selector: 'app-addcommentmodal',
  templateUrl: './addcommentmodal.component.html',
  styleUrls: ['./addcommentmodal.component.css']
})
export class AddcommentmodalComponent implements OnInit {
  checklist = new Checklistcl();
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toastr: ToastrService, public svceCheckList: ChecklistService , public dialogRef: MatDialogRef<AddcommentmodalComponent>) { }

  ngOnInit(): void {
    console.log(this.data['checkList']);
  }
  close = () => {
    this.dialogRef.close(false);
  }
  updateCommentCheckList = (comment: string) => {
    this.checklist.comment = comment;
    this.checklist.checkListId = this.data['checkList'].checkListId;
    this.checklist.affectationMissionId = this.data['checkList'].affectationMissionId;
    this.checklist.membreId = this.data['checkList'].membreId;
    this.checklist.etat = this.data['checkList'].etat;;
    this.checklist.actionType = 'update';
    this.checklist.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.svceCheckList.addorUpdateCheckList(this.checklist).subscribe(
      (res) => {
        console.log(res);
        this.toastr.success('CheckList modifiée avec succès', 'Message', {
          timeOut: 3000,
        });
        this.dialogRef.close(true);
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
}
