import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Membrecl} from '../../Models/membrecl.model';
import {Affectation} from '../../Models/affectation.model';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import {AffectationService} from '../../Services/affectation.service';
import {CordinateurService} from '../../Services/cordinateur.service';
import {ToastrService} from 'ngx-toastr';
import {AffectationconfirmationComponent} from '../affectationconfirmation/affectationconfirmation.component';

@Component({
  selector: 'app-detailsmembre-district',
  templateUrl: './detailsmembre-district.component.html',
  styleUrls: ['./detailsmembre-district.component.css']
})
export class DetailsmembreDistrictComponent implements OnInit {
  tableDataSource = new MatTableDataSource<Membrecl>();
  affectation = new Affectation();
  private dialogConfig;
  public displayedColumns = [ 'Electeur', 'Nom&Prenom', 'Gender', 'Email', 'Phone', 'Rôle', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private router: Router, public svceMembre: UtilisateursService, public svceAffectation: AffectationService, public svceCoordinateur: CordinateurService , private toastr: ToastrService , public dialogRef: MatDialogRef<DetailsmembreDistrictComponent>) { }

  ngOnInit(): void {
    // console.log(this.data['section']);
    this.getAllMembresBy(this.data.blocId);
  }
  getAllMembresBy = (districtId: string) => {
    this.svceMembre.getAllMembresByDistrict(districtId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.toastr.warning('Aucun militant trouvé pour le district ' + this.data.libelle + '.', 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
    this.dialogRef.close(true);
  }
  addAffectation = (membreId: string, libelle: string, type: number, isActif: string, affectationId: string) => {
    if (isActif !== '0' && type == 0){
      this.toastr.warning('Ce membre à déja un poste de reponsabilité dans une autre entité', 'Message', {
        timeOut: 3000,
      });
    }
    else {
      console.log(membreId + ' ' + this.data['blocId']);
      this.affectation.blocId = this.data['blocId'];
      this.affectation.acteurId = membreId;
      this.affectation.affectationId = '';
      this.affectation.status = 1;
      this.affectation.actionType = 'add';
      this.affectation.type = 6;
      this.affectation.createdBy = localStorage.getItem('USER_CONNECTED_ID');
      this.dialogConfig = {
        width: '500px',
        disableClose: true,
        position: {top: '10%'},
        data: {
          'itemId': membreId,
          'libelle': libelle + ' en tant que Coordinnateur du district ' + this.data.libelle,
          'type': type
        }
      };
      const dialogRef = this.dialog.open(AffectationconfirmationComponent,
        this.dialogConfig
      );
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // tslint:disable-next-line:triple-equals
          if (type == 0) {
            this.svceAffectation.addAffectation(this.affectation).subscribe(
              (res) => {
                if (res !== null && !res['status']) {
                  this.toastr.success('Coordinateur nommé avec succès', 'Message', {
                    timeOut: 3000,
                  });
                  this.getAllMembresBy(this.data.blocId);
                } else {
                  if (res !== null && res['status']) {
                    this.toastr.warning(res['message'], 'Message', {
                      timeOut: 3000,
                    });
                  } else {
                    console.log(res);
                  }
                }
              }
            );
          } else {
            console.log('retirer ' + affectationId + ' ' + type);
            this.svceCoordinateur.deleteCordinateur(affectationId).subscribe(
              (res) => {
                if (res !== null && !res['status']) {
                  this.toastr.success('Affectation supprimée avec succès', 'Message', {
                    timeOut: 3000,
                  });
                  this.getAllMembresBy(this.data.blocId);
                } else {
                  if (res !== null && res['status']) {
                    this.toastr.warning(res['message'], 'Message', {
                      timeOut: 3000,
                    });
                  } else {
                    console.log(res);
                  }
                }
              },
              (error) => {
              }
            );
          }
        }
      });
    }
  }
}
