import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Missioncl} from '../../Models/missioncl.model';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MissionService} from '../../Services/mission.service';
import {Comitecl} from '../../Models/comitecl.model';
import {ComiteService} from '../../Services/comite.service';
import {Cordinateurcl} from '../../Models/cordinateurcl.model';

@Component({
  selector: 'app-detailscomitemodal',
  templateUrl: './detailscomitemodal.component.html',
  styleUrls: ['./detailscomitemodal.component.css']
})
export class DetailscomitemodalComponent implements OnInit {
  tableDataSource = new MatTableDataSource<Comitecl>();
  public displayedColumns = [ 'Code', 'Libelle', 'Coordinateur', 'Phone'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public svceComite: ComiteService, public dialogRef: MatDialogRef<DetailscomitemodalComponent>) { }

  ngOnInit(): void {
    this.getAllComitesBy(this.data['acteur'].blocId);
  }
  getAllComitesBy = (sectionId: string) => {
    this.svceComite.getAllComiteBy(sectionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
