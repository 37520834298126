import {Actioncl} from './actioncl.model';

export class Affectation extends Actioncl{
  affectationId: string;
  acteurId: string;
  blocId: string;
  type: number;
  status: number;
  createdBy: string;
  createdOn: string;
}
