import { Router } from '@angular/router';
import { Sousprefecturecl } from '../../Models/sousprefecturecl.model';
import { SectionService } from '../../Services/section.service';
import { DepartementService } from '../../Services/departement.service';
import { Sectioncl } from '../../Models/sectioncl.model';
import { Departementcl } from '../../Models/departementcl.model';
import { DeletealertComponent } from '../../Modals/deletealert/deletealert.component';
import { AffectationmisionComponent } from '../../Modals/affectationmision/affectationmision.component';
import { CordinateurService } from '../../Services/cordinateur.service';
import { RegionService } from '../../Services/region.service';
import { Regioncl } from '../../Models/regioncl.model';
import { Cordinateurcl } from '../../Models/cordinateurcl.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SousprefectureService } from 'src/app/Services/sousprefecture.service';
import {DetailsmissionmodalComponent} from '../../Modals/detailsmissionmodal/detailsmissionmodal.component';
import {DetailsmembresectionComponent} from '../../Modals/detailsmembresection/detailsmembresection.component';
import {Access} from '../../Models/access.model';
import {UtilisateurComponent} from '../../Modals/utilisateur/utilisateur.component';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-cordinateur',
  templateUrl: './cordinateur.component.html',
  styleUrls: ['./cordinateur.component.css']
})
export class CordinateurComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  private dialogConfig;
  public ELEMENT_DATA: Cordinateurcl[] = [];
  tableDataSource = new MatTableDataSource<Cordinateurcl>();
  public displayedColumns = ['check', 'Nom', 'Prenoms', 'Gender', 'Phone', 'Commune', 'Section', 'NbreCB', 'Nbre', 'NbreML',  'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  lstCordinateursCheck = new SelectionModel<Cordinateurcl>(true, []);
  /*Choix des listes contenant les filtres*/
  lstRegions = new SelectionModel<Regioncl>(true, []);
  lstDeps = new SelectionModel<Departementcl>(true, []);
  lstDepsTrie = new SelectionModel<Departementcl>(true, []);
  lstCommuneSP = new SelectionModel<Sousprefecturecl>(true, []);
  lstCommuneSPTrie = new SelectionModel<Sousprefecturecl>(true, []);
  lstSections = new SelectionModel<Sectioncl>(true, []);
  lstSectionsTrie = new SelectionModel<Sectioncl>(true, []);
  /* Gestion des filtres */
  public selectControlRegion: FormControl = new FormControl();
  public selectFilterCtrlRegion: FormControl = new FormControl();
  /** Départements */
  public selectControlDep: FormControl = new FormControl();
  public selectFilterCtrlDep: FormControl = new FormControl();
  /** Sections */
  public selectControlCommune: FormControl = new FormControl();
  public selectFilterCtrlCommune: FormControl = new FormControl();
  /** Sections */
  public selectControlSection: FormControl = new FormControl();
  public selectFilterCtrlSection: FormControl = new FormControl();
  constructor(private svceRegion: RegionService, private svceDep: DepartementService, private router: Router, private svceSp: SousprefectureService, private svceSection: SectionService, private svceCordi: CordinateurService, private dialog: MatDialog, private toastr: ToastrService) { }

  ngOnInit(): void {
    // this.getAllCordinateursBy('94d71682-e420-11ea-8111-acde48001122');
    this.getAllCordinateurs();
    this.getAllRegion();
    this.getAllDep();
    this.getAllComSp();
    this.getAllSec();
  }
  /**Récuperation des cordinateurs du système */
  getAllCordinateurs = () => {
    this.svceCordi.getAllCordinateurs().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**
   * Récuperation des cordinateurs du système selon la Sp
   * @param sousprefectureId
   */
getAllCordinateursBy = (sousprefectureId: string) => {
    this.svceCordi.getAllCordinateursBy(sousprefectureId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected = () => {
    const numSelected = this.lstCordinateursCheck.selected.length;
    const numRows = this.tableDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle = () => {
    this.isAllSelected() ?
        this.lstCordinateursCheck.clear() :
        this.tableDataSource.data.forEach(row => this.lstCordinateursCheck.select(row as Cordinateurcl));
  }

  /** Gestion des listes pour le filtre
  /**Regions */
  getAllRegion = () => {
    this.svceRegion.getAllRegion().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstRegions.clear();
          res['donnees'].forEach(element => {
            this.lstRegions.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**Fin Region */
  /**Departement */
  getAllDep = () => {
    this.svceDep.getAllDepartement().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstDeps.clear();
          res['donnees'].forEach(element => {
            this.lstDeps.select(element);
            this.lstDepsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieDep = (RegionId: string) => {
    this.lstDepsTrie.clear();
    this.lstDeps.selected.forEach(element => {
      if (element.regionId === RegionId)
      {
        this.lstDepsTrie.select(element);
      }
    });

    if (this.lstDepsTrie.selected.length <= 0)
    {
      this.lstDeps.selected.forEach(element => {
          this.lstDepsTrie.select(element);
      });
    }
  }
  /**Fin departement */
  /**Commune */
  getAllComSp = () => {
    this.svceSp.getAllCommuneSp().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstCommuneSP.clear();
          res['donnees'].forEach(element => {
            this.lstCommuneSP.select(element);
            this.lstCommuneSPTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieCommune = (DepartementId: string) => {
    this.lstCommuneSPTrie.clear();
    this.lstCommuneSP.selected.forEach(element => {
      if (element.departementId === DepartementId)
      {
        this.lstCommuneSPTrie.select(element);
      }
    });

    if (this.lstCommuneSPTrie.selected.length <= 0)
    {
      this.lstCommuneSP.selected.forEach(element => {
          this.lstCommuneSPTrie.select(element);
      });
    }
  }
  /** Fin Commune */
  /** Section */
  getAllSec = () => {
    this.svceSection.getAllSections().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstSections.clear();
          res['donnees'].forEach(element => {
            this.lstSections.select(element);
            this.lstSectionsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieSection = (sousprefectureId: string) => {
    this.lstSectionsTrie.clear();
    this.lstSections.selected.forEach(element => {
      if (element.communeId === sousprefectureId)
      {
        this.lstSectionsTrie.select(element);
      }
    });

    if (this.lstSectionsTrie.selected.length <= 0)
    {
      this.lstSections.selected.forEach(element => {
          this.lstSectionsTrie.select(element);
      });
    }
  }
  /** Fin Section */
  /** Affectation de missions */
  openAffectaionMission = (membreId: string, blocId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'type': 4,
        'blocId': blocId
      }
    };
    const dialogRef = this.dialog.open(AffectationmisionComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
          console.log(result);
          this.getAllCordinateurs();
      }
    });
  }
  openDetailsMission = (acteur: Cordinateurcl) => {
    this.dialogConfig = {
      width: '1000px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        acteur
      }
    };
    const dialogRef = this.dialog.open(DetailsmissionmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
    });
  }
  redirectToDelete = (affectationId: string, cordinateur: string, sousPrefectureId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': affectationId,
        'libelle': cordinateur
      }
    };
    const dialogRef = this.dialog.open(DeletealertComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
          this.svceCordi.deleteCordinateur(affectationId).subscribe(
            (res) => {
              if (res !== null && !res['status']){
                this.toastr.success('Affectation supprimée avec succès', 'Message', {
                  timeOut: 3000,
                });
                this.getAllCordinateurs();
              }
              else {
                if (res !== null && res['status']){
                  this.toastr.warning(res['message'], 'Message', {
                    timeOut: 3000,
                  });
                }
                else{
                  console.log(res);
                }
              }
            },
            (error) => {}
          );
      }
    });
  }
  /**Fin affectaion */
  openDetailsMembre = (section: Sectioncl) => {
    // console.log(section);
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        section
      }
    };
    const dialogRef = this.dialog.open(DetailsmembresectionComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      } else {
        this.getAllCordinateurs();
      }
    });
  }
  redirectToAddAccess = (access: Access) => {
    access.accessId = '';
    access.actionType = 'add';
    console.log(access);
    this.openModalAccess(access);
  }
  openModalAccess = (access: Access) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        access
      }
    };
    const dialogRef = this.dialog.open(UtilisateurComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
      this.getAllCordinateurs();
    });
  }
}
