import { Actioncl } from './actioncl.model';
export class Membrecl extends Actioncl{
  membreId: string;
  numeroElecteur: string;
  numeroCNI: string;
  nom: string;
  prenom: string;
  email: string;
  contact: string;
  sex: string;
  communeId: string;
  comiteBaseId: string;
  lieuVoteId: string;
  dateNaissance: string;
  lieuNaissance: string;
  adressePhysique: string;
  adressePostale: string;
  profession: string;
  nomPere: string;
  prenomPere: string;
  dateNaissancePere: string;
  lieuNaissancePere: string;
  nomMere: string;
  prenomMere: string;
  dateNaissanceMere: string;
  lieuNaissanceMere: string;
  autreInfos: string;
  affectationMembreId: string;
  parrainId: string;
  NbreFilleule: number;
  postStaffRestant: string;
  isMemberStaff: string;
  Nbre: number;
  MembreExisted: number;
  status: number;
  createdBy: string;
  createdOn: string;
}
