import {Membrecl} from './membrecl.model';

export class Dashboradcl extends Membrecl{
  regionId: string;
  departementId: string;
  communeId: string;
  nbreML: number;
  nbreSEC: number;
  nbreS: number;
  nbreCB: number;
  nbreVotant: number;
  nbreRestant: number;
  nbreRestants: number;
  nbreCEI: number;
  objectifElecteur: number;
  objectifSection: number;
  totalMission: number;
  totalMissionEffectuee: number;
  totalMissionEnCours: number;
  totalMissionTerminees: number;
  totalMissionNotStart: number;
  nbreAnimateur: number;
  nbreSecretaire: number;
  Missions: number;
  MissionsDone: number;
  Taux: number;
  Etat: number;
  blocId: string;
  libelle: string;
  missionId: string;
  Coordinateur: string;
}
