<div class="container-fluid">
  <div class="row clearfix">
    <div class="card">
      <div class="header">
          <h2><strong>Affectation</strong></h2>
          <ul class="header-dropdown">
              <li class="remove">
                  <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
              </li>
          </ul>
      </div>
    </div>
  </div>
</div>
