import { ListecommunesComponent } from './Views/listecommunes/listecommunes.component';
import { ListedepartementsComponent } from './Views/listedepartements/listedepartements.component';
import { ListeregionsComponent } from './Views/listeregions/listeregions.component';
import { ListecomitefullComponent } from './Views/listecomitefull/listecomitefull.component';
import { ListeSectionfullComponent } from './Views/liste-sectionfull/liste-sectionfull.component';
import { DetailsmissionComponent } from './Views/detailsmission/detailsmission.component';
import { ManagementdashComponent } from './Views/managementdash/managementdash.component';
import { NewmembreComponent } from './Views/newmembre/newmembre.component';
import { DetailsmembreComponent } from './Views/detailsmembre/detailsmembre.component';
import { StudentComponent } from './Layout/student/student.component';
import { StaffComponent } from './Views/staff/staff.component';
import { DashboardComponent } from './Views/dashboard/dashboard.component';
import { ProfileComponent } from './Views/profile/profile.component';
import { CordinateurComponent } from './Views/cordinateur/cordinateur.component';
import { AnimateurComponent } from './Views/animateur/animateur.component';
import { AffectationComponent } from './Views/affectation/affectation.component';
import { ActiviteComponent } from './Views/activite/activite.component';
import { DepartementsComponent } from './Views/departements/departements.component';
import { SingupComponent } from './Auth/singup/singup.component';
import { UsersComponent } from './Views/users/users.component';
import { MasterComponent } from './Layout/master/master.component';
import { ForgotComponent } from './Auth/forgot/forgot.component';
import { NotfoundComponent } from './404/notfound/notfound.component';
import { LoginComponent } from './Auth/login/login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SectionComponent } from './Views/section/section.component';
import { MissionComponent } from './Views/mission/mission.component';
import { ObjectifComponent } from './Views/objectif/objectif.component';
import { CampagneComponent } from './Views/campagne/campagne.component';
import { ListemilitantwocomiteComponent } from './Views/listemilitantwocomite/listemilitantwocomite.component';
import { ListemissionsComponent } from './Views/listemissions/listemissions.component';
import { TableaubordComponent } from './Views/tableaubord/tableaubord.component';
import { PartiComponent } from './Views/parti/parti.component';
import { RecensementComponent } from './Views/recensement/recensement.component';
import { RoleequipeComponent } from './Views/roleequipe/roleequipe.component';
import { ListesecretaireCommuneComponent } from './Views/listesecretaire-commune/listesecretaire-commune.component';
import { ListdistrictfullComponent } from './Views/listdistrictfull/listdistrictfull.component';


const routes: Routes = [
  { path: 'singin', component: LoginComponent },
  { path: 'singup', component: SingupComponent },
  { path: 'forgotpassword', component: ForgotComponent },
  {
    path: 'dashboard', component: MasterComponent, children: [
      { path: 'management', component: ManagementdashComponent },
      { path: 'listeUsers', component: UsersComponent },
      { path: 'listeMembres', component: StaffComponent },
      { path: 'nouveauMembre', component: NewmembreComponent },
      { path: 'membreDetails/:id', component: DetailsmembreComponent },
      { path: 'listeCordinateurs', component: CordinateurComponent },
      { path: 'listeAnimateurs', component: AnimateurComponent },
      { path: 'listeAffectations', component: AffectationComponent },
      { path: 'listeSections', component: ListeSectionfullComponent },
      { path: 'listeComites', component: ListecomitefullComponent },
      { path: 'comite', component: DepartementsComponent },
      { path: 'listeActivites', component: ActiviteComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'sections', component: SectionComponent },
      { path: 'missions', component: MissionComponent },
      { path: 'objectifs', component: ObjectifComponent },
      { path: 'campagnes', component: CampagneComponent },
      { path: 'detailsMision', component: DetailsmissionComponent },
      { path: 'listeRegions', component: ListeregionsComponent },
      { path: 'listeDistricts', component: ListdistrictfullComponent },
      { path: 'listeDepartements', component: ListedepartementsComponent },
      { path: 'listeCommuneSp', component: ListecommunesComponent },
      { path: 'recensement', component: RecensementComponent },
      { path: 'roleEquipe', component: RoleequipeComponent },
      { path: 'secretaireCommune', component: ListesecretaireCommuneComponent },
      { path: 'listeMilitantwoComite', component: ListemilitantwocomiteComponent },
      { path: 'listeMissions', component: ListemissionsComponent },
      { path: 'tableaubord', component: TableaubordComponent },
      { path: 'parti', component: PartiComponent },
      { path: 'electiondays', component: DashboardComponent },
      { path: '', component: DashboardComponent },
    ]
  },
  { path: '', pathMatch: 'full', redirectTo: '/singin' },
  { path: '**', component: NotfoundComponent }
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
