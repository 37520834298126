import { Membrecl } from './../../Models/membrecl.model';
import { UtilisateursService } from './../../Services/utilisateurs.service';
import {Router} from '@angular/router';
import { Access } from './../../Models/access.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-listemembre',
  templateUrl: './listemembre.component.html',
  styleUrls: ['./listemembre.component.css']
})
export class ListemembreComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Id', 'Login', 'Nom', 'Prenoms', 'Gender', 'Email', 'Phone',   'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private svceUser: UtilisateursService, private router: Router) { }
  ngOnInit(): void {
    this.getAllMembres();
  }
  getAllMembres = () => {
    this.svceUser.getAllMembres().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  redirectToDetails = (membre: Membrecl) => {
    this.router.navigate(['dashboard/listeMembres/membreDetails', membre.membreId]) ;
  }
  redirectToUpdate = (membre: Membrecl) => {

  }
  redirectToDelete = (membreId: string) => {

  }
  doFilter = (value: string) => {
      this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  addMembre = () => {
    this.router.navigate(['dashboard/nouveauMembre']) ;
  }

}
