import { Comitecl } from '../../Models/comitecl.model';
import { ComiteService } from '../../Services/comite.service';
import { ServiceComponent } from '../../Modals/service/service.component';
import { Service } from '../../Models/service.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import {DeletealertComponent} from '../../Modals/deletealert/deletealert.component';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-departements',
  templateUrl: './departements.component.html',
  styleUrls: ['./departements.component.css']
})
export class DepartementsComponent implements OnInit {
  userConnected: {};
  appName: string = myGlobals.APP_NAME;
  private dialogConfig;
  comite = new Comitecl();
  service: Service;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Id', 'Section' , 'Code', 'Libelle',  'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private dialog: MatDialog, private svceComite: ComiteService,  private toastr: ToastrService) { }

  ngOnInit(): void {
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
    this.loadData();
    this.comite.actionType = 'add';
    this.comite.createdBy = localStorage.getItem('USER_CONNECTED_ID');
  }
  loadData = () => {
    switch (this.userConnected['typemembre']) {
      case 'Animateur': {
        break;
      }
      case 'Secrétaire': {
        this.getAllComiteBySection(this.userConnected['sectionId']);
        this.comite.sectionId = this.userConnected['sectionId'];
        break;
      }
      default: {
        this.getAllComite();
        break;
      }
    }
  }
  getAllComiteBySection = (sectionId: string) => {
    this.svceComite.getAllComiteBy(sectionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllComite = () => {
    this.svceComite.getAllComiteBases().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  openModal = (comite: Comitecl) => {
    if (this.userConnected['typemembre'] === 'Secrétaire'){
      comite.sectionId = this.userConnected['sectionId'];
    }
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        comite
      }
    };
    const dialogRef = this.dialog.open(ServiceComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.loadData();
      }
    });
  }
  cleanFielsd = (comite: Comitecl) => {
    this.comite = new Comitecl();
    this.comite.actionType = 'add';
    this.comite.comiteBaseId = '';
    this.comite.NbreCB = 0;
    this.comite.createdBy = localStorage.getItem('USER_CONNECTED_ID');
  }
  redirectToUpdate = (comite: Comitecl) => {
    console.log(comite);
    // this.section.actionType = 'update';
    this.comite.comiteBaseId = '';
    comite.actionType = 'update';
    this.openModal(comite);
  }
  redirectToDelete = (comiteId: string, libelle: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': comiteId,
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DeletealertComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceComite.deleteComite(comiteId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Comité supprimé avec succès', 'Message', {
                timeOut: 3000,
              });
              this.loadData();
            }
          }
        );
      }
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
}
