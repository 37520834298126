<section class="content home">
  <div class="block-header">
      <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12">
              <h2>Comités de base<small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 text-right">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
                <li class="breadcrumb-item">Accueil</li>
                <li class="breadcrumb-item active">Profil</li>
            </ul>
          </div>
      </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix profile-page">
      <div class="col-lg-4 col-md-12">
        <div class="card profile-header">
            <div class="body text-center">
                <div class="profile-image"> <img src="../assets/images/profile_av.jpg" alt=""> </div>
                <div>
                    <h4 class="m-b-0"><strong>Dr. Charlotte</strong> Deo</h4>
                    <span class="job_post">Neurologist</span>
                    <p>795 Folsom Ave, Suite 600<br> San Francisco, CADGE 94107</p>
                </div>
            </div>
        </div>

        <div class="card">
          <div class="tab-pane body">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Username">
            </div>
            <div class="form-group">
                <input type="password" class="form-control" placeholder="Current Password">
            </div>
            <div class="form-group">
                <input type="password" class="form-control" placeholder="New Password">
            </div>
            <button class="btn btn-info btn-round">Enregister</button>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="card">
          <div class="header">
              <h2><strong>Recent</strong> Activity</h2>
              <ul class="header-dropdown">
                  <li class="remove">
                      <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                  </li>
              </ul>
          </div>
          <div class="body user_activity">
              <div class="streamline b-accent">
                  <div class="sl-item">
                      <img class="user rounded-circle" src="../assets/images/xs/avatar4.jpg" alt="">
                      <div class="sl-content">
                          <h5 class="m-b-0">Admin Birthday</h5>
                          <small>Jan 21 <a href="javascript:void(0);" class="text-info">Sophia</a>.</small>
                      </div>
                  </div>
                  <div class="sl-item">
                      <img class="user rounded-circle" src="../assets/images/xs/avatar5.jpg" alt="">
                      <div class="sl-content">
                          <h5 class="m-b-0">Add New Contact</h5>
                          <small>30min ago <a href="javascript:void(0);">Alexander</a>.</small>
                          <small><strong>P:</strong> +264-625-2323</small>
                          <small><strong>E:</strong> maryamamiri@gmail.com</small>
                      </div>
                  </div>
                  <div class="sl-item">
                      <img class="user rounded-circle" src="../assets/images/xs/avatar6.jpg" alt="">
                      <div class="sl-content">
                          <h5 class="m-b-0">General Surgery</h5>
                          <small>Today <a href="javascript:void(0);">Grayson</a>.</small>
                          <small>The standard chunk of Lorem Ipsum used since the 1500s is reproduced</small>
                      </div>
                  </div>
                  <div class="sl-item">
                      <img class="user rounded-circle" src="../assets/images/xs/avatar7.jpg" alt="">
                      <div class="sl-content">
                          <h5 class="m-b-0">General Surgery</h5>
                          <small>45min ago <a href="javascript:void(0);" class="text-info">Fidel Tonn</a>.</small>
                          <small><strong>P:</strong> +264-625-2323</small>
                          <small>The standard chunk of Lorem Ipsum used since the 1500s is reproduced used since the 1500s is reproduced</small>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </div>
    </div>
  </div>
</section>
