import { Affectation } from './../../Models/affectation.model';
import { AffectationService } from './../../Services/affectation.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
@Component({
  selector: 'app-getmilitantaffection',
  templateUrl: './getmilitantaffection.component.html',
  styleUrls: ['./getmilitantaffection.component.css']
})
export class GetmilitantaffectionComponent implements OnInit {
  public affectation = new Affectation();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private svceAff: AffectationService, private toastr: ToastrService, public dialogRef: MatDialogRef<GetmilitantaffectionComponent>) { }

  ngOnInit(): void {
    console.log(this.data['membre']);
    this.affectation.type = this.data['type'];
    this.affectation.blocId = this.data['blocId'];
    this.affectation.acteurId = this.data['membre']['membreId'];
    this.affectation.status = 1;
    this.affectation.createdBy = localStorage.getItem('USER_CONNECTED_ID');
  }
  addAffectation = () => {
    this.svceAff.addAffectation(this.affectation).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.toastr.success('Mission affectée avec succès', 'Message', {
            timeOut: 3000,
          });
          this.dialogRef.close(true);
        }
        else{
          if (res !== null && res['status']){
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
            this.toastr.warning('Oups! erreur pendant l\'affectation. veuillez réessayer svp.', 'Message', {
              timeOut: 3000,
            });
          }
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'affectation. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
}
