import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {Animateur} from '../Models/animateur.model';

@Injectable({
  providedIn: 'root'
})
export class AnimateurService {

  URL_API_LOCAL = myGlobals.API_REST_URL + 'coAffectation.php';
  /*Headers*/
  headers = new HttpHeaders();

  constructor(private httpQuery: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }

  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  getAllAnimateurs = (): Observable<Animateur[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('type', '5');
    return this.httpQuery.post<Animateur[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /**
   * Récupération des cordinateurs selon la sous préfecture
   * @param Param
   */
  getAllAnimateursBy = (Param: string): Observable<Animateur[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('type', '5');
    FORMDATA.append('valeur', Param);
    return this.httpQuery.post<Animateur[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTBY_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  addAnimateur = (animateur: Animateur): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('affectationId', animateur.affectationId);
    FORMDATA.append('acteurId', animateur.acteurId);
    FORMDATA.append('blockId', animateur.blockId);
    FORMDATA.append('type', animateur.type);
    FORMDATA.append('createdBy', animateur.createdBy);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${animateur.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteAnimateur = (affectationId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('affectationId', affectationId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.DELETEBY_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
}
