<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2>
          <strong>List des campagnes </strong>
          <small>Choisir la campagne pour laquelle vous vous connectez...</small>
        </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <div class="body">
        <div class="list-group">
          <a href="javascript:void(0);" class="list-group-item" *ngFor="let item of this.lstCampagne.selected" (click)="onClickCampagneSelected(item.campagneId)">{{item.libelle}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
