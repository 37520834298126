import { ToastrService } from 'ngx-toastr';
import { UtilisateursService } from './../../Services/utilisateurs.service';
import { Membrecl } from './../../Models/membrecl.model';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {AffectationconfirmationComponent} from '../affectationconfirmation/affectationconfirmation.component';
import {AffectationService} from '../../Services/affectation.service';
import {Affectation} from '../../Models/affectation.model';
import {CordinateurService} from '../../Services/cordinateur.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-detailsmembresection',
  templateUrl: './detailsmembresection.component.html',
  styleUrls: ['./detailsmembresection.component.css']
})
export class DetailsmembresectionComponent implements OnInit {
  tableDataSource = new MatTableDataSource<Membrecl>();
  affectation = new Affectation();
  private dialogConfig;
  public displayedColumns = [ 'Electeur', 'Nom', 'Prenoms', 'Gender', 'Email', 'Phone', 'Rôle', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private router: Router, public svceMembre: UtilisateursService, public svceAffectation: AffectationService, public svceCoordinateur: CordinateurService , private toastr: ToastrService , public dialogRef: MatDialogRef<DetailsmembresectionComponent>) { }

  ngOnInit(): void {
    // console.log(this.data['section']);
    this.getAllMembresBy(this.data['section'].blocId);
  }
  getAllMembresBy = (sectionId: string) => {
    this.svceMembre.getAllMembresBySection(sectionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.toastr.warning('Aucun militant trouvé pour la section ' + this.data['section']['libelle'] + '.', 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
    this.dialogRef.close(true);
  }
  addAffectation = (membreId: string, libelle: string, type: number, affectationId: string) => {
   // if ()
    console.log(membreId + ' ' + this.data['section'].blocId);
    this.affectation.blocId = this.data['section'].blocId;
    this.affectation.acteurId = membreId;
    this.affectation.affectationId = '';
    this.affectation.status = 1;
    this.affectation.actionType = 'add';
    this.affectation.type = 4;
    this.affectation.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': membreId,
        'libelle': libelle + ' en tant que Coordinateur',
        'type': type
      }
    };
    const dialogRef = this.dialog.open(AffectationconfirmationComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        // tslint:disable-next-line:triple-equals
        if (type == 0){
            this.svceAffectation.addAffectation(this.affectation).subscribe(
              (res) => {
                if (res !== null && !res['status']){
                  this.toastr.success('Animateur nommé avec succès', 'Message', {
                    timeOut: 3000,
                  });
                  this.getAllMembresBy(this.data['section'].blocId);
                }
                else {
                  if (res !== null && res['status']){
                    this.toastr.warning(res['message'], 'Message', {
                      timeOut: 3000,
                    });
                  }
                  else{
                    console.log(res);
                  }
                }
              }
            );
        } else {
          console.log('retirer ' + affectationId + ' ' + type);
          this.svceCoordinateur.deleteCordinateur(affectationId).subscribe(
            (res) => {
              if (res !== null && !res['status']){
                this.toastr.success('Affectation supprimée avec succès', 'Message', {
                  timeOut: 3000,
                });
                this.getAllMembresBy(this.data['section'].blocId);
              }
              else {
                if (res !== null && res['status']){
                  this.toastr.warning(res['message'], 'Message', {
                    timeOut: 3000,
                  });
                }
                else{
                  console.log(res);
                }
              }
            },
            (error) => {}
          );
        }
      }
    });
  }
}
