import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Membrecl} from '../../Models/membrecl.model';
import {Affectation} from '../../Models/affectation.model';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import {AffectationService} from '../../Services/affectation.service';
import {CordinateurService} from '../../Services/cordinateur.service';
import {ToastrService} from 'ngx-toastr';
import {AffectationconfirmationComponent} from '../affectationconfirmation/affectationconfirmation.component';

@Component({
  selector: 'app-detailsmembre-commune',
  templateUrl: './detailsmembre-commune.component.html',
  styleUrls: ['./detailsmembre-commune.component.css']
})
export class DetailsmembreCommuneComponent implements OnInit {
  tableDataSource = new MatTableDataSource<Membrecl>();
  affectation = new Affectation();
  private dialogConfig;
  public displayedColumns = [ 'Electeur', 'Nom&Prenoms', 'Gender', 'Email', 'Phone', 'Rôle', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private router: Router, public svceMembre: UtilisateursService, public svceAffectation: AffectationService, public svceCoordinateur: CordinateurService , private toastr: ToastrService , public dialogRef: MatDialogRef<DetailsmembreCommuneComponent>) { }

  ngOnInit(): void {
    console.log(this.data.commune);
    this.getAllMembresBy(this.data.commune.blocId);
  }
  getAllMembresBy = (communeId: string) => {
    this.svceMembre.getAllMembresByCommune(communeId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.toastr.warning('Aucun militant trouvé pour la commune ' + this.data.commune.libelle + '.', 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
    this.dialogRef.close(true);
  }
  addAffectation = (membreId: string, libelle: string, type: number, affectationId: string) => {
    this.affectation.blocId = this.data.commune.blocId;
    this.affectation.acteurId = membreId;
    this.affectation.affectationId = '';
    this.affectation.status = 1;
    this.affectation.actionType = 'add';
    this.affectation.type = 3;
    this.affectation.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        itemId: membreId,
        libelle: libelle + ' en tant que Secrétaire',
        type
      }
    };
    const dialogRef = this.dialog.open(AffectationconfirmationComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        if (type == 0){
          this.svceAffectation.addAffectation(this.affectation).subscribe(
            (res) => {
              if (res !== null && !res['status']){
                this.toastr.success('Secrétaire nommé avec succès', 'Message', {
                  timeOut: 3000,
                });
                this.getAllMembresBy(this.data.commune.blocId);
              }
              else {
                if (res !== null && res['status']){
                  this.toastr.warning(res['message'], 'Message', {
                    timeOut: 3000,
                  });
                }
                else{
                  console.log(res);
                }
              }
            }
          );
        } else {
          this.svceCoordinateur.deleteCordinateur(affectationId).subscribe(
            (res) => {
              if (res !== null && !res['status']){
                this.toastr.success('Affectation supprimée avec succès', 'Message', {
                  timeOut: 3000,
                });
                this.getAllMembresBy(this.data.commune.blocId);
              }
              else {
                if (res !== null && res['status']){
                  this.toastr.warning(res['message'], 'Message', {
                    timeOut: 3000,
                  });
                }
                else{
                  console.log(res);
                }
              }
            },
            (error) => {}
          );
        }
      }
    });
  }
}
