import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ObjectifService} from '../../Services/objectif.service';
import {AffectationObjectifcl} from '../../Models/affectation-objectifcl.model';
import {AffectationobjectifService} from '../../Services/affectationobjectif.service';
import {FormGroup} from '@angular/forms';
import {DepartementService} from '../../Services/departement.service';
import {Departementcl} from '../../Models/departementcl.model';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-affectationobjectifmodal',
  templateUrl: './affectationobjectifmodal.component.html',
  styleUrls: ['./affectationobjectifmodal.component.css'],
  providers: [DatePipe],
})
export class AffectationobjectifmodalComponent implements OnInit {
  public lstDepartement: Departementcl[];
  form: FormGroup;
  minDate: Date = new Date();
  // tslint:disable-next-line:max-line-length variable-name
  constructor(@Inject(MAT_DIALOG_DATA) public data: AffectationObjectifcl, public datepipe: DatePipe, private toastr: ToastrService, private svceAffectationObjectif: AffectationobjectifService, private svceObjectif: ObjectifService, private svceDepartement: DepartementService, public dialogRef: MatDialogRef<AffectationobjectifmodalComponent>) { }

  ngOnInit(): void {
    if (this.data['affectationObjectif'].actionType === 'add')
    {
      this.getAllDepartementsByObjectif();
    } else {
      this.getAllDepartements();
    }
  }
  getAllDepartements = () => {
    this.svceDepartement.getAllDepartement().subscribe(
      (result) => {
        this.lstDepartement = result['donnees'];
        console.log(result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllDepartementsByObjectif = () => {
    this.svceAffectationObjectif.getAllDepartementByObjectif(this.data['affectationObjectif'].objectifId).subscribe(
      (result) => {
        this.lstDepartement = result['donnees'];
        console.log(result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  close = () => {
    this.dialogRef.close(false);
  }
  crudAffectationObjectif = (affectationObjectif: AffectationObjectifcl, type: string) => {
    this.svceAffectationObjectif.addAffectationObjectif(affectationObjectif).subscribe(
      (res) => {
        console.log(res);
        switch (type) {
          case 'add':
            this.toastr.success('Affectation insérée avec succès', 'Message', {
              timeOut: 3000,
            });
            this.getAllDepartementsByObjectif();
            break;
          case 'update':
            this.toastr.success('Affectation modifiée avec succès', 'Message', {
              timeOut: 3000,
            });
            this.dialogRef.close(true);
            this.getAllDepartementsByObjectif();
            break;
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  onAdd = (affectationObjectif: AffectationObjectifcl) => {
    switch (affectationObjectif.actionType){
      case 'add':
        this.crudAffectationObjectif(affectationObjectif, affectationObjectif.actionType);
        break;
      case 'update':
        this.crudAffectationObjectif(affectationObjectif, affectationObjectif.actionType);
        break;
      default:
        break;
    }
  }
}
