import {Actioncl} from './actioncl.model';

export class Equipecl extends Actioncl{
  id: number;
  equipeId: string;
  blockId: string;
  type: number;
  acteurId: string;
  membreId: string;
  roleequipeId: string;
  createdBy: string;
  createdOn: string;
  status: number;
}
