import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Sectioncl} from '../Models/sectioncl.model';
import {catchError, retry} from 'rxjs/operators';
import {Campagnecl} from '../Models/campagnecl.model';
import {Checklistcl} from '../Models/checklistcl.model';
import {Particampagecl} from '../Models/particampagecl.model';

@Injectable({
  providedIn: 'root'
})
export class CampagneService {

  URL_API_LOCAL = myGlobals.API_REST_URL + 'coCampagne.php';
  URL_API_LOCAL_PARTI = myGlobals.API_REST_URL + 'coPartiCampagne.php';
  /*Headers*/
    headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  /* Récupération des Comités de base actifs du système
   Bamba Aboubacar : 17-08-2020
   params: null // Prends aucun paramètre
  */
  getAllCampagne = (): Observable<Campagnecl> => {
    /*this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coComiteBase.php';*/
    return this.httpQuery.get<Campagnecl>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION , {headers: this.headers })
      .pipe(retry(3), catchError(this.handleError));
  }
  addCampagne = (campagne: Campagnecl): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('campagneId', campagne.campagneId);
    FORMDATA.append('libelle', campagne.libelle);
    FORMDATA.append('scrutin', this.getMMDDYYYY(campagne.scrutin));
    FORMDATA.append('lancement', this.getMMDDYYYY(campagne.lancement));
    FORMDATA.append('slogan', campagne.slogan);
    FORMDATA.append('createdBy', campagne.createdBy);
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + (campagne.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION)  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteCampagne = (campagneId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('campagneId', campagneId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.DELETEBY_ACTION  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  addorUpdatePartiCampagne = (Particampage: Particampagecl): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('campagneId', Particampage.IdCampagne);
    FORMDATA.append('partiCampagneId', Particampage.partiCampagneId);
    FORMDATA.append('partiId', Particampage.partiId);
    FORMDATA.append('etat', Particampage.etat);
    FORMDATA.append('createdBy', Particampage.createdBy);
    // console.log(checkList);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL_PARTI}?Action=${Particampage.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllPartiByCampagne = (campagneId: string): Observable<Particampagecl> => {
    /*this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coComiteBase.php';*/
    const FORMDATA: any = new FormData();
    FORMDATA.append('campagneId', campagneId);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${ myGlobals.SELECT_ACTION}`  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getMMDDYYYY = (date) => {
    let m: string;
    let d: string;
    let h: string;
    let min: string;
    let s: string;
    const today = new Date(date);
    const dd: number = today.getDate();
    const mm: number = today.getMonth() + 1;
    const ho: number = today.getHours();
    const mi: number = today.getMinutes();
    const se: number = today.getSeconds();
    const yyyy = today.getFullYear();
    if (dd < 10) {
      d = '0' + dd;
    } else {
      d = dd + '';
    }
    if (mm < 10) {
      m = '0' + mm;
    } else {
      m = mm + '';
    }
    if (ho < 10) {
      h = '0' + ho;
    } else {
      h = ho + '';
    }
    if (mi < 10) {
      min = '0' + mi;
    } else {
      min = mi + '';
    }
    if (se < 10) {
      s = '0' + se;
    } else {
      s = se + '';
    }
    return yyyy + '/' + m + '/' + d + ' ' + h + ':' + min + ':' + s;
  }
}
