<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>List des Militants - {{this.data['libelle']}} </strong> </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <form id="detailsmilitantForm" #detailsmilitantForm="ngForm" action="post">
        <div class="body">
          <div class="col-sm-12 col-lg-auto">
            <div class="row">
              <div class="col-sm-10 col-lg-10">
                <div class="input-group search">
                  <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                  <!-- <span class="input-group-addon">
                      <i class="zmdi zmdi-search"></i>
                  </span> -->
                </div>
              </div>
              <div class="col-sm-2 col-lg-2  text-right processBtn">
                <button (click)="addMembre(this.membre)" mat-icon-button class="btn btn-icon btn-neutral btn-icon-mini">
                  <i class="material-icons add">person_add</i>
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <div class="col-sm-12 col-lg-auto">
              <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                <ng-container matColumnDef="Electeur">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Code Electeur </th>
                  <td mat-cell *matCellDef="let element"> {{element.numeroElecteur}} </td>
                </ng-container>
                <ng-container matColumnDef="Nom">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
                  <td mat-cell *matCellDef="let element"> <a (click)="redirectToDetails(element.membreId)" >{{element.nom}}</a> </td>
                </ng-container>
                <ng-container matColumnDef="Prenoms">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom(s) </th>
                  <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
                </ng-container>
                <ng-container matColumnDef="Gender">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Sexe </th>
                  <td mat-cell *matCellDef="let element"> {{element.sex == 'M' ? 'Masculin' : 'Fénimin'}} </td>
                </ng-container>
                <ng-container matColumnDef="Email">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
                  <td mat-cell *matCellDef="let element"> <a href="mailto:{{ element.email === '' || element.email == 'null' ?  '' :  element.email }}">{{ element.email === '' || element.email == 'null' ?  '' :  element.email }}</a></td>
                </ng-container>
                <ng-container matColumnDef="Rôle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Rôle </th>
                  <td mat-cell *matCellDef="let element">  <span *ngIf="element.AnimateurStatut == 1"  class="badge badge-success">ANIMATEUR</span><span *ngIf="element.AnimateurStatut == 0" class="badge badge-pill">MILITANT</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Phone">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Contacts </th>
                  <td mat-cell *matCellDef="let element"><span class="phone"  ><i *ngIf=" element.contact !== '' && element.contact !== null" class="zmdi zmdi-phone m-r-5"> </i>{{ element.contact === '' || element.contact === null ?  (element.contact | mask: '(00) 00-00-00') :  (element.contact | mask: '(00) 00-00-00') }}</span> </td>
                </ng-container>
                <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef style="width: 140px;text-align: center"> Actions </th>
                  <td mat-cell *matCellDef=" let element">
                    <button [disabled]="element.AnimateurStatut == 0 ? element.AnimateurActif == 1 ? true : false : false" mat-icon-button (click)="addAffectation(element.membreId, element.nom + ' ' + element.prenom, element.AnimateurStatut, element.affectationId)" class="btn btn-icon btn-neutral  btn-icon-mini animateur" >
                      <span *ngIf="element.AnimateurStatut == 1"   class="badge badge-danger">Rétirer</span><span *ngIf="element.AnimateurStatut == 0" class="badge badge-success">Nommer</span>
                    </button>
                   <!--<button mat-icon-button class="btn btn-icon btn-neutral  btn-icon-mini" (click)="redirectToDelete(element.affectationMembreId, (' le millitant '+ element.nom +' '+ element.prenom+ ' de son comité de base'))" >
                      <i class="material-icons">delete_outline</i>
                    </button>-->
                    <button mat-icon-button (click)="redirectToDelete(element.affectationMembreId, (' le millitant '+ element.nom +' '+ element.prenom+ ' de son comité de base'))" class="btn btn-icon btn-neutral  btn-icon-mini animateur" >
                      <span class="badge badge-danger">Supprimer</span>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Loading">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Chargement en cours....
                  </th>
                </ng-container>
                <ng-container matColumnDef="NoData">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Aucunes données trouvées....
                  </th>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10"  showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
