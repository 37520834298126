import {Actioncl} from './actioncl.model';

export class Checklistcl extends Actioncl{
  id: string;
  checkListId: string;
  affectationMissionId: string;
  membreId: string;
  etat: number;
  comment: string;
  status: number;
  createdBy: string;
  createdOn: string;
}
