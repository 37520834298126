import {Actioncl} from './actioncl.model';

export class Campagnecl extends Actioncl{
  campagneId: string;
  accessId: string;
  libelle: string;
  etat: number;
  scrutin: Date;
  lancement: Date;
  slogan: string;
  status: number;
  createdBy: string;
  createdOn: string;
}
