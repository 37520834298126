<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Parti</strong> </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <form id="partiForm" #partiForm="ngForm" action="post">
        <div class="body">
          <div class="row clearfix">
            <div class="col-sm-12">
              <mat-form-field class="example-full-width">
                <mat-label>Libelle</mat-label>
                <input matInput [(ngModel)]="data['parti'].libelle" name="libelle" placeholder="Libelle" required>
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <mat-checkbox class="" [checked]="data['parti'].mutipleCandidat == 1 ? true : false" [value]="data['parti'].mutipleCandidat " (change)="onMutipleCandidatChange($event)" name="mutipleCandidat">Ce parti prend en compte plusieurs candidats ?</mat-checkbox>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <mat-checkbox class=""  [checked]="data['parti'].principal == 1 ? true : false" [value]="data['parti'].principal " (change)="onPrincipalChange($event)" name="principal">Cet parti est-il le parti principal ?</mat-checkbox>
              </div>
            </div>
            <div class="col-sm-12" align="end">
              <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close()" >ANNULER</button>
              <button mat-button [disabled]="!partiForm.form.valid" class="btn btn-primary waves-effect m-r-20" (click)="onAdd(data['parti'])" cdkFocusInitial>VALIDER</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- <mat-dialog-actions align="end">
  <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>Cancel</button>
  <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
