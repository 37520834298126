import { Membrecl } from '../../Models/membrecl.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilisateursService } from '../../Services/utilisateurs.service';
import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FounddetailsmilitantComponent} from '../founddetailsmilitant/founddetailsmilitant.component';
import Swal from 'sweetalert2';
import {isNull} from 'util';

@Component({
  selector: 'app-recherche-membre',
  templateUrl: './recherche-membre.component.html',
  styleUrls: ['./recherche-membre.component.css']
})
export class RechercheMembreComponent implements OnInit {
  private dialogConfig;
  membre = new Membrecl();
  constructor(@Inject(MAT_DIALOG_DATA) public data: Membrecl, private router: Router, private dialog: MatDialog, public dialogRef: MatDialogRef<RechercheMembreComponent>, private svceMembre: UtilisateursService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.membre.numeroElecteur = localStorage.getItem('numeroElecteur');
    localStorage.removeItem('numeroElecteur');
  }
  close = (param: boolean) => {
    this.dialogRef.close(param);
  }
  onRechercher = () => {
    this.svceMembre.SearchAllMillitantByCode(this.membre).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          if ( parseInt(res['donnees']['Nbre']) === 0)
          {
            const newMembre = res['donnees'] as Membrecl;
            newMembre.comiteBaseId = this.data['membre'].comiteBaseId;
            if (newMembre.membreId === '' || newMembre.membreId === null) {
              newMembre.membreId = '';
            }
            console.log(newMembre);
            newMembre.actionType = this.data['membre'].actionType;
            newMembre.createdBy = localStorage.getItem('USER_CONNECTED_ID');
            newMembre.parrainId = this.data['membre'].parrainId;
            if (newMembre.actionType === 'parrain' && newMembre.membreId !== ''){
              this.toastr.warning('Ce militant existe déjà dans notre base.', 'Message', {
                timeOut: 3000,
              });
            } else {
              this.toastr.success('Electeur trouvé sur la liste electorale.', 'Message', {
                timeOut: 3000,
              });
              this.openConfirmationDetailsMembre(newMembre);
            }
          }
          else{

            this.toastr.warning('Electeur trouvé sur la liste electorale mais existant dans la liste des militant.', 'Message', {
              timeOut: 3000,
            });
          }
        }
        else{
          if (res !== null && res['status']){
            Swal.fire({
              title: 'Aucun élément trouvé dans le système',
              icon: 'warning',
              confirmButtonColor: '#f3a704',
              cancelButtonColor: '#888',
              showCancelButton: true,
              confirmButtonText: 'Renseigner les informations',
              cancelButtonText: 'Nouvelle recherche'
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['dashboard/membreDetails', '']) ;
                this.dialogRef.close(true);
              }
            });
            /*this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });*/
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        this.toastr.warning('Erreur lors de la recherche. Veuillez réessayer svp et si cela persiste contacter l\'administrateur.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  openConfirmationDetailsMembre = (membre: Membrecl) => {
    this.dialogConfig = {
      width: '400px',
      disableClose: true,
      position: {top: '1%'},
      data: {
        membre
      }
    };
    const dialogRef = this.dialog.open(FounddetailsmilitantComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
        this.close(true);
      }
    });
    // this.router.navigate(['dashboard/nouveauMembre']) ;
  }
  /* onRechercher = (codeElecteur: string) => {
    this.svceMembre.SearchAllMillitantByCode(codeElecteur).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          if ( parseInt(res['donnees']['Nbre']) === 0)
          {
            this.dialogRef.close(true);
            this.toastr.success('Electeur trouvé sur la liste electorale.', 'Message', {
              timeOut: 3000,
            });
            const newMembre = res['donnees'] as Membrecl ;
            localStorage.setItem("mewMillitant", JSON.stringify(newMembre));
            this.router.navigate(['dashboard/nouveauMembre']) ;
          }
          else{

            this.toastr.warning('Electeur trouvé sur la liste electorale mais existant dans la liste des millitant.', 'Message', {
              timeOut: 3000,
            });
          }
        }
        else{
          if (res !== null && res['status']){
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        // tslint:disable-next-line:max-line-length
        // tslint:disable-next-line:max-line-length
        // tslint:disable-next-line:max-line-length
        // tslint:disable-next-line:max-line-length
        // tslint:disable-next-line:max-line-length
        this.toastr.warning('Erreur lors de la recherche. Veuillez réessayer svp et si cela persiste contacter l\'administrateur.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  } */
}
