<!-- Page Loader -->
<div class="page-loader-wrapper">
  <div class="loader">
      <div class="m-t-30"><img class="zmdi-hc-spin" src="../assets/images/logo.svg" width="48" height="48" alt="Oreo"></div>
      <p>Please wait...</p>
  </div>
</div>
<!-- Overlay For Sidebars -->
<div class="overlay"></div>
<nav class="navbar p-l-5 p-r-5">
<ul class="nav navbar-nav navbar-left">
<li>
  <div class="navbar-header">
      <a href="javascript:void(0);" class="bars"></a>
      <a class="navbar-brand" href="../dashboard/index.html"><img src="../assets/images/logo.svg" width="30" alt="Oreo"><span class="m-l-10">Oreo</span></a>
  </div>
</li>
<li><a href="javascript:void(0);" class="ls-toggle-btn" data-close="true"><i class="zmdi zmdi-swap"></i></a></li>
<li class="d-none d-lg-inline-block"><a href="../doctor/events.html" title="Events"><i class="zmdi zmdi-calendar"></i></a></li>
<li class="d-none d-lg-inline-block"><a href="../app/inbox.html" title="Inbox"><i class="zmdi zmdi-email"></i></a></li>
<li><a href="../app/contact-list.html" title="Contact List"><i class="zmdi zmdi-account-box-phone"></i></a></li>
<li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button"><i class="zmdi zmdi-notifications"></i>
  <div class="notify"><span class="heartbit"></span><span class="point"></span></div>
  </a>
  <ul class="dropdown-menu pullDown">
      <li class="body">
          <ul class="menu list-unstyled">
              <li>
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object" src="../assets/images/xs/avatar2.jpg" alt="">
                          <div class="media-body">
                              <span class="name">Sophia <span class="time">30min ago</span></span>
                              <span class="message">There are many variations of passages</span>
                          </div>
                      </div>
                  </a>
              </li>
              <li>
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object" src="../assets/images/xs/avatar3.jpg" alt="">
                          <div class="media-body">
                              <span class="name">Sophia <span class="time">31min ago</span></span>
                              <span class="message">There are many variations of passages of Lorem Ipsum</span>
                          </div>
                      </div>
                  </a>
              </li>
              <li>
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object" src="../assets/images/xs/avatar4.jpg" alt="">
                          <div class="media-body">
                              <span class="name">Isabella <span class="time">35min ago</span></span>
                              <span class="message">There are many variations of passages</span>
                          </div>
                      </div>
                  </a>
              </li>
              <li>
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object" src="../assets/images/xs/avatar5.jpg" alt="">
                          <div class="media-body">
                              <span class="name">Alexander <span class="time">35min ago</span></span>
                              <span class="message">Contrary to popular belief, Lorem Ipsum random</span>
                          </div>
                      </div>
                  </a>
              </li>
              <li>
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object" src="../assets/images/xs/avatar6.jpg" alt="">
                          <div class="media-body">
                              <span class="name">Grayson <span class="time">1hr ago</span></span>
                              <span class="message">There are many variations of passages</span>
                          </div>
                      </div>
                  </a>
              </li>
          </ul>
      </li>
      <li class="footer"> <a href="javascript:void(0);">View All</a> </li>
  </ul>
</li>
<li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button"><i class="zmdi zmdi-flag"></i>
  <div class="notify">
      <span class="heartbit"></span>
      <span class="point"></span>
  </div>
  </a>
  <ul class="dropdown-menu pullDown">
      <li class="header">Project</li>
      <li class="body">
          <ul class="menu tasks list-unstyled">
              <li>
                  <a href="javascript:void(0);">
                      <div class="progress-container progress-primary">
                          <span class="progress-badge">Neurology</span>
                          <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="86" aria-valuemin="0" aria-valuemax="100" style="width: 86%;">
                                  <span class="progress-value">86%</span>
                              </div>
                          </div>
                          <ul class="list-unstyled team-info">
                              <li class="m-r-15"><small class="text-muted">Team</small></li>
                              <li>
                                  <img src="../assets/images/xs/avatar2.jpg" alt="Avatar">
                              </li>
                              <li>
                                  <img src="../assets/images/xs/avatar3.jpg" alt="Avatar">
                              </li>
                              <li>
                                  <img src="../assets/images/xs/avatar4.jpg" alt="Avatar">
                              </li>
                          </ul>
                      </div>
                  </a>
              </li>
              <li>
                  <a href="javascript:void(0);">
                      <div class="progress-container progress-info">
                          <span class="progress-badge">Gynecology</span>
                          <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style="width: 45%;">
                                  <span class="progress-value">45%</span>
                              </div>
                          </div>
                          <ul class="list-unstyled team-info">
                              <li class="m-r-15"><small class="text-muted">Team</small></li>
                              <li>
                                  <img src="../assets/images/xs/avatar10.jpg" alt="Avatar">
                              </li>
                              <li>
                                  <img src="../assets/images/xs/avatar9.jpg" alt="Avatar">
                              </li>
                              <li>
                                  <img src="../assets/images/xs/avatar8.jpg" alt="Avatar">
                              </li>
                              <li>
                                  <img src="../assets/images/xs/avatar7.jpg" alt="Avatar">
                              </li>
                              <li>
                                  <img src="../assets/images/xs/avatar6.jpg" alt="Avatar">
                              </li>
                          </ul>
                      </div>
                  </a>
              </li>
              <li>
                  <a href="javascript:void(0);">
                      <div class="progress-container progress-warning">
                          <span class="progress-badge">Cardio Monitoring</span>
                          <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="29" aria-valuemin="0" aria-valuemax="100" style="width: 29%;">
                                  <span class="progress-value">29%</span>
                              </div>
                          </div>
                          <ul class="list-unstyled team-info">
                              <li class="m-r-15"><small class="text-muted">Team</small></li>
                              <li>
                                  <img src="../assets/images/xs/avatar5.jpg" alt="Avatar">
                              </li>
                              <li>
                                  <img src="../assets/images/xs/avatar2.jpg" alt="Avatar">
                              </li>
                              <li>
                                  <img src="../assets/images/xs/avatar7.jpg" alt="Avatar">
                              </li>
                          </ul>
                      </div>
                  </a>
              </li>
          </ul>
      </li>
      <li class="footer"><a href="javascript:void(0);">View All</a></li>
  </ul>
</li>
<li class="d-none d-md-inline-block">
  <div class="input-group">
      <input type="text" class="form-control" placeholder="Search...">
      <span class="input-group-addon">
          <i class="zmdi zmdi-search"></i>
      </span>
  </div>
</li>
<li class="float-right">
  <a href="../authentication/login.html" class="mega-menu" data-close="true"><i class="zmdi zmdi-power"></i></a>
  <a href="javascript:void(0);" class="js-right-sidebar" data-close="true"><i class="zmdi zmdi-settings zmdi-hc-spin"></i></a>
</li>
</ul>
</nav>        <!-- Left Sidebar -->
<aside id="leftsidebar" class="sidebar">
<ul class="nav nav-tabs">
<li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#dashboard"><i class="zmdi zmdi-home m-r-5"></i>Oreo</a></li>
<li class="nav-item"><a class="nav-link" data-toggle="tab" href="#user">Docter</a></li>
</ul>
<div class="tab-content">
<div class="tab-pane stretchRight active" id="dashboard">
  <div class="menu">
      <ul class="list">
          <li>
              <div class="user-info">
                  <div class="image"><a href="../doctor/profile.html"><img src="../assets/images/profile_av.jpg" alt="User"></a></div>
                  <div class="detail">
                      <h4>Dr. Charlotte</h4>
                      <small>Neurologist</small>
                  </div>
              </div>
          </li>
          <li class="header">MAIN</li>

          <li class=""><a href="../dashboard/index.html"><i class="zmdi zmdi-home"></i><span>Dashboard</span></a></li>
          <li class=""><a href="../appointment/book-appointment.html"><i class="zmdi zmdi-calendar-check"></i><span>Appointment</span></a></li>
          <li class="">
              <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-account-add"></i><span>Doctors</span></a>
              <ul class="ml-menu">
                  <li class=""><a href="../doctor/doctors.html">All Doctors</a></li>
                  <li class=""><a href="../doctor/add-doctor.html">Add Doctor</a></li>
                  <li class=""><a href="../doctor/profile.html">Doctor Profile</a></li>
                  <li class=""><a href="../doctor/events.html">Doctor Schedule</a></li>
              </ul>
          </li>
          <li class="">
              <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-account-o"></i><span>Patients</span></a>
              <ul class="ml-menu">
                  <li class=""><a href="../patients/all-patients.html">All Patients</a></li>
                  <li class=""><a href="../patients/add-patients.html">Add Patient</a></li>
                  <li class=""><a href="../patients/patients-profile.html">Patient Profile</a></li>
                  <li class=""><a href="../patients/invoice.html">Invoice</a></li>
              </ul>
          </li>
          <li class="">
              <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-balance-wallet"></i><span>Payments</span></a>
              <ul class="ml-menu">
                  <li class=""><a href="../payment/all-payment.html">All Payments</a></li>
                  <li class=""><a href="../payment/add-payment.html">Add Payment</a></li>
                  <li class=""><a href="../patients/invoice.html">Invoice</a></li>
              </ul>
          </li>
          <li class="">
              <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-label-alt"></i><span>Departments</span></a>
              <ul class="ml-menu">
                  <li class=""><a href="../departments/add-department.html">Add</a></li>
                  <li class=""><a href="../departments/all-departments.html">All Departments</a></li>
                  <li><a href="javascript:void(0);">Cardiology</a></li>
                  <li><a href="javascript:void(0);">Pulmonology</a></li>
                  <li><a href="javascript:void(0);">Gynecology</a></li>
                  <li><a href="javascript:void(0);">Neurology</a></li>
                  <li><a href="javascript:void(0);">Urology</a></li>
                  <li><a href="javascript:void(0);">Gastrology</a></li>
                  <li><a href="javascript:void(0);">Pediatrician</a></li>
                  <li><a href="javascript:void(0);">Laboratory</a></li>
              </ul>
          </li>
          <li>
              <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-lock"></i><span>Authentication</span></a>
              <ul class="ml-menu">
                  <li><a href="../authentication/login.html">Sign In</a></li>
                  <li><a href="../authentication/register.html">Sign Up</a></li>
                  <li><a href="../authentication/forgot-password.html">Forgot Password</a></li>
                  <li><a href="../authentication/page404.html">Page 404</a></li>
                  <li><a href="../authentication/page500.html">Page 500</a></li>
                  <li><a href="../authentication/page-offline.html">Page Offline</a></li>
                  <li><a href="../authentication/lockscreen.html">Locked Screen</a></li>
              </ul>
          </li>
          <li class="header">EXTRA COMPONENTS</li>
          <li class="">
              <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-blogger"></i><span>Blog</span></a>
              <ul class="ml-menu">
                  <li class=""><a href="../blog/dashboard.html">Dashboard</a></li>
                  <li class=""><a href="../blog/new-post.html">New Post</a></li>
                  <li class=""><a href="../blog/list.html">Blog List</a></li>
                  <li class=""><a href="../blog/grid.html">Blog Grid</a></li>
                  <li class=""><a href="../blog/detail.html">Blog Detail</a></li>
              </ul>
          </li>
          <li class="">
              <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-folder"></i><span>File Manager</span></a>
              <ul class="ml-menu">
                  <li class=""><a href="../file-manager/dashboard.html">All File</a></li>
                  <li class=""><a href="../file-manager/documents.html" >Documents</a></li>
                  <li class=""><a href="../file-manager/media.html">Media</a></li>
                  <li class=""><a href="../file-manager/image.html">Images</a></li>
              </ul>
          </li>
          <li class="">
              <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-apps"></i><span>App</span></a>
              <ul class="ml-menu">
                  <li class=""><a href="../app/inbox.html">Inbox</a></li>
                  <li class=""><a href="../app/chat.html">Chat</a></li>
                  <li class=""><a href="../app/contact-list.html">Contact list</a></li>
              </ul>
          </li>
          <li class="">
              <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-delicious"></i><span>Widgets</span></a>
              <ul class="ml-menu">
                  <li class=""><a href="../widgets/apps.html">Apps Widgetse</a></li>
                  <li class=""><a href="../widgets/data.html">Data Widgetse</a></li>
              </ul>
          </li>
          <li class="active open">
              <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-copy"></i><span>Sample Pages</span></a>
              <ul class="ml-menu">
                  <li class="active"><a href="blank-page.html">Blank Page</a></li>
                  <li class=""><a href="rtl.html">RTL Support</a></li>
                  <li class=""><a href="image-gallery.html">Image Gallery</a></li>
                  <li class=""><a href="profile.html">Profile</a></li>
                  <li class=""><a href="timeline.html">Timeline</a></li>
                  <li class=""><a href="invoices.html">Invoices</a></li>
                  <li class=""><a href="search-results.html">Search Results</a></li>
              </ul>
          </li>
          <li class="">
              <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-swap-alt"></i><span>User Interface (UI)</span></a>
              <ul class="ml-menu">
                  <li class=""><a href="../ui/ui-kit.html">UI KIT</a></li>
                  <li class=""><a href="../ui/alerts.html">Alerts</a></li>
                  <li class=""><a href="../ui/collapse.html">Collapse</a></li>
                  <li class=""><a href="../ui/colors.html">Colors</a></li>
                  <li class=""><a href="../ui/dialogs.html">Dialogs</a></li>
                  <li class=""><a href="../ui/icons.html">Icons</a></li>
                  <li class=""><a href="../ui/list-group.html">List Group</a></li>
                  <li class=""><a href="../ui/media-object.html">Media Object</a></li>
                  <li class=""><a href="../ui/modals.html">Modals</a></li>
                  <li class=""><a href="../ui/notifications.html">Notifications</a></li>
                  <li class=""><a href="../ui/progressbars.html">Progress Bars</a></li>
                  <li class=""><a href="../ui/range-sliders.html">Range Sliders</a></li>
                  <li class=""><a href="../ui/sortable-nestable.html">Sortable Nestable</a></li>
                  <li class=""><a href="../ui/tabs.html">Tabs</a></li>
                  <li class=""><a href="../ui/waves.html">Waves</a></li>
              </ul>
          </li>
          <li class="header">Extra</li>
          <li>
              <div class="progress-container progress-primary m-t-10">
                  <span class="progress-badge">Traffic this Month</span>
                  <div class="progress">
                      <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100" style="width: 67%;">
                          <span class="progress-value">67%</span>
                      </div>
                  </div>
              </div>
              <div class="progress-container progress-info">
                  <span class="progress-badge">Server Load</span>
                  <div class="progress">
                      <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="86" aria-valuemin="0" aria-valuemax="100" style="width: 86%;">
                          <span class="progress-value">86%</span>
                      </div>
                  </div>
              </div>
          </li>
      </ul>
  </div>
</div>
<div class="tab-pane stretchLeft" id="user">
  <div class="menu">
      <ul class="list">
          <li>
              <div class="user-info m-b-20 p-b-15">
                  <div class="image"><a href="../doctor/profile.html"><img src="../assets/images/profile_av.jpg" alt="User"></a></div>
                  <div class="detail">
                      <h4>Dr. Charlotte</h4>
                      <small>Neurologist</small>
                  </div>
                  <div class="row">
                      <div class="col-12">
                          <a title="facebook" href="#"><i class="zmdi zmdi-facebook"></i></a>
                          <a title="twitter" href="#"><i class="zmdi zmdi-twitter"></i></a>
                          <a title="instagram" href="#"><i class="zmdi zmdi-instagram"></i></a>
                      </div>
                      <div class="col-4 p-r-0">
                          <h5 class="m-b-5">18</h5>
                          <small>Exp</small>
                      </div>
                      <div class="col-4">
                          <h5 class="m-b-5">125</h5>
                          <small>Awards</small>
                      </div>
                      <div class="col-4 p-l-0">
                          <h5 class="m-b-5">148</h5>
                          <small>Clients</small>
                      </div>
                  </div>
              </div>
          </li>
          <li>
              <small class="text-muted">Location: </small>
              <p>795 Folsom Ave, Suite 600 San Francisco, CADGE 94107</p>
              <hr>
              <small class="text-muted">Email address: </small>
              <p>Charlotte@example.com</p>
              <hr>
              <small class="text-muted">Phone: </small>
              <p>+ 202-555-0191</p>
              <hr>
              <small class="text-muted">Website: </small>
              <p>http://dr.charlotte.com/ </p>
              <hr>
              <ul class="list-unstyled">
                  <li>
                      <div>Colorectal Surgery</div>
                      <div class="progress m-b-20">
                          <div class="progress-bar l-blue " role="progressbar" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100" style="width: 89%"> <span class="sr-only">62% Complete</span></div>
                      </div>
                  </li>
                  <li>
                      <div>Endocrinology</div>
                      <div class="progress m-b-20">
                          <div class="progress-bar l-green " role="progressbar" aria-valuenow="56" aria-valuemin="0" aria-valuemax="100" style="width: 56%"> <span class="sr-only">87% Complete</span></div>
                      </div>
                  </li>
                  <li>
                      <div>Dermatology</div>
                      <div class="progress m-b-20">
                          <div class="progress-bar l-amber" role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" style="width: 78%"> <span class="sr-only">32% Complete</span></div>
                      </div>
                  </li>
                  <li>
                      <div>Neurophysiology</div>
                      <div class="progress m-b-20">
                          <div class="progress-bar l-blush" role="progressbar" aria-valuenow="43" aria-valuemin="0" aria-valuemax="100" style="width: 43%"> <span class="sr-only">56% Complete</span></div>
                      </div>
                  </li>
              </ul>
          </li>
      </ul>
  </div>
</div>
</div>
</aside>
<!-- Right Sidebar -->
<aside id="rightsidebar" class="right-sidebar">
<ul class="nav nav-tabs">
<li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#setting"><i class="zmdi zmdi-settings zmdi-hc-spin"></i></a></li>
<li class="nav-item"><a class="nav-link" data-toggle="tab" href="#chat"><i class="zmdi zmdi-comments"></i></a></li>
<li class="nav-item"><a class="nav-link" data-toggle="tab" href="#activity">Activity</a></li>
</ul>
<div class="tab-content">
<div class="tab-pane slideRight active" id="setting">
  <div class="slim_scroll">
      <div class="card">
          <h6>General Settings</h6>
          <ul class="setting-list list-unstyled">
              <li>
                  <div class="checkbox">
                      <input id="checkbox1" type="checkbox">
                      <label for="checkbox1">Report Panel Usage</label>
                  </div>
              </li>
              <li>
                  <div class="checkbox">
                      <input id="checkbox2" type="checkbox" checked="">
                      <label for="checkbox2">Email Redirect</label>
                  </div>
              </li>
              <li>
                  <div class="checkbox">
                      <input id="checkbox3" type="checkbox" checked="">
                      <label for="checkbox3">Notifications</label>
                  </div>
              </li>
              <li>
                  <div class="checkbox">
                      <input id="checkbox4" type="checkbox" checked="">
                      <label for="checkbox4">Auto Updates</label>
                  </div>
              </li>
          </ul>
      </div>
      <div class="card">
          <h6>Skins</h6>
          <ul class="choose-skin list-unstyled">
              <li data-theme="purple">
                  <div class="purple"></div>
              </li>
              <li data-theme="blue">
                  <div class="blue"></div>
              </li>
              <li data-theme="cyan" class="active">
                  <div class="cyan"></div>
              </li>
              <li data-theme="green">
                  <div class="green"></div>
              </li>
              <li data-theme="orange">
                  <div class="orange"></div>
              </li>
              <li data-theme="blush">
                  <div class="blush"></div>
              </li>
          </ul>
      </div>
      <div class="card">
          <h6>Account Settings</h6>
          <ul class="setting-list list-unstyled">
              <li>
                  <div class="checkbox">
                      <input id="checkbox5" type="checkbox" checked="">
                      <label for="checkbox5">Offline</label>
                  </div>
              </li>
              <li>
                  <div class="checkbox">
                      <input id="checkbox6" type="checkbox" checked="">
                      <label for="checkbox6">Location Permission</label>
                  </div>
              </li>
          </ul>
      </div>
      <div class="card theme-light-dark">
          <h6>Left Menu</h6>
          <button class="t-light btn btn-default btn-simple btn-round btn-block">Light</button>
          <button class="t-dark btn btn-default btn-round btn-block">Dark</button>
<button class="m_img_btn btn btn-primary btn-round btn-block">Sidebar Image</button>
      </div>
      <div class="card">
          <h6>Information Summary</h6>
          <div class="row m-b-20">
              <div class="col-7">
                  <small class="displayblock">MEMORY USAGE</small>
                  <h5 class="m-b-0 h6">512</h5>
              </div>
              <div class="col-5">
                  <div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#00ced1">8,7,9,5,6,4,6,8</div>
              </div>
          </div>
          <div class="row m-b-20">
              <div class="col-7">
                  <small class="displayblock">CPU USAGE</small>
                  <h5 class="m-b-0 h6">90%</h5>
              </div>
              <div class="col-5">
                  <div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#F15F79">6,5,8,2,6,4,6,4</div>
              </div>
          </div>
          <div class="row m-b-20">
              <div class="col-7">
                  <small class="displayblock">DAILY TRAFFIC</small>
                  <h5 class="m-b-0 h6">25 142</h5>
              </div>
              <div class="col-5">
                  <div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#78b83e">7,5,8,7,4,2,6,5</div>
              </div>
          </div>
          <div class="row">
              <div class="col-7">
                  <small class="displayblock">DISK USAGE</small>
                  <h5 class="m-b-0 h6">60.10%</h5>
              </div>
              <div class="col-5">
                  <div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#457fca">7,5,2,5,6,7,6,4</div>
              </div>
          </div>
      </div>
  </div>
</div>
<div class="tab-pane right_chat stretchLeft" id="chat">
  <div class="slim_scroll">
      <div class="card">
          <div class="search">
              <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search...">
                  <span class="input-group-addon">
                      <i class="zmdi zmdi-search"></i>
                  </span>
              </div>
          </div>
      </div>
      <div class="card">
          <h6>Recent</h6>
          <ul class="list-unstyled">
              <li class="online">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar4.jpg" alt="">
                          <div class="media-body">
                              <span class="name">Sophia</span>
                              <span class="message">There are many variations of passages of Lorem Ipsum available</span>
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="online">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar5.jpg" alt="">
                          <div class="media-body">
                              <span class="name">Grayson</span>
                              <span class="message">All the Lorem Ipsum generators on the</span>
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="offline">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar2.jpg" alt="">
                          <div class="media-body">
                              <span class="name">Isabella</span>
                              <span class="message">Contrary to popular belief, Lorem Ipsum</span>
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="me">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar1.jpg" alt="">
                          <div class="media-body">
                              <span class="name">John</span>
                              <span class="message">It is a long established fact that a reader</span>
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="online">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar3.jpg" alt="">
                          <div class="media-body">
                              <span class="name">Alexander</span>
                              <span class="message">Richard McClintock, a Latin professor</span>
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
          </ul>
      </div>
      <div class="card">
          <h6>Contacts</h6>
          <ul class="list-unstyled">
              <li class="offline inlineblock">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar10.jpg" alt="">
                          <div class="media-body">
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="offline inlineblock">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar6.jpg" alt="">
                          <div class="media-body">
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="offline inlineblock">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar7.jpg" alt="">
                          <div class="media-body">
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="offline inlineblock">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar8.jpg" alt="">
                          <div class="media-body">
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="offline inlineblock">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar9.jpg" alt="">
                          <div class="media-body">
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="online inlineblock">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar5.jpg" alt="">
                          <div class="media-body">
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="offline inlineblock">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar4.jpg" alt="">
                          <div class="media-body">
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="offline inlineblock">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar3.jpg" alt="">
                          <div class="media-body">
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="online inlineblock">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar2.jpg" alt="">
                          <div class="media-body">
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
              <li class="offline inlineblock">
                  <a href="javascript:void(0);">
                      <div class="media">
                          <img class="media-object " src="../assets/images/xs/avatar1.jpg" alt="">
                          <div class="media-body">
                              <span class="badge badge-outline status"></span>
                          </div>
                      </div>
                  </a>
              </li>
          </ul>
      </div>
  </div>
</div>
<div class="tab-pane slideLeft" id="activity">
  <div class="slim_scroll">
      <div class="card user_activity">
          <h6>Recent Activity</h6>
          <div class="streamline b-accent">
              <div class="sl-item">
                  <img class="user rounded-circle" src="../assets/images/xs/avatar4.jpg" alt="">
                  <div class="sl-content">
                      <h5 class="m-b-0">Admin Birthday</h5>
                      <small>Jan 21 <a href="javascript:void(0);" class="text-info">Sophia</a>.</small>
                  </div>
              </div>
              <div class="sl-item">
                  <img class="user rounded-circle" src="../assets/images/xs/avatar5.jpg" alt="">
                  <div class="sl-content">
                      <h5 class="m-b-0">Add New Contact</h5>
                      <small>30min ago <a href="javascript:void(0);">Alexander</a>.</small>
                      <small><strong>P:</strong> +264-625-2323</small>
                      <small><strong>E:</strong> maryamamiri@gmail.com</small>
                  </div>
              </div>
              <div class="sl-item">
                  <img class="user rounded-circle" src="../assets/images/xs/avatar6.jpg" alt="">
                  <div class="sl-content">
                      <h5 class="m-b-0">Code Change</h5>
                      <small>Today <a href="javascript:void(0);">Grayson</a>.</small>
                      <small>The standard chunk of Lorem Ipsum used since the 1500s is reproduced</small>
                  </div>
              </div>
              <div class="sl-item">
                  <img class="user rounded-circle" src="../assets/images/xs/avatar7.jpg" alt="">
                  <div class="sl-content">
                      <h5 class="m-b-0">New Email</h5>
                      <small>45min ago <a href="javascript:void(0);" class="text-info">Fidel Tonn</a>.</small>
                  </div>
              </div>
          </div>
      </div>
      <div class="card">
          <h6>Recent Attachments</h6>
          <ul class="list-unstyled activity">
              <li>
                  <a href="javascript:void(0)">
                      <i class="zmdi zmdi-collection-pdf l-blush"></i>
                      <div class="info">
                          <h4>info_258.pdf</h4>
                          <small>2MB</small>
                      </div>
                  </a>
              </li>
              <li>
                  <a href="javascript:void(0)">
                      <i class="zmdi zmdi-collection-text l-amber"></i>
                      <div class="info">
                          <h4>newdoc_214.doc</h4>
                          <small>900KB</small>
                      </div>
                  </a>
              </li>
              <li>
                  <a href="javascript:void(0)">
                      <i class="zmdi zmdi-image l-parpl"></i>
                      <div class="info">
                          <h4>MG_4145.jpg</h4>
                          <small>5.6MB</small>
                      </div>
                  </a>
              </li>
              <li>
                  <a href="javascript:void(0)">
                      <i class="zmdi zmdi-image l-parpl"></i>
                      <div class="info">
                          <h4>MG_4100.jpg</h4>
                          <small>5MB</small>
                      </div>
                  </a>
              </li>
              <li>
                  <a href="javascript:void(0)">
                      <i class="zmdi zmdi-collection-text l-amber"></i>
                      <div class="info">
                          <h4>Reports_end.doc</h4>
                          <small>780KB</small>
                      </div>
                  </a>
              </li>
              <li>
                  <a href="javascript:void(0)">
                      <i class="zmdi zmdi-videocam l-turquoise"></i>
                      <div class="info">
                          <h4>movie2018.MKV</h4>
                          <small>750MB</small>
                      </div>
                  </a>
              </li>
          </ul>
      </div>
  </div>
</div>
</div>
</aside>        <!-- Chat-launcher -->
<div class="chat-launcher"></div>
<div class="chat-wrapper">
<div class="card">
<div class="header">
  <ul class="list-unstyled team-info margin-0">
      <li class="m-r-15"><h2>Dr. Team</h2></li>
      <li>
          <img src="../assets/images/xs/avatar2.jpg" alt="Avatar">
      </li>
      <li>
          <img src="../assets/images/xs/avatar3.jpg" alt="Avatar">
      </li>
      <li>
          <img src="../assets/images/xs/avatar4.jpg" alt="Avatar">
      </li>
      <li>
          <img src="../assets/images/xs/avatar6.jpg" alt="Avatar">
      </li>
      <li>
          <a href="javascript:void(0);" title="Add Member"><i class="zmdi zmdi-plus-circle"></i></a>
      </li>
  </ul>
</div>
<div class="body">
  <div class="chat-widget">
  <ul class="chat-scroll-list clearfix">
      <li class="left float-left">
          <img src="../assets/images/xs/avatar3.jpg" class="rounded-circle" alt="">
          <div class="chat-info">
              <a class="name" href="#">Alexander</a>
              <span class="datetime">6:12</span>
              <span class="message">Hello, John </span>
          </div>
      </li>
      <li class="right">
          <div class="chat-info"><span class="datetime">6:15</span> <span class="message">Hi, Alexander<br> How are you!</span> </div>
      </li>
      <li class="right">
          <div class="chat-info"><span class="datetime">6:16</span> <span class="message">There are many variations of passages of Lorem Ipsum available</span> </div>
      </li>
      <li class="left float-left"> <img src="../assets/images/xs/avatar2.jpg" class="rounded-circle" alt="">
          <div class="chat-info"> <a class="name" href="#">Elizabeth</a> <span class="datetime">6:25</span> <span class="message">Hi, Alexander,<br> John <br> What are you doing?</span> </div>
      </li>
      <li class="left float-left"> <img src="../assets/images/xs/avatar1.jpg" class="rounded-circle" alt="">
          <div class="chat-info"> <a class="name" href="#">Michael</a> <span class="datetime">6:28</span> <span class="message">I would love to join the team.</span> </div>
      </li>
          <li class="right">
          <div class="chat-info"><span class="datetime">7:02</span> <span class="message">Hello, <br>Michael</span> </div>
      </li>
  </ul>
  </div>
  <div class="input-group p-t-15">
      <input type="text" class="form-control" placeholder="Enter text here...">
      <span class="input-group-addon">
          <i class="zmdi zmdi-mail-send"></i>
      </span>
  </div>
</div>
</div>
</div>        <section class="content home">
  <div class="block-header">
      <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12">
              <h2>Page Blank <small class="text-muted">Welcome to Oreo</small></h2>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 text-right">



              <ul class="breadcrumb float-md-right">
                  <li class="breadcrumb-item"><a href="../dashboard/index.html"><i class="zmdi zmdi-home"></i> Oreo</a></li>
                                                  <li class="breadcrumb-item">Pages</li>
                                                                              <li class="breadcrumb-item active">Page Blank</li>
                                          </ul>
          </div>
      </div>
  </div>
  <div class="container-fluid">
      <div class="row clearfix">
<div class="col-lg-12">
<div class="card">
  <div class="body">
      <h4>Stater page</h4>
  </div>
</div>
</div>
</div>
  </div>
</section>
