import { Affectationmissioncl } from './../Models/affectationmissioncl';
import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {Membrecl} from '../Models/membrecl.model';

@Injectable({
  providedIn: 'root'
})
export class AffectationmissionService {

  URL_API_LOCAL = myGlobals.API_REST_URL + 'coAffectationMission.php';
  /*Headers*/
    headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private httpQuery: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }

  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  getAllAffectations = (): Observable<Affectationmissioncl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('type', '2');
    return this.httpQuery.post<Affectationmissioncl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  addAffectation = (affecationMission: Affectationmissioncl): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('affectationMissionId', typeof affecationMission.affectationMissionId !== 'undefined' ? affecationMission.affectationMissionId : '');
    FORMDATA.append('blocId', affecationMission.blocId);
    FORMDATA.append('missionId', affecationMission.missionId);
    FORMDATA.append('type', affecationMission.type);
    FORMDATA.append('comment', affecationMission.comment);
    FORMDATA.append('campagneId', affecationMission.campagneId);
    FORMDATA.append('deadline', this.getMMDDYYYY(affecationMission.deadline));
    FORMDATA.append('etat', affecationMission.etat);
    FORMDATA.append('createdBy', affecationMission.createdBy);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${ myGlobals.INSERT_ACTION }`, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  updateAffectation = (affecationMission: Affectationmissioncl): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('affectationMissionId', affecationMission.affectationMissionId );
    FORMDATA.append('blocId', affecationMission.blocId);
    FORMDATA.append('missionId', affecationMission.missionId);
    FORMDATA.append('type', affecationMission.type);
    FORMDATA.append('comment', affecationMission.comment);
    FORMDATA.append('campagneId', affecationMission.campagneId);
    FORMDATA.append('deadline', affecationMission.deadline);
    FORMDATA.append('etat', affecationMission.etat);
    FORMDATA.append('createdBy', affecationMission.createdBy);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${ myGlobals.UPDATEBY_ACTION }`, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteAffectationMission = (affecationMissionId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('affectationMissionId', affecationMissionId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.DELETEBY_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMembresByComite = (missionId: string, comiteBaseId: string): Observable<Membrecl> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('missionId', missionId);
    FORMDATA.append('blocId', comiteBaseId);
    return this.httpQuery.post<Membrecl>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALLBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /* Récupération des Membres actifs d'une section du système
   Bamba Aboubacar : 13-08-2020
   params: null // Prends aucun paramètre
  */
  getAllMembresBySection = (missionId: string, sectionId: string): Observable<Membrecl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('missionId', missionId);
    FORMDATA.append('blocId', sectionId);
    return this.httpQuery.post<Membrecl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.FILTRE_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }

  getMMDDYYYY = (date) => {
    let m: string;
    let d: string;
    const today = new Date(date);
    const dd: number = today.getDate();
    const mm: number = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      d = '0' + dd;
    } else {
      d = dd + '';
    }
    if (mm < 10) {
      m = '0' + mm;
    } else {
      m = mm + '';
    }
    return m + '/' + d + '/' + yyyy;
  }
}
