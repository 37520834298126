import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {Objectifcl} from '../../Models/objectifcl.model';
import {ObjectifService} from '../../Services/objectif.service';
import {AffectationObjectifcl} from '../../Models/affectation-objectifcl.model';

@Component({
  selector: 'app-objectifmodal',
  templateUrl: './objectifmodal.component.html',
  styleUrls: ['./objectifmodal.component.css']
})
export class ObjectifmodalComponent implements OnInit {
  affectationObjectif = new AffectationObjectifcl();
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: Objectifcl, private toastr: ToastrService, private svceObjectif: ObjectifService, public dialogRef: MatDialogRef<ObjectifmodalComponent>) { }

  ngOnInit(): void {
  }
  close = () => {
    this.dialogRef.close(false);
  }
  crudSection = (objectif: Objectifcl, type: string) => {
    this.svceObjectif.addObjectif(objectif).subscribe(
      (res) => {
        console.log(res);
        switch (type) {
          case 'add':
            this.toastr.success('Objectif inséré avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
          case 'update':
            this.toastr.success('Objectif modifié avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
        }
        this.dialogRef.close(true);
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  onAdd = (objectif: Objectifcl) => {
    switch (objectif.actionType){
      case 'add':
        this.crudSection(objectif, objectif.actionType);
        break;
      case 'update':
        this.crudSection(objectif, objectif.actionType);
        break;
      default:
        break;
    }
  }
}
