import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {LoaderService} from '../loader/loader.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit {
  logindURL = '/singin';
  userConnected: {};
  full: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  constructor(private cookieSce: CookieService, private router: Router, public loaderService: LoaderService) { }

  ngOnInit(): void {
    const verifUserCookies = this.cookieSce.get('oClinicUser');
    if ( verifUserCookies === '')
    {
        this.cookieSce.deleteAll('/', '/', true);
        window.localStorage.removeItem('oClinicUser');
        this.cookieSce.set('oClinicUser', '', -1);
        // this.router.navigate([this.logindURL]);
        window.location.href = this.full;
        this.router.navigate([this.logindURL], {queryParams: {refresh: new Date().getTime()}});
    }
    else{
      this.userConnected = JSON.parse(verifUserCookies);
      // console.log(this.userConnected);
    }
    setTimeout(() => {
      const timerCookies = this.cookieSce.get('oClinicUser');
      if ( timerCookies === '')
      {
        this.cookieSce.deleteAll('/', '/', true);
        window.localStorage.removeItem('oClinicUser');
        this.cookieSce.set('oClinicUser', '', -1);
        // this.router.navigate([this.logindURL]);
        window.location.href = this.full;
      }
    }, myGlobals.CHECK_COKIES_TIME);

    $(document).ready(function(){
      $('.ls-toggle-btn').on('click', function() {
          $('body').toggleClass('ls-toggle-menu');
      });
      ($.AdminOreo = {}),
      ($.AdminOreo.options = {
        colors: {
            red: '#ec3b57',
            pink: '#E91E63',
            purple: '#ba3bd0',
            deepPurple: '#673AB7',
            indigo: '#3F51B5',
            blue: '#2196f3',
            lightBlue: '#03A9F4',
            cyan: '#00bcd4',
            green: '#4CAF50',
            lightGreen: '#8BC34A',
            yellow: '#ffe821',
            orange: '#FF9800',
            deepOrange: '#f83600',
            grey: '#9E9E9E',
            blueGrey: '#607D8B',
            black: '#000000',
            blush: '#dd5e89',
            white: '#ffffff',
        },
        leftSideBar: { scrollColor: 'rgba(0,0,0,0.5)', scrollWidth: '4px', scrollAlwaysVisible: !1, scrollBorderRadius: '0', scrollRailBorderRadius: '0' },
        dropdownMenu: { effectIn: 'fadeIn', effectOut: 'fadeOut' },
    }),
    ($.AdminOreo.leftSideBar = {
        activate: function () {
            var t = this,
                i = $('body'),
                o = $('.overlay');
            $(window).on('click', function (e) {
                var a = $(e.target);
                'i' === e.target.nodeName.toLowerCase() && (a = $(e.target).parent()),
                    !a.hasClass('bars') && t.isOpen() && 0 === a.parents('#leftsidebar').length && (a.hasClass('js-right-sidebar') || o.fadeOut(), i.removeClass('overlay-open'));
            }),
                $.each($('.menu-toggle.toggled'), function (e, a) {
                    $(a).next().slideToggle(0);
                }),
                $.each($('.menu .list li.active'), function (e, a) {
                    var t = $(a).find('a:eq(0)');
                    t.addClass('toggled'), t.next().show();
                }),
                $('.menu-toggle').on('click', function (e) {
                    var a = $(this),
                        t = a.next();
                    if ($(a.parents('ul')[0]).hasClass('list')) {
                        var i = $(e.target).hasClass('menu-toggle') ? e.target : $(e.target).parents('.menu-toggle');
                        $.each($('.menu-toggle.toggled').not(i).next(), function (e, a) {
                            $(a).is(':visible') && ($(a).prev().toggleClass('toggled'), $(a).slideUp());
                        });
                    }
                    a.toggleClass('toggled'), t.slideToggle(320);
                }),
                t.checkStatuForResize(!0),
                $(window).resize(function () {
                    t.checkStatuForResize(!1);
                })
        },
        checkStatuForResize: function (e) {
            var a = $('body'),
                t = $('.navbar .navbar-header .bars'),
                i = a.width();
            e &&
                a
                    .find('.content, .sidebar')
                    .addClass('no-animate')
                    .delay(1e3)
                    .queue(function () {
                        $(this).removeClass('no-animate').dequeue();
                    }),
                i < 1170 ? (a.addClass('ls-closed'), t.fadeIn()) : (a.removeClass('ls-closed'), t.fadeOut());
        },
        isOpen: function () {
            return $('body').hasClass('overlay-open');
        },
    });
      $.AdminOreo.leftSideBar.activate();
    });
  }
}
