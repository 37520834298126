<section class="content home">
  <div class="block-header">
      <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12">
              <h2>Missions<small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 text-right">

            <!-- <button (click)="openModal(service)" class="btn btn-white btn-icon btn-round d-none d-md-inline-block float-right m-l-10" type="button">
                <i class="zmdi zmdi-plus"></i>
            </button> -->

            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
                <li class="breadcrumb-item">Accueil</li>
                <li class="breadcrumb-item active">Details missions</li>
            </ul>
          </div>
      </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
          <div class="card">
            <div class="body">
                <div class="row">
                  <div class="col-sm-12 col-lg-12"></div>
                  <div class="table-responsive">
                    <div class="col-lg-12 col-md-5 col-12">
                      <div class="input-group search">
                          <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                          <!-- <span class="input-group-addon">
                              <i class="zmdi zmdi-search"></i>
                          </span> -->
                      </div>
                  </div>
                    <div class="col-sm-12 col-lg-12">
                      <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                        <ng-container matColumnDef="Id">
                          <th class="center" mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Login">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
                          <td mat-cell *matCellDef="let element"> {{element.numeroElecteur !== null ? element.numeroElecteur : 'Non inscrit'}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Nom">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ville </th>
                          <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Prenoms">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Libelle </th>
                          <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Gender">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Bloc </th>
                        <td mat-cell *matCellDef="let element"> {{element.sex == 'M' ? 'Masculin' : 'Fénimin'}} </td>
                    </ng-container>
                      <ng-container matColumnDef="Email">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acteur </th>
                          <td mat-cell *matCellDef="let element"> <a href="mailto:{{element.email}}">{{element.email}}</a></td>
                      </ng-container>
                      <ng-container matColumnDef="Phone">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tel </th>
                          <td mat-cell *matCellDef="let element"> {{ element.contact === '' || element.contact === null ?  element.contact :  element.contact }} </td>
                      </ng-container>

                      <ng-container matColumnDef="Loading">
                          <th class="w-75" mat-footer-cell *matFooterCellDef colspan="8">
                              Chargement en cour....
                          </th>
                      </ng-container>
                      <ng-container matColumnDef="NoData">
                          <th class="w-75" mat-footer-cell *matFooterCellDef colspan="8">
                              Aucunes données trouvées....
                          </th>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                      <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10"  showFirstLastButtons></mat-paginator>
                  </div>
                  </div>

                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
