<section class="content home">
  <div class="block-header">
    <div class="row">
      <div class="col-lg-5 col-md-5 col-sm-12">
        <h2>Militants<small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-12 text-right">

        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
          <li class="breadcrumb-item">Accueil</li>
          <li class="breadcrumb-item active">Missions</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="body">
            <div class="row">
              <div class="col-sm-12 col-lg-12"></div>
              <div class="table-responsive">
                <div class="col-lg-12 col-md-5 col-12">
                  <div class="input-group search">
                    <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                    <!-- <span class="input-group-addon">
                        <i class="zmdi zmdi-search"></i>
                    </span> -->
                  </div>
                </div>
                  <div class="col-sm-12 col-lg-12">
                    <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                      <ng-container matColumnDef="Libelle">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mission </th>
                        <td mat-cell *matCellDef="let element"> {{element.libelle}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Date Limite">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Limite </th>
                        <td mat-cell *matCellDef="let element"> {{element.deadline}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Statut">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
                        <td mat-cell *matCellDef="let element"> <span *ngIf="element.etat == 1" class="badge badge-success">TERMINE</span><span *ngIf="element.etat == 0" class="badge badge-warning">EN COURS</span></td>
                      </ng-container>
                      <ng-container matColumnDef="Taux">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Taux </th>
                        <td mat-cell *matCellDef="let element"> {{element.checkList == 0 ? element.etat == 1 ? '100%' : '0%' : element.taux > 0 ? ((element.taux/this.maxMembreNbre)*100).toFixed(2) + '%' : '0%'}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Loading">
                        <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                          Chargement en cours....
                        </th>
                      </ng-container>
                      <ng-container matColumnDef="NoData">
                        <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                          Aucunes données trouvées....
                        </th>
                      </ng-container>
                      <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef style="width: 140px;text-align: center"> Actions </th>
                        <td mat-cell *matCellDef=" let element">
                          <button  mat-icon-button [disabled]=" element.etat == 1 ? true : false" class="btn btn-icon btn-neutral  btn-icon-mini" (click)="checkedBeforeFinishedMission(element, element.checkList,element.taux)" >
                            <i class="material-icons">check_circle</i>
                          </button>
                          <button  mat-icon-button [disabled]=" element.etat == 1 ? false : true " class="btn btn-icon btn-neutral  btn-icon-mini" (click)="reactivatedMission(element)" >
                            <i class="material-icons">restore</i>
                          </button>
                          <button  mat-icon-button [disabled]="element.comment == '' || element.comment == null || element.comment == 'undefined' ? true : false" class="btn btn-icon btn-neutral  btn-icon-mini" (click)="voirCommentaire(element.comment)" >
                            <i class="material-icons">visibility</i>
                          </button>
                          <button [disabled]="element.checkList == 1 ? false : true" (click)="openCheckList(element, element.libelle,element.blocId,element.missionId,element.commentActif)" class="btn btn-icon btn-neutral  btn-icon-mini" >
                            <i class="material-icons">playlist_add_check</i>
                          </button>
                          <button  class="btn btn-icon btn-neutral btn-icon-mini" (click)="deleteAffectationMission(element.affectationMissionId)">
                            <i class="material-icons delete">delete_outline</i>
                          </button>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                      <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10"  showFirstLastButtons></mat-paginator>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
