<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Details Mission - {{this.data['acteur'].libelle}} ({{this.data['acteur'].type == 4 ? 'Coordinateur:' : this.data['acteur'].type == 5 ? 'Animateur' : ''}} M. {{this.data['acteur'].nom}})</strong> </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <form id="missionForm" #missionForm="ngForm" action="post">
        <div class="body">
          <div class="table-responsive">
            <div class="col-sm-12 col-lg-12">
              <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                <ng-container matColumnDef="Libelle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Mission </th>
                  <td mat-cell *matCellDef="let element"> {{element.libelle}} </td>
                </ng-container>
                <ng-container matColumnDef="Date Limite">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Limite </th>
                  <td mat-cell *matCellDef="let element"> {{element.deadline}} </td>
                </ng-container>
                <ng-container matColumnDef="Statut">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
                  <td mat-cell *matCellDef="let element"> <span *ngIf="element.etat == 1" class="badge badge-success">TERMINE</span><span *ngIf="element.etat == 0" class="badge badge-warning">EN COURS</span></td>
                </ng-container>
                <ng-container matColumnDef="Taux">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Taux </th>
                  <td mat-cell *matCellDef="let element"> {{element.checkList == 0 ? element.etat == 1 ? '100%' : '0%' : element.taux > 0 ? ((element.taux/this.maxMembreNbre)*100).toFixed(2) + '%' : '0%'}} </td>
                </ng-container>
                <ng-container matColumnDef="Loading">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Chargement en cours....
                  </th>
                </ng-container>
                <ng-container matColumnDef="NoData">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Aucunes données trouvées....
                  </th>
                </ng-container>
                <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef style="width: 140px;text-align: center"> Actions </th>
                  <td mat-cell *matCellDef=" let element">
                    <button  mat-icon-button [disabled]=" element.etat == 1 ? true : false" class="btn btn-icon btn-neutral  btn-icon-mini" (click)="checkedBeforeFinishedMission(element, element.checkList,element.taux)" >
                      <i class="material-icons">check_circle</i>
                    </button>
                    <button  mat-icon-button [disabled]=" element.etat == 1 ? false : true " class="btn btn-icon btn-neutral  btn-icon-mini" (click)="reactivatedMission(element)" >
                      <i class="material-icons">restore</i>
                    </button>
                    <button  mat-icon-button [disabled]="element.comment == '' || element.comment == null || element.comment == 'undefined' ? true : false" class="btn btn-icon btn-neutral  btn-icon-mini" (click)="voirCommentaire(element.comment)" >
                      <i class="material-icons">visibility</i>
                    </button>
                    <button [disabled]="element.checkList == 1 ? false : true" (click)="openCheckList(element, element.libelle,element.blocId,element.missionId,element.commentActif)" class="btn btn-icon btn-neutral  btn-icon-mini" >
                      <i class="material-icons">playlist_add_check</i>
                    </button>
                    <button  class="btn btn-icon btn-neutral btn-icon-mini" (click)="deleteAffectationMission(element.affectationMissionId)">
                      <i class="material-icons delete">delete_outline</i>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10"  showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- <mat-dialog-actions align="end">
  <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>Cancel</button>
  <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
