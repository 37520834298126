<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Liste des Postes disponible  </strong> <!--{{this.data['libelle']}}--></h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <form id="listPostForm" #listPostForm="ngForm" action="post">
        <div class="body">
          <!--<div class="col-sm-12 col-lg-auto">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <div class="input-group search">
                  <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                  &lt;!&ndash; <span class="input-group-addon">
                      <i class="zmdi zmdi-search"></i>
                  </span> &ndash;&gt;
                </div>
              </div>
            </div>
          </div>-->
          <div class="table-responsive">
            <div class="col-sm-12 col-lg-auto">
              <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                <ng-container matColumnDef="Code">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
                  <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                </ng-container>
                <ng-container matColumnDef="Libelle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Libelle </th>
                  <td mat-cell *matCellDef="let element"> {{element.libelle}} </td>
                </ng-container>
                <!--<ng-container matColumnDef="Gender">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Sexe </th>
                  <td mat-cell *matCellDef="let element"> {{element.sex == 'M' ? 'Masculin' : 'Fénimin'}} </td>
                </ng-container>
                <ng-container matColumnDef="Email">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
                  <td mat-cell *matCellDef="let element"> <a href="mailto:{{ element.email === '' || element.email == 'null' ?  '' :  element.email }}">{{ element.email === '' || element.email == 'null' ?  '' :  element.email }}</a></td>
                </ng-container>
                <span>
                      <input type="checkbox" id="checkbox{{element.weekId}}" [checked]="element.selected === '0' ? false : true" (change)="onClickCheckBox($event, element)" class="filled-in chk-col-light-blue">
                      <label class="" for="checkbox{{element.weekId}}">{{element.selected === '0' ? 'Ajouter' : 'Rétirer'}}</label>
                    </span>
                                -->
                <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                  <td mat-cell *matCellDef="let element" style="width: 140px;text-align: center">
                    <span>
                      <input type="radio" id="radio{{element.roleequipeId}}" name="radioPost" class="q156" [checked]="element.roleequipeId === this.data['membre']['roleEquipeSelected']" (change)="onClickRadioButton(element)">
                      <label class="" for="radio{{element.roleequipeId}}"></label>
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Loading">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Chargement en cours....
                  </th>
                </ng-container>
                <ng-container matColumnDef="NoData">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Aucunes données trouvées....
                  </th>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10"  showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
