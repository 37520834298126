import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {RoleEquipeCl} from '../../Models/roleequipe.model';
import {RoleequipeService} from '../../Services/roleequipe.service';

@Component({
  selector: 'app-role-equipe-modal',
  templateUrl: './role-equipe-modal.component.html',
  styleUrls: ['./role-equipe-modal.component.css']
})
export class RoleEquipeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: RoleEquipeCl, private toastr: ToastrService,
              private svceRoleEquipe: RoleequipeService, public dialogRef: MatDialogRef<RoleEquipeModalComponent>) { }

  ngOnInit(): void {
  }
  close = () => {
    this.dialogRef.close(false);
  }
  crudSection = (roleequipe: RoleEquipeCl, type: string) => {
    this.svceRoleEquipe.addRoleEquipe(roleequipe).subscribe(
      (res) => {
        console.log(res);
        switch (type) {
          case 'add':
            this.toastr.success('Role équipe insérée avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
          case 'update':
            this.toastr.success('Role équipe modifiée avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
        }
        this.dialogRef.close(true);
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  onAdd = (roleequipe: RoleEquipeCl) => {
    switch (roleequipe.actionType){
      case 'add':
        this.crudSection(roleequipe, roleequipe.actionType);
        break;
      case 'update':
        this.crudSection(roleequipe, roleequipe.actionType);
        break;
      default:
        break;
    }
  }

}
