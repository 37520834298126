import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import {Sectioncl} from '../../Models/sectioncl.model';
import {ToastrService} from 'ngx-toastr';
import {SectionService} from '../../Services/section.service';
import {ComiteService} from '../../Services/comite.service';
import {Comitecl} from '../../Models/comitecl.model';
import {Particl} from '../../Models/particl.model';
import {element} from 'protractor';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {InformationmodalComponent} from '../informationmodal/informationmodal.component';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {
  public lstSection: Sectioncl[];
  validate: boolean;
  private dialogConfig;
  userConnected: {};
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: Comitecl, private toastr: ToastrService, private svceComite: ComiteService, private dialog: MatDialog, private svceSection: SectionService, public dialogRef: MatDialogRef<ServiceComponent>) { }

  ngOnInit(): void {
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
    this.getAllSections();
    if (this.data['comite'].sectionId === '' || this.data['comite'].sectionId === null) {
      this.validate = false;
    } else {
      if (this.data['comite'].NbreCB >= myGlobals.NBRE_MAX_CB) {
        this.validate = false;
      } else {
        this.validate = true;
      }
    }
  }
  getAllSections = () => {
    this.svceSection.getAllSections().subscribe(
      (result) => {
        this.lstSection = result['donnees'];
        console.log(result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  close = () => {
    this.dialogRef.close(false);
  }
  crudComite = (comite: Comitecl, type: string) => {
    this.svceComite.addComite(comite).subscribe(
      (res) => {
        console.log(res);
        switch (type) {
          case 'add':
            this.toastr.success('Comité inséré avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
          case 'update':
            this.toastr.success('Comité modifié avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
        }
        this.dialogRef.close(true);
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  onAdd = (comite: Comitecl) => {
    switch (comite.actionType){
      case 'add':
        if (this.validate === true )
        {
          this.crudComite(comite, comite.actionType);
        } else {
          this.openInformationDialog();
        }
        break;
      case 'update':
        this.crudComite(comite, comite.actionType);
        break;
      default:
        break;
    }
  }
onSelectedChanged = (sectionId: string) => {
  // tslint:disable-next-line:triple-equals no-shadowed-variable
    const value = this.lstSection.find(element => element.sectionId == sectionId).NbreCB;
    if (value >= myGlobals.NBRE_MAX_CB){
      this.validate = false;
      this.openInformationDialog();
    } else {
      this.validate = true;
    }
    console.log(this.validate);
}
openInformationDialog = () => {
  this.dialogConfig = {
    width: '500px',
    disableClose: true,
    position: {top: '10%'},
    data: {
      // tslint:disable-next-line:max-line-length
      'libelle': 'Merci de bien vouloir contacter l\'administrateur car vous avez atteint le nombre maximal de comité de base (' + myGlobals.NBRE_MAX_CB + ')'
    }
  };
  const dialogRef = this.dialog.open(InformationmodalComponent,
    this.dialogConfig
  );
  dialogRef.afterClosed().subscribe(result =>
  {
    if (result)
    {
      console.log('Good');
    }
  });
}
}
