<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Commentaire</strong></h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <form id="checkListForm"  #checkListForm="ngForm" action="post">
        <div class="body">
          <div class="row clearfix">
            <div class="col-sm-12">
              <label><small>Commentaires</small></label>
              <div class="form-group">
                <textarea [(ngModel)]="data['checkList'].comment" name="comment" class="form-control" required></textarea>
              </div>
            </div>
            <div class="col-sm-12" align="end">
              <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close()" >ANNULER</button>
              <button mat-button [disabled]="!checkListForm.form.valid" class="btn btn-primary waves-effect m-r-20" (click)="updateCommentCheckList(data['checkList'].comment); this.checkListForm.form.reset();" cdkFocusInitial>VALIDER</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <mat-dialog-actions align="end">
  <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>Cancel</button>
  <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
