<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Accès utilisateur</strong> </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <form id="utilisateurForm" #utilisateurForm="ngForm" action="post">
        <div class="body">
          <div class="row clearfix">
            <div class="col-sm-12">
              <mat-form-field class="example-full-width">
                <mat-label>Nom</mat-label>
                <input matInput [(ngModel)]="this.data['access'].nom" [disabled]="true" name="nom" placeholder="Nom" required>
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <mat-form-field class="example-full-width">
                <mat-label>Prénoms</mat-label>
                <input matInput [(ngModel)]="this.data['access'].prenom" [disabled]="true" name="prenom" placeholder="Prenoms" required>
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <mat-form-field class="example-full-width">
                <mat-label>Nom utilisateur</mat-label>
                <input matInput [(ngModel)]="this.data['access'].pseudo" [disabled]="this.data['access'].actionType === 'updateP' ? true : false" name="pseudo" placeholder="Nom utilisateur" required>
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <mat-form-field class="example-full-width">
                <mat-label>Mot de passe</mat-label>
                <input matInput [(ngModel)]="this.data['access'].password" name="password" type="password" placeholder="Mot de passe" required>
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <mat-form-field class="example-full-width">
                <mat-label>Mot de passe confirmation</mat-label>
                <input matInput [(ngModel)]="this.cpassword" name="cpassword" type="password" placeholder="Mot de passe confirmation" required>
              </mat-form-field>
            </div>
            <div class="col-sm-12">
                <mat-form-field>
                  <input matInput [disabled]="this.data['access'].actionType === 'updateP' ? true : false" [(ngModel)]="this.data['access'].expiredOn" [matDatepicker] = "picker" [min]="this.minDate"  name="deadline" placeholder = "Date d'expiration" required>
                  <mat-datepicker-toggle matSuffix [for] = "picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-sm-12" align="end">
              <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close()" >ANNULER</button>
              <button mat-button [disabled]="!utilisateurForm.form.valid" class="btn btn-primary waves-effect m-r-20" (click)="onAdd(data['access'])" cdkFocusInitial>VALIDER</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- <mat-dialog-actions align="end">
  <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>Cancel</button>
  <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
