import {Router} from '@angular/router';
import { UtilisateursService } from './../../Services/utilisateurs.service';
import { Access } from './../../Models/access.model';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {SelectionModel} from '@angular/cdk/collections';
import {Campagnecl} from '../../Models/campagnecl.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CampagnemodalComponent} from '../../Modals/campagnemodal/campagnemodal.component';
import {ChoixcampagneComponent} from '../../Modals/choixcampagne/choixcampagne.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userConnected: {};
  dashboardURL = '/dashboard';
  tableauBordUrl = '/dashboard/tableaubord';
  full: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  lstCampagne = new SelectionModel<Campagnecl>(true, []);

  // tslint:disable-next-line:max-line-length
  private dialogConfig;
  constructor(private dialog: MatDialog, private usersService: UtilisateursService, private cookieSce: CookieService, private router: Router, private locationUrl: Location, private toastr: ToastrService) { }

  ngOnInit(): void {
    const verifUserCookies = this.cookieSce.get('oClinicUser');
    if ( verifUserCookies !== null && verifUserCookies !== '')
    {
      this.locationUrl.replaceState('/');
      this.router.navigate([this.dashboardURL], {queryParams: {refresh: new Date().getTime()}});
    }
  }
  onSubmit = (formulaireLogin: NgForm) => {
    const access =  new Access();
    access.pseudo = formulaireLogin.form.value.Pseudo;
    access.password = formulaireLogin.form.value.Password;
    this.usersService.connectUser(access).subscribe(
      (res) => {
        if (res !== null && !res.status){
          const today = new Date();
          const tomorrow = new Date(today);
          this.toastr.success(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.checkCampagneUser(res.donnees);
        }
        else{
          if (res !== null && res.status){
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  checkCampagneUser = (userData: any) => {
    this.usersService.getCampagneByAccessID(userData['accessId']).subscribe(
      (res) => {
        if (res !== null && !res.status){
          console.log(res['donnees']);
          this.lstCampagne = res['donnees'];
          const len = Object.keys(this.lstCampagne).length;
          if (len === 1) {
            this.cookieSce.set('oClinicUser', JSON.stringify(userData), 1);
            localStorage.setItem('USER_CONNECTED_INFO', JSON.stringify(userData));
            localStorage.setItem('USER_CAMPAGNE_ID', this.lstCampagne[0]['campagneId']);
            this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
            if (this.userConnected['typemembre'] === 'Animateur' || this.userConnected['typemembre'] === 'Secrétaire')
            {
              this.router.navigate([this.tableauBordUrl], {queryParams: {refresh: new Date().getTime()}});
            } else {
              this.router.navigate([this.dashboardURL], {queryParams: {refresh: new Date().getTime()}});
            }
          } else {
            console.log('Modal');
            this.openModalCampagne(userData, this.lstCampagne);
          }
        }
        else{
          if (res !== null && res.status){
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  openModalCampagne = (userData: any, campagneData: any) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'user': userData,
        'campagne': campagneData
      }
    };
    const dialogRef = this.dialog.open(ChoixcampagneComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
      }
    });
  }
}
