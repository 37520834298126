
<div class="alert">
  <div class="row clearfix">
    <div class="col-lg-12 col-md-12">
      <div class="card tasks_report">
        <div class="header">
          <h2><strong>Alert</strong> Choisir la méthode d'ajout </h2>
          <ul class="header-dropdown">
            <li class="remove">
              <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
            </li>
          </ul>
        </div>
        <div class="body text-center">
          <button class="btn btn-primary waves-effect m-r-20" (click)="searchOnList(data['membre'])"><i class="zmdi zmdi-view-list-alt"></i> Rechercher sur la liste électorale </button>
          <button class="btn btn-info waves-effect m-r-20" style="width: 254px;" (click)="renseignInfo(data['membre'])"><i class="zmdi zmdi-loupe"></i> Renseigner les informations  </button>
        </div>
      </div>
    </div>
  </div>
</div>
