import {Component, OnInit, ViewChild} from '@angular/core';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {RoleequipeService} from '../../Services/roleequipe.service';
import {DeletealertComponent} from '../../Modals/deletealert/deletealert.component';
import {RoleEquipeCl} from '../../Models/roleequipe.model';
import {RoleEquipeModalComponent} from '../../Modals/role-equipe-modal/role-equipe-modal.component';

@Component({
  selector: 'app-roleequipe',
  templateUrl: './roleequipe.component.html',
  styleUrls: ['./roleequipe.component.css']
})
export class RoleequipeComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  roleEquipe = new RoleEquipeCl();
  private dialogConfig;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Id', 'Code', 'Libelle', 'Description', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private dialog: MatDialog, private svceRoleEquipe: RoleequipeService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAllRoleEquipe();
  }
  getAllRoleEquipe = () => {
    this.svceRoleEquipe.getAllRoleEquipe().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  openModal = (roleequipe: RoleEquipeCl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        roleequipe
      }
    };
    const dialogRef = this.dialog.open(RoleEquipeModalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.getAllRoleEquipe();
      }
    });
  }
  cleanFielsd = (roleEquipe: RoleEquipeCl) => {
    this.roleEquipe = new RoleEquipeCl();
    this.roleEquipe.actionType = 'add';
    this.roleEquipe.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.roleEquipe.code = '';
    this.roleEquipe.libelle = '';
    this.roleEquipe.roleequipeId = '';
  }
  redirectToUpdate = (roleEquipe: RoleEquipeCl) => {
    console.log(roleEquipe);
    roleEquipe.actionType = 'update';
    this.openModal(roleEquipe);
  }
  redirectToDelete = (roleEquipeId: string, libelle: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': roleEquipeId,
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DeletealertComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceRoleEquipe.deleteRoleEquipe(roleEquipeId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Role Equipe supprimée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.getAllRoleEquipe();
            }
            else {
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          }
        );
      }
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
}
