'use strict';
//export const API_REST_URL = 'http://localhost/TerebeiProjects/API_REST_CAMP/controlers/';
export const API_REST_URL = 'https://eparti.syabe-tech.com/API_REST_CAMP/controlers/';
export const INSERT_ACTION = 'Insert';
export const SELECTALL_ACTION = 'SelectAll';
export const SELECTALLBY_ACTION = 'SelectAllBy';
export const SELECT_ACTION = 'Select';
export const LOAD_ACTION = 'Load';
export const LOADALL_ACTION = 'LoadAll';
export const SELECTOBY_ACTION = 'SelectBy';
export const SELECTBY_ACTION = 'SelectById';
export const UPDATEBY_ACTION = 'UpdateById';
export const DELETEBY_ACTION = 'DeleteById';
export const SEARCH_ACTION = 'Rechercher';
export const FIND_ACTION = 'Retrouver';
export const FILTRE_ACTION = 'Filtre';
export const CONNECT_ACTION = 'Connect';
export const LIST_ACTION = 'List';
export const LISTBY_ACTION = 'ListBy';
export const LISTALL_ACTION = 'ListAll';
export const LISTALLBY_ACTION = 'ListAllBy';
export const SELECTALLDATA_ACTION = 'SelectAllData';
export const SELECTALLDATABY_ACTION = 'SelectAllDataBy';
export const REMOVE_ACTION = 'destroy';
export const ACTION_SUCCESS = 'success';
export const ACTION_ERROR = 'error';
export const ACTION_WARNING = 'warning';
export const NBRE_MAX_CB = 10;
export const NBRE_MAX_ML = 26;
export const MESSAGE_TEXT = 'Bienvenue sur OCamp';
export const APP_NAME = 'ePARTI';
export const APP_LOGO = '';
export const ELECTION_DAY = '2020-09-28 23:00:00';
export const NBRE_DAY_BEFORE_ARLET = 5;
export const TAUX_CONFORME_GOOD = 90;
export const TAUX_CONFORME_NOTBAD = 80;
export const TAUX_VOTANT = 80;
export const TAUX_PERTE = 20;
export const TAUX_PARTICIPATION = 65;
export const TIME_RELOAD = 60000;
export const CHECK_COKIES_TIME = 15000;
export const USER_CONNECTED_INFO: {} = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
