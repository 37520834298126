import {Actioncl} from './actioncl.model';
export class Particampagecl extends Actioncl{
  partiCampagneId: string;
  IdCampagne: string;
  IdParti: string;
  LibelleParti: string;
  LibelleCampagne: string;
  partiId: string;
  etat: number;
  status: number;
  createdBy: string;
  createdOn: string;
}
