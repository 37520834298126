import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-affectationmission',
  templateUrl: './affectationmission.component.html',
  styleUrls: ['./affectationmission.component.css']
})
export class AffectationmissionComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AffectationmissionComponent>) { }

  ngOnInit(): void {
  }
  close = () => {
    this.dialogRef.close(true);
  }
  onAdd = () => {};
}
