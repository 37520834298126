import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Membrecl} from '../../Models/membrecl.model';
import {EquipeService} from '../../Services/equipe.service';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-staff-member-modal',
  templateUrl: './staff-member-modal.component.html',
  styleUrls: ['./staff-member-modal.component.css']
})
export class StaffMemberModalComponent implements OnInit {
  tableDataSource = new MatTableDataSource<Membrecl>();
  private dialogConfig;
  public displayedColumns = [ 'Electeur', 'Nom&Prenoms', 'Gender', 'Email', 'Phone', 'Rôle'/*, 'Actions'*/];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private svceEquipe: EquipeService, private toastr: ToastrService, private dialog: MatDialog, public dialogRef: MatDialogRef<StaffMemberModalComponent>) { }

  ngOnInit(): void {
    this.getAllMemberStaff(this.data['blocId']);
  }
  getAllMemberStaff = (equipeId) => {
    this.svceEquipe.getAllMemberStaff(equipeId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
    this.dialogRef.close(true);
  }
}
