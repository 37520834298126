import { Access } from './access.model';
import {Actioncl} from './actioncl.model';
export class Utilisteur extends  Actioncl{
  membreId: string;
  numeroElecteur: string;
  nom: string;
  prenom: string;
  email: string;
  contact: string;
  sex: string;
  communeId: string;
  lieuVoteId: string;
  dateNaissance: string;
  lieuNaissance: string;
  adressePhysique: string;
  adressePostale: string;
  profession: string;
  nomPere: string;
  prenomPere: string;
  dateNaissancePere: string;
  lieuNaissancePere: string;
  nomMere: string;
  prenomMere: string;
  dateNaissanceMere: string;
  lieuNaissanceMere: string;
  status: number;
  createdBy: string;
  createdOn: string;
}
