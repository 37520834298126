import { Missioncl } from './../../Models/missioncl.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-detailsmission',
  templateUrl: './detailsmission.component.html',
  styleUrls: ['./detailsmission.component.css']
})
export class DetailsmissionComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Id', 'Login', 'Nom', 'Prenoms', 'Gender', 'Email', 'Phone'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor() { }

  ngOnInit(): void {
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  redirectToDetails = (mission: Missioncl) => {
  }
  redirectToUpdate = (mission: Missioncl) => {

  }
  redirectToDelete = (missionId: string) => {

  }
}
