import {Actioncl} from './actioncl.model';

export class RoleEquipeCl extends Actioncl{
  id: number;
  roleequipeId: string;
  code: string;
  libelle: string;
  description: string;
  createdBy: string;
  createdOn: string;
  status: string;
}
