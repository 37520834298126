
<div class="alert">
  <div class="row clearfix">
    <div class="col-lg-12 col-md-12">
      <div class="card tasks_report">
        <div class="header">
          <h2><strong>Alert</strong> Confirmation </h2>
        </div>
        <div class="body text-center">
          <strong>Attention! </strong> {{data['libelle']}}
        </div>
        <div class="footer text-right">
          <button class="btn btn-primary waves-effect m-r-20" mat-button [mat-dialog-close] ="false" >NON</button>
          <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close] ="true" cdkFocusInitial>OUI</button>
        </div>
      </div>
    </div>
  </div>
</div>
