import {Actioncl} from './actioncl.model';

export class Particl extends Actioncl{
  partiId: string;
  libelle: string;
  mutipleCandidat: number;
  principal: number;
  status: number;
  createdBy: string;
  createdOn: string;
}
