import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  URL_API_LOCAL = myGlobals.API_REST_URL + 'coDashboard.php';
  /*Headers*/
    headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  getAllNumbers = (): Observable<any[]> => {
    return this.httpQuery.get<any[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION , {headers: this.headers })
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllNumbersByRegion = (regionId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('regionId', regionId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.FILTRE_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllNumbersByDepartement = (departementId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('departementId', departementId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.FILTRE_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllNumbersByCommune = (communeId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('communeId', communeId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.FILTRE_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopSection = (): Observable<any> => {
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LIST_ACTION ,   {
      headers: this.headers
    }).pipe(retry(3), catchError(this.handleError));
  }
  getAllTopSectionsByRegion = (regionId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('regionId', regionId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LISTBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopSectionsByDepartement = (departementId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('departementId', departementId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LISTBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopSectionsByCommune = (communeId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('communeId', communeId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LISTBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopComiteBase = (): Observable<any> => {
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LISTALL_ACTION ,   {
      headers: this.headers
    }).pipe(retry(3), catchError(this.handleError));
  }
  getAllTopComiteBasesByRegion = (regionId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('regionId', regionId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LISTALLBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopComiteBasesByDepartement = (departementId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('departementId', departementId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LISTALLBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopComiteBasesByCommune = (communeId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('communeId', communeId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LISTALLBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopSecretaire = (): Observable<any> => {
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.SEARCH_ACTION ,   {
      headers: this.headers
    }).pipe(retry(3), catchError(this.handleError));
  }
  getAllTopSecretairesByRegion = (regionId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('regionId', regionId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.FIND_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopSecretairesByDepartement = (departementId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('departementId', departementId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.FIND_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopSecretairesByCommune = (communeId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('communeId', communeId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.FIND_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopAnimateur = (): Observable<any> => {
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALLDATA_ACTION ,   {
      headers: this.headers
    }).pipe(retry(3), catchError(this.handleError));
  }
  getAllTopAnimateursByRegion = (regionId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('regionId', regionId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALLDATABY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopAnimateursByDepartement = (departementId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('departementId', departementId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALLDATABY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTopAnimateursByCommune = (communeId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('communeId', communeId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALLDATABY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }

  getAllMissions = (): Observable<any> => {
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECT_ACTION ,   {
      headers: this.headers
    }).pipe(retry(3), catchError(this.handleError));
  }
  getAllMissionsByRegion = (regionId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('regionId', regionId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTOBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMissionsByDepartement = (departementId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('departementId', departementId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTOBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMissionsByCommune = (communeId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('communeId', communeId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTOBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllElectionDayNumbers = (): Observable<any> => {
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LOAD_ACTION ,   {
      headers: this.headers
    }).pipe(retry(3), catchError(this.handleError));
  }
  getAllElectionDayNumbersGroupByDistricts = (): Observable<any> => {
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LOADALL_ACTION ,   {
      headers: this.headers
    }).pipe(retry(3), catchError(this.handleError));
  }
}
