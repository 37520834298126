import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Membrecl} from '../../Models/membrecl.model';
import {Affectation} from '../../Models/affectation.model';
import {MatTableDataSource} from '@angular/material/table';
import {Missioncl} from '../../Models/missioncl.model';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AnimateurService} from '../../Services/animateur.service';
import {AffectationService} from '../../Services/affectation.service';
import {ToastrService} from 'ngx-toastr';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import {Router} from '@angular/router';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {Checklistcl} from '../../Models/checklistcl.model';
import {ChecklistService} from '../../Services/checklist.service';
import {AffectationmissionService} from '../../Services/affectationmission.service';
import {InformationmodalComponent} from '../informationmodal/informationmodal.component';
import {AddcommentmodalComponent} from '../addcommentmodal/addcommentmodal.component';

@Component({
  selector: 'app-checklistmodal',
  templateUrl: './checklistmodal.component.html',
  styleUrls: ['./checklistmodal.component.css']
})
export class ChecklistmodalComponent implements OnInit {
  private dialogConfig;
  membre = new Membrecl();
  maxMembreNbre: number;
  addNewMembreActivated: boolean;
  affectation = new Affectation();
  checklist = new Checklistcl();
  check: boolean = false;
  tableDataSource = new MatTableDataSource<Missioncl>();
  public displayedColumns = [ 'Electeur', 'Nom', 'Prenoms', 'Gender',  'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, public svceAnimateur: AnimateurService, public svceCheckList: ChecklistService , public svceAffectation: AffectationService, private toastr: ToastrService, public svceAffectationMission: AffectationmissionService, private router: Router, public dialogRef: MatDialogRef<ChecklistmodalComponent>) { }

  ngOnInit(): void {
    this.refresh();
  }
  refresh = () => {
    console.log(this.data['type']);
    if (this.data['type'] == 4) {
      this.getAllMembresBySection();
    } else {
      this.getAllMembresByComite();
    }
  }
  onSwitchChange = (e, membreId: string, checkListId: string, comment: string) => {
    console.log(comment);
    this.checklist.checkListId = checkListId;
    this.checklist.comment = comment;
    this.checklist.membreId = membreId;
    this.checklist.affectationMissionId = this.data['affectationMissionId'];
    this.checklist.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    if (e.checked){
      this.checklist.etat = 1;
      this.check = true;
      console.log(checkListId);
      // tslint:disable-next-line:triple-equals
      if (checkListId == null || checkListId == ''){
        this.checklist.actionType = 'add';
        this.checklist.checkListId = '';
        this.addCheckList(this.checklist);
      } else {
        this.checklist.actionType = 'update';
        this.updateCheckList(this.checklist);
      }
    } else {
      this.checklist.etat = 0;
      this.checklist.actionType = 'update';
      this.check = false;
      this.updateCheckList(this.checklist);
    }
    if (this.data['commentActif'] === '1') {
      this.addComment(this.checklist);
    }
  }
  addCheckList = (checkList: Checklistcl) => {
    console.log(checkList);
    this.svceCheckList.addorUpdateCheckList(checkList).subscribe(
      (res) => {
        console.log(res);
        this.toastr.success('Checklist enregistrée', 'Message', {
          timeOut: 3000,
        });
        this.refresh();
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  updateCheckList = (checkList: Checklistcl) => {
    this.svceCheckList.addorUpdateCheckList(checkList).subscribe(
      (res) => {
        console.log(res);
        this.toastr.success('Checklist enregistrée', 'Message', {
          timeOut: 3000,
        });
        this.refresh();
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  getAllMembresBySection = () => {
    this.svceAffectationMission.getAllMembresBySection(this.data['missionId'], this.data['blocId']).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.toastr.warning('Aucun militant trouvé pour la section ' + this.data['section']['libelle'] + '.', 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMembresByComite = () => {
    this.svceAffectationMission.getAllMembresByComite(this.data['missionId'], this.data['blocId']).subscribe(
      (res) => {
        if (res !== null && !res.status){
          this.tableDataSource.data = res['donnees'];
          console.log(Object.keys(this.tableDataSource.data).length);
          this.maxMembreNbre = Object.keys(this.tableDataSource.data).length;
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
          if (this.maxMembreNbre >= myGlobals.NBRE_MAX_ML) {
            this.addNewMembreActivated = true;
          } else {
            this.addNewMembreActivated = false;
          }
          console.log(this.addNewMembreActivated);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  addComment = (checkList: Checklistcl) => {
    console.log(checkList);
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '20%'},
      data: {
        checkList
       }
    };
    const dialogRef = this.dialog.open(AddcommentmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.refresh();
      }
    });
  }
}
