<section class="content home">
  <div class="block-header">
    <div class="row">
      <div class="col-lg-5 col-md-5 col-sm-12">
        <h2>Millitants<small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-12 text-right">

        <button *ngIf="this.addNewMembreVisible === true" (click)="addMembre(this.membre)" class="btn btn-white btn-icon btn-round d-none d-md-inline-block float-right m-l-10" type="button">
          <i class="zmdi zmdi-plus"></i>
        </button>

        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
          <li class="breadcrumb-item">Accueil</li>
          <li class="breadcrumb-item active">Millitants</li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="this.searchBarActivated === true" class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="body">
            <div class="row filtrepan enteteFeuille">
              <div class="col-lg-2">
                <div class="form-group" *ngIf="this.selectBoxRegionActivated === true">
                  <mat-form-field>
                    <mat-select  (selectionChange)="getAllTrieDep(singleSelect.value)" placeholder="Régions" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of lstRegions.selected" [value]="item.regionId">
                        {{item.libelle}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group" *ngIf="this.selectBoxDepartementActivated === true">
                  <mat-form-field>
                    <mat-select  (selectionChange)="getAllTrieCommune(singleDepSelect.value)" placeholder="Departements" #singleDepSelect>
                      <mat-option>
                        <ngx-mat-select-search ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of lstDepsTrie.selected" [value]="item.departementId">
                        {{item.libelle}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group" *ngIf="this.selectBoxCommuneActivated === true">
                  <mat-form-field>
                    <mat-select  (selectionChange) ="getAllTrieSection(singleCommuneSelect.value)" placeholder="Communes / SP" #singleCommuneSelect>
                      <mat-option>
                        <ngx-mat-select-search ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of lstCommuneSPTrie.selected" [value]="item.sousPrefectureId">
                        {{item.libelle}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group" *ngIf="this.selectBoxSectionActivated === true">
                  <mat-form-field>
                    <mat-select  (selectionChange)="getAllTrieComite(singleSectionSelect.value)" placeholder="Sections" #singleSectionSelect>
                      <mat-option>
                        <ngx-mat-select-search ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of lstSectionsTrie.selected" [value]="item.sectionId">
                        {{item.libelle}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group" *ngIf="this.selectBoxComiteActivated === true">
                  <mat-form-field>
                    <mat-select  (selectionChange)="getAllMillitantsBy(singleComiteSelect.value)" placeholder="Comités de base" #singleComiteSelect>
                      <mat-option>
                        <ngx-mat-select-search ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of lstComitesTrie.selected" [value]="item.comiteBaseId">
                        {{item.libelle}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="col-lg-4">
                <label><small>Sections</small></label>
                <div class="form-group">
                  <select class="form-control"></select>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="body">
            <div class="row">
              <div class="col-sm-12 col-lg-12"></div>
              <div class="table-responsive">
                <div class="col-lg-12 col-md-5 col-12">
                  <div class="input-group search">
                    <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                    <!-- <span class="input-group-addon">
                        <i class="zmdi zmdi-search"></i>
                    </span> -->
                  </div>
                </div>
                <div class="col-sm-12 col-lg-12">
                  <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                    <!-- <ng-container matColumnDef="Id">
                      <th class="center" mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                  </ng-container> -->
                    <ng-container matColumnDef="Login">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Code Electeur </th>
                      <td mat-cell *matCellDef="let element"> {{element.numeroElecteur !== null ? element.numeroElecteur : 'Non inscrit'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Nom">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
                      <td mat-cell *matCellDef="let element"><a (click)="redirectToDetails(element)" title="Voir plus">{{element.nom}}</a>  </td>
                    </ng-container>
                    <ng-container matColumnDef="Prenoms">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom(s) </th>
                      <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Gender">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sexe </th>
                      <td mat-cell *matCellDef="let element"> {{element.sex == 'M' ? 'Masculin' : 'Féminin'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Email">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
                      <td mat-cell *matCellDef="let element"> <a href="mailto:{{ element.email === '' || element.email == 'null' ?  '' :  element.email }}">{{ element.email === '' || element.email == 'null' ?  '' :  element.email }}</a></td>
                    </ng-container>
                    <ng-container matColumnDef="Phone">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tel </th>
                      <td mat-cell *matCellDef="let element"><span class="phone"  ><i *ngIf=" element.contact !== '' && element.contact !== null" class="zmdi zmdi-phone m-r-5"> </i>{{ element.contact === '' || element.contact === null ?  (element.contact | mask: '(00) 00-00-00') :  (element.contact | mask: '(00) 00-00-00') }}</span> </td>
                    </ng-container>
                    <ng-container matColumnDef="Commune">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Commune </th>
                      <td mat-cell *matCellDef="let element"> {{ element.libelleCommune }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Comite">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Comite B. </th>
                      <td mat-cell *matCellDef="let element"> {{ element.libelleCB }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Role">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Rôles </th>
                      <td mat-cell *matCellDef="let element"> <span _ngcontent-cfp-c215="" class="badge badge-success ng-star-inserted">{{ element.role }} </span></td>
                    </ng-container>
                    <ng-container matColumnDef="Filleule">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header># Filleules </th>
                      <td mat-cell *matCellDef="let element"> <span _ngcontent-cfp-c215="" class="badge badge-pill ng-star-inserted">{{ element.NbreFilleule }} Filleule(s)</span></td>
                    </ng-container>
                    <ng-container matColumnDef="Actions">
                      <th mat-header-cell *matHeaderCellDef style="width: 140px;text-align: center"> Actions </th>
                      <td mat-cell *matCellDef=" let element">
                        <button mat-icon-button [disabled]="element.NbreFilleule > 0 ? false : true" class="btn btn-icon btn-neutral  btn-icon-mini" (click)="openListFilleule(element.membreId, (element.sex === 'F' ? '(Marraine : Mme. ' : '(Parrain : M. ') + ' '+ (element.nom + ' ' + element.prenom + ')'))">
                          <i class="material-icons">visibility</i>
                        </button>
                        <button mat-icon-button  class="btn btn-icon btn-neutral  btn-icon-mini" (click)="addParrainage(this.membre,element.membreId)">
                          <i class="material-icons">transfer_within_a_station</i>
                        </button>
                        <button mat-icon-button  *ngIf=" element.libelleCB !== null"  class="btn btn-icon btn-neutral  btn-icon-mini" (click)="deleteAffectationMembre(element.affectationMembreId,element,element.libelleCB)">
                          <i class="material-icons">link_off</i>
                        </button>
                        <button mat-icon-button *ngIf="element.libelleCB === null"  class="btn btn-icon btn-neutral  btn-icon-mini" (click)="addAffectation(element)">
                          <i class="material-icons">link</i>
                        </button>
                        <button mat-icon-button class="btn btn-icon btn-neutral  btn-icon-mini" (click)="redirectToUpdate(element)">
                          <i class="material-icons">edit</i>
                        </button>
                        <button mat-icon-button [disabled]="this.userConnected['typemembre'] === 'Animateur' ?  element.type !== null ? true : false : this.userConnected['typemembre'] === 'Secrétaire' ? element.type !== null && element.type <= 4 ? true : false : false " class="btn btn-icon btn-neutral btn-icon-mini" (click)="redirectToDelete(element.membreId, (' le millitant '+ element.nom +' '+ element.prenom))">
                          <i class="material-icons">delete_outline</i>
                        </button>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Loading">
                      <th class="w-75" mat-footer-cell *matFooterCellDef colspan="11">
                        Chargement en cour....
                      </th>
                    </ng-container>
                    <ng-container matColumnDef="NoData">
                      <th class="w-75" mat-footer-cell *matFooterCellDef colspan="11">
                        Aucunes données trouvées....
                      </th>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                    <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[20, 40, 60]" [pageSize]="20"  showFirstLastButtons></mat-paginator>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
