<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Role Equipe</strong> </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <form id="roleequipeForm" #roleequipeForm="ngForm" action="post">
        <div class="body">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Libellé</mat-label>
                <input matInput [(ngModel)]="data['roleequipe'].libelle" name="libelle" placeholder="Libellé role equipe" required>
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <!-- <input [(ngModel)]="data['roleequipe'].code" name="code" type="text" class="form-control" placeholder="Code" required> -->
              <mat-form-field class="example-full-width">
                <mat-label>Code</mat-label>
                <input matInput [(ngModel)]="data['roleequipe'].code" name="code" [disabled]="data['roleequipe'].actionType == 'update'" placeholder="Code role equipe" required>
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <!-- <input [(ngModel)]="data['roleequipe'].libelle" name="libelle" type="text" class="form-control" placeholder="Libelle" required> -->
              <mat-form-field class="example-full-width">
                <mat-label>Description</mat-label>
                <input matInput [(ngModel)]="data['roleequipe'].description" name="description" placeholder="Description role equipe" required>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="">
          <div class="col-sm-12 text-right" align="end">
            <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close()" >ANNULER</button>
            <button mat-button [disabled]="!roleequipeForm.form.valid" class="btn btn-primary waves-effect m-r-20" (click)="onAdd(data['roleequipe'])" cdkFocusInitial>VALIDER</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
