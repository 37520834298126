<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Liste Comité de base - {{this.data['acteur'].libelle}} ({{this.data['acteur'].type == 4 ? 'Coordinateur:' : this.data['acteur'].type == 5 ? 'Animateur' : ''}} M. {{this.data['acteur'].nom}})</strong> </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <form id="detailscomiteForm" #detailscomiteForm="ngForm" action="post">
        <div class="body">
          <div class="table-responsive">
            <div class="col-sm-12 col-lg-12">
              <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                <ng-container matColumnDef="Code">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
                  <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                </ng-container>
                <ng-container matColumnDef="Libelle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Comité </th>
                  <td mat-cell *matCellDef="let element"> {{element.libelle}} </td>
                </ng-container>
                <ng-container matColumnDef="Coordinateur">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Animateur </th>
                  <td mat-cell *matCellDef="let element"> {{element.Coordinateur}} </td>
                </ng-container>
                <ng-container matColumnDef="Phone">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Contacts </th>
                  <td mat-cell *matCellDef="let element"> {{ element.contact === '' || element.contact === null ?  element.contact :  element.contact }} </td>
                </ng-container>
                <ng-container matColumnDef="Loading">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Chargement en cours....
                  </th>
                </ng-container>
                <ng-container matColumnDef="NoData">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Aucunes données trouvées....
                  </th>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10"  showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- <mat-dialog-actions align="end">
  <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>Cancel</button>
  <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
