import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Checklistcl} from '../Models/checklistcl.model';
import {catchError, retry} from 'rxjs/operators';
import {Campagnecl} from '../Models/campagnecl.model';
import {Accesscampagne} from '../Models/accesscampagne.model';

@Injectable({
  providedIn: 'root'
})
export class AccesscampagneService {
  URL_API_LOCAL = myGlobals.API_REST_URL + 'coAccessCampagne.php';
  /*Headers*/
    headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  addorUpdateAccessCampagne = (accessCampagne: Accesscampagne): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('accessId', accessCampagne.accessId);
    FORMDATA.append('accessCampagneId', accessCampagne.accessCampagneId);
    FORMDATA.append('campagneId', accessCampagne.campagneId);
    FORMDATA.append('etat', accessCampagne.etat);
    FORMDATA.append('createdBy', accessCampagne.createdBy);
    console.log(accessCampagne);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${accessCampagne.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteAccessCampagne = (accessCampagneId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('accessCampagneId', accessCampagneId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.DELETEBY_ACTION  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
}
