import { DeconexionComponent } from './../../Modals/deconexion/deconexion.component';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import * as $ from 'jquery';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  logindURL = '/singin';
  private dialogConfig;
  full: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  constructor(private cookieSce: CookieService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    $(function(){
      ($.AdminOreo = {}),
      ($.AdminOreo.options = {
        colors: {
            red: '#ec3b57',
            pink: '#E91E63',
            purple: '#ba3bd0',
            deepPurple: '#673AB7',
            indigo: '#3F51B5',
            blue: '#2196f3',
            lightBlue: '#03A9F4',
            cyan: '#00bcd4',
            green: '#4CAF50',
            lightGreen: '#8BC34A',
            yellow: '#ffe821',
            orange: '#FF9800',
            deepOrange: '#f83600',
            grey: '#9E9E9E',
            blueGrey: '#607D8B',
            black: '#000000',
            blush: '#dd5e89',
            white: '#ffffff',
        },
        leftSideBar: { scrollColor: 'rgba(0,0,0,0.5)', scrollWidth: '4px', scrollAlwaysVisible: !1, scrollBorderRadius: '0', scrollRailBorderRadius: '0' },
        dropdownMenu: { effectIn: 'fadeIn', effectOut: 'fadeOut' },
    }),
    ($.AdminOreo.leftSideBar = {
        activate: function () {
            var t = this,
                i = $('body'),
                o = $('.overlay');
            $(window).on('click', function (e) {
                var a = $(e.target);
                'i' === e.target.nodeName.toLowerCase() && (a = $(e.target).parent()),
                    !a.hasClass('bars') && t.isOpen() && 0 === a.parents('#leftsidebar').length && (a.hasClass('js-right-sidebar') || o.fadeOut(), i.removeClass('overlay-open'));
            }),
                $.each($('.menu-toggle.toggled'), function (e, a) {
                    $(a).next().slideToggle(0);
                }),
                $.each($('.menu .list li.active'), function (e, a) {
                    var t = $(a).find('a:eq(0)');
                    t.addClass('toggled'), t.next().show();
                }),
                $('.menu-toggle').on('click', function (e) {
                    var a = $(this),
                        t = a.next();
                    if ($(a.parents('ul')[0]).hasClass('list')) {
                        var i = $(e.target).hasClass('menu-toggle') ? e.target : $(e.target).parents('.menu-toggle');
                        $.each($('.menu-toggle.toggled').not(i).next(), function (e, a) {
                            $(a).is(':visible') && ($(a).prev().toggleClass('toggled'), $(a).slideUp());
                        });
                    }
                    a.toggleClass('toggled'), t.slideToggle(320);
                }),
                t.checkStatuForResize(!0),
                $(window).resize(function () {
                    t.checkStatuForResize(!1);
                });
        },
        checkStatuForResize: function (e) {
            var a = $('body'),
                t = $('.navbar .navbar-header .bars'),
                i = a.width();
            e &&
                a
                    .find('.content, .sidebar')
                    .addClass('no-animate')
                    .delay(1e3)
                    .queue(function () {
                        $(this).removeClass('no-animate').dequeue();
                    }),
                i < 1170 ? (a.addClass('ls-closed'), t.fadeIn()) : (a.removeClass('ls-closed'), t.fadeOut());
        },
        isOpen: function () {
            return $('body').hasClass('overlay-open');
        },
    });
      $.AdminOreo.leftSideBar.activate();
    });
  }
  logOut = () => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'}
    };
    const dialogRef = this.dialog.open(DeconexionComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.cookieSce.deleteAll('/', '/', true);
        window.localStorage.removeItem('oClinicUser');
        localStorage.removeItem('USER_CONNECTED_INFO');
        this.cookieSce.set('oClinicUser', '', -1);
        // this.router.navigate([this.logindURL]);
        window.location.href = this.full;
      }
    });
  }
}
