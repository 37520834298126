import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  constructor() { }

  ngOnInit(): void {
  }

}
