import {Actioncl} from './actioncl.model';

export class AffectationObjectifcl extends Actioncl{
  affectationobjectifId: string;
  objectifId: string;
  departementId: string;
  valeur: string;
  deadline: Date;
  comment: string;
  campagneId: string;
  status: number;
  createdBy: string;
  createdOn: string;
}
