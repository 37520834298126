<section class="content home">
  <div class="block-header">
    <div class="row">
      <div class="col-lg-5 col-md-5 col-sm-12">
        <h2>Election Days<small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-12 text-right">
        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a>
          </li>
          <li class="breadcrumb-item">Accueil</li>
          <li class="breadcrumb-item active">Election Days</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix mb-5">
      <div class="col-lg-12">
        <div class="card">
          <ul class="row profile_state list-unstyled">
            <li class="col-lg-3 col-md-3 col-6">
              <div class="body">
                <i class="zmdi zmdi-time-countdown col-red"></i>
                <h4>
                  <countdown [config]="{leftTime: pastDaysOfYear}"></countdown>
                </h4>
                <span>Compteur</span>
              </div>
            </li>
            <li class="col-lg-3 col-md-3 col-6">
              <div class="body">
                <i class="zmdi zmdi-account-add col-amber"></i>
                <h4>{{this.dashboard.nbreML | mask: 'separator':' '}}</h4>
                <span>Electeurs</span>
              </div>
            </li>
            <li class="col-lg-3 col-md-3 col-6">
              <div class="body">
                <i class="zmdi zmdi-thumb-up text-success"></i>
                <h4>{{this.dashboard.nbreVotant | mask: 'separator':' '}}</h4>
                <span>Votant(s)</span>
              </div>
            </li>
            <li class="col-lg-3 col-md-3 col-6">
              <div class="body">
                <i class="zmdi zmdi-thumb-down text-danger"></i>
                <h4>{{this.dashboard.nbreRestant | mask: 'separator':' '}}</h4>
                <span>Non votant(s)</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row clearfix">

    </div>
    <div class="row clearfix">
      <div class="col-lg-4 col-md-12 mb-5">
        <div class="card tasks_report">
          <div class="header">
            <h2><strong>Suffrages</strong> exprimé sur notre base d'électeurs <small>Détails.</small></h2>
          </div>
          <div class="body text-center">
            <div class="chart">
              <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
                [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors"
                [legend]="pieChartLegend">
              </canvas>
            </div>
            <h6 class="m-t-30">Pourcentage Suffrage Exprimé </h6>
            <small class="displayblock">
              <h2 class="m-t-30">{{((this.nbreVotant / this.nbreML) * 100).toFixed(0)}}%</h2>
            </small>
          </div>
          <div class="text-center">
            <div class="row">
              <div class="col-sm-3">
              </div>
              <div class="col-sm-3">
                <div class="progress">
                  <div class="progress-bar progress-bar-green" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                    aria-valuemax="100" style="width: 100%;"></div>
                </div>
                <h3 class="number m-b-0"> {{this.nbreVotant | mask: 'separator':' '}}</h3>
                <p class="text-muted">Votants</p>
                <!-- <small>Change 23%</small> -->
              </div>
              <div class="col-sm-3">
                <div class="progress">
                  <div class="progress-bar progress-bar-red" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                    aria-valuemax="100" style="width: 100%;"></div>
                </div>
                <h3 class="number m-b-0"> {{this.nbreRestant | mask: 'separator':' '}}</h3>
                <p class="text-muted">Non Votants</p>
                <!-- <small class="text-left">Change 15%</small> -->
              </div>
              <div class="col-sm-3">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mb-5">
        <div class="card tasks_report">
          <div class="header">
            <h2><strong>Suffrages</strong> exprimé sur notre base d'élécteur (-{{this.tauxPerte}}% Perte)
              <small>Détails.</small>
            </h2>
          </div>
          <div class="body text-center">
            <div class="chart">
              <canvas baseChart [data]="pieChartDataTP" [labels]="pieChartLabelsTP" [chartType]="pieChartTypeTP"
                [options]="pieChartOptionsTP" [plugins]="pieChartPluginsTP" [colors]="pieChartColorsTP"
                [legend]="pieChartLegendTP">
              </canvas>
            </div>
            <h6 class="m-t-30">Pourcentage Suffrage Exprimé (-{{this.tauxPerte}}% Perte)</h6>
            <small class="displayblock">
              <h2 class="m-t-30">{{((this.nbreVotant / this.nbreML) * (100 - this.tauxPerte)).toFixed(0)}}%</h2>
            </small>
          </div>
          <div class="text-center">
            <div class="row">
              <div class="col-sm-3">
              </div>
              <div class="col-sm-3">
                <div class="progress">
                  <div class="progress-bar progress-bar-green" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                    aria-valuemax="100" style="width: 100%;"></div>
                </div>
                <h3 class="number m-b-0"> {{ (this.nbreVotant - pourCentageTauxPerte) | mask: 'separator':' '}}</h3>
                <p class="text-muted">Votants</p>
                <!-- <small>Change 23%</small> -->
              </div>
              <div class="col-sm-3">
                <div class="progress">
                  <div class="progress-bar progress-bar-red" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                    aria-valuemax="100" style="width: 100%;"></div>
                </div>
                <h3 class="number m-b-0"> {{this.nbreRestant | mask: 'separator':' '}}</h3>
                <p class="text-muted">Non Votants</p>
                <!-- <small class="text-left">Change 15%</small> -->
              </div>
              <div class="col-sm-3">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mb-5">
        <div class="card tasks_report">
          <div class="header">
            <h2><strong>Suffrages </strong> exprimé selon la base de la CEI (-{{this.tauxPerte}}% Perte)
              <small>Détails.</small>
            </h2>
          </div>
          <div class="body text-center">
            <div class="chart">
              <canvas baseChart [data]="pieChartDataCEI" [labels]="pieChartLabelsCEI" [chartType]="pieChartTypeCEI"
                [options]="pieChartOptionsCEI" [plugins]="pieChartPluginsCEI" [colors]="pieChartColorsCEI"
                [legend]="pieChartLegendCEI">
              </canvas>
            </div>
            <h6 class="m-t-30">Pourcentage Suffrage Exprimé (-{{this.tauxPerte}}% Perte)</h6>
            <small class="displayblock">
              <h2 class="m-t-30">{{((this.nbreVotant / this.nbreCEI) * 100).toFixed(0)}}%</h2>
            </small>
          </div>
          <div class="text-center">
            <div class="row">
              <div class="col-sm-3">
              </div>
              <div class="col-sm-3">
                <div class="progress">
                  <div class="progress-bar progress-bar-orange" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                    aria-valuemax="100" style="width: 100%;"></div>
                </div>
                <h3 class="number m-b-0"> {{(this.nbreVotant - pourCentageTauxPerte) | mask: 'separator':' '}}</h3>
                <p class="text-muted">Votants</p>
                <!-- <small>Change 23%</small> -->
              </div>
              <div class="col-sm-3">
                <div class="progress">
                  <div class="progress-bar progress-bar-electeurcei" role="progressbar" aria-valuenow="50"
                    aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                </div>
                <h3 class="number m-b-0"> {{((this.nbreCEI * this.tauxParticipation) / 100).toFixed(0) | mask:
                  'separator':' '}}</h3>
                <p class="text-muted">Electeurs CEI</p>
                <!-- <small class="text-left">Change 15%</small> -->
              </div>

              <div class="col-sm-3">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 mb-5">
        <div class="card">
          <div class="header">
            <h2> <strong>Districts</strong> - Informations <small>Nombre d'électeurs, Nombre de votants et le nombre des
                nom votants.</small> </h2>
          </div>
          <div class="body">
            <div class="progress m-b-20 progressMission" *ngFor="let item of lstVotantsGroupByDisctrict">
              <div class="progress-bar progress-bar-electeurcei" style="width: 15%; font-size: 20px;">
                <strong>{{item.libelle}} [{{item.nbreML | mask: 'separator':' '}}]</strong>
              </div>
              <div class="progress-bar progress-bar-green"
                [ngStyle]="{'width': ((item.nbreVotant / item.nbreML) * 100).toFixed(0) + '%' ,  'font-size': 20 +'px'}">
                <strong>{{item.nbreVotant | mask: 'separator':' '}}</strong>
              </div>
              <div class="progress-bar progress-bar-red"
                [ngStyle]="{'width': (85 - ((item.nbreVotant / item.nbreML) * 100)).toFixed(0) + '%',  'font-size': 20 +'px'}">
                <strong>{{item.nbreRestants | mask: 'separator':' '}}</strong>
              </div>
            </div>

            <div class="text-center" style="padding-top: 24px;">
              <div class="row">
                <div class="col-sm-5">

                </div>
                <div class="col-sm-4">

                </div>
                <div class="col-sm-1">
                  <div class="progress">
                    <div class="progress-bar progress-bar-electeurcei" role="progressbar" aria-valuenow="50"
                      aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                  </div>
                  <h6></h6>
                  <h6>Districts</h6>
                  <!-- <small>Change 23%</small> -->
                </div>
                <div class="col-sm-1">
                  <div class="progress">
                    <div class="progress-bar progress-bar-green" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                      aria-valuemax="100" style="width: 100%;"></div>
                  </div>
                  <h6></h6>
                  <h6>Votants</h6>
                  <!-- <small>Change 23%</small> -->
                </div>
                <div class="col-sm-1">
                  <div class="progress">
                    <div class="progress-bar progress-bar-red" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                      aria-valuemax="100" style="width: 100%;"></div>
                  </div>
                  <h6></h6>
                  <h6>N-Votants</h6>
                  <!-- <small class="text-left">Change 15%</small> -->
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-lg-12 col-md-12">
        <div class="card tasks_report">
          <div class="header">
            <h2><strong>Départements</strong> - Informations <small>Nombre d'électeurs, Nombre de votants et le nombre
                des nom votants.</small></h2>
          </div>
          <div class="body">
            <div class="table-responsive">
              <div class="col-sm-12 col-lg-12">
                <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"
                  [dataSource]="tableDataSource" matSort>
                  <!-- <ng-container matColumnDef="Id">
                            <th class="center" mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                        </ng-container> -->
                  <ng-container matColumnDef="Dep">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Departements </th>
                    <td mat-cell *matCellDef="let element"> <span class="list-name">{{element.libelle}}</span></td>
                  </ng-container>
                  <ng-container matColumnDef="Coor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Coordinateurs </th>
                    <td mat-cell *matCellDef="let element">{{element.Coordinateur}}</td>
                  </ng-container>
                  <ng-container matColumnDef="Con">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Contatcs </th>
                    <td mat-cell *matCellDef="let element"> <span class="phone"><i
                          *ngIf=" element.contact !== '' && element.contact !== null" class="zmdi zmdi-phone m-r-5">
                        </i>{{ element.contact === '' || element.contact === null ? (element.contact | mask: '(00)
                        00-00-00') : (element.contact | mask: '(00) 00-00-00') }}</span> </td>
                  </ng-container>
                  <ng-container matColumnDef="Ele">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Electeurs </th>
                    <td mat-cell *matCellDef="let element"> <span class="badge badge-pill">{{element.nbreML | mask:
                        'separator':' '}}</span></td>
                  </ng-container>
                  <ng-container matColumnDef="Vot">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Votants </th>
                    <td mat-cell *matCellDef="let element"> <span class="badge badge-success">{{element.nbreVotant |
                        mask: 'separator':' '}}</span></td>
                  </ng-container>
                  <ng-container matColumnDef="Res">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Restants </th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.nbreRestants > 0" class="badge badge-danger">{{element.nbreRestants | mask:
                        'separator':' '}}</span>
                      <span *ngIf="element.nbreRestants <= 0" class="badge badge-success">{{element.nbreRestants | mask:
                        'separator':' '}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Tau">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Taux </th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="((element.nbreVotant / element.nbreML) * 100) >= this.maxGood"
                        class="badge badge-success">{{((element.nbreVotant / element.nbreML) * 100).toFixed(0)}}%</span>
                      <span
                        *ngIf="((element.nbreVotant / element.nbreML) * 100) >= this.maxNotBad && ((element.nbreVotant / element.nbreML) * 100) < this.maxGood"
                        class="badge badge-warning">{{((element.nbreVotant / element.nbreML) * 100).toFixed(0)}}%</span>
                      <span *ngIf="((element.nbreVotant / element.nbreML) * 100) < this.maxNotBad"
                        class="badge badge-danger">{{((element.nbreVotant / element.nbreML) * 100).toFixed(0)}}%</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Loading">
                    <th class="w-75" mat-footer-cell *matFooterCellDef colspan="10">
                      Chargement en cour....
                    </th>
                  </ng-container>
                  <ng-container matColumnDef="NoData">
                    <th class="w-75" mat-footer-cell *matFooterCellDef colspan="10">
                      Aucunes données trouvées....
                    </th>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                  <tr mat-footer-row *matFooterRowDef="['NoData']"
                    [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[20, 40, 60]" [pageSize]="20" showFirstLastButtons></mat-paginator>
              </div>
              <!-- <table class="mat-table cdk-table mat-sort table table-bordered table-striped table-hover js-basic-example dataTable">
                    <thead>
                    <tr>
                      <th class="text-left">Departements</th>
                      <th class="text-left">Coordinateurs</th>
                      <th class="text-left">Contatcs</th>
                      <th>Electeurs</th>
                      <th>Votants</th>
                      <th>Restants</th>
                      <th>Taux</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of lstNonVotantsRestant">
                      <td class="text-left"><span class="list-name">{{item.libelle}}</span></td>
                      <td class="text-left">{{item.Coordinateur}}</td>
                      <td class="text-left">{{item.contact}}</td>
                      <td><span class="badge badge-pill">{{item.nbreML}}</span></td>
                      <td><span class="badge badge-success">{{item.nbreVotant}}</span></td>
                      <td>
                        <span *ngIf="item.nbreRestant > 0" class="badge badge-danger">{{item.nbreRestant}}</span>
                        <span *ngIf="item.nbreRestant <= 0" class="badge badge-success">{{item.nbreRestant}}</span>
                      </td>
                      <td>
                        <span *ngIf="((item.nbreVotant / item.nbreML) * 100) >= this.maxGood" class="badge badge-success">{{((item.nbreVotant / item.nbreML) * 100).toFixed(0)}}%</span>
                        <span *ngIf="((item.nbreVotant / item.nbreML) * 100) >= this.maxNotBad && ((item.nbreVotant / item.nbreML) * 100) < this.maxGood" class="badge badge-warning">{{((item.nbreVotant / item.nbreML) * 100).toFixed(0)}}%</span>
                        <span *ngIf="((item.nbreVotant / item.nbreML) * 100) < this.maxNotBad" class="badge badge-danger">{{((item.nbreVotant / item.nbreML) * 100).toFixed(0)}}%</span>
                      </td>
                    </tr>
                    </tbody>
                  </table> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>