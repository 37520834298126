import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Sectioncl} from '../Models/sectioncl.model';
import {Missioncl} from '../Models/missioncl.model';

@Injectable({
  providedIn: 'root'
})
export class SectionService {

  URL_API_LOCAL = myGlobals.API_REST_URL + 'coSection.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
      this.headers.append('Content-Type', 'multipart/form-data');
      this.headers.append('Accept', 'application/json');
      this.headers.append('Access-Control-Allow-Origin', '*');
   }
   /*Gestion des error HttpClient*/
   handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  /* Récupération des Comités de base actifs du système
   Bamba Aboubacar : 17-08-2020
   params: null // Prends aucun paramètre
  */
 getAllSections = (): Observable<Sectioncl[]> => {
    return this.httpQuery.get<Sectioncl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION , {headers: this.headers})
      .pipe(retry(3), catchError(this.handleError));
  }
  addSection = (section: Sectioncl): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('sectionId', section.sectionId);
    FORMDATA.append('libelle', section.libelle);
    FORMDATA.append('code', section.code);
    FORMDATA.append('communeId', section.communeId);
    FORMDATA.append('createdBy', section.createdBy);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + (section.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION)  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteSection = (sectionId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('sectionId', sectionId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.DELETEBY_ACTION  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMissionBySection = (section: string): Observable<Missioncl> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('sectionId', section);
    return this.httpQuery.post<Missioncl>(this.URL_API_LOCAL + '?Action=' + myGlobals.FILTRE_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllNumbersBySection = (sectionId: string): Observable<any> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coComiteBase.php';
    const FORMDATA: any = new FormData();
    FORMDATA.append('sectionId', sectionId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LOAD_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMissionsBySection = (sectionId: string): Observable<any> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coComiteBase.php';
    const FORMDATA: any = new FormData();
    FORMDATA.append('sectionId', sectionId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LOADALL_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
}
