<aside id="leftsidebar" class="sidebar">
  <ul class="nav nav-tabs">
      <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#dashboard"><i class="zmdi zmdi-home m-r-5"></i>{{this.appName}}</a></li>
      <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#user">Profile</a></li>
  </ul>
  <div class="tab-content">
      <div class="tab-pane stretchRight active" id="dashboard">
          <div class="menu">
              <ul class="list">
                  <li>
                      <div class="user-info">
                          <div class="image"><a><img src="../../../assets/images/default.jpg" alt="User"></a></div>
                          <div class="detail">
                            <h4>{{ userConnected['sex'] === 'F' ? 'Mme' : 'M' }}. {{ userConnected['nom'] }}</h4>
                            <h4> {{ userConnected['prenom'] }}</h4>
                            <small>{{ userConnected['typemembre'] }}</small>
                          </div>
                      </div>
                  </li>
                  <li class="header">PRINCIPAL</li>
                <li routerLinkActive="active" class=""><a routerLink="tableaubord" routerLinkActive="active"><i class="zmdi zmdi-view-dashboard"></i><span>Tableau de Bord</span></a></li>
                  <li routerLinkActive="active" class=""><a routerLink="listeMembres" routerLinkActive="active"><i class="zmdi zmdi-account-add"></i><span>Militants</span></a></li>
                <li routerLinkActive="active" class=""><a routerLink="listeMilitantwoComite" routerLinkActive="active"><i class="zmdi zmdi-accounts-alt"></i><span>Militants Sans CB</span></a></li>
                <li routerLinkActive="active" class=""><a routerLink="listeMissions" routerLinkActive="active"><i class="zmdi zmdi-check-all"></i><span>Missions</span></a></li>
              </ul>
          </div>
      </div>
      <div class="tab-pane stretchLeft" id="user">
          <div class="menu">
              <ul class="list">
                  <li>
                      <div class="user-info m-b-20 p-b-15">
                          <div class="image"><a><img src="../assets/images/default.jpg" alt="User"></a></div>
                          <div class="detail">
                            <h4>{{ userConnected['sex'] === 'F' ? 'Mme' : 'M' }}. {{ userConnected['nom'] }}</h4>
                            <h4> {{ userConnected['prenom'] }}</h4>
                            <small>{{ userConnected['typemembre'] }}</small>
                          </div>
                          <div class="row">
                             <!-- <div class="col-12">
                                  <a title="facebook" href="#"><i class="zmdi zmdi-facebook"></i></a>
                                  <a title="twitter" href="#"><i class="zmdi zmdi-twitter"></i></a>
                                  <a title="instagram" href="#"><i class="zmdi zmdi-instagram"></i></a>
                              </div>-->
                              <div class="col-4 p-r-0">
                                  <h5 class="m-b-5">{{ userConnected['NbreFilleule'] }}</h5>
                                  <small>Parainages</small>
                              </div>
                            <div class="col-4 p-r-0">
                              <h5 class="m-b-5">{{ userConnected['NbreMI'] }}</h5>
                              <small>Missions</small>
                            </div>
                            <div class="col-4">
                              <h5 class="m-b-5">{{ userConnected['NbreML'] }}</h5>
                              <small>Militants</small>
                            </div>
                          </div>
                      </div>
                  </li>
                  <li>
                    <small class="text-muted">SP/Commune: </small>
                    <p>{{ userConnected['LibelleCommune'] }}</p>
                    <hr>
                    <small class="text-muted">Localisation: </small>
                    <p>{{ userConnected['adressePhysique'] }}</p>
                    <hr>
                    <small class="text-muted">Adresse Postale: </small>
                    <p>{{ userConnected['adressePostale'] }}</p>
                    <hr>
                    <small class="text-muted">Lieu de Vode: </small>
                    <p>{{ userConnected['lieuVoteId'] }}</p>
                    <hr>
                    <small class="text-muted">Section </small>
                    <p>{{ userConnected['LibelleSEC'] }}</p>
                    <hr>
                    <small class="text-muted">Comité </small>
                    <p>{{ userConnected['LibelleCB'] }}</p>
                    <hr>
                      <small class="text-muted">Email adresse: </small>
                      <p>{{ userConnected['email'] }}</p>
                      <hr>
                      <small class="text-muted">Contact: </small>
                      <p>+ 225-{{ userConnected['contact'] }}</p>
                      <hr>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</aside>

