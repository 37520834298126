import { AffectationmisionComponent } from './../../Modals/affectationmision/affectationmision.component';
import { Router } from '@angular/router';
import { SectionService } from './../../Services/section.service';
import { SousprefectureService } from './../../Services/sousprefecture.service';
import { Sectioncl } from './../../Models/sectioncl.model';
import { Sousprefecturecl } from './../../Models/sousprefecturecl.model';
import { DepartementService } from './../../Services/departement.service';
import { Departementcl } from './../../Models/departementcl.model';
import { Districtcl } from './../../Models/districtcl.model';
import { Regioncl } from './../../Models/regioncl.model';
import { RegionService } from './../../Services/region.service';
import { DistrictService } from './../../Services/district.service';
import { DetailsanimateurComponent } from './../../Modals/detailsanimateur/detailsanimateur.component';
import { Animateur } from './../../Models/animateur.model';
import {SelectionModel} from '@angular/cdk/collections';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import {AnimateurService} from '../../Services/animateur.service';
import {Access} from '../../Models/access.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {Cordinateurcl} from '../../Models/cordinateurcl.model';
import {DetailsmissionmodalComponent} from '../../Modals/detailsmissionmodal/detailsmissionmodal.component';
import {DeletealertComponent} from '../../Modals/deletealert/deletealert.component';
import {DetailsmilitantsmodalComponent} from '../../Modals/detailsmilitantsmodal/detailsmilitantsmodal.component';
import {UtilisateurComponent} from '../../Modals/utilisateur/utilisateur.component';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-animateur',
  templateUrl: './animateur.component.html',
  styleUrls: ['./animateur.component.css']
})
export class AnimateurComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  private dialogConfig;
  animateur: Animateur;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['check', 'Nom', 'Prenoms', 'Gender', 'Phone', 'Commune', 'Comite', 'NbreML', 'Nbre',  'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  lstAnimateursCheck = new SelectionModel<Animateur>(true, []);

  lstRegions = new SelectionModel<Regioncl>(true, []);
  lstRegionTrie = new SelectionModel<Regioncl>(true, []);
  lstDeps = new SelectionModel<Departementcl>(true, []);
  lstDepsTrie = new SelectionModel<Departementcl>(true, []);
  lstCommuneSP = new SelectionModel<Sousprefecturecl>(true, []);
  lstCommuneSPTrie = new SelectionModel<Sousprefecturecl>(true, []);
  lstSections = new SelectionModel<Sectioncl>(true, []);
  lstSectionsTrie = new SelectionModel<Sectioncl>(true, []);
  /* Gestion des filtres */
  public selectControlRegion: FormControl = new FormControl();
  public selectFilterCtrlRegion: FormControl = new FormControl();
  /** Départements */
  public selectControlDep: FormControl = new FormControl();
  public selectFilterCtrlDep: FormControl = new FormControl();
  /** Sous prefectures / Commune */
  public selectControlCommune: FormControl = new FormControl();
  public selectFilterCtrlCommune: FormControl = new FormControl();
  /** Sections */
  public selectControlSection: FormControl = new FormControl();
  public selectFilterCtrlSection: FormControl = new FormControl();
  /* Gestion des filtres */
  userConnected: {};
  paramMaxMembreNumber: number;
  addNewMembreActivated: boolean;
  addNewMembreVisible: boolean;
  searchBarActivated: boolean;
  selectBoxRegionActivated: boolean;
  selectBoxDepartementActivated: boolean;
  selectBoxCommuneActivated: boolean;
  selectBoxSectionActivated: boolean;
  selectBoxComiteActivated: boolean;
  constructor( private router: Router, private dialog: MatDialog, private svceAnimateur: AnimateurService, private svceRegion: RegionService, private svceDep: DepartementService, private svceSp: SousprefectureService, private svceSection: SectionService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
    this.loadData();
  }
  loadData = () => {
    switch (this.userConnected['typemembre']) {
      case 'Animateur': {
        break;
      }
      case 'Secrétaire': {
        this.getAllAnimateursBy(this.userConnected['sectionId']);
        this.searchBarActivated = false;
        this.addNewMembreVisible = false;
        break;
      }
      default: {
        this.getAllAnimateurs();
        this.getAllRegion();
        this.getAllDep();
        this.getAllComSp();
        this.getAllSec();
        this.searchBarActivated = true;
        break;
      }
    }
  }
  getAllRegion = () => {
    this.svceRegion.getAllRegion().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstRegions.clear();
          res['donnees'].forEach(element => {
            this.lstRegions.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**Departement */
  getAllDep = () => {
    this.svceDep.getAllDepartement().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstDeps.clear();
          res['donnees'].forEach(element => {
            this.lstDeps.select(element);
            this.lstDepsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieDep = (RegionId: string) => {
    this.lstDepsTrie.clear();
    this.lstDeps.selected.forEach(element => {
      if (element.regionId === RegionId)
      {
        this.lstDepsTrie.select(element);
      }
    });

    if (this.lstDepsTrie.selected.length <= 0)
    {
      this.lstDeps.selected.forEach(element => {
        this.lstDepsTrie.select(element);
      });
    }
  }
  /** Fin departement */
  /** Commune */
  getAllComSp = () => {
    this.svceSp.getAllCommuneSp().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstCommuneSP.clear();
          res['donnees'].forEach(element => {
            this.lstCommuneSP.select(element);
            this.lstCommuneSPTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieCommune = (DepartementId: string) => {
    this.lstCommuneSPTrie.clear();
    this.lstCommuneSP.selected.forEach(element => {
      if (element.departementId === DepartementId)
      {
        this.lstCommuneSPTrie.select(element);
      }
    });

    if (this.lstCommuneSPTrie.selected.length <= 0)
    {
      this.lstCommuneSP.selected.forEach(element => {
        this.lstCommuneSPTrie.select(element);
      });
    }
  }
  /** Fin Commune */
  /** Section */
  getAllSec = () => {
    this.svceSection.getAllSections().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstSections.clear();
          res['donnees'].forEach(element => {
            this.lstSections.select(element);
            this.lstSectionsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieSection = (sousprefectureId: string) => {
    this.lstSectionsTrie.clear();
    this.lstSections.selected.forEach(element => {
      if (element.communeId === sousprefectureId)
      {
        this.lstSectionsTrie.select(element);
      }
    });

    if (this.lstSectionsTrie.selected.length <= 0)
    {
      this.lstSections.selected.forEach(element => {
        this.lstSectionsTrie.select(element);
      });
    }
  }
  /** Fin Section */
  getAllAnimateurs = () => {
    this.svceAnimateur.getAllAnimateurs().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllAnimateursBy = (sousprefectureId: string) => {
    this.svceAnimateur.getAllAnimateursBy(sousprefectureId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
  }
  redirectToUpdate = (animateur: Animateur) => {
    console.log(animateur);
  }
  redirectToDelete = (affectationId: string, animateur: string, sectionId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': affectationId,
        'libelle': animateur
      }
    };
    const dialogRef = this.dialog.open(DeletealertComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceAnimateur.deleteAnimateur(affectationId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Affectation supprimée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.loadData();
            }
            else {
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {}
        );
      }
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  openModal = (animateur: any) => {
    this.dialogConfig = {
      width: '900px',
      disableClose: true,
      position: {top: '5%'},
      data: {
        animateur
      }
    };
    const dialogRef = this.dialog.open(DetailsanimateurComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      console.log(result);

    });
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected = () => {
    const numSelected = this.lstAnimateursCheck.selected.length;
    const numRows = this.tableDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle = () => {
    this.isAllSelected() ?
      this.lstAnimateursCheck.clear() :
      this.tableDataSource.data.forEach(row => this.lstAnimateursCheck.select(row as Animateur));
  }
  /** Affectation de missions */
  openAffectaionMission = (membreId: string, blocId: string) => {
    console.log(blocId);
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'type': 5,
        'blocId': blocId
      }
    };
    const dialogRef = this.dialog.open(AffectationmisionComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
        this.loadData();
      }
    });
  }
  openDetailsMission = (acteur: Animateur) => {
    console.log(acteur);
    this.dialogConfig = {
      width: '1000px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        acteur
      }
    };
    const dialogRef = this.dialog.open(DetailsmissionmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
    });
  }
  openListMilitant = (blocId: string, libelle: string) => {
    console.log(blocId);
    console.log(libelle);
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        'blocId': blocId,
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DetailsmilitantsmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      } else {
        this.loadData();
      }
    });
  }
  redirectToAddAccess = (access: Access) => {
    access.accessId = '';
    access.actionType = 'add';
    console.log(access);
    this.openModalAccess(access);
  }
  openModalAccess = (access: Access) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        access
      }
    };
    const dialogRef = this.dialog.open(UtilisateurComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
      this.loadData();
    });
  }
}
