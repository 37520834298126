<section class="content home">
  <div class="block-header">
      <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12">
              <h2>Regions<small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 text-right">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
                <li class="breadcrumb-item">Accueil</li>
                <li class="breadcrumb-item active">Regions</li>
            </ul>
          </div>
      </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
          <div class="card">
            <div class="body">
              <div class="table-responsive">
                <div class="col-lg-12 col-md-5 col-12">
                  <div class="row">
                    <div class="col-sm-12 col-lg-12">
                      <div class="input-group search">
                        <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                        <!-- <span class="input-group-addon">
                            <i class="zmdi zmdi-search"></i>
                        </span> -->
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-12">
                      <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                        <ng-container matColumnDef="Nom">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
                          <td mat-cell *matCellDef="let element"> <a (click)="redirectToDetails(element.membreId)" >{{element.nom}}</a>  </td>
                        </ng-container>
                        <ng-container matColumnDef="Prenoms">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom(s) </th>
                          <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Gender">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Sexe </th>
                          <td mat-cell *matCellDef="let element"> {{element.sex == 'M' ? 'Masculin' : element.sex == 'F' ? 'Fénimin' : ''}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Region">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>Regions </th>
                          <td mat-cell *matCellDef="let element"> {{element.libelle}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Section">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>#Sections </th>
                          <td mat-cell *matCellDef="let element"><span *ngIf="element.NbreSEC > 0" class="badge badge-pill">{{element.NbreSEC}} SEC(S)</span> <span *ngIf="element.NbreSEC == 0" class="badge badge-primary">AUCUNE MISSION</span></td>
                        </ng-container>
                        <ng-container matColumnDef="Nbre">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header># Missions </th>
                          <td mat-cell *matCellDef="let element"><span *ngIf="element.NbreMI > 0" class="badge badge-pill">{{element.NbreMI}} MISSION(S)</span> <span *ngIf="element.NbreMI == 0" class="badge badge-primary">AUCUNE MISSION</span></td>
                        </ng-container>
                        <ng-container matColumnDef="NbreCB">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header># Comités </th>
                          <td mat-cell *matCellDef="let element"><span *ngIf="element.NbreCB > 0" class="badge badge-pill">{{element.NbreCB}} COMITE B.</span> <span *ngIf="element.NbreCB == 0 || element.NbreCB == null" class="badge badge-primary">AUCUN CB</span></td>
                        </ng-container>
                        <ng-container matColumnDef="NbreML">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header># Militants </th>
                          <td mat-cell *matCellDef="let element"><span *ngIf="element.NbreML > 0" class="badge badge-pill">{{element.NbreML}} MILITANT(S)</span> <span *ngIf="element.NbreML == 0 || element.NbreML == null" class="badge badge-primary">AUCUN MILITANT</span></td>
                        </ng-container>
                        <ng-container matColumnDef="Phone">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Contacts </th>
                          <td mat-cell *matCellDef="let element"><span class="phone"  ><i *ngIf=" element.contact !== '' && element.contact !== null" class="zmdi zmdi-phone m-r-5"> </i>{{ element.contact === '' || element.contact === null ?  (element.contact | mask: '(00) 00-00-00') :  (element.contact | mask: '(00) 00-00-00') }}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="Actions">
                          <th mat-header-cell *matHeaderCellDef style="width: 140px;text-align: center"> Actions </th>
                          <td mat-cell *matCellDef=" let element">
                            <!--<button [disabled]="element.NbreMI == 0 ? true : false" mat-icon-button class="btn btn-icon btn-neutral  btn-icon-mini" (click)="openDetailsMission(element)">
                              <i class="material-icons">visibility</i>
                            </button>-->
                            <button [disabled]="element.nom !== null ? true : false" mat-icon-button class="btn btn-icon btn-neutral  btn-icon-mini" (click)="openAffectaionCordi(element.blocId)">
                              <i class="material-icons">supervisor_account</i>
                            </button>
                            <button [disabled]="element.nom == null ? true : false" mat-icon-button class="btn btn-icon btn-neutral btn-icon-mini" (click)="redirectToDelete(element.affectationId, ('le Coordinateur '+element.nom+' '+element.prenom+ ' de la region '+ element.libelle), element.sousPrefectureId)">
                              <i class="material-icons delete">delete_outline</i>
                            </button>
                            <button mat-icon-button class="btn btn-icon btn-neutral  btn-icon-mini" (click)="openListMilitant(element.blocId, ' '+element.libelle+ '')">
                              <i class="material-icons">format_list_bulleted</i>
                            </button>
                            <button mat-icon-button [disabled]="element.asStaff == '0'" class="btn btn-icon btn-neutral  btn-icon-mini" (click)="getListStaff(element.blocId, ' '+element.libelle+ '')">
                              <i class="material-icons">people</i>
                            </button>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Loading">
                          <th class="w-75" mat-footer-cell *matFooterCellDef colspan="10">
                            Chargement en cour....
                          </th>
                        </ng-container>
                        <ng-container matColumnDef="NoData">
                          <th class="w-75" mat-footer-cell *matFooterCellDef colspan="10">
                            Aucunes données trouvées....
                          </th>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                        <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
                      </table>
                      <mat-paginator [pageSizeOptions]="[15, 30, 60]" [pageSize]="15"  showFirstLastButtons></mat-paginator>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</section>
