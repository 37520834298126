<aside id="leftsidebar" class="sidebar">
  <ul class="nav nav-tabs">
      <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#dashboard"><i class="zmdi zmdi-home m-r-5"></i>{{this.appName}}</a></li>
      <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#user">Profile</a></li>
  </ul>
  <div class="tab-content scroll-window" slimScroll  [options]="opts" [scrollEvents] = "scrollEvents">
      <div class="tab-pane stretchRight active" id="dashboard">
          <div class="menu">
              <ul class="list">
                  <li>
                      <div class="user-info">
                          <div class="image"><a><img src="../assets/images/{{ userConnected['photo'] == null || userConnected['photo'] == '' ? 'default.jpg' : userConnected['photo']}}" alt="User"></a></div>
                          <div class="detail">
                            <h4>{{ userConnected['sex'] === 'F' ? 'Mme' : 'M' }}. {{ userConnected['nom'] }}</h4>
                            <h4> {{ userConnected['prenom'] }}</h4>
                            <small>{{ userConnected['typemembre'] }}</small>
                          </div>
                      </div>
                  </li>
                  <li class="header">PRINCIPAL</li>

                  <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class=""><a routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i class="zmdi zmdi-home"></i><span>Tableau de bord</span></a></li> -->
                  <!--<li class="" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">
                    <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-home"></i><span>Tableau de bords</span></a>
                    <ul class="ml-menu">
                        <li class="" routerLinkActive="active"><a routerLink="/dashboard" routerLinkActive="active"><span>Election Bords</span></a></li>
                        <li class="" routerLinkActive="active"><a routerLink="management" routerLinkActive="active"><span>Management Bords</span></a></li>
                    </ul>
                </li>-->
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class=""><a routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i class="zmdi zmdi-view-day"></i><span>Election Days</span></a></li>
                <li routerLinkActive="active" class=""><a routerLink="management" routerLinkActive="active"><i class="zmdi zmdi-view-dashboard"></i><span>Management Bord</span></a></li>
                  <li routerLinkActive="active" class=""><a routerLink="listeMembres" routerLinkActive="active"><i class="zmdi zmdi-account-add"></i><span>Militants</span></a></li>
                  <li routerLinkActive="active" class=""><a routerLink="listeSections" routerLinkActive="active"><i class="zmdi zmdi-gps"></i><span>Sections</span></a></li>
                <li routerLinkActive="active" class=""><a routerLink="listeComites" routerLinkActive="active"><i class="zmdi zmdi-local-activity"></i><span>Comités de base</span></a></li>
                  <li routerLinkActive="active" class=""><a routerLink="listeCordinateurs" routerLinkActive="active"><i class="zmdi zmdi-delicious"></i><span>Secrétaires</span></a></li>
                  <li routerLinkActive="active" class=""><a routerLink="listeAnimateurs" routerLinkActive="active"><i class="zmdi zmdi-nature-people"></i><span>Animateurs</span></a></li>
                  <li routerLinkActive="active" class=""><a routerLink="listeRegions" routerLinkActive="active"><i class="zmdi zmdi-arrow-split"></i><span>Régions</span></a></li>
                  <li routerLinkActive="active" class=""><a routerLink="listeDepartements" routerLinkActive="active"><i class="zmdi zmdi-arrow-split"></i><span>Departements</span></a></li>
                  <li routerLinkActive="active" class=""><a routerLink="listeCommuneSp" routerLinkActive="active"><i class="zmdi zmdi-arrow-split"></i><span>Communes/SP</span></a></li>

                  <li class="header">PARAMETRAGES</li>
                  <li class="" routerLinkActive="active"><a routerLink="listeUsers" routerLinkActive="active"><i class="zmdi zmdi-account-o"></i><span>Utilisateurs</span></a></li>
                  <li class="" routerLinkActive="active"><a routerLink="campagnes" routerLinkActive="active"><i class="zmdi zmdi-photo-size-select-small"></i><span>Campagnes</span></a></li>
                <li class="" routerLinkActive="active"><a routerLink="parti" routerLinkActive="active"><i class="zmdi zmdi-store"></i><span>Partis</span></a></li>


                <li class="header">DECOUPAGE</li>
                <li routerLinkActive="active" class=""><a routerLink="sections" routerLinkActive="active"><i class="zmdi zmdi-gps"></i><span>Sections</span></a></li>
                <li routerLinkActive="active" class=""><a routerLink="comite" routerLinkActive="active"><i class="zmdi zmdi-local-activity"></i><span>Comités de base</span></a></li>
                <li routerLinkActive="active" class=""><a routerLink="missions" routerLinkActive="active"><i class="zmdi zmdi-check-all"></i><span>Missions</span></a></li>
                <li routerLinkActive="active" class=""><a routerLink="objectifs" routerLinkActive="active"><i class="zmdi zmdi-view-list-alt"></i><span>Objectifs</span></a></li>


               <!-- <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-label-alt"></i><span>Découpage</span></a>
                    <ul class="ml-menu">
                        <li class="" routerLinkActive="active"><a routerLink="sections" routerLinkActive="active"><span>Sections</span></a></li>
                        <li class="" routerLinkActive="active"><a routerLink="comite" routerLinkActive="active"><span>Comités de Base</span></a></li>
                        <li class="" routerLinkActive="active"><a routerLink="missions" routerLinkActive="active"><span>Missions</span></a></li>
                      <li routerLinkActive="active" class=""><a routerLink="objectifs" routerLinkActive="active"><span>Objectifs</span></a></li>
                    </ul>
                </li>-->
              </ul>
          </div>
      </div>
      <div class="tab-pane stretchLeft" id="user">
          <div class="menu">
              <ul class="list">
                  <li>
                      <div class="user-info m-b-20 p-b-15">
                          <div class="image"><a><img src="../assets/images/{{ userConnected['photo'] == null || userConnected['photo'] == '' ? 'default.jpg' : userConnected['photo']}}" alt="User"></a></div>
                          <div class="detail">
                            <h4>{{ userConnected['sex'] === 'F' ? 'Mme' : 'M' }}. {{ userConnected['nom'] }}</h4>
                            <h4> {{ userConnected['prenom'] }}</h4>
                            <small>{{ userConnected['typemembre'] }}</small>
                          </div>
                          <div class="row">
                              <!--<div class="col-12">
                                  <a title="facebook" href="#"><i class="zmdi zmdi-facebook"></i></a>
                                  <a title="twitter" href="#"><i class="zmdi zmdi-twitter"></i></a>
                                  <a title="instagram" href="#"><i class="zmdi zmdi-instagram"></i></a>
                              </div>-->
                              <div class="col-4 p-r-0">
                                  <h5 class="m-b-5">{{ userConnected['NbreSEC'] }}</h5>
                                <small>Sections</small>
                              </div>
                            <div class="col-4 p-r-0">
                              <h5 class="m-b-5">{{ userConnected['NbreCB'] }}</h5>
                              <small>Comités</small>
                            </div>
                              <div class="col-4">
                                  <h5 class="m-b-5">{{ userConnected['NbreML'] }}</h5>
                                  <small>Militants</small>
                              </div>
                          </div>
                        <button class="btn btn-outline-primary btn-round btn-simple" (click)="redirectToUpdate(this.userConnected)">Modifier votre de passe</button>
                      </div>
                  </li>
                  <li>
                    <small class="text-muted">SP/Commune: </small>
                    <p>{{ userConnected['LibelleCommune'] }}</p>
                    <hr>
                      <small class="text-muted">Localisation: </small>
                      <p>{{ userConnected['adressePhysique'] }}</p>
                      <hr>
                      <small class="text-muted">Adresse Postale: </small>
                      <p>{{ userConnected['adressePostale'] }}</p>
                      <hr>
                    <small class="text-muted">Lieu de Vode: </small>
                    <p>{{ userConnected['lieuVoteId'] }}</p>
                    <hr>
                    <small class="text-muted">Section </small>
                    <p>{{ userConnected['LibelleSEC'] }}</p>
                    <hr>
                    <small class="text-muted">Comité </small>
                    <p>{{ userConnected['LibelleCB'] }}</p>
                    <hr>
                      <small class="text-muted">Email adresse: </small>
                      <p>{{ userConnected['email'] }}</p>
                      <hr>
                      <small class="text-muted">Contact: </small>
                      <p>+ 225-{{ userConnected['contact'] }}</p>
                      <hr>

                      <!-- <small class="text-muted">Website: </small>
                      <p><a style="padding: 0px;" href="{{ userConnected['Website'] ? 'http://'+userConnected['Website'] : 'http://dr.charlotte.com/' }}" target="_blank">{{ userConnected['Website'] ? userConnected['Website'] : 'dr.charlotte.com/' }}</a>  </p>
                      <hr> -->
                      <!-- <ul class="list-unstyled">
                          <li>
                              <div>Colorectal Surgery</div>
                              <div class="progress m-b-20">
                                  <div class="progress-bar l-blue " role="progressbar" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100" style="width: 89%"> <span class="sr-only">62% Complete</span></div>
                              </div>
                          </li>
                          <li>
                              <div>Endocrinology</div>
                              <div class="progress m-b-20">
                                  <div class="progress-bar l-green " role="progressbar" aria-valuenow="56" aria-valuemin="0" aria-valuemax="100" style="width: 56%"> <span class="sr-only">87% Complete</span></div>
                              </div>
                          </li>
                          <li>
                              <div>Dermatology</div>
                              <div class="progress m-b-20">
                                  <div class="progress-bar l-amber" role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" style="width: 78%"> <span class="sr-only">32% Complete</span></div>
                              </div>
                          </li>
                          <li>
                              <div>Neurophysiology</div>
                              <div class="progress m-b-20">
                                  <div class="progress-bar l-blush" role="progressbar" aria-valuenow="43" aria-valuemin="0" aria-valuemax="100" style="width: 43%"> <span class="sr-only">56% Complete</span></div>
                              </div>
                          </li>
                      </ul> -->
                  </li>
              </ul>
          </div>
      </div>
  </div>
</aside>

<aside id="rightsidebar" class="right-sidebar">
  <ul class="nav nav-tabs">
      <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#setting"><i class="zmdi zmdi-settings zmdi-hc-spin"></i></a></li>
      <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#chat"><i class="zmdi zmdi-comments"></i></a></li>
      <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#activity">Activity</a></li>
  </ul>
  <div class="tab-content">
      <div class="tab-pane slideRight active" id="setting">
          <div class="slim_scroll">
              <div class="card">
                  <h6>General Settings</h6>
                  <ul class="setting-list list-unstyled">
                      <li>
                          <div class="checkbox">
                              <input id="checkbox1" type="checkbox">
                              <label for="checkbox1">Report Panel Usage</label>
                          </div>
                      </li>
                      <li>
                          <div class="checkbox">
                              <input id="checkbox2" type="checkbox" checked="">
                              <label for="checkbox2">Email Redirect</label>
                          </div>
                      </li>
                      <li>
                          <div class="checkbox">
                              <input id="checkbox3" type="checkbox" checked="">
                              <label for="checkbox3">Notifications</label>
                          </div>
                      </li>
                      <li>
                          <div class="checkbox">
                              <input id="checkbox4" type="checkbox" checked="">
                              <label for="checkbox4">Auto Updates</label>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="card">
                  <h6>Skins</h6>
                  <ul class="choose-skin list-unstyled">
                      <li data-theme="purple">
                          <div class="purple"></div>
                      </li>
                      <li data-theme="blue">
                          <div class="blue"></div>
                      </li>
                      <li data-theme="cyan" class="active">
                          <div class="cyan"></div>
                      </li>
                      <li data-theme="green">
                          <div class="green"></div>
                      </li>
                      <li data-theme="orange">
                          <div class="orange"></div>
                      </li>
                      <li data-theme="blush">
                          <div class="blush"></div>
                      </li>
                  </ul>
              </div>
              <div class="card">
                  <h6>Account Settings</h6>
                  <ul class="setting-list list-unstyled">
                      <li>
                          <div class="checkbox">
                              <input id="checkbox5" type="checkbox" checked="">
                              <label for="checkbox5">Offline</label>
                          </div>
                      </li>
                      <li>
                          <div class="checkbox">
                              <input id="checkbox6" type="checkbox" checked="">
                              <label for="checkbox6">Location Permission</label>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="card theme-light-dark">
                  <h6>Left Menu</h6>
                  <button class="t-light btn btn-default btn-simple btn-round btn-block">Light</button>
                  <button class="t-dark btn btn-default btn-round btn-block">Dark</button>
        <button class="m_img_btn btn btn-primary btn-round btn-block">Sidebar Image</button>
              </div>
              <div class="card">
                  <h6>Information Summary</h6>
                  <div class="row m-b-20">
                      <div class="col-7">
                          <small class="displayblock">MEMORY USAGE</small>
                          <h5 class="m-b-0 h6">512</h5>
                      </div>
                      <div class="col-5">
                          <div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#00ced1">8,7,9,5,6,4,6,8</div>
                      </div>
                  </div>
                  <div class="row m-b-20">
                      <div class="col-7">
                          <small class="displayblock">CPU USAGE</small>
                          <h5 class="m-b-0 h6">90%</h5>
                      </div>
                      <div class="col-5">
                          <div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#F15F79">6,5,8,2,6,4,6,4</div>
                      </div>
                  </div>
                  <div class="row m-b-20">
                      <div class="col-7">
                          <small class="displayblock">DAILY TRAFFIC</small>
                          <h5 class="m-b-0 h6">25 142</h5>
                      </div>
                      <div class="col-5">
                          <div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#78b83e">7,5,8,7,4,2,6,5</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-7">
                          <small class="displayblock">DISK USAGE</small>
                          <h5 class="m-b-0 h6">60.10%</h5>
                      </div>
                      <div class="col-5">
                          <div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#457fca">7,5,2,5,6,7,6,4</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="tab-pane right_chat stretchLeft" id="chat">
          <div class="slim_scroll">
              <div class="card">
                  <div class="search">
                      <div class="input-group">
                          <input type="text" class="form-control" placeholder="Search...">
                          <span class="input-group-addon">
                              <i class="zmdi zmdi-search"></i>
                          </span>
                      </div>
                  </div>
              </div>
              <div class="card">
                  <h6>Recent</h6>
                  <ul class="list-unstyled">
                      <li class="online">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar4.jpg" alt="">
                                  <div class="media-body">
                                      <span class="name">Sophia</span>
                                      <span class="message">There are many variations of passages of Lorem Ipsum available</span>
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="online">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar5.jpg" alt="">
                                  <div class="media-body">
                                      <span class="name">Grayson</span>
                                      <span class="message">All the Lorem Ipsum generators on the</span>
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="offline">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar2.jpg" alt="">
                                  <div class="media-body">
                                      <span class="name">Isabella</span>
                                      <span class="message">Contrary to popular belief, Lorem Ipsum</span>
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="me">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar1.jpg" alt="">
                                  <div class="media-body">
                                      <span class="name">John</span>
                                      <span class="message">It is a long established fact that a reader</span>
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="online">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar3.jpg" alt="">
                                  <div class="media-body">
                                      <span class="name">Alexander</span>
                                      <span class="message">Richard McClintock, a Latin professor</span>
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                  </ul>
              </div>
              <div class="card">
                  <h6>Contacts</h6>
                  <ul class="list-unstyled">
                      <li class="offline inlineblock">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar10.jpg" alt="">
                                  <div class="media-body">
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="offline inlineblock">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar6.jpg" alt="">
                                  <div class="media-body">
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="offline inlineblock">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar7.jpg" alt="">
                                  <div class="media-body">
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="offline inlineblock">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar8.jpg" alt="">
                                  <div class="media-body">
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="offline inlineblock">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar9.jpg" alt="">
                                  <div class="media-body">
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="online inlineblock">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar5.jpg" alt="">
                                  <div class="media-body">
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="offline inlineblock">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar4.jpg" alt="">
                                  <div class="media-body">
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="offline inlineblock">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar3.jpg" alt="">
                                  <div class="media-body">
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="online inlineblock">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar2.jpg" alt="">
                                  <div class="media-body">
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li class="offline inlineblock">
                          <a href="javascript:void(0);">
                              <div class="media">
                                  <img class="media-object " src="../assets/images/xs/avatar1.jpg" alt="">
                                  <div class="media-body">
                                      <span class="badge badge-outline status"></span>
                                  </div>
                              </div>
                          </a>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <div class="tab-pane slideLeft" id="activity">
          <div class="slim_scroll">
              <div class="card user_activity">
                  <h6>Recent Activity</h6>
                  <div class="streamline b-accent">
                      <div class="sl-item">
                          <img class="user rounded-circle" src="../assets/images/xs/avatar4.jpg" alt="">
                          <div class="sl-content">
                              <h5 class="m-b-0">Admin Birthday</h5>
                              <small>Jan 21 <a href="javascript:void(0);" class="text-info">Sophia</a>.</small>
                          </div>
                      </div>
                      <div class="sl-item">
                          <img class="user rounded-circle" src="../assets/images/xs/avatar5.jpg" alt="">
                          <div class="sl-content">
                              <h5 class="m-b-0">Add New Contact</h5>
                              <small>30min ago <a href="javascript:void(0);">Alexander</a>.</small>
                              <small><strong>P:</strong> +264-625-2323</small>
                              <small><strong>E:</strong> maryamamiri@gmail.com</small>
                          </div>
                      </div>
                      <div class="sl-item">
                          <img class="user rounded-circle" src="../assets/images/xs/avatar6.jpg" alt="">
                          <div class="sl-content">
                              <h5 class="m-b-0">Code Change</h5>
                              <small>Today <a href="javascript:void(0);">Grayson</a>.</small>
                              <small>The standard chunk of Lorem Ipsum used since the 1500s is reproduced</small>
                          </div>
                      </div>
                      <div class="sl-item">
                          <img class="user rounded-circle" src="../assets/images/xs/avatar7.jpg" alt="">
                          <div class="sl-content">
                              <h5 class="m-b-0">New Email</h5>
                              <small>45min ago <a href="javascript:void(0);" class="text-info">Fidel Tonn</a>.</small>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="card">
                  <h6>Recent Attachments</h6>
                  <ul class="list-unstyled activity">
                      <li>
                          <a href="javascript:void(0)">
                              <i class="zmdi zmdi-collection-pdf l-blush"></i>
                              <div class="info">
                                  <h4>info_258.pdf</h4>
                                  <small>2MB</small>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="javascript:void(0)">
                              <i class="zmdi zmdi-collection-text l-amber"></i>
                              <div class="info">
                                  <h4>newdoc_214.doc</h4>
                                  <small>900KB</small>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="javascript:void(0)">
                              <i class="zmdi zmdi-image l-parpl"></i>
                              <div class="info">
                                  <h4>MG_4145.jpg</h4>
                                  <small>5.6MB</small>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="javascript:void(0)">
                              <i class="zmdi zmdi-image l-parpl"></i>
                              <div class="info">
                                  <h4>MG_4100.jpg</h4>
                                  <small>5MB</small>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="javascript:void(0)">
                              <i class="zmdi zmdi-collection-text l-amber"></i>
                              <div class="info">
                                  <h4>Reports_end.doc</h4>
                                  <small>780KB</small>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="javascript:void(0)">
                              <i class="zmdi zmdi-videocam l-turquoise"></i>
                              <div class="info">
                                  <h4>movie2018.MKV</h4>
                                  <small>750MB</small>
                              </div>
                          </a>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</aside>
<!-- <div class="chat-launcher"></div>
<div class="chat-launcher"></div>
<div class="chat-wrapper">
    <div class="card">
        <div class="header">
            <ul class="list-unstyled team-info margin-0">
                <li class="m-r-15"><h2>Dr. Team</h2></li>
                <li>
                    <img src="../assets/images/xs/avatar2.jpg" alt="Avatar">
                </li>
                <li>
                    <img src="../assets/images/xs/avatar3.jpg" alt="Avatar">
                </li>
                <li>
                    <img src="../assets/images/xs/avatar4.jpg" alt="Avatar">
                </li>
                <li>
                    <img src="../assets/images/xs/avatar6.jpg" alt="Avatar">
                </li>
                <li>
                    <a href="javascript:void(0);" title="Add Member"><i class="zmdi zmdi-plus-circle"></i></a>
                </li>
            </ul>
        </div>
        <div class="body">
            <div class="chat-widget">
            <ul class="chat-scroll-list clearfix">
                <li class="left float-left">
                    <img src="../assets/images/xs/avatar3.jpg" class="rounded-circle" alt="">
                    <div class="chat-info">
                        <a class="name" href="#">Alexander</a>
                        <span class="datetime">6:12</span>
                        <span class="message">Hello, John </span>
                    </div>
                </li>
                <li class="right">
                    <div class="chat-info"><span class="datetime">6:15</span> <span class="message">Hi, Alexander<br> How are you!</span> </div>
                </li>
                <li class="right">
                    <div class="chat-info"><span class="datetime">6:16</span> <span class="message">There are many variations of passages of Lorem Ipsum available</span> </div>
                </li>
                <li class="left float-left"> <img src="../assets/images/xs/avatar2.jpg" class="rounded-circle" alt="">
                    <div class="chat-info"> <a class="name" href="#">Elizabeth</a> <span class="datetime">6:25</span> <span class="message">Hi, Alexander,<br> John <br> What are you doing?</span> </div>
                </li>
                <li class="left float-left"> <img src="../assets/images/xs/avatar1.jpg" class="rounded-circle" alt="">
                    <div class="chat-info"> <a class="name" href="#">Michael</a> <span class="datetime">6:28</span> <span class="message">I would love to join the team.</span> </div>
                </li>
                    <li class="right">
                    <div class="chat-info"><span class="datetime">7:02</span> <span class="message">Hello, <br>Michael</span> </div>
                </li>
            </ul>
            </div>
            <div class="input-group p-t-15">
                <input type="text" class="form-control" placeholder="Enter text here...">
                <span class="input-group-addon">
                    <i class="zmdi zmdi-mail-send"></i>
                </span>
            </div>
        </div>
    </div>
</div> -->
