import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CampagneService} from '../../Services/campagne.service';
import {SelectionModel} from '@angular/cdk/collections';
import {Campagnecl} from '../../Models/campagnecl.model';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-choixcampagne',
  templateUrl: './choixcampagne.component.html',
  styleUrls: ['./choixcampagne.component.css']
})
export class ChoixcampagneComponent implements OnInit {
lstCampagne = new SelectionModel<Campagnecl>(true, []);
  userConnected: {};
  dashboardURL = '/dashboard';
  tableauBordUrl = '/dashboard/tableaubord';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public svceCampagne: CampagneService, private cookieSce: CookieService, private router: Router, public dialogRef: MatDialogRef<ChoixcampagneComponent>) { }

  ngOnInit(): void {
    this.data['campagne'].forEach(item => {
      this.lstCampagne.select(item);
    });
    console.log(this.lstCampagne.selected);
  }
  close = () => {
    this.dialogRef.close(false);
  }
  onClickCampagneSelected = (campagneId: string) => {
    this.cookieSce.set('oClinicUser', JSON.stringify(this.data['user']), 1);
    localStorage.setItem('USER_CONNECTED_INFO', JSON.stringify(this.data['user']));
    localStorage.setItem('USER_CAMPAGNE_ID', campagneId);
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
    this.close();
    if (this.userConnected['typemembre'] === 'Animateur' || this.userConnected['typemembre'] === 'Secrétaire')
    {
      this.router.navigate([this.tableauBordUrl], {queryParams: {refresh: new Date().getTime()}});
    } else {
      this.router.navigate([this.dashboardURL], {queryParams: {refresh: new Date().getTime()}});
    }
  }
}
