<div class="row clearfix">
  <div class="col-md-12">
      <div class="card patients-list">
        <div class="header">
            <h2><strong>Section</strong> </h2>
            <ul class="header-dropdown">
                <li class="remove">
                    <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                </li>
            </ul>
        </div>
        <form id="sectionForm" #sectionForm="ngForm" action="post">
          <div class="body">
            <div class="col-sm-12">
              <div class="form-group">
                <mat-form-field>
                  <mat-select [(ngModel)]="data['section'].communeId" name="communeId" class="" required placeholder="Communes/SP" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option  *ngFor="let commune of lstCommune" [value]="commune.sousPrefectureId">
                      {{commune.libelle}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!--<mat-form-field appearance="fill">
                  <mat-label>Communes / Sous-préfectures</mat-label>
                  <mat-select [(ngModel)]="data['section'].communeId" name="communeId" class="form-control" required>
                    <mat-option *ngFor="let commune of lstCommune" value="{{commune.sousPrefectureId}}">{{commune.libelle}}</mat-option>
                  </mat-select>
                </mat-form-field>-->
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <!-- <input [(ngModel)]="data['section'].code" name="code" type="text" class="form-control" placeholder="Code" required> -->
                <mat-form-field class="example-full-width">
                  <mat-label>Code</mat-label>
                  <input matInput [(ngModel)]="data['section'].code" name="code" placeholder="Code section" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <!-- <input [(ngModel)]="data['section'].libelle" name="libelle" type="text" class="form-control" placeholder="Libelle" required> -->
                <mat-form-field class="example-full-width">
                  <mat-label>Libellé</mat-label>
                  <input matInput [(ngModel)]="data['section'].libelle" name="libellé" placeholder="Libellé section" required>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="">
            <div class="col-sm-12 text-right" align="end">
              <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close()" >ANNULER</button>
        <button mat-button [disabled]="!sectionForm.form.valid" class="btn btn-primary waves-effect m-r-20" (click)="onAdd(data['section'])" cdkFocusInitial>VALIDER</button>
            </div>
          </div>
        </form>
      </div>
  </div>
</div>
