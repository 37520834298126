import { Component, OnInit, OnDestroy, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import {Dashboradcl} from '../../Models/dashboradcl.model';
import {DashboardService} from '../../Services/dashboard.service';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy{
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Dep', 'Coor', 'Con', 'Ele', 'Vot', 'Res', 'Tau'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  appName: string = myGlobals.APP_NAME;
  dashboard = new Dashboradcl();
  lstNonVotantsRestant: Dashboradcl[];
  lstVotantsGroupByDisctrict: Dashboradcl[];
  maxGood: number = myGlobals.TAUX_CONFORME_GOOD;
  maxNotBad: number = myGlobals.TAUX_CONFORME_NOTBAD;
  tauxPerte: number = myGlobals.TAUX_PERTE;
  tauxParticipation: number = myGlobals.TAUX_PARTICIPATION;
  nbreML: number;
  nbreVotant: number;
  nbreRestant: number;
  nbreCEI: number;
  /*Pie diagram Electeur VS Votant*/
  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  pieChartLabels: Label[] = [['Votans'], ['Non votants']];
  pieChartData: number[] = [0, 0];
  pieChartType: ChartType = 'pie';
  pieChartPlugins = [];
  pieChartLegend = true;
  pieChartColors = [
    {
      backgroundColor: ['rgba(30, 130, 76, 1)', 'rgba(217, 30, 24, 1)'],
    },
  ];

  /*Pie diagram Electeur CEI VS Votant*/
  pieChartOptionsCEI: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    showLines: true,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  pieChartLabelsCEI: Label[] = [['Votans'], ['Electeurs CEI']];
  pieChartDataCEI: number[] = [0, 0];
  pieChartTypeCEI: ChartType = 'pie';
  pieChartPluginsCEI = [];
  pieChartLegendCEI = true;
  pieChartColorsCEI = [
    {
      backgroundColor: ['rgba(229, 112, 9, 1)', 'rgba(31, 58, 147, 1)'],
    },
  ];

  /*Pie diagram Electeur VS Votant y compris le taux de perte*/
  pieChartOptionsTP: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    showLines: true,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  pieChartLabelsTP: Label[] = [['Votans'], ['Non votants']];
  pieChartDataTP: number[] = [0, 0];
  pieChartTypeTP: ChartType = 'pie';
  pieChartPluginsTP = [];
  pieChartLegendTP = true;
  pieChartColorsTP = [
    {
      backgroundColor: ['rgba(30, 130, 76, 1)', 'rgba(217, 30, 24, 1)'],
    },
  ];
  pastDaysOfYear: number ;
  pourCentageTauxPerte: number;
  timerOut: Timeout;
  constructor(private svceDashboard: DashboardService) { }

  ngOnInit(): void {
    this.countDown();
    this.getAllNumbers();
    this.getAllNonVotantsRestant();
    this.getAllVotantsGroupByDistricts();
    this.timerOut = setTimeout(() => {
      // window.location.reload();
      this.loadValue();
      console.log('IN IN');
    }, myGlobals.TIME_RELOAD);
  }
  ngOnDestroy(): void {
    clearTimeout(this.timerOut);
  }
  loadValue = () => {
    this.getAllNumbers();
    this.getAllNonVotantsRestant();
    this.getAllVotantsGroupByDistricts();
  }
countDown = () => {
  // TypeScript
  const today = new Date();
  const h = today.getHours() * 60 * 60;
  const m = today.getMinutes() * 60;
  const s = today.getSeconds();
  const t = h + m + s;
  const firstDayOfYear = new Date(myGlobals.ELECTION_DAY);
  const ht = firstDayOfYear.getHours() * 60 * 60;
  const mt = firstDayOfYear.getMinutes() * 60;
  const st = firstDayOfYear.getSeconds();
  const tt = ht + mt + st;
  // Explicitly convert Date to Number
  this.pastDaysOfYear = (Number(tt) - Number(t));
}
loadCharts = () => {
    this.pieChartData = [this.nbreVotant, this.nbreRestant];
    this.pieChartDataTP = [(this.nbreVotant - ((this.nbreVotant * this.tauxPerte) / 100)), this.nbreRestant];
  // tslint:disable-next-line:max-line-length
    this.pieChartDataCEI = [this.nbreVotant, (((this.nbreCEI * myGlobals.TAUX_PARTICIPATION) / 100) - (this.nbreVotant - ((this.nbreVotant * this.tauxPerte) / 100)))];
}
getAllNumbers = () => {
    this.svceDashboard.getAllNumbers().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.dashboard = res['donnees'][0] as Dashboradcl;
          this.nbreCEI = this.dashboard.nbreCEI;
          this.nbreML = this.dashboard.nbreML;
          this.nbreRestant = this.dashboard.nbreRestant;
          this.nbreVotant = this.dashboard.nbreVotant;
          this.loadCharts();
          this.nbreVotant = typeof this.nbreVotant === 'undefined' ? 0 : this.nbreVotant ;
          this.pourCentageTauxPerte = parseInt(((this.nbreVotant  * this.tauxPerte) / 100).toFixed(0), 10);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllNonVotantsRestant = () => {
    this.svceDashboard.getAllElectionDayNumbers().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstNonVotantsRestant = res['donnees'];
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        } else {
          this.lstNonVotantsRestant = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllVotantsGroupByDistricts = () => {
    this.svceDashboard.getAllElectionDayNumbersGroupByDistricts().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstVotantsGroupByDisctrict = res['donnees'];
        } else {
          this.lstVotantsGroupByDisctrict = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
