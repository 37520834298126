<div class="row clearfix">
  <div class="col-md-12">
      <div class="card patients-list">
        <div class="header">
            <h2><strong>Affectation</strong> Missions</h2>
            <ul class="header-dropdown">
                <li class="remove">
                    <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                </li>
            </ul>
        </div>
        <form id="affectationForm" #affectationForm="ngForm" action="post">
          <div class="body">
            <div class="col-sm-12">
              <div class="form-group">
                <mat-form-field>
                  <mat-select [(ngModel)]="affectationmission.missionId" name="missionId" placeholder="Choix de la mission" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of lstMissions.selected" [value]="item.missionId">
                      {{item.libelle}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <mat-form-field>
                  <input matInput [matDatepicker] = "picker" [(ngModel)] ="affectationmission.deadline" [min]="this.minDate"  name="deadline" placeholder = "Date de fin">
                  <mat-datepicker-toggle matSuffix [for] = "picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
               </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <textarea [(ngModel)]="affectationmission.comment" name="comment" class="form-control" placeholder="Commenataire" ></textarea>
              </div>
            </div>
          </div>
          <div class="">
            <div class="col-sm-12 text-right" align="end">
              <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close()" >ANNULER</button>
              <button [disabled]="!affectationForm.form.valid" class="btn btn-primary waves-effect m-r-20" (click)="onAdd()" cdkFocusInitial>VALIDER</button>
            </div>
          </div>
        </form>
      </div>
  </div>
</div>
