<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Comité de Base</strong> </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
  <form id="comiteForm" #comiteForm="ngForm" action="post">
    <div class="body">
    <div class="row clearfix">
      <div class="col-sm-12">
        <div class="form-group">
          <mat-form-field>
            <mat-select [disabled]="this.userConnected['typemembre'] === 'Secrétaire'" [(ngModel)]="data['comite'].sectionId" (selectionChange)="onSelectedChanged(singleSelect.value)" name="sectionId" class="" required placeholder="Sections" #singleSelect>
              <mat-option>
                <ngx-mat-select-search ></ngx-mat-select-search>
              </mat-option>
              <mat-option  *ngFor="let section of lstSection" [value]="section.sectionId">
                {{section.libelle}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
            <!-- <input type="text" [(ngModel)]="data['comite'].code" [ngModelOptions]="{standalone: true}" name="Code" class="form-control" placeholder="Code"> -->
            <mat-form-field class="example-full-width">
              <mat-label>Code</mat-label>
              <input matInput [(ngModel)]="data['comite'].code" name="code" placeholder="Code" required>
            </mat-form-field>
          </div>
      </div>
      <div class="col-sm-12">
          <div class="form-group">
              <!-- <input type="text" [(ngModel)]="data['comite'].libelle" [ngModelOptions]="{standalone: true}" name="Libelle" class="form-control" placeholder="Libelle"> -->
              <mat-form-field class="example-full-width">
                <mat-label>Libellé</mat-label>
                <input matInput [(ngModel)]="data['comite'].libelle" name="Libelle" placeholder="Libellé secdu comité" required>
              </mat-form-field>
           </div>
      </div>
      <div class="col-sm-12" align="end">
          <button class="btn btn-default waves-effect m-r-20" mat-button (click)="close()">ANNULER</button>
          <button mat-button [disabled]="!comiteForm.form.valid" class="btn btn-primary waves-effect m-r-20" (click)="onAdd(data['comite'])" cdkFocusInitial>VALIDER</button>
      </div>
  </div>
    </div>
  </form>
    </div>
  </div>
</div>
<!-- <mat-dialog-actions align="end">
  <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>Cancel</button>
  <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
