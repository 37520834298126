import { AffectationService } from '../../Services/affectation.service';
import { RecherchemembredateComponent } from '../../Modals/recherchemembredate/recherchemembredate.component';
import { Router } from '@angular/router';
import { DetailsmissionmodalComponent } from '../../Modals/detailsmissionmodal/detailsmissionmodal.component';
import { Cordinateurcl } from '../../Models/cordinateurcl.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import { RegionService } from '../../Services/region.service';
import {DeletealertComponent} from '../../Modals/deletealert/deletealert.component';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {DetailsmilitantsmodalComponent} from '../../Modals/detailsmilitantsmodal/detailsmilitantsmodal.component';
import {DetailsmembreregionComponent} from '../../Modals/detailsmembreregion/detailsmembreregion.component';
import {StaffMemberModalComponent} from '../../Modals/staff-member-modal/staff-member-modal.component';

@Component({
  selector: 'app-listeregions',
  templateUrl: './listeregions.component.html',
  styleUrls: ['./listeregions.component.css']
})
export class ListeregionsComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  private dialogConfig;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Nom', 'Prenoms', 'Gender', 'Phone', 'Region', 'Section', 'NbreCB', 'Nbre', 'NbreML',  'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private router: Router, private dialog: MatDialog, private svceAf: AffectationService, private toastr: ToastrService, private svceRegion: RegionService ) { }

  ngOnInit(): void {
    this.getAllCordinateurs();
  }
  /** Récuperation des cordinateurs du système */
  getAllCordinateurs = () => {
    // const regionId = 'edc91132-e3da-11ea-8111-acde48001122';
    this.svceRegion.getAllCordinateurRegion().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
  }
  /** Affectation de missions */
  openAffectaionCordi = (blocId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'type': 1,
        'blocId': blocId
      }
    };
    const dialogRef = this.dialog.open(RecherchemembredateComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
          console.log(result);
          this.getAllCordinateurs();
      }
    });
  }
  openDetailsMission = (acteur: Cordinateurcl) => {
    console.log(acteur);
    acteur.type = 1;
    this.dialogConfig = {
      width: '800px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        acteur
      }
    };
    const dialogRef = this.dialog.open(DetailsmissionmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
    });
  }
  redirectToDelete = (affectationId: string, cordinateur: string, sousPrefectureId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': affectationId,
        'libelle': cordinateur
      }
    };
    const dialogRef = this.dialog.open(DeletealertComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceAf.deleteAffectation(affectationId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success(res['message'], 'Message', {
                timeOut: 3000,
              });
              this.getAllCordinateurs();
            }
            else{
              if (res !== null && res['status']){
                this.tableDataSource.data = [];
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
  openListMilitant = (blocId: string, libelle: string) => {
    console.log(blocId);
    console.log(libelle);
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        'blocId': blocId,
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DetailsmembreregionComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result) {
        this.getAllCordinateurs();
      }
      else {
        // this.loadData();
      }
    });
  }
  getListStaff = (blocId: string, libelle: string) => {
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        'blocId': blocId,
        'libelle': 'Région ' + libelle
      }
    };
    const dialogRef = this.dialog.open(StaffMemberModalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result) {
        this.getAllCordinateurs();
      }
      else {
        // this.loadData();
      }
    });
  }
}
