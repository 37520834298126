import {Actioncl} from './actioncl.model';

export class Affectationmissioncl extends Actioncl{
  affectationMissionId: string;
  missionId: string;
  blocId: string;
  type: number;
  comment: string;
  campagneId: string;
  deadline: string;
  etat: number;
  status: number;
  createdBy: string;
  createdOn: string;
}
