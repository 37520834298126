import { Cordinateurcl } from '../Models/cordinateurcl.model';
import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CordinateurService {
  URL_API_LOCAL = myGlobals.API_REST_URL + 'coAffectation.php';
  /*Headers*/
    headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private httpQuery: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }

  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
 /* getAllCordinateurs = (): Observable<Cordinateurcl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('type', '4');
    return this.httpQuery.post<Cordinateurcl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }*/

  getAllCordinateurs = (): Observable<Cordinateurcl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('type', '4');
    return this.httpQuery.post<Cordinateurcl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /**
   * Récupération des cordinateurs selon la sous préfecture
   * @param Param
   */
  getAllCordinateursBy = (Param: string): Observable<Cordinateurcl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('type', '4');
    FORMDATA.append('valeur', Param);
    return this.httpQuery.post<Cordinateurcl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTBY_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  addCordinateur = (cordinateur: Cordinateurcl): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('affectationId', cordinateur.affectationId);
    FORMDATA.append('acteurId', cordinateur.acteurId);
    FORMDATA.append('blockId', cordinateur.blockId);
    FORMDATA.append('type', cordinateur.type);
    FORMDATA.append('createdBy', cordinateur.createdBy);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${cordinateur.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteCordinateur = (affectationId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('affectationId', affectationId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.DELETEBY_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
}
