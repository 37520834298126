import {Membrecl} from './membrecl.model';

export class Affectationmembrecl extends Membrecl{
  affectationMembreId: string;
  membreId: string;
  comiteBaseId: string;
  status: number;
  createdBy: string;
  createdOn: string;
}
