import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Missioncl} from '../../Models/missioncl.model';
import {ToastrService} from 'ngx-toastr';
import {MissionService} from '../../Services/mission.service';
import {Campagnecl} from '../../Models/campagnecl.model';
import {CampagneService} from '../../Services/campagne.service';

@Component({
  selector: 'app-campagnemodal',
  templateUrl: './campagnemodal.component.html',
  styleUrls: ['./campagnemodal.component.css']
})
export class CampagnemodalComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  minDateScrutin: Date = new Date();
  // minDateLancement: Date = this.minDateScrutin;
  constructor(@Inject(MAT_DIALOG_DATA) public data: Campagnecl, private toastr: ToastrService, public svceCampagne: CampagneService, public dialogRef: MatDialogRef<CampagnemodalComponent>) {
  }

  ngOnInit(): void {
  }
  close = () => {
    this.dialogRef.close(false);
  }
  crudSection = (campagne: Campagnecl, type: string) => {
    this.svceCampagne.addCampagne(campagne).subscribe(
      (res) => {
        switch (type) {
          case 'add':
            this.toastr.success('Campagne insérée avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
          case 'update':
            this.toastr.success('Camapgne modifiée avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
        }
        this.dialogRef.close(true);
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  onAdd = (campagne: Campagnecl) => {
    switch (campagne.actionType){
      case 'add':
        this.crudSection(campagne, campagne.actionType);
        break;
      case 'update':
        this.crudSection(campagne, campagne.actionType);
        break;
      default:
        break;
    }
  }

}
