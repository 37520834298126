import {Component, Inject, OnInit} from '@angular/core';
import {RechercheMembreComponent} from '../recherche-membre/recherche-membre.component';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Membrecl} from '../../Models/membrecl.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-methodusedtoaddmembermodal',
  templateUrl: './methodusedtoaddmembermodal.component.html',
  styleUrls: ['./methodusedtoaddmembermodal.component.css']
})
export class MethodusedtoaddmembermodalComponent implements OnInit {

  private dialogConfig;
  userConnected: {};
  constructor(@Inject(MAT_DIALOG_DATA) public data: Membrecl, private dialog: MatDialog, private router: Router, private dialogRef: MatDialogRef<MethodusedtoaddmembermodalComponent>) { }

  ngOnInit(): void {
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
  }

  searchOnList = (membre: Membrecl) => {
    membre.comiteBaseId = this.userConnected['comiteBaseId'];
    membre.actionType = 'add';
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        membre
      }
    };
    const dialogRef = this.dialog.open(RechercheMembreComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log('Good');
      }
      else{
        dialogRef.close();
      }
    });
    this.dialogRef.close(true);
  }
  renseignInfo = (membre: Membrecl) => {
    membre.membreId = '';
    membre.comiteBaseId = this.userConnected['comiteBaseId'];
    membre.actionType = 'add';
    this.dialogRef.close(true);
    this.router.navigate(['dashboard/membreDetails', membre.membreId]) ;
  }
}
