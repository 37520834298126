import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Missioncl} from '../../Models/missioncl.model';
import {Affectationmissioncl} from '../../Models/affectationmissioncl';
import {Membrecl} from '../../Models/membrecl.model';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MissionService} from '../../Services/mission.service';
import {AffectationmissionService} from '../../Services/affectationmission.service';
import {ToastrService} from 'ngx-toastr';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import {InformationmodalComponent} from '../../Modals/informationmodal/informationmodal.component';
import {ConfirmationmodalComponent} from '../../Modals/confirmationmodal/confirmationmodal.component';
import {ChecklistmodalComponent} from '../../Modals/checklistmodal/checklistmodal.component';

@Component({
  selector: 'app-listemissions',
  templateUrl: './listemissions.component.html',
  styleUrls: ['./listemissions.component.css']
})
export class ListemissionsComponent implements OnInit {
  userConnected: {};
  private dialogConfig;
  maxMembreNbre: number;
  missionIdentifiant: string;
  affectationMissionTerminate: Affectationmissioncl;
  lstMembres: Membrecl[];
  appName: string = myGlobals.APP_NAME;
  type: number;
  value: string;
  tableDataSource = new MatTableDataSource<Missioncl>();
  public displayedColumns = [ 'Libelle', 'Date Limite', 'Statut', 'Taux', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // tslint:disable-next-line:max-line-length
  constructor(private dialog: MatDialog, public svceMission: MissionService, public svceAffectationMission: AffectationmissionService , private toastr: ToastrService, public svceMembre: UtilisateursService) { }

  ngOnInit(): void {
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
    if (this.userConnected['typemembre'] === 'Animateur') {
      this.value = this.userConnected['comiteBaseId'];
      this.type = 5;
    }
    if (this.userConnected['typemembre'] === 'Secrétaire') {
      this.value = this.userConnected['sectionId'];
      this.type = 4;
    }
    console.log(this.value);
    this.refresh();
    this.getAllMissions(false);
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  refresh = () => {
    if (this.userConnected['typemembre'] == 'Animateur') {
      this.getAllMembresByComite();
    } else {
      this.getAllMembresBySection();
    }
    console.log(this.maxMembreNbre);
  }
  getAllMissions = (reload: boolean) => {
    this.svceMission.getAllMissionBy(this.value).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        if (reload === true) {
          this.checkPercentBeforeFinalizeMission(this.missionIdentifiant);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMembresBySection = () => {
    console.log('IN');
    this.svceMembre.getAllMembresBySection(this.value).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMembres = res['donnees'];
          this.maxMembreNbre = Object.keys(this.lstMembres).length;
        }
        else{
          this.lstMembres = res['donnees'];
          this.maxMembreNbre = Object.keys(this.lstMembres).length;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMembresByComite = () => {
    console.log('Comite');
    this.svceMembre.getAllMembresBy(this.value).subscribe(
      (res) => {
        if (res !== null && !res.status){
          this.lstMembres = res['donnees'];
          this.maxMembreNbre = Object.keys(this.lstMembres).length;
        } else {
          this.lstMembres = res['donnees'];
          this.maxMembreNbre = Object.keys(this.lstMembres).length;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  voirCommentaire = (comment: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': comment
      }
    };
    const dialogRef = this.dialog.open(InformationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log('Good');
      }
    });
  }
  deleteAffectationMission = (affectationMissionId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': ' Etes-vous sûr de bien vouloir supprimer cette mission ?'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceAffectationMission.deleteAffectationMission(affectationMissionId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Affectation supprimée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.getAllMissions(false);
            }
            else {
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {}
        );
      }
      else {}
    });
  }
  openCheckList = (affectationMission: Affectationmissioncl, libelle: string, blocId: string, missionId: string, commentActif: number) => {
    this.dialogConfig = {
      width: '1000px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'affectationMissionId': affectationMission.affectationMissionId,
        'type': this.type,
        'libelle': libelle,
        'blocId': this.value,
        'missionId': missionId,
        'commentActif': commentActif
      }
    };
    const dialogRef = this.dialog.open(ChecklistmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log('Good');
      } else {
        this.missionIdentifiant = missionId;
        this.affectationMissionTerminate = affectationMission;
        this.getAllMissions(true);
      }
    });
  }
  checkPercentBeforeFinalizeMission = (missionId: string) => {
    const object = this.tableDataSource.data.find(e => e.missionId === missionId);
    if (((object.taux / this.maxMembreNbre) * 100) >= 100) {
      console.log(this.affectationMissionTerminate);
      if (this.affectationMissionTerminate.etat === 0 ){
        this.affectationMissionTerminate.etat = 1;
        this.svceAffectationMission.updateAffectation(this.affectationMissionTerminate).subscribe(
          (res) => {
            console.log('hummmm');
            if (res !== null && !res['status']){
              this.toastr.success('Mission terminée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.getAllMissions(false);
            }
            else {
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {}
        );
      }
    } else {
      console.log('Mission toujours en cours');
    }
  }
  checkedBeforeFinishedMission = (affectationMission: Affectationmissioncl, checkList: number, taux: number) => {
    console.log(checkList);
    if (checkList === 1){
      if ( ((taux / this.maxMembreNbre) * 100) < 100 ){
        this.dialogConfig = {
          width: '500px',
          disableClose: true,
          position: {top: '10%'},
          data: {
            // tslint:disable-next-line:max-line-length
            'libelle': 'Cette mission est à ' + ((taux / this.maxMembreNbre) * 100).toFixed(2) + '% en terme de taux de progression. Etes-vous sûr de bien vouloir terminer cette mission ?'
          }
        };
        const dialogRef = this.dialog.open(ConfirmationmodalComponent,
          this.dialogConfig
        );
        dialogRef.afterClosed().subscribe(result =>
        {
          if (result)
          {
            this.finishedMission(affectationMission);
          }
          else {}
        });
      }
    } else {
      this.finishedMission(affectationMission);
    }
  }
  finishedMission = (affectationMission: Affectationmissioncl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': ' Etes-vous sûr de bien vouloir terminer cette mission ?'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        affectationMission.etat = 1;
        this.svceAffectationMission.updateAffectation(affectationMission).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Mission terminée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.getAllMissions(false);
            }
            else {
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {}
        );
      }
      else {}
    });
  }
  reactivatedMission = (affectationMission: Affectationmissioncl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': ' Etes-vous sûr de bien vouloir réactiver cette mission ?'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        affectationMission.etat = 0;
        this.svceAffectationMission.updateAffectation(affectationMission).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Mission réactivée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.getAllMissions(false);
            }
            else {
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {}
        );
      }
      else {}
    });
  }
}
