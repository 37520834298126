
<div class="alert">
  <div class="row clearfix">
    <div class="col-lg-12 col-md-12">
      <div class="card tasks_report">
        <div class="header">
          <h2><strong>Alert</strong> Information </h2>
        </div>
        <div class="body text-center">
           {{data['libelle']}}
        </div>
        <div class="footer text-right">
          <button class="btn btn-primary waves-effect m-r-20" mat-button [mat-dialog-close] ="true" >OK</button>
        </div>
      </div>
    </div>
  </div>

  <!-- <strong>Heads up!</strong> This alert needs your attention, but it's not super important. -->
</div>
