import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import {MatTableDataSource} from '@angular/material/table';
import {Missioncl} from '../../Models/missioncl.model';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {Router} from '@angular/router';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-detailsfilleulemodal',
  templateUrl: './detailsfilleulemodal.component.html',
  styleUrls: ['./detailsfilleulemodal.component.css']
})
export class DetailsfilleulemodalComponent implements OnInit {
  tableDataSource = new MatTableDataSource<Missioncl>();
  public displayedColumns = [ 'Electeur', 'Nom', 'Prenoms', 'Gender', 'Email', 'Phone'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private svceUser: UtilisateursService, private router: Router, public dialogRef: MatDialogRef<DetailsfilleulemodalComponent>) { }

  ngOnInit(): void {
    this.getAllFilleulesByParrain();
  }
  getAllFilleulesByParrain = () => {
    this.svceUser.getAllFilleuleByParrain(this.data['parrainId']).subscribe(
      (res) => {
        if (res !== null && !res.status){
          this.tableDataSource.data = res['donnees'];
          console.log(this.tableDataSource.data);
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        } else {
          this.tableDataSource.data = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
    this.dialogRef.close(true);
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
}
