import { AffectationService } from './../../Services/affectation.service';
import { SousprefectureService } from 'src/app/Services/sousprefecture.service';
import { RecherchemembredateComponent } from './../../Modals/recherchemembredate/recherchemembredate.component';
import { DeletealertComponent } from './../../Modals/deletealert/deletealert.component';
import { DetailsmissionmodalComponent } from './../../Modals/detailsmissionmodal/detailsmissionmodal.component';
import { Cordinateurcl } from './../../Models/cordinateurcl.model';

import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {StaffMemberModalComponent} from '../../Modals/staff-member-modal/staff-member-modal.component';
import {Sectioncl} from '../../Models/sectioncl.model';
import {DetailsmembresectionComponent} from '../../Modals/detailsmembresection/detailsmembresection.component';
import {Communecl} from '../../Models/communecl.model';
import {DetailsmembreCommuneComponent} from '../../Modals/detailsmembre-commune/detailsmembre-commune.component';
@Component({
  selector: 'app-listecommunes',
  templateUrl: './listecommunes.component.html',
  styleUrls: ['./listecommunes.component.css']
})
export class ListecommunesComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  private dialogConfig;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Nom', 'Prenoms', 'Gender', 'Phone', 'Region', 'Section', 'NbreCB', 'Nbre', 'NbreML',  'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private router: Router, private dialog: MatDialog, private svceAf: AffectationService, private toastr: ToastrService, private svceSp: SousprefectureService) { }

  ngOnInit(): void {
    this.getAllCordinateurs();
  }
  /** Récuperation des cordinateurs du système */
  getAllCordinateurs = () => {
    // const regionId = 'edc91132-e3da-11ea-8111-acde48001122';
    this.svceSp.getAllCordinateurSp().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
  }
  /** Affectation de missions */
  openAffectaionCordi = (blocId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'type': 3,
        'blocId': blocId
      }
    };
    const dialogRef = this.dialog.open(RecherchemembredateComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
        this.getAllCordinateurs();
      }
    });
  }
  redirectToDelete = (affectationId: string, cordinateur: string, sousPrefectureId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': affectationId,
        'libelle': cordinateur
      }
    };
    const dialogRef = this.dialog.open(DeletealertComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceAf.deleteAffectation(affectationId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success(res['message'], 'Message', {
                timeOut: 3000,
              });
              this.getAllCordinateurs();
            }
            else{
              if (res !== null && res['status']){
                this.tableDataSource.data = [];
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
  /** Liste des membres de la commune */
  openDetailsMembre = (commune: Communecl) => {
    // console.log(section);
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        commune
      }
    };
    const dialogRef = this.dialog.open(DetailsmembreCommuneComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      } else {
        this.getAllCordinateurs();
      }
    });
  }
  openDetailsMission = (acteur: Cordinateurcl) => {
    this.dialogConfig = {
      width: '800px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        acteur
      }
    };
    const dialogRef = this.dialog.open(DetailsmissionmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
    });
  }/*Récuperation de tous les membres du staff*/
  getListStaff = (blocId: string, libelle: string) => {
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        'blocId': blocId,
        'libelle': 'Commune ' + libelle
      }
    };
    const dialogRef = this.dialog.open(StaffMemberModalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result) {
        console.log('membre du staff');
      }
      else {
        // this.loadData();
      }
    });
  }
}
