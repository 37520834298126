<section class="content home">
  <div class="block-header">
    <div class="row">
      <div class="col-lg-5 col-md-5 col-sm-12">
        <h2>Militants<small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-12 text-right">

        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
          <li class="breadcrumb-item">Accueil</li>
          <li class="breadcrumb-item active">Millitants sans Comité de Base</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="body">
            <div class="row">
              <div class="col-sm-12 col-lg-12"></div>
              <div class="table-responsive">
                <div class="col-lg-12 col-md-5 col-12">
                  <div class="input-group search">
                    <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                    <!-- <span class="input-group-addon">
                        <i class="zmdi zmdi-search"></i>
                    </span> -->
                  </div>
                </div>
                <div class="col-sm-12 col-lg-12">
                  <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                    <ng-container matColumnDef="Electeur">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Code Electeur </th>
                      <td mat-cell *matCellDef="let element"> {{element.numeroElecteur !== null ? element.numeroElecteur : 'Non inscrit'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Nom">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
                      <td mat-cell *matCellDef="let element"><a (click)="redirectToDetails(element)" >{{element.nom}}</a>  </td>
                    </ng-container>
                    <ng-container matColumnDef="Prenoms">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom(s) </th>
                      <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Gender">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sexe </th>
                      <td mat-cell *matCellDef="let element"> {{element.sex == 'M' ? 'Masculin' : 'Fénimin'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Email">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
                      <td mat-cell *matCellDef="let element"> <a href="mailto:{{ element.email === '' || element.email == 'null' ?  '' :  element.email }}">{{ element.email === '' || element.email == 'null' ?  '' :  element.email }}</a></td>
                    </ng-container>
                    <ng-container matColumnDef="Phone">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tel </th>
                      <td mat-cell *matCellDef="let element"><span class="phone"  ><i *ngIf=" element.contact !== '' && element.contact !== null" class="zmdi zmdi-phone m-r-5"> </i>{{ element.contact === '' || element.contact === null ?  (element.contact | mask: '(00) 00-00-00') :  (element.contact | mask: '(00) 00-00-00') }}</span> </td>
                    </ng-container>
                    <ng-container matColumnDef="Actions">
                      <th mat-header-cell *matHeaderCellDef style="width: 140px;text-align: center"> Actions </th>
                      <td mat-cell *matCellDef=" let element">
                        <button mat-icon-button  class="btn btn-icon btn-neutral  btn-icon-mini" (click)="addAffectation(element)">
                          <i class="material-icons">link</i>
                        </button>
                        <button mat-icon-button class="btn btn-icon btn-neutral  btn-icon-mini" (click)="redirectToUpdate(element)">
                          <i class="material-icons">edit</i>
                        </button>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Loading">
                      <th class="w-75" mat-footer-cell *matFooterCellDef colspan="10">
                        Chargement en cour....
                      </th>
                    </ng-container>
                    <ng-container matColumnDef="NoData">
                      <th class="w-75" mat-footer-cell *matFooterCellDef colspan="10">
                        Aucunes données trouvées....
                      </th>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                    <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[20, 40, 60]" [pageSize]="20"  showFirstLastButtons></mat-paginator>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
