import { FounddetailsmilitantComponent } from './../../Modals/founddetailsmilitant/founddetailsmilitant.component';
import { UtilisateursService } from './../../Services/utilisateurs.service';
import { Access } from './../../Models/access.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {UtilisateurComponent} from '../../Modals/utilisateur/utilisateur.component';
import {ConfirmationmodalComponent} from '../../Modals/confirmationmodal/confirmationmodal.component';
import {ToastrService} from 'ngx-toastr';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {Router} from '@angular/router';
import {AccesscampagnemodalComponent} from '../../Modals/accesscampagnemodal/accesscampagnemodal.component';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Id', 'Login', 'Nom', 'Prenoms', 'Gender', 'Email', 'Phone' , 'ExpireDate' ,   'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  private dialogConfig;
  access = new Access();
  constructor(private svceUser: UtilisateursService, private toastr: ToastrService, private router: Router,  private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllUsers();
  }
  getAllUsers = () => {
    this.svceUser.getAllUsers().subscribe(
      (res) => {
        if (res !== null && !res.status){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
  }
  redirectToUpdate = (access: Access) => {
    const dt = new Date(access.expiredOn);
    //console.log(dt);
    access.expiredOn = dt;
    access.actionType = 'update';
    this.openModal(access);
  }
  redirectToDelete = (accessId: string, access: Access) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': ' Etes-vous sûr de bien vouloir supprimer les accèss de l\'utilisateur ' + access.nom + ' ' + access.prenom + '?'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceUser.deleteAccess(accessId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Affectation supprimée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.getAllUsers();
            }
            else {
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {}
        );
      }
      else {}
    });
  }
  doFilter = (value: string) => {
      this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  openModal = (access: Access) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        access
      }
    };
    const dialogRef = this.dialog.open(UtilisateurComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
          console.log(result);
      }
      this.getAllUsers();
    });
  }
  accessCampagne = (access: Access) => {
    this.dialogConfig = {
      width: '700px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        access
      }
    };
    const dialogRef = this.dialog.open(AccesscampagnemodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
      this.getAllUsers();
    });
  }
}
