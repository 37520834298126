import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {Missioncl} from '../../Models/missioncl.model';
import {MissionService} from '../../Services/mission.service';

@Component({
  selector: 'app-missionmodal',
  templateUrl: './missionmodal.component.html',
  styleUrls: ['./missionmodal.component.css']
})
export class MissionmodalComponent implements OnInit {
  private check: number;
  private checkComment: number;
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: Missioncl, private toastr: ToastrService, public svceMission: MissionService, public dialogRef: MatDialogRef<MissionmodalComponent>) { }

  ngOnInit(): void {
  }
  close = () => {
    this.dialogRef.close(false);
  }
  crudSection = (mission: Missioncl, type: string) => {
    this.svceMission.addMission(mission).subscribe(
      (res) => {
        switch (type) {
          case 'add':
            this.toastr.success('Mission insérée avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
          case 'update':
            this.toastr.success('Mission modifiée avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
        }
        this.dialogRef.close(true);
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  onCheckboxChange = (e) => {
    if (e.checked){
      this.check = 1;
    } else {
      this.check = 0;
    }
  }
  onCommentChange = (e) => {
    if (e.checked){
      this.checkComment = 1;
    } else {
      this.checkComment = 0;
    }
  }
  onAdd = (mission: Missioncl) => {
    if (this.check != null){
      mission.checkList = this.check;
    }
    if (this.checkComment != null){
      mission.commentActif = this.checkComment;
    }
    switch (mission.actionType){
      case 'add':
        this.crudSection(mission, mission.actionType);
        break;
      case 'update':
        this.crudSection(mission, mission.actionType);
        break;
      default:
        break;
    }
  }
}
