import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MissionService} from '../../Services/mission.service';
import {MatTableDataSource} from '@angular/material/table';
import {Cordinateurcl} from '../../Models/cordinateurcl.model';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Missioncl} from '../../Models/missioncl.model';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {InformationmodalComponent} from '../informationmodal/informationmodal.component';
import {ConfirmationmodalComponent} from '../confirmationmodal/confirmationmodal.component';
import {Comitecl} from '../../Models/comitecl.model';
import {AffectationService} from '../../Services/affectation.service';
import {AffectationmissionService} from '../../Services/affectationmission.service';
import {ToastrService} from 'ngx-toastr';
import {ChecklistmodalComponent} from '../checklistmodal/checklistmodal.component';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import {Affectationmissioncl} from '../../Models/affectationmissioncl';
import {Membrecl} from '../../Models/membrecl.model';

@Component({
  selector: 'app-detailsmissionmodal',
  templateUrl: './detailsmissionmodal.component.html',
  styleUrls: ['./detailsmissionmodal.component.css']
})
export class DetailsmissionmodalComponent implements OnInit {
  tableDataSource = new MatTableDataSource<Missioncl>();
  private dialogConfig;
  maxMembreNbre: number;
  missionIdentifiant: string;
  affectationMissionTerminate: Affectationmissioncl;
  lstMembres: Membrecl[];
  public displayedColumns = [ 'Libelle', 'Date Limite', 'Statut', 'Taux', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, public svceMission: MissionService, public svceAffectationMission: AffectationmissionService , private toastr: ToastrService, public svceMembre: UtilisateursService, public dialogRef: MatDialogRef<DetailsmissionmodalComponent>) { }

  ngOnInit(): void {
    this.refresh();
    this.loadData(false);
    console.log(this.data['acteur'].type);
    console.log(this.data['acteur'].blocId);
    console.log('Total users : ' + this.maxMembreNbre);
  }
  loadData = (reload: boolean) => {
    if (this.data['acteur'].type == 4) {
      this.getAllMissionsBySection(reload);
    } else {
      this.getAllMissions(reload);
    }
  }
  refresh = () => {
    if (this.data['acteur'].type == 4) {
      this.getAllMembresBySection();
    } else {
      this.getAllMembresByComite();
    }
  }
  getAllMissions = (reload: boolean) => {
    this.svceMission.getAllMissionBy(this.data['acteur'].blocId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        if (reload === true) {
          this.checkPercentBeforeFinalizeMission(this.missionIdentifiant);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMissionsBySection = (reload: boolean) => {
    this.svceMission.getAllMissionBySection(this.data['acteur'].blocId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        if (reload === true) {
          this.checkPercentBeforeFinalizeMission(this.missionIdentifiant);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMembresBySection = () => {
    this.svceMembre.getAllMembresBySection(this.data['acteur'].blocId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMembres = res['donnees'];
          this.maxMembreNbre = Object.keys(this.lstMembres).length;
        }
        else{
          this.lstMembres = res['donnees'];
          this.maxMembreNbre = Object.keys(this.lstMembres).length;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMembresByComite = () => {
    this.svceMembre.getAllMembresBy(this.data['acteur'].blocId).subscribe(
      (res) => {
        if (res !== null && !res.status){
          this.lstMembres = res['donnees'];
          this.maxMembreNbre = Object.keys(this.lstMembres).length;
        } else {
          this.lstMembres = res['donnees'];
          this.maxMembreNbre = Object.keys(this.lstMembres).length;
        }
        console.log('Total users : ' + this.maxMembreNbre);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  voirCommentaire = (comment: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': comment
      }
    };
    const dialogRef = this.dialog.open(InformationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log('Good');
      }
    });
  }
  deleteAffectationMission = (affectationMissionId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': ' Etes-vous sûr de bien vouloir supprimer cette mission ?'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.svceAffectationMission.deleteAffectationMission(affectationMissionId).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Affectation supprimée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.loadData(false);
            }
            else {
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {}
        );
      }
      else {}
    });
  }
  openCheckList = (affectationMission: Affectationmissioncl, libelle: string, blocId: string, missionId: string, commentActif: number) => {
    this.dialogConfig = {
      width: '1000px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'affectationMissionId': affectationMission.affectationMissionId,
        'type': this.data['acteur'].type,
        'libelle': libelle,
        'blocId': blocId,
        'missionId': missionId,
        'commentActif': commentActif
      }
    };
    const dialogRef = this.dialog.open(ChecklistmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log('Good');
      } else {
        this.missionIdentifiant = missionId;
        this.affectationMissionTerminate = affectationMission;
        this.loadData(true);
      }
    });
  }
  checkPercentBeforeFinalizeMission = (missionId: string) => {
    const object = this.tableDataSource.data.find(e => e.missionId === missionId);
    console.log(object.taux);
    console.log(this.maxMembreNbre);
    if (((object.taux / this.maxMembreNbre) * 100) >= 100) {
      console.log(this.affectationMissionTerminate);
      if (this.affectationMissionTerminate.etat == 0 ){
        this.affectationMissionTerminate.etat = 1;
        this.svceAffectationMission.updateAffectation(this.affectationMissionTerminate).subscribe(
          (res) => {
            console.log('hummmm');
            if (res !== null && !res['status']){
              this.toastr.success('Mission terminée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.loadData(false);
            }
            else {
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {}
        );
      }
    } else {
      console.log('Mission toujours en cours');
    }
  }
  checkedBeforeFinishedMission = (affectationMission: Affectationmissioncl, checkList: number, taux: number) => {
    console.log(checkList);
    if (checkList == 1){
      if ( ((taux / this.maxMembreNbre) * 100) < 100 ){
        this.dialogConfig = {
          width: '500px',
          disableClose: true,
          position: {top: '10%'},
          data: {
            // tslint:disable-next-line:max-line-length
            'libelle': 'Cette mission est à ' + ((taux / this.maxMembreNbre) * 100).toFixed(2) + '% en terme de taux de progression. Etes-vous sûr de bien vouloir terminer cette mission ?'
          }
        };
        const dialogRef = this.dialog.open(ConfirmationmodalComponent,
          this.dialogConfig
        );
        dialogRef.afterClosed().subscribe(result =>
        {
          if (result)
          {
              this.finishedMission(affectationMission);
          }
          else {}
        });
      }
    } else {
      this.finishedMission(affectationMission);
    }
}
  finishedMission = (affectationMission: Affectationmissioncl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': ' Etes-vous sûr de bien vouloir terminer cette mission ?'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        affectationMission.etat = 1;
        this.svceAffectationMission.updateAffectation(affectationMission).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Mission terminée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.loadData(false);
            }
            else {
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {}
        );
      }
      else {}
    });
  }
  reactivatedMission = (affectationMission: Affectationmissioncl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': ' Etes-vous sûr de bien vouloir réactiver cette mission ?'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        affectationMission.etat = 0;
        this.svceAffectationMission.updateAffectation(affectationMission).subscribe(
          (res) => {
            if (res !== null && !res['status']){
              this.toastr.success('Mission réactivée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.loadData(false);
            }
            else {
              if (res !== null && res['status']){
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else{
                console.log(res);
              }
            }
          },
          (error) => {}
        );
      }
      else {}
    });
  }
}
