<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>List des campagnes - {{this.data['access'].nom}} {{this.data['access'].prenom}} </strong> </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <form id="accesscampagneForm" #accesscampagneForm="ngForm" action="post">
        <div class="body">
          <div class="col-sm-12 col-lg-auto">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <div class="input-group search">
                  <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                  <!-- <span class="input-group-addon">
                      <i class="zmdi zmdi-search"></i>
                  </span> -->
                </div>
              </div>
              <!--<div class="col-sm-2 col-lg-2  text-right processBtn">
                <button (click)="addMembre(this.membre)" mat-icon-button class="btn btn-icon btn-neutral btn-icon-mini">
                  <i class="material-icons add">person_add</i>
                </button>
              </div>-->
            </div>
          </div>
          <div class="table-responsive">
            <div class="col-sm-12 col-lg-auto">
              <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                <ng-container matColumnDef="libelle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Campagne </th>
                  <td mat-cell *matCellDef="let element"> {{element.libelle}} </td>
                </ng-container>
                <ng-container matColumnDef="etat">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Etat </th>
                  <td mat-cell *matCellDef="let element"> <mat-slide-toggle class="checklist" [checked]="element.etat == 1 ? true : false" (change)="onSwitchChange($event,element.accessId,element.campagneId,element.accessCampagneId)"></mat-slide-toggle> </td>
                </ng-container>
                <ng-container matColumnDef="Loading">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Chargement en cours....
                  </th>
                </ng-container>
                <ng-container matColumnDef="NoData">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Aucunes données trouvées....
                  </th>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10"  showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
