import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {FormControl} from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {Regioncl} from '../../Models/regioncl.model';
import {Departementcl} from '../../Models/departementcl.model';
import {RegionService} from '../../Services/region.service';
import {DepartementService} from '../../Services/departement.service';
import {Router} from '@angular/router';
import {SousprefectureService} from '../../Services/sousprefecture.service';
import {SectionService} from '../../Services/section.service';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {Sousprefecturecl} from '../../Models/sousprefecturecl.model';
import {Sectioncl} from '../../Models/sectioncl.model';
import {AnimateurService} from '../../Services/animateur.service';
import {Animateur} from '../../Models/animateur.model';
import {DetailsmissionmodalComponent} from '../../Modals/detailsmissionmodal/detailsmissionmodal.component';
import {DetailsmilitantsmodalComponent} from '../../Modals/detailsmilitantsmodal/detailsmilitantsmodal.component';
import {Comitecl} from '../../Models/comitecl.model';
import {ServiceComponent} from '../../Modals/service/service.component';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {StaffMemberModalComponent} from '../../Modals/staff-member-modal/staff-member-modal.component';

@Component({
  selector: 'app-listecomitefull',
  templateUrl: './listecomitefull.component.html',
  styleUrls: ['./listecomitefull.component.css']
})
export class ListecomitefullComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  /*Choix des listes contenant les filtres*/
  lstRegions = new SelectionModel<Regioncl>(true, []);
  lstDeps = new SelectionModel<Departementcl>(true, []);
  lstDepsTrie = new SelectionModel<Departementcl>(true, []);
  lstCommuneSP = new SelectionModel<Sousprefecturecl>(true, []);
  lstCommuneSPTrie = new SelectionModel<Sousprefecturecl>(true, []);
  lstSections = new SelectionModel<Sectioncl>(true, []);
  lstSectionsTrie = new SelectionModel<Sectioncl>(true, []);
  private dialogConfig;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = [ 'Comite', 'Commune', 'Section', 'Animateur', 'Contact', 'NbreML' , 'NbreMI', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  lstAnimateursCheck = new SelectionModel<Animateur>(true, []);
  /* Gestion des filtres */
  @Input()
  public selectControlRegion: FormControl;
  @Input()
  public selectFilterCtrlRegion: FormControl;
  /** Départements */
  public selectControlDep: FormControl = new FormControl();
  public selectFilterCtrlDep: FormControl = new FormControl();
  /** Sous prefectures / Commune */
  public selectControlCommune: FormControl = new FormControl();
  public selectFilterCtrlCommune: FormControl = new FormControl();
  /** Sections */
  public selectControlSection: FormControl = new FormControl();
  public selectFilterCtrlSection: FormControl = new FormControl();
  comite = new Comitecl();
  userConnected: {};
  paramMaxMembreNumber: number;
  addNewMembreActivated: boolean;
  addNewMembreVisible: boolean;
  searchBarActivated: boolean;
  selectBoxRegionActivated: boolean;
  selectBoxDepartementActivated: boolean;
  selectBoxCommuneActivated: boolean;
  selectBoxSectionActivated: boolean;
  selectBoxComiteActivated: boolean;
  constructor(private svceRegion: RegionService, private svceDep: DepartementService, private router: Router, private svceSp: SousprefectureService, private svceSection: SectionService, private svceAnimateur: AnimateurService, private dialog: MatDialog, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
    this.loadData();
  }
  loadData = () => {
    switch (this.userConnected['typemembre']) {
      case 'Animateur': {
        break;
      }
      case 'Secrétaire': {
        this.getAllAnimateursBy(this.userConnected['sectionId']);
        this.searchBarActivated = false;
        this.addNewMembreVisible = false;
        break;
      }
      default: {
        this.getAllAnimateurs();
        this.getAllRegion();
        this.getAllDep();
        this.getAllComSp();
        this.getAllSec();
        this.searchBarActivated = true;
        break;
      }
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected = () => {
    const numSelected = this.lstAnimateursCheck.selected.length;
    const numRows = this.tableDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle = () => {
    this.isAllSelected() ?
      this.lstAnimateursCheck.clear() :
      this.tableDataSource.data.forEach(row => this.lstAnimateursCheck.select(row as Animateur));
  }
  /** Gestion des listes pour le filtre
   /**Regions */
  getAllRegion = () => {
    this.svceRegion.getAllRegion().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstRegions.clear();
          res['donnees'].forEach(element => {
            this.lstRegions.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**Fin Region */
  /**Departement */
  getAllDep = () => {
    this.svceDep.getAllDepartement().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstDeps.clear();
          res['donnees'].forEach(element => {
            this.lstDeps.select(element);
            this.lstDepsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /** Fin departement */
  /** Commune */
  getAllComSp = () => {
    this.svceSp.getAllCommuneSp().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstCommuneSP.clear();
          res['donnees'].forEach(element => {
            this.lstCommuneSP.select(element);
            this.lstCommuneSPTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /** Fin Commune */
  /** Section */
  getAllSec = () => {
    this.svceSection.getAllSections().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstSections.clear();
          res['donnees'].forEach(element => {
            this.lstSections.select(element);
            this.lstSectionsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieCommune = (DepartementId: string) => {
    this.lstCommuneSPTrie.clear();
    this.lstCommuneSP.selected.forEach(element => {
      if (element.departementId === DepartementId)
      {
        this.lstCommuneSPTrie.select(element);
      }
    });

    if (this.lstCommuneSPTrie.selected.length <= 0)
    {
      this.lstCommuneSP.selected.forEach(element => {
        this.lstCommuneSPTrie.select(element);
      });
    }
  }
  getAllTrieSection = (sousprefectureId: string) => {
    this.lstSectionsTrie.clear();
    this.lstSections.selected.forEach(element => {
      if (element.communeId === sousprefectureId) {
        this.lstSectionsTrie.select(element);
      }
    });

    if (this.lstSectionsTrie.selected.length <= 0) {
      this.lstSections.selected.forEach(element => {
        this.lstSectionsTrie.select(element);
      });
    }
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  getAllTrieDep = (RegionId: string) => {
    this.lstDepsTrie.clear();
    this.lstDeps.selected.forEach(element => {
      if (element.regionId === RegionId)
      {
        this.lstDepsTrie.select(element);
      }
    });

    if (this.lstDepsTrie.selected.length <= 0)
    {
      this.lstDeps.selected.forEach(element => {
        this.lstDepsTrie.select(element);
      });
    }
  }
  getAllAnimateursBy = (sousprefectureId: string) => {
    this.svceAnimateur.getAllAnimateursBy(sousprefectureId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllAnimateurs = () => {
    this.svceAnimateur.getAllAnimateurs().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
            this.toastr.warning(res['message'], 'Message', {
              timeOut: 3000,
            });
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  redirectToDetails = (membreId: string) => {
    this.router.navigate(['dashboard/membreDetails', membreId]) ;
  }
  cleanFielsd = () => {
    this.comite = new Comitecl();
    this.comite.actionType = 'add';
    this.comite.comiteBaseId = '';
    this.comite.NbreCB = 0;
    this.comite.sectionId = '';
    this.comite.createdBy = localStorage.getItem('USER_CONNECTED_ID');
  }
  openModalComite = (comite: Comitecl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        comite
      }
    };
    const dialogRef = this.dialog.open(ServiceComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.getAllAnimateurs();
      }
    });
  }
  /** Affectation de missions */
  openListMilitant = (blocId: string, libelle: string) => {
    console.log(blocId);
    console.log(libelle);
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        'blocId': blocId,
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DetailsmilitantsmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      } else {
        this.loadData();
      }
    });
  }
  openDetailsMission = (acteur: Animateur) => {
    console.log(acteur);
    this.dialogConfig = {
      width: '800px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        acteur
      }
    };
    const dialogRef = this.dialog.open(DetailsmissionmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
    });
  }
  /*Récuperation de tous les membres du staff*/
  getListStaff = (blocId: string, libelle: string) => {
    this.dialogConfig = {
      width: '1200px',
      disableClose: true,
      position: {top: '0%'},
      data: {
        'blocId': blocId,
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(StaffMemberModalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result) {
        console.log('membre du staff');
      }
      else {
        // this.loadData();
      }
    });
  }
}
