import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Membrecl} from '../../Models/membrecl.model';
import {Affectation} from '../../Models/affectation.model';
import {MatTableDataSource} from '@angular/material/table';
import {Missioncl} from '../../Models/missioncl.model';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import {AnimateurService} from '../../Services/animateur.service';
import {AffectationService} from '../../Services/affectation.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {Campagnecl} from '../../Models/campagnecl.model';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {Accesscampagne} from '../../Models/accesscampagne.model';
import {Checklistcl} from '../../Models/checklistcl.model';
import {AccesscampagneService} from '../../Services/accesscampagne.service';

@Component({
  selector: 'app-accesscampagnemodal',
  templateUrl: './accesscampagnemodal.component.html',
  styleUrls: ['./accesscampagnemodal.component.css']
})
export class AccesscampagnemodalComponent implements OnInit {
  private dialogConfig;
  accessCampagne = new Accesscampagne();
  check: boolean = false;
  tableDataSource = new MatTableDataSource<Campagnecl>();
  public displayedColumns = [ 'libelle', 'etat'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private svceUser: UtilisateursService, private dialog: MatDialog, public svceAccessCampagne: AccesscampagneService, private toastr: ToastrService,  private router: Router, public dialogRef: MatDialogRef<AccesscampagnemodalComponent>) { }

  ngOnInit(): void {
    console.log(this.data['access'].accessId);
    this.refresh();
  }
  getCampagneByAccess = (accessId: string) => {
    this.svceUser.getCampagneByAccess(accessId).subscribe(
      (res) => {
        if (res !== null && !res.status){
          this.tableDataSource.data = res['donnees'];
          console.log(this.tableDataSource.data);
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  refresh = () => {
    this.getCampagneByAccess(this.data['access'].accessId);
  }
  onSwitchChange = (e, accessId: string, campagneId: string, accessCampagneId: string) => {
    this.accessCampagne.accessCampagneId = accessCampagneId;
    this.accessCampagne.accessId = this.data['access'].accessId;
    this.accessCampagne.campagneId = campagneId;
    this.accessCampagne.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    if (e.checked){
      this.accessCampagne.etat = 1;
      this.check = true;
      console.log(accessCampagneId);
      // tslint:disable-next-line:triple-equals
      if (accessCampagneId == null || accessCampagneId == ''){
        this.accessCampagne.actionType = 'add';
        this.accessCampagne.accessCampagneId = '';
        this.addAccessCampagne(this.accessCampagne);
      } else {
        this.accessCampagne.actionType = 'update';
        this.updateAccessCampagne(this.accessCampagne);
      }
    } else {
      this.accessCampagne.etat = 0;
      this.accessCampagne.actionType = 'update';
      this.check = false;
      console.log(this.accessCampagne);
      this.updateAccessCampagne(this.accessCampagne);
    }
  }
  addAccessCampagne = (accessCampagne: Accesscampagne) => {
    console.log(accessCampagne);
    this.svceAccessCampagne.addorUpdateAccessCampagne(accessCampagne).subscribe(
      (res) => {
        console.log(res);
        this.toastr.success('Campagne enregistrée', 'Message', {
          timeOut: 3000,
        });
        this.refresh();
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  updateAccessCampagne = (accessCampagne: Accesscampagne) => {
    this.svceAccessCampagne.deleteAccessCampagne(accessCampagne.accessCampagneId).subscribe(
      (res) => {
        console.log(res);
        this.toastr.success('Campagne supprimée', 'Message', {
          timeOut: 3000,
        });
        this.refresh();
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
}
