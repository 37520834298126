import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Campagnecl} from '../../Models/campagnecl.model';
import {ToastrService} from 'ngx-toastr';
import {CampagneService} from '../../Services/campagne.service';
import {MatTableDataSource} from '@angular/material/table';
import {Missioncl} from '../../Models/missioncl.model';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {Checklistcl} from '../../Models/checklistcl.model';
import {Particampagecl} from '../../Models/particampagecl.model';

@Component({
  selector: 'app-checkpatiscampagne',
  templateUrl: './checkpatiscampagne.component.html',
  styleUrls: ['./checkpatiscampagne.component.css']
})
export class CheckpatiscampagneComponent implements OnInit {
  tableDataSource = new MatTableDataSource<Missioncl>();
  public displayedColumns = [ 'Code', 'Libelle', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  partCampagne = new Particampagecl();
  constructor(@Inject(MAT_DIALOG_DATA) public data: Campagnecl, private toastr: ToastrService, public svceCampagne: CampagneService,  public dialogRef: MatDialogRef<CheckpatiscampagneComponent>) { }
  ngOnInit(): void {
    this.getListPartis(this.data["itemId"]);
  }
  getListPartis = (value: string) => {
    this.svceCampagne.getAllPartiByCampagne(this.data['itemId']).subscribe(
      (res) => {
        if (res !== null && !res.status){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
 }
  onSwitchChange = (e, PartiCampagne: Particampagecl) => {
    /*this.partCampagne.partiCampagneId = PartiCampagne.partiCampagneId;
    this.partCampagne.IdParti = PartiCampagne.IdParti;*/
    PartiCampagne.IdCampagne = this.data['itemId'];
    PartiCampagne.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    if (e.checked){
      // this.partCampagne.etat = 1;
      PartiCampagne.etat = 1;
      if (PartiCampagne.partiCampagneId == null || PartiCampagne.partiCampagneId == ''){
        PartiCampagne.actionType = 'add';
        PartiCampagne.partiCampagneId = '';
      } else {
        PartiCampagne.actionType = 'update';
      }
    } else {
      PartiCampagne.etat = 0;
      // PartiCampagne.actionType = 'update';
      // this.updateCheckList(this.checklist);
    }
    this.addPartiCampagne(PartiCampagne);
  }
  addPartiCampagne = (PartiCampagne: Particampagecl) => {
    this.svceCampagne.addorUpdatePartiCampagne(PartiCampagne).subscribe(
      (res) => {
        this.toastr.success('Operation reussit', 'Message', {
          timeOut: 3000,
        });
         this.getListPartis(this.data["itemId"]);
      },
      (error) => {
        console.log(error);
        // this.partCampagne.etat = PartiCampagne.etat ? 0 : 1;
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
        // this.getListPartis(this.data["itemId"]);
      }
    );
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
}
