import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Affectationmissioncl} from '../Models/affectationmissioncl';
import {catchError, retry} from 'rxjs/operators';
import {Affectation} from '../Models/affectation.model';

@Injectable({
  providedIn: 'root'
})
export class AffectationService {
  URL_API_LOCAL = myGlobals.API_REST_URL + 'coAffectation.php';
  /*Headers*/
    headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private httpQuery: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }

  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  addAffectation = (affecation: Affectation): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('affecationId', typeof affecation.affectationId !== 'undefined' ? affecation.affectationId : '');
    FORMDATA.append('acteurId', affecation.acteurId);
    FORMDATA.append('blocId', affecation.blocId);
    FORMDATA.append('type', affecation.type);
    FORMDATA.append('status', affecation.status);
    FORMDATA.append('createdBy', affecation.createdBy);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${ myGlobals.INSERT_ACTION }`, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }

  deleteAffectation = (affecationId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('affectationId', affecationId);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${ myGlobals.DELETEBY_ACTION }`, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
}
