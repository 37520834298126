import { Component, OnInit, Input, EventEmitter  } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import {Access} from '../../Models/access.model';
import {UtilisateurComponent} from '../../Modals/utilisateur/utilisateur.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-leftnav',
  templateUrl: './leftnav.component.html',
  styleUrls: ['./leftnav.component.css']
})
export class LeftnavComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  private dialogConfig;
  logindURL = '/singin';
  constructor(private cookieSce: CookieService, private router: Router, private dialog: MatDialog) { }
  userConnected: {};
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  ngOnInit(): void {
    const verifUserCookies = this.cookieSce.get('oClinicUser');
    if ( verifUserCookies !== null)
    {
      this.userConnected = JSON.parse(verifUserCookies);
      localStorage.setItem('USER_CONNECTED_ID', this.userConnected['membreId']);
      /*console.log(this.userConnect);*/
    }
    else{
      this.router.navigate([this.logindURL]);
    }
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.opts = {
      position: 'right',
      barBackground: '#C9C9C9',
      alwaysVisible: true,
      visibleTimeout: 1000,
      alwaysPreventDefaultScroll: true
    };
    this.play();
  }
  play(): void {
    let event = null;
    Promise.resolve()
      .then(() => this.timeout(3000))
      .then(() => {
        event = new SlimScrollEvent({
          type: 'scrollToBottom',
          duration: 2000,
          easing: 'inOutQuad'
        });
        this.scrollEvents.emit(event);
      })
      .then(() => this.timeout(3000))
      .then(() => {
        event = new SlimScrollEvent({
          type: 'scrollToTop',
          duration: 3000,
          easing: 'outCubic'
        });
        this.scrollEvents.emit(event);
      })
      .then(() => this.timeout(4000))
      .then(() => {
        event = new SlimScrollEvent({
          type: 'scrollToPercent',
          percent: 80,
          duration: 1000,
          easing: 'linear'
        });
        this.scrollEvents.emit(event);
      })
      .then(() => this.timeout(2000))
      .then(() => {
        event = new SlimScrollEvent({
          type: 'scrollTo',
          y: 200,
          duration: 4000,
          easing: 'inOutQuint'
        });
        this.scrollEvents.emit(event);
      });
  }
  timeout(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
  }
  redirectToUpdate = (accessAny: any) => {
    const access: Access = accessAny as Access ;
    const dt = new Date(access.expiredOn);
    console.log(dt);
    access.expiredOn = dt;
    access.actionType = 'updateP';
    this.openModal(access);
  }
  openModal = (access: Access) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        access
      }
    };
    const dialogRef = this.dialog.open(UtilisateurComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        console.log(result);
      }
    });
  }
}
