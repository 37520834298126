import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Comitecl} from '../Models/comitecl.model';
import {Particl} from '../Models/particl.model';
import {Membrecl} from '../Models/membrecl.model';
import {Missioncl} from '../Models/missioncl.model';

@Injectable({
  providedIn: 'root'
})
export class ComiteService {

  URL_API_LOCAL = myGlobals.API_REST_URL + 'coComiteBase.php';
  URL_API_LOCAL_PARTI = myGlobals.API_REST_URL + 'coParti.php';
  /*Headers*/
    headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
      this.headers.append('Content-Type', 'multipart/form-data');
      this.headers.append('Accept', 'application/json');
      this.headers.append('Access-Control-Allow-Origin', '*');
   }
   /*Gestion des error HttpClient*/
   handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  /* Récupération des Comités de base actifs du système
   Bamba Aboubacar : 17-08-2020
   params: null // Prends aucun paramètre
  */
  getAllComiteBases = (): Observable<Comitecl[]> => {
    /*this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coComiteBase.php';*/
    return this.httpQuery.get<Comitecl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION , {headers: this.headers })
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllComiteBy = (sectionId: string): Observable<Comitecl> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', sectionId);
    return this.httpQuery.post<Comitecl>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMissionByComite = (comite: string): Observable<Missioncl> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('comiteBaseId', comite);
    return this.httpQuery.post<Missioncl>(this.URL_API_LOCAL + '?Action=' + myGlobals.FILTRE_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  addComite = (comite: Comitecl): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('comiteBaseId', comite.comiteBaseId);
    FORMDATA.append('libelle', comite.libelle);
    FORMDATA.append('code', comite.code);
    FORMDATA.append('createdBy', comite.createdBy);
    FORMDATA.append('sectionId', comite.sectionId);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${comite.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteComite = (comiteBaseId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('comiteBaseId', comiteBaseId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.DELETEBY_ACTION  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllNumbersByComite = (comiteBaseId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('comiteBaseId', comiteBaseId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LOAD_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMissionsByComite = (comiteBaseId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('comiteBaseId', comiteBaseId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.LOADALL_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
}

























