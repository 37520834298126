<div class="row clearfix profile-page">
  <div class="col-lg-12 col-md-12">
      <div class="card profile-header info-membre">
          <div class="body text-center">
              <div class="profile-image"> <img src="../assets/images/default.jpg" alt=""> </div>
              <div>
                  <h4 class="m-b-0"><strong>
                    {{ data['membre']['sex'] == 'M' ? 'M.' : data['membre']['sex'] == 'F' ? 'Mme.' : '' }} {{ data['membre']['nom'] }}
                  </strong>{{ data['membre']['prenom'] }}</h4>
                  <span class="job_post">{{data['membre']['profession']}}</span>
                  <p>{{data['membre']['adressePostale']}}<br> {{data['membre']['adressePhysique']}}</p>
              </div>
          </div>
        <div class="col-sm-12">
          <div class="form-group">
            <mat-form-field class="example-full-width">
              <mat-label>Contact</mat-label>
              <input matInput mask="00-00-00-00" [showMaskTyped]="true" [dropSpecialCharacters]="true" [(ngModel)]="data['membre']['contact']" name="contact" placeholder="Contact" required #contact="ngModel">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="card info-membre cardFoud">
          <div class="body">
              <div class="workingtime">
                  <h6>Autres Infos</h6>
                  <small class="text-muted">Lieu de naissance</small>
                  <p>{{data['membre']['lieuNaissance']}}</p>
                  <hr>
                  <small class="text-muted">Date de naissance</small>
                  <p>{{data['membre']['dateNaissance']}}</p>
                  <hr>
                  <small class="text-muted">Nom et prénom(s) du père</small>
                  <p>{{data['membre']['nomPere']+' '+ data['membre']['prenomPere']}}</p>
                  <hr>
                  <small class="text-muted">Nom et prénom(s) de la mère</small>
                  <p>{{data['membre']['nomMere']+' '+ data['membre']['prenomMere']}}</p>
                  <hr>
              </div>
              <div class="text-right">
                <button mat-dialog-close class="btn btn-primary btn-round">ANNULER</button>
                <button [disabled] = "contact.invalid ? true: false"  class="btn btn-primary btn-round btn-simple" (click)="onAddMembre(data['membre'])">AFFECTER</button>
            </div>
          </div>
      </div>
  </div>
</div>
