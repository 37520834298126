import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {Membrecl} from '../../Models/membrecl.model';
import {Router} from '@angular/router';
import {InformationmodalComponent} from '../../Modals/informationmodal/informationmodal.component';
import {Affectationmembrecl} from '../../Models/affectationmembrecl.model';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationmodalComponent} from '../../Modals/confirmationmodal/confirmationmodal.component';

@Component({
  selector: 'app-listemilitantwocomite',
  templateUrl: './listemilitantwocomite.component.html',
  styleUrls: ['./listemilitantwocomite.component.css']
})
export class ListemilitantwocomiteComponent implements OnInit {
  private lstMembreComiteBase: Membrecl[];
  maxMembreNbre: number;
  addNewMembreActivated: boolean;
  public affectationMembre = new Affectationmembrecl();
  appName: string = myGlobals.APP_NAME;
  userConnected: {};
  tableDataSource = new MatTableDataSource<Membrecl>();
  private dialogConfig;
  public displayedColumns = [ 'Electeur', 'Nom', 'Prenoms', 'Gender', 'Email', 'Phone', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor( private router: Router, private svceUser: UtilisateursService, private toastr: ToastrService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
    this.loadData();
  }
  loadData = () => {
    switch (this.userConnected['typemembre']) {
      case 'Animateur': {
        this.getAllMillitantsByComiteBase();
        break;
      }
      case 'Secrétaire': {
        this.addNewMembreActivated = false;
        break;
      }
      default: {
        break;
      }
    }
    this.getAllMembreswoComite();
  }
  getAllMillitantsByComiteBase = () => {
    this.svceUser.getAllMembresBy(this.userConnected['comiteBaseId']).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMembreComiteBase = res['donnees'];
          this.maxMembreNbre = Object.keys(this.lstMembreComiteBase).length;
          if (this.maxMembreNbre >= myGlobals.NBRE_MAX_ML) {
            this.addNewMembreActivated = true;
          } else {
            this.addNewMembreActivated = false;
          }
        }
        else{
          if (res !== null && res['status']){
            this.lstMembreComiteBase = [];
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMembreswoComite = () => {
    this.svceUser.getAllMembresWithOutComite().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else{
          if (res !== null && res['status']){
            this.tableDataSource.data = [];
          }
          else{
            console.log(res);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  redirectToDetails = (membre: Membrecl) => {
    this.router.navigate(['dashboard/membreDetails', membre.membreId]) ;
  }
  redirectToUpdate = (membre: Membrecl) => {
    this.router.navigate(['dashboard/membreDetails', membre.membreId]) ;
  }
  addAffectation = (membre: Membrecl) => {
    let libelle: string;
    if (this.userConnected['typemembre'] === 'Animateur'){
      libelle = ' Vous allez affecter ' + (membre.sex === 'M' ? 'M. ' : 'Mme. ' ) + ( membre.nom + ' ' + membre.prenom ) + ' à votre comité de base. Etes-vous sûr ?';
    } else {
      // tslint:disable-next-line:max-line-length
      libelle = ' Vous allez être rédirigé sur la liste des comités de base afin de réaffecter ' + (membre.sex === 'M' ? 'M. ' : 'Mme. ' ) + ( membre.nom + ' ' + membre.prenom ) + ' à son nouveau comité de base.';
    }
    if (this.addNewMembreActivated === false) {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '20%'},
      data: {
        // tslint:disable-next-line:max-line-length
        'libelle': libelle
      }
    };
    let dialogRef;
    if (this.userConnected['typemembre'] === 'Animateur') {
         dialogRef = this.dialog.open(ConfirmationmodalComponent,
          this.dialogConfig
        );
    } else {
        dialogRef = this.dialog.open(InformationmodalComponent,
          this.dialogConfig
        );
    }
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        if (this.userConnected['typemembre'] === 'Animateur') {
          // Affectation seulement d'un membre à un comité de base
          this.affectationMembre.affectationMembreId = '';
          this.affectationMembre.comiteBaseId = this.userConnected['comiteBaseId'];
          this.affectationMembre.membreId = membre.membreId;
          this.affectationMembre.actionType = 'add';
          this.affectationMembre.createdBy = localStorage.getItem('USER_CONNECTED_ID');
          console.log(this.affectationMembre);
          this.svceUser.addAffectaionMembre(this.affectationMembre).subscribe(
            (res) => {
              console.log(res);
              this.toastr.success('Militant affecté avec succès', 'Message', {
                timeOut: 3000,
              });
              this.loadData();
            },
            (error) => {
              console.log(error);
              this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
                timeOut: 3000,
              });
            }
          );
        } else {
          localStorage.setItem('numeroElecteur', membre.numeroElecteur);
          this.router.navigate(['dashboard/listeComites']) ;
        }
      }
    });
    } else {
      console.log('Voir Admin');
      this.dialogConfig = {
        width: '500px',
        disableClose: true,
        position: {top: '10%'},
        data: {
          // tslint:disable-next-line:max-line-length
          'libelle': 'Merci de bien vouloir contacter l\'administrateur car vous avez atteint le nombre maximal de militant dans un comité de base (' + myGlobals.NBRE_MAX_ML + ')'
        }
      };
      const dialogRef = this.dialog.open(InformationmodalComponent,
        this.dialogConfig
      );
      dialogRef.afterClosed().subscribe(result =>
      {
        if (result)
        {
          console.log('Good');
        }
      });
    }
  }
}
