import { SEARCH_ACTION } from './../Tools/fonctionsGenerales';
import { Membrecl } from '../Models/membrecl.model';
import { Access } from '../Models/access.model';
import * as myGlobals from '../Tools/fonctionsGenerales';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Affectationmembrecl} from '../Models/affectationmembrecl.model';
import {Campagnecl} from '../Models/campagnecl.model';

@Injectable({
  providedIn: 'root'
})
export class UtilisateursService {
  URL_API_LOCAL = myGlobals.API_REST_URL + 'coUtilisateur.php';
  /*Headers*/
    headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient, private toastr: ToastrService) {
      this.headers.append('Content-Type', 'multipart/form-data');
      this.headers.append('Accept', 'application/json');
      this.headers.append('Access-Control-Allow-Origin', '*');
   }
   /*Gestion des error HttpClient*/
   handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    this.toastr.warning('Oups!!! Un soucis lié au réseau empêche le bon déroulement de l\'opération. Contactez l\'administrateur.', 'Message', {
      timeOut: 3000,
    });
    return throwError(errorMessage);
  }
/* Récupération des Membres actifs du système
   Bamba Aboubacar : 13-08-2020
   params: null // Prends aucun paramètre
  */
  getAllMembres = (): Observable<Membrecl[]> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    return this.httpQuery.get<Membrecl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION , {headers: this.headers })
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMembresWithOutComite = (): Observable<Membrecl[]> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    return this.httpQuery.get<Membrecl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECT_ACTION , {headers: this.headers })
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMembresBy = (comiteBaseId: string): Observable<Membrecl> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', comiteBaseId);
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    return this.httpQuery.post<Membrecl>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALLBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /* Récupération des Membres actifs d'une section du système
   Bamba Aboubacar : 13-08-2020
   params: null // Prends aucun paramètre
  */
  getAllMembresBySection = (sectionId: string): Observable<Membrecl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', sectionId);
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    return this.httpQuery.post<Membrecl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.FILTRE_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMembresByCommune = (communeId: string): Observable<Membrecl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', communeId);
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    return this.httpQuery.post<Membrecl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALLDATABY_ACTION,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMembresByRegion = (regionId: string): Observable<Membrecl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', regionId);
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    return this.httpQuery.post<Membrecl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.LISTALL_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMembresByDistrict = (districtId: string): Observable<Membrecl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', districtId);
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    return this.httpQuery.post<Membrecl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.LISTALLBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMembresByDepartement = (departementId: string): Observable<Membrecl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', departementId);
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    return this.httpQuery.post<Membrecl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALLDATA_ACTION,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllFilleuleByParrain = (parrainId: string): Observable<Membrecl> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', parrainId);
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coParrainage.php';
    return this.httpQuery.post<Membrecl>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /* Récupération d'un Membre actifs du système
   Bamba Aboubacar : 19-08-2020
   params: null // Prends aucun paramètre
  */
 getOneMembres = (membreId: string): Observable<Membrecl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', membreId);
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    return this.httpQuery.post<Membrecl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTBY_ACTION ,  FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /* Récupération des Membres actifs du système selon le filtre
   Bamba Aboubacar : 13-08-2020
   params: null // Prends aucun paramètre
  */
 getAllMembresByZone = (Param: string): Observable<Membrecl[]> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    const FORMDATA: any = new FormData();
    FORMDATA.append('type', '5');
    FORMDATA.append('valeur', Param);
    return this.httpQuery.post<Membrecl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /* Recherche d'un futur millitant dans la base des electeurs de la liste electorale
   Bamba Aboubacar : 25-08-2020
   params: null // Prends aucun paramètre
  */
 SearchAllMillitantByCode = (membre: Membrecl): Observable<Membrecl> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', membre.numeroElecteur);
    return this.httpQuery.post<Membrecl>(this.URL_API_LOCAL + '?Action=' + myGlobals.SEARCH_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /* Recherche d'un millitant dans la base des electeurs de la liste electorale
  Pour affectation (cordinateur Region/ Departement/ Commune)
   Bamba Aboubacar : 30-08-2020
   params: null // Prends aucun paramètre
  */
 SearchAllMillitantForAff = (Param: Membrecl): Observable<Membrecl> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    const FORMDATA: any = new FormData();
    FORMDATA.append('numeroElecteur', Param.numeroElecteur);
    FORMDATA.append('nom', Param.nom);
    FORMDATA.append('prenom', Param.prenom);
    FORMDATA.append('dateNaissance', Param.dateNaissance);
    return this.httpQuery.post<Membrecl>(this.URL_API_LOCAL + '?Action=' + myGlobals.FIND_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /* Récupération des utilisateurs actifs du système
   Bamba Aboubacar : 13-08-2020
   params: null // Prends aucun paramètre
  */
  getAllUsers = (): Observable<Access> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coUtilisateur.php';
    return this.httpQuery.get<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION , {headers: this.headers })
      .pipe(retry(3), catchError(this.handleError));
  }
  getCampagneByAccess = (accessId: string): Observable<Campagnecl> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coUtilisateur.php';
    const FORMDATA: any = new FormData();
    FORMDATA.append('accessId', accessId);
    return this.httpQuery.post<Campagnecl>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECT_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  getCampagneByAccessID = (accessId: string): Observable<Campagnecl> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coUtilisateur.php';
    const FORMDATA: any = new FormData();
    FORMDATA.append('accessId', accessId);
    return this.httpQuery.post<Campagnecl>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTOBY_ACTION, FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /*Connexion utilisateur
  Bamba Aboubacar : 13-08-2020
  params: access: Access // Parametre de type access model
  */
  connectUser = (access: Access): Observable<any> => {
      const FORMDATA: any = new FormData();
      FORMDATA.append('pseudo', access.pseudo);
      FORMDATA.append('password', access.password);
      return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.CONNECT_ACTION ,  FORMDATA)
        .pipe(retry(3), catchError(this.handleError));
  }
  addAccess = (access: Access): Observable<any> => {
    console.log(access.password + ' ' + access.pseudo);
    const FORMDATA: any = new FormData();
    FORMDATA.append('accessId', access.accessId);
    FORMDATA.append('pseudo', access.pseudo);
    FORMDATA.append('password', access.password);
    FORMDATA.append('membreId', access.membreId);
    FORMDATA.append('expiredOn', this.getMMDDYYYY(access.expiredOn));
    FORMDATA.append('status', 1);
    FORMDATA.append('createdBy', access.createdBy);
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + (access.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION)  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteAccess = (accessId: string): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('accessId', accessId);
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' +  myGlobals.DELETEBY_ACTION  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
    addMembre = (membre: Membrecl): Observable<any> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    const FORMDATA: any = new FormData();
    FORMDATA.append('membreId', membre.membreId);
    FORMDATA.append('numeroElecteur', membre.numeroElecteur);
    FORMDATA.append('numeroCNI', membre.numeroCNI);
    FORMDATA.append('nom', membre.nom);
    FORMDATA.append('prenom', membre.prenom);
    FORMDATA.append('email', membre.email);
    FORMDATA.append('contact', membre.contact);
    FORMDATA.append('sex', membre.sex);
    FORMDATA.append('communeId', membre.communeId);
    FORMDATA.append('comiteBaseId', membre.comiteBaseId);
    FORMDATA.append('lieuVoteId', membre.lieuVoteId);
    FORMDATA.append('dateNaissance', membre.dateNaissance);
    FORMDATA.append('lieuNaissance', membre.lieuNaissance);
    FORMDATA.append('adressePhysique', membre.adressePhysique);
    FORMDATA.append('adressePostale', membre.adressePostale);
    FORMDATA.append('profession', membre.profession);
    FORMDATA.append('nomPere', membre.nomPere);
    FORMDATA.append('prenomPere', membre.prenomPere);
    FORMDATA.append('dateNaissancePere', membre.dateNaissancePere);
    FORMDATA.append('lieuNaissancePere', membre.lieuNaissancePere);
    FORMDATA.append('nomMere', membre.nomMere);
    FORMDATA.append('prenomMere', membre.prenomMere);
    FORMDATA.append('dateNaissanceMere', membre.dateNaissanceMere);
    FORMDATA.append('lieuNaissanceMere', membre.lieuNaissanceMere);
    FORMDATA.append('autreInfos', membre.autreInfos);
    FORMDATA.append('status', 1);
    FORMDATA.append('createdBy', membre.createdBy);
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + (membre.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION)  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /*Supression d'un militant
  Bamba Aboubacar : 31-08-2020
  params: access: Access // Parametre de type access model
  */
  deleteMembre = (membreId: string): Observable<any> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coMembre.php';
    const FORMDATA: any = new FormData();
    FORMDATA.append('membreId', membreId);
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' +  myGlobals.DELETEBY_ACTION  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  addAffectaionMembre = (affectationMembre: Affectationmembrecl): Observable<any> => {
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coAffectationMembre.php';
    const FORMDATA: any = new FormData();
    FORMDATA.append('affectationMembreId', affectationMembre.affectationMembreId);
    FORMDATA.append('membreId', affectationMembre.membreId);
    FORMDATA.append('comiteBaseId', affectationMembre.comiteBaseId);
    FORMDATA.append('status', 1);
    FORMDATA.append('createdBy', affectationMembre.createdBy);
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' +  myGlobals.INSERT_ACTION  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /*Supression d'un militant
  Bamba Aboubacar : 31-08-2020
  params: access: Access // Parametre de type access model
  */
 deleteAffectaionMembre = (affectationMembreId: string): Observable<any> => {
  this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coAffectationMembre.php';
  const FORMDATA: any = new FormData();
  FORMDATA.append('affectationMembreId', affectationMembreId);
  // tslint:disable-next-line:max-line-length
  return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' +  myGlobals.DELETEBY_ACTION  , FORMDATA)
    .pipe(retry(3), catchError(this.handleError));
}
  getMMDDYYYY = (date) => {
    let m: string;
    let d: string;
    let h: string;
    let min: string;
    let s: string;
    const today = new Date(date);
    const dd: number = today.getDate();
    const mm: number = today.getMonth() + 1;
    const ho: number = today.getHours();
    const mi: number = today.getMinutes();
    const se: number = today.getSeconds();
    const yyyy = today.getFullYear();
    if (dd < 10) {
      d = '0' + dd;
    } else {
      d = dd + '';
    }
    if (mm < 10) {
      m = '0' + mm;
    } else {
      m = mm + '';
    }
    if (ho < 10) {
      h = '0' + ho;
    } else {
      h = ho + '';
    }
    if (mi < 10) {
      min = '0' + mi;
    } else {
      min = mi + '';
    }
    if (se < 10) {
      s = '0' + se;
    } else {
      s = se + '';
    }
    return m + '/' + d + '/' + yyyy + ' ' + h + ':' + min + ':' + s;
  }
}
