import {Component, OnInit, ViewChild} from '@angular/core';
import {Sectioncl} from '../../Models/sectioncl.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {SectionService} from '../../Services/section.service';
import {ToastrService} from 'ngx-toastr';
import {SectionmodalComponent} from '../../Modals/sectionmodal/sectionmodal.component';
import {DeletealertComponent} from '../../Modals/deletealert/deletealert.component';
import {Campagnecl} from '../../Models/campagnecl.model';
import {CampagneService} from '../../Services/campagne.service';
import {CampagnemodalComponent} from '../../Modals/campagnemodal/campagnemodal.component';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {CheckpatiscampagneComponent} from '../../Modals/checkpatiscampagne/checkpatiscampagne.component';

@Component({
  selector: 'app-campagne',
  templateUrl: './campagne.component.html',
  styleUrls: ['./campagne.component.css']
})
export class CampagneComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  campagne = new Campagnecl();
  private dialogConfig;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Id', 'Libelle', 'Scrutin', 'Lancement' , 'Slogan' , 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private dialog: MatDialog, private svceCampagne: CampagneService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.campagne.actionType = 'add';
    this.campagne.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.getAllCampagnes();
  }
  getAllCampagnes = () => {
    this.svceCampagne.getAllCampagne().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  openModal = (campagne: Campagnecl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        campagne
      }
    };
    const dialogRef = this.dialog.open(CampagnemodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.getAllCampagnes();
      }
    });
  }
  cleanFielsd = (campagne: Campagnecl) => {
    this.campagne.actionType = 'add';
    this.campagne.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.campagne.libelle = '';
    this.campagne.campagneId = '';
  }
  redirectToUpdate = (campagne: Campagnecl) => {
    console.log(campagne);
    // this.section.actionType = 'update';
    campagne.actionType = 'update';
    var dt = new Date(campagne.lancement);
    campagne.lancement = dt;
    dt = new Date(campagne.scrutin);
    campagne.scrutin = dt;
    this.openModal(campagne);
  }
  redirectToDelete = (campagneId: string, libelle: string, typeModale: number) => {
    switch (typeModale){
      case  1 :
        this.dialogConfig = {
          width: '500px',
          disableClose: true,
          position: {top: '10%'},
          data: {
            'itemId': campagneId,
            'libelle': libelle
          }
        };
        const dialogRef = this.dialog.open(DeletealertComponent,
          this.dialogConfig
        );
        dialogRef.afterClosed().subscribe(result =>
        {
          if (result)
          {
            this.svceCampagne.deleteCampagne(campagneId).subscribe(
              (res) => {
                if (res !== null && !res['status']){
                  this.toastr.success('Campagne supprimée avec succès', 'Message', {
                    timeOut: 3000,
                  });
                  this.getAllCampagnes();
                }
              }
            );
          }
        });
        break;
      case 2 :
        this.dialogConfig = {
          width: '700px',
          disableClose: true,
          position: {top: '10%'},
          data: {
            'itemId': campagneId,
            'libelle': libelle
          }
        };
        const dialogRefList = this.dialog.open(CheckpatiscampagneComponent,
          this.dialogConfig
        );
        break;
      default :
        break;
    }
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
}
