<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>CheckList pour la Mission  </strong> - {{this.data['libelle']}} </h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <form id="detailsmilitantForm" #detailsmilitantForm="ngForm" action="post">
        <div class="body">
          <div class="col-sm-12 col-lg-auto">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <div class="input-group search">
                  <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                  <!-- <span class="input-group-addon">
                      <i class="zmdi zmdi-search"></i>
                  </span> -->
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <div class="col-sm-12 col-lg-auto">
              <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                <ng-container matColumnDef="Electeur">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Code Electeur </th>
                  <td mat-cell *matCellDef="let element"> {{element.numeroElecteur}} </td>
                </ng-container>
                <ng-container matColumnDef="Nom">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
                  <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
                </ng-container>
                <ng-container matColumnDef="Prenoms">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom(s) </th>
                  <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
                </ng-container>
                <ng-container matColumnDef="Gender">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Sexe </th>
                  <td mat-cell *matCellDef="let element"> {{element.sex == 'M' ? 'Masculin' : 'Fénimin'}} </td>
                </ng-container>
                <ng-container matColumnDef="Phone">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Tel </th>
                  <td mat-cell *matCellDef="let element"> {{ element.contact === '' || element.contact === null ?  element.contact :  element.contact }} </td>
                </ng-container>
                <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef style="width: 140px;text-align: center"> Actions </th>
                  <td mat-cell *matCellDef=" let element"  >
                      <mat-slide-toggle class="checklist" [checked]="element.etat == 1 ? true : false" (change)="onSwitchChange($event,element.membreId,element.checkListId,element.comment)" ></mat-slide-toggle>
                      <button mat-icon-button  [disabled]="this.data['commentActif'] === '1' ? element.comment !== null ? false : true : true" class="btn btn-icon btn-neutral  btn-icon-mini" (click)="addComment(element)">
                        <i class="material-icons">comment</i>
                      </button>
                  </td>

                </ng-container>
                <ng-container matColumnDef="Loading">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Chargement en cours....
                  </th>
                </ng-container>
                <ng-container matColumnDef="NoData">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Aucunes données trouvées....
                  </th>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10"  showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
