import {Actioncl} from './actioncl.model';

export class Missioncl extends Actioncl{
  missionId: string;
  libelle: string;
  checkList: number;
  commentActif: number;
  status: number;
  taux: number;
  createdBy: string;
  createdOn: string;
}
