<section class="content home">
  <div class="block-header">
      <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12">
              <h2>Affectations<small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 text-right">

            <!-- <button (click)="openModal(service)" class="btn btn-white btn-icon btn-round d-none d-md-inline-block float-right m-l-10" type="button">
                <i class="zmdi zmdi-plus"></i>
            </button> -->

            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
                <li class="breadcrumb-item">Departement</li>
                <li class="breadcrumb-item active">Affectations</li>
            </ul>
          </div>
      </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
          <div class="card">
            <div class="body" style="min-height: 200px;">
                <div class="row">
                  <div class="col-sm-12 col-lg-12"></div>
                  <div class="col-sm-12 col-lg-12"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
