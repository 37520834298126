import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RoleequipeService} from '../../Services/roleequipe.service';
import {Membrecl} from '../../Models/membrecl.model';
import {RoleEquipeCl} from '../../Models/roleequipe.model';
import {Equipecl} from '../../Models/equipecl.model';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {AffectationconfirmationComponent} from '../affectationconfirmation/affectationconfirmation.component';
import {EquipeService} from '../../Services/equipe.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-list-post-staff-modal',
  templateUrl: './list-post-staff-modal.component.html',
  styleUrls: ['./list-post-staff-modal.component.css']
})
export class ListPostStaffModalComponent implements OnInit {
  tableDataSource = new MatTableDataSource<RoleEquipeCl>();
  equipe = new Equipecl();
  userConnected: {};
  private dialogConfig;
  public displayedColumns = ['Code', 'Libelle', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toastr: ToastrService, private svceEquipe: EquipeService, private svceRoleEquipe: RoleequipeService, private dialog: MatDialog, public dialogRef: MatDialogRef<ListPostStaffModalComponent>) { }

  ngOnInit(): void {
    this.getAllRoleEquipeBy(this.data['blocId']);
    this.userConnected = JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'));
  }
  getAllRoleEquipeBy = (blocId) => {
    this.svceRoleEquipe.getAllRoleEquipeBy(blocId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onClickRadioButton = (role: RoleEquipeCl) => {
    switch (this.userConnected['typemembre']) {
      case 'Secrétaire': {
        this.equipe.type = 2;
        break;
      }
      case 'Animateur': {
        this.equipe.type = 1;
        break;
      }
      default: {
        this.equipe.type = 5;
        break;
      }
    }
    this.equipe.actionType = 'add';
    this.equipe.equipeId = '';
    this.equipe.blockId = this.userConnected['blocId'];
    this.equipe.acteurId = localStorage.getItem('USER_CONNECTED_ID');
    this.equipe.membreId = this.data['membre']['membreId'];
    this.equipe.roleequipeId = role.roleequipeId;
    this.equipe.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        itemId: this.data.membre.membreId,
        libelle: this.data.membre.nom + ' ' + this.data.membre.prenom + ' ' + role.libelle + ' dans votre staff',
        type: 0
      }
    };
    const dialogRef = this.dialog.open(AffectationconfirmationComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.svceEquipe.addMemberStaff(this.equipe).subscribe(
          (res) => {
            if (res !== null && !res['status']) {
              this.toastr.success(role.libelle + ' nommé avec succès', 'Message', {
                timeOut: 3000,
              });
              this.dialogRef.close(true);
              // this.getAllMembresBy(this.data['blocId']);
            }
            else {
              if (res !== null && res['status']) {
                this.toastr.warning(res['message'], 'Message', {
                  timeOut: 3000,
                });
              }
              else {
                console.log(res);
              }
            }
          }
        );
      }
      else{
        this.getAllRoleEquipeBy(this.data.blocId);
      }
    });
  }

  /*
  * (membreId: string, nom: string, roleId: string, libelleRole: string, isActif: string, affectationId: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': membreId,
        'libelle': nom + ' en tant que ' + libelleRole,
        'type': isActif
      }
    };
    const dialogRef = this.dialog.open(AffectationconfirmationComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // tslint:disable-next-line:triple-equals
        if (isActif == '0') {
          this.svceEquipe.addMemberStaff(this.equipe).subscribe(
            (res) => {
              if (res !== null && !res['status']) {
                this.toastr.success('Sécretaire nommé avec succès', 'Message', {
                  timeOut: 3000,
                });
                this.getAllMembresBy(this.data['blocId']);
              } else {
                if (res !== null && res['status']) {
                  this.toastr.warning(res['message'], 'Message', {
                    timeOut: 3000,
                  });
                } else {
                  console.log(res);
                }
              }
            }
          );
        } else {
          console.log('retirer ' + affectationId + ' ' + isActif);
          this.svceEquipe.deleteMemberStaff(affectationId).subscribe(
            (res) => {
              if (res !== null && !res['status']) {
                this.toastr.success('Nomination supprimée avec succès', 'Message', {
                  timeOut: 3000,
                });
                this.getAllMembresBy(this.data['blocId']);
              } else {
                if (res !== null && res['status']) {
                  this.toastr.warning(res['message'], 'Message', {
                    timeOut: 3000,
                  });
                } else {
                  console.log(res);
                }
              }
            },
            (error) => {
            }
          );
        }
      }
    });
    /*if (isActif !== '0' && type == '0'){
      this.toastr.warning('Ce membre à déja un poste de reponsabilité dans une autre entité', 'Message', {
        timeOut: 3000,
      });
    }
    else {
      console.log(membreId + ' ' + this.data['blocId']);
      this.affectation.blocId = this.data['blocId'];
      this.affectation.acteurId = membreId;
      this.affectation.affectationId = '';
      this.affectation.status = 1;
      this.affectation.actionType = 'add';
      this.affectation.type = 2;
      this.affectation.createdBy = localStorage.getItem('USER_CONNECTED_ID');
      this.dialogConfig = {
        width: '500px',
        disableClose: true,
        position: {top: '10%'},
        data: {
          'itemId': membreId,
          'libelle': libelle + ' en tant que sécretaire départemental',
          'type': type
        }
      };
      const dialogRef = this.dialog.open(AffectationconfirmationComponent,
        this.dialogConfig
      );
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // tslint:disable-next-line:triple-equals
          if (type == '0') {
            this.svceAffectation.addAffectation(this.affectation).subscribe(
              (res) => {
                if (res !== null && !res['status']) {
                  this.toastr.success('Sécretaire nommé avec succès', 'Message', {
                    timeOut: 3000,
                  });
                  this.getAllMembresBy(this.data['blocId']);
                } else {
                  if (res !== null && res['status']) {
                    this.toastr.warning(res['message'], 'Message', {
                      timeOut: 3000,
                    });
                  } else {
                    console.log(res);
                  }
                }
              }
            );
          } else {
            console.log('retirer ' + affectationId + ' ' + type);
            this.svceCoordinateur.deleteCordinateur(affectationId).subscribe(
              (res) => {
                if (res !== null && !res['status']) {
                  this.toastr.success('Nomination supprimée avec succès', 'Message', {
                    timeOut: 3000,
                  });
                  this.getAllMembresBy(this.data['blocId']);
                } else {
                  if (res !== null && res['status']) {
                    this.toastr.warning(res['message'], 'Message', {
                      timeOut: 3000,
                    });
                  } else {
                    console.log(res);
                  }
                }
              },
              (error) => {
              }
            );
          }
        }
      });
    }
}
  * */
}
