import { Departementcl } from '../Models/departementcl.model';
import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DepartementService {

  URL_API_LOCAL = myGlobals.API_REST_URL + 'coDepartement.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient, private toastr: ToastrService) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    this.toastr.warning('Oups!!! Un soucis lié au réseau empêche le bon déroulement de l\'opération. Contactez l\'administrateur.', 'Message', {
      timeOut: 3000,
    });
    return throwError(errorMessage);
  }
  /*Récupération des Departements de base actifs du système
   Bamba Aboubacar : 21-08-2020
   params: null // Prends aucun paramètre
  */
  getAllDepartement = (): Observable<Departementcl[]> => {
    /* this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coDistrict.php'; */
    return this.httpQuery.get<Departementcl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION , {headers: this.headers})
      .pipe(retry(3), catchError(this.handleError));
  }
  /*Récupération des Departements by District de base actifs du système
    Bamba Aboubacar : 21-08-2020
    params: null // Prends aucun paramètre
  */
  getAllDepartementByRgion = (regionId: string): Observable<Departementcl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', regionId);
    /* this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coDistrict.php'; */
    return this.httpQuery.post<Departementcl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTBY_ACTION , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  /*Récupération des Cordianteurs de chaque departement actifs du système
   Bamba Aboubacar : 21-08-2020
   params: null // Prends aucun paramètre
  */
 getAllCordinateurDep = (): Observable<any[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('type', '2');
    // FORMDATA.append('valeur', Param);
    this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coAffectation.php';
    return this.httpQuery.post<any[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION, FORMDATA).pipe(retry(3), catchError(this.handleError));
  }
}
