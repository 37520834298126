<div class="authentication sidebar-collapse">
  <div class="page-header">
    <div class="page-header-image" style="background-image:url('../../../assets/images/login.jpg')"></div>

        <div class="container">
  <div class="col-md-12 content-center">
    <div class="card-plain">
      <form class="form" method="" action="#">
        <div class="header">
            <div class="logo-container">
              <img src="../../../assets/images/logo.svg" alt="">
            </div>
            <h5>Avez-vous oublié votre mot de passe ?</h5>
            <span>Entrer le E-mail utilisateur pour réinitialiser.</span>
        </div>
        <div class="content">
            <div class="input-group input-lg">
                <input type="text" class="form-control" placeholder="Enter Email">
                <span class="input-group-addon">
                    <i class="zmdi zmdi-email"></i>
                </span>
            </div>
        </div>
        <div class="footer text-center">
            <button type="button" class="btn btn-primary btn-round btn-lg btn-block waves-effect waves-light">VALIDER</button>
            <h5><a routerLink="/singin" class="link">J'ai dejà un compte. Me connecter</a></h5>
        </div>
    </form>
    </div>
  </div>
  </div>
  </div>
</div>


