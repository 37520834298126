<section class="content home">
  <div class="block-header">
      <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12">
              <h2>Comités de base<small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 text-right">

            <button (click)="cleanFielsd(comite); openModal(comite)" class="btn btn-white btn-icon btn-round d-none d-md-inline-block float-right m-l-10" type="button">
                <i class="zmdi zmdi-plus"></i>
            </button>

            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
                <li class="breadcrumb-item">Découpage</li>
                <li class="breadcrumb-item active">Comités de base</li>
            </ul>
          </div>
      </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
          <div class="card">
            <div class="body" style="min-height: 200px;">
                <div class="row">
                  <div class="col-sm-12 col-lg-12">
                    <div class="input-group search">
                      <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                      <!-- <span class="input-group-addon">
                          <i class="zmdi zmdi-search"></i>
                      </span> -->
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-12">
                    <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                      <ng-container matColumnDef="Id">
                        <th class="center" mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>
                      <ng-container matColumnDef="Section">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Section </th>
                        <td mat-cell *matCellDef="let element"> {{element.LibelleSection}} </td>
                      </ng-container>
                    <ng-container matColumnDef="Code">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
                        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Libelle">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Libelle </th>
                        <td mat-cell *matCellDef="let element"> {{element.libelle}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef style="width: 140px;text-align: center"> Actions </th>
                        <td mat-cell *matCellDef=" let element">
                            <button mat-icon-button class="btn btn-icon btn-neutral  btn-icon-mini" (click)="redirectToUpdate(element)">
                                <i class="material-icons">edit</i>
                            </button>
                            <button mat-icon-button class="btn btn-icon btn-neutral btn-icon-mini" (click)="redirectToDelete(element.comiteBaseId,element.libelle)">
                                <i class="material-icons delete">delete_outline</i>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Loading">
                        <th class="w-75" mat-footer-cell *matFooterCellDef colspan="8">
                            Chargement en cour....
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="NoData">
                        <th class="w-75" mat-footer-cell *matFooterCellDef colspan="8">
                            Aucunes données trouvées....
                        </th>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                    <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[15, 30, 60]" [pageSize]="15"  showFirstLastButtons></mat-paginator>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
