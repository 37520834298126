import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Comitecl} from '../Models/comitecl.model';
import {catchError, retry} from 'rxjs/operators';
import {Missioncl} from '../Models/missioncl.model';

@Injectable({
  providedIn: 'root'
})
export class MissionService {

  URL_API_LOCAL = myGlobals.API_REST_URL + 'coMission.php';
  /*Headers*/
  headers = new HttpHeaders();
  constructor(private httpQuery: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  getAllMission = (): Observable<Missioncl[]> => {
    /*this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coComiteBase.php';*/
    return this.httpQuery.get<Missioncl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION , {headers: this.headers })
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMissionBy = (blocId: string): Observable<Missioncl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', blocId);
    return this.httpQuery.post<Missioncl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTBY_ACTION , FORMDATA )
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMissionBySection = (blocId: string): Observable<Missioncl[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('valeur', blocId);
    return this.httpQuery.post<Missioncl[]>(this.URL_API_LOCAL + '?Action=' + myGlobals.FILTRE_ACTION , FORMDATA )
      .pipe(retry(3), catchError(this.handleError));
  }
  addMission = (mission: Missioncl): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('missionId', mission.missionId);
    FORMDATA.append('libelle', mission.libelle);
    FORMDATA.append('checkList', mission.checkList);
    FORMDATA.append('commentActif', mission.commentActif);
    FORMDATA.append('createdBy', mission.createdBy);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${mission.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteMission = (missionId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('missionId', missionId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.DELETEBY_ACTION  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
}
