import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Comitecl} from '../../Models/comitecl.model';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComiteService} from '../../Services/comite.service';
import {AffectationobjectifService} from '../../Services/affectationobjectif.service';
import {AffectationObjectifcl} from '../../Models/affectation-objectifcl.model';
import {Objectifcl} from '../../Models/objectifcl.model';
import {AffectationobjectifmodalComponent} from '../affectationobjectifmodal/affectationobjectifmodal.component';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-detailsobjectifsperdepartement',
  templateUrl: './detailsobjectifsperdepartement.component.html',
  styleUrls: ['./detailsobjectifsperdepartement.component.css']
})
export class DetailsobjectifsperdepartementComponent implements OnInit {
  tableDataSource = new MatTableDataSource<Comitecl>();
  private dialogConfig;
  public displayedColumns = [ 'Libelle', 'Valeur', 'Electeur' , 'Pourcent' , 'Delai', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  maxGood: number = myGlobals.TAUX_CONFORME_GOOD;
  maxNotBad: number = myGlobals.TAUX_CONFORME_NOTBAD;
  // tslint:disable-next-line:variable-name
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, public svceAffectationObjectif: AffectationobjectifService, public dialogRef: MatDialogRef<DetailsobjectifsperdepartementComponent>) { }

  ngOnInit(): void {
    this.getAllAffectionObjectifBy();
  }
  getAllAffectionObjectifBy = () => {
    this.svceAffectationObjectif.getAllAffectationObjectifBy(this.data['affectationObjectif'].objectifId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  redirectToUpdateAffectationObjectif = (affectationObjectif: AffectationObjectifcl, libelle: string) => {
    affectationObjectif.actionType = 'update';
    affectationObjectif.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    console.log('Object to updated : ');
    console.log(affectationObjectif);
    const dt = new Date(affectationObjectif.deadline);
    console.log(dt);
    affectationObjectif.deadline = dt;
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'affectationObjectif': affectationObjectif,
        'libelleObjectif': libelle
      }
    };
    const dialogRef = this.dialog.open(AffectationobjectifmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        this.getAllAffectionObjectifBy();
      } else {
        this.getAllAffectionObjectifBy();
      }
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
}
