import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {Access} from '../../Models/access.model';
import {UtilisateursService} from '../../Services/utilisateurs.service';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {InformationmodalComponent} from '../informationmodal/informationmodal.component';

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.css']
})
export class UtilisateurComponent implements OnInit {
  minDate: Date = new Date();
  cpassword: string;
  private dialogConfig;
  constructor(@Inject(MAT_DIALOG_DATA) public data: Access, private dialog: MatDialog, public svceAccess: UtilisateursService, private toastr: ToastrService, public dialogRef: MatDialogRef<UtilisateurComponent>) { }

  ngOnInit(): void {
  }
  close = () => {
    this.dialogRef.close(false);
  }
  crudAccess = (access: Access, type: string) => {
    this.svceAccess.addAccess(access).subscribe(
      (res) => {
        console.log(res);
        switch (type) {
          case 'add':
            this.toastr.success('Accès inséré avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
          case 'update':
            this.toastr.success('Accès modifié avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
          case 'updateP':
            this.toastr.success('Mot de passe modifié avec succès', 'Message', {
              timeOut: 3000,
            });
            break;
        }
        this.dialogRef.close(true);
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  onAdd = (access: Access) => {
    if (access.password == this.cpassword) {
      switch (access.actionType){
        case 'add':
          console.log('ADD');
          console.log(access);
          this.crudAccess(access, access.actionType);
          break;
        case 'update':
          console.log('UPDATE');
          console.log(access);
          this.crudAccess(access, access.actionType);
          break;
        case 'updateP':
          console.log('UPDATEP');
          console.log(access);
          this.crudAccess(access, access.actionType);
          break;
        default:
          break;
      }
    } else {
      console.log('Password no match');
      this.dialogConfig = {
        width: '500px',
        disableClose: true,
        position: {top: '10%'},
        data: {
          // tslint:disable-next-line:max-line-length
          'libelle': 'Merci de confirmer votre mot de passe. Ils ne sont pas identiques.'
        }
      };
      const dialogRef = this.dialog.open(InformationmodalComponent,
        this.dialogConfig
      );
      dialogRef.afterClosed().subscribe(result =>
      {
        if (result)
        {
          console.log('Good');
        }
      });
    }
  }
}
