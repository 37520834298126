import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-animateurleftnav',
  templateUrl: './animateurleftnav.component.html',
  styleUrls: ['./animateurleftnav.component.css']
})
export class AnimateurleftnavComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  logindURL = '/singin';
  constructor(private cookieSce: CookieService, private router: Router) { }
  userConnected: {};
  ngOnInit(): void {
    const verifUserCookies = this.cookieSce.get('oClinicUser');
    if ( verifUserCookies !== null)
    {
      this.userConnected = JSON.parse(verifUserCookies);
      localStorage.setItem('USER_CONNECTED_ID', this.userConnected['membreId']);
      /*console.log(this.userConnect);*/
    }
    else{
      this.router.navigate([this.logindURL]);
    }
  }

}
