import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-affectation',
  templateUrl: './affectation.component.html',
  styleUrls: ['./affectation.component.css']
})
export class AffectationComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  constructor() { }

  ngOnInit(): void {
  }

}
