import { DeletealertComponent } from './../../Modals/deletealert/deletealert.component';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ServiceComponent} from '../../Modals/service/service.component';
import {Sectioncl} from '../../Models/sectioncl.model';
import {SectionmodalComponent} from '../../Modals/sectionmodal/sectionmodal.component';
import {SectionService} from '../../Services/section.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {Comitecl} from '../../Models/comitecl.model';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  section = new Sectioncl();
  private dialogConfig;
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['Id', 'Code', 'Libelle', 'Commune', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private dialog: MatDialog, private svceSection: SectionService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.section.actionType = 'add';
    this.section.createdBy = localStorage.getItem('USER_CONNECTED_ID');
    this.getAllSections();
  }
  getAllSections = () => {
    this.svceSection.getAllSections().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  openModal = (section: Sectioncl) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        section
      }
    };
    const dialogRef = this.dialog.open(SectionmodalComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
          this.getAllSections();
      }
    });
  }
  cleanFielsd = (section: Sectioncl) => {
    this.section = new Sectioncl();
    this.section.actionType = 'add';
    this.section.createdBy = localStorage.getItem('USER_CONNECTED_ID');
   /*  this.section.code = '';
    this.section.libelle = ''; */
    this.section.sectionId = '';
  }
  redirectToUpdate = (section: Sectioncl) => {
    console.log(section);
    // this.section.actionType = 'update';
    section.actionType = 'update';
    this.openModal(section);
  }
  redirectToDelete = (sectionId: string, libelle: string) => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'},
      data: {
        'itemId': sectionId,
        'libelle': libelle
      }
    };
    const dialogRef = this.dialog.open(DeletealertComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
      {
        if (result)
        {
          this.svceSection.deleteSection(sectionId).subscribe(
            (res) => {
              if (res !== null && !res['status']){
                this.toastr.success('Section supprimée avec succès', 'Message', {
                  timeOut: 3000,
                });
                this.getAllSections();
              }
              else {
                if (res !== null && res['status']){
                  this.toastr.warning(res['message'], 'Message', {
                    timeOut: 3000,
                  });
                }
                else{
                  console.log(res);
                }
              }
            }
          );
        }
      });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
}
