<div class="authentication sidebar-collapse">
  <div class="page-header">
    <div class="page-header-image" style="background-image:url('../../../assets/images/login.jpg')"></div>

        <div class="container">
  <div class="col-md-12 content-center">
    <div class="card-plain">
        <form class="form" #formulaireRegister="ngForm" (ngSubmit)="onSubmit(formulaireRegister)" >
            <div class="header">
                <div class="logo-container">
                    <img src="../../../assets/images/logo.svg" alt="">
                </div>
                <h5>Connexion</h5>
            </div>
            <div class="content">
                <div class="input-group input-lg">
                    <input type="text" #inputPseudo="ngModel" ngModel name="Pseudo" class="form-control" required placeholder="Nom utilisateur">
                    <span class="input-group-addon">
                        <i class="zmdi zmdi-account-circle"></i>
                    </span>
                </div>
                <div class="input-group input-lg">
                  <input type="text" #inputEmail="ngModel" ngModel name="Email" class="form-control" required placeholder="Addresse E-mail">
                  <span class="input-group-addon">
                    <i class="zmdi zmdi-email"></i>
                  </span>
              </div>
                <div class="input-group input-lg">
                    <input type="password" #inputPassword="ngModel" ngModel name="Password" required placeholder="Mot de passe" class="form-control" />
                    <span class="input-group-addon">
                        <i class="zmdi zmdi-lock"></i>
                    </span>
                </div>
                <div class="checkbox">
                  <input id="terms" type="checkbox">
                  <label for="terms">
                    J'ai lu et j'accepte les <a href="javascript:void(0);">Termes</a>
                  </label>
              </div>
            </div>
            <div class="footer text-center">
                <button type="submit" [disabled]="!formulaireRegister.form.valid" class="btn btn-primary btn-round btn-lg btn-block ">CREER MON COMPTE</button>
                <h5><a routerLink="/singin" class="link">J'ai dejà un compte</a></h5>
            </div>
        </form>
    </div>
  </div>
  </div>
  </div>
</div>

