import {Actioncl} from './actioncl.model';

export class Objectifcl extends Actioncl{
  objectifId: string;
  libelle: string;
  code: string;
  status: number;
  createdBy: string;
  createdOn: string;
}
