import { Injectable } from '@angular/core';
import * as myGlobals from '../Tools/fonctionsGenerales';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {Objectifcl} from '../Models/objectifcl.model';

@Injectable({
  providedIn: 'root'
})
export class ObjectifService {
  URL_API_LOCAL = myGlobals.API_REST_URL + 'coObjectif.php';
  /*Headers*/
    headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  /* Récupération des Comités de base actifs du système
   Bamba Aboubacar : 17-08-2020
   params: null // Prends aucun paramètre
  */
  getAllObjectif = (): Observable<Objectifcl> => {
    /*this.URL_API_LOCAL = myGlobals.API_REST_URL + 'coComiteBase.php';*/
    return this.httpQuery.get<Objectifcl>(this.URL_API_LOCAL + '?Action=' + myGlobals.SELECTALL_ACTION , {headers: this.headers })
      .pipe(retry(3), catchError(this.handleError));
  }
  addObjectif = (objectif: Objectifcl): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('objectifId', objectif.objectifId);
    FORMDATA.append('libelle', objectif.libelle);
    FORMDATA.append('code', objectif.code);
    FORMDATA.append('createdBy', objectif.createdBy);
    return this.httpQuery.post<any>(`${this.URL_API_LOCAL}?Action=${objectif.actionType === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteObjectif = (objectifId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('objectifId', objectifId);
    return this.httpQuery.post<any>(this.URL_API_LOCAL + '?Action=' + myGlobals.DELETEBY_ACTION  , FORMDATA)
      .pipe(retry(3), catchError(this.handleError));
  }
}
