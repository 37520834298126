import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-informationmodal',
  templateUrl: './informationmodal.component.html',
  styleUrls: ['./informationmodal.component.css']
})
export class InformationmodalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit(): void {
  }

}
