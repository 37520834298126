<section class="content home">
  <div class="block-header">
      <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12">
              <h2>Membre <small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 text-right">
              <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
                <li class="breadcrumb-item">Accueil</li>
                  <li class="breadcrumb-item active">Nouveau membre</li>
              </ul>
          </div>
      </div>
  </div>
  <div class="container-fluid">
      <div class="row clearfix">
        <div class="col-sm-6">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="header">
                      <h2><strong>Electeurs</strong></h2>
                      <ul class="header-dropdown">
                          <li class="remove">
                              <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                          </li>
                      </ul>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-sm-6">
                        <!-- <label><small>Carte CNI</small></label> -->
                        <div class="form-group">
                          <!-- <input name="libelle" type="text" class="form-control" placeholder="Nom" required> -->
                          <mat-form-field class="example-full-width">
                            <mat-label>Carte CNI</mat-label>
                            <input matInput [(ngModel)]="newMembre.numeroCNI"  name="numeroCNI" placeholder="Carte CNI" required>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <!-- <label><small>Commune</small></label> -->
                        <div class="form-group">
                          <mat-form-field>
                            <mat-select [(ngModel)]="newMembre.communeId" name="communeId" class="" required placeholder="Communes/SP" #singleSelect>
                              <mat-option>
                                <ngx-mat-select-search ></ngx-mat-select-search>
                              </mat-option>
                              <mat-option  *ngFor="let commune of lstCommune" [value]="commune.sousPrefectureId">
                                {{commune.libelle}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <!-- <label><small>Lieu de vote</small></label> -->
                        <div class="form-group">
                          <mat-form-field class="example-full-width">
                            <mat-label>Lieu de vote</mat-label>
                            <input matInput [(ngModel)]="newMembre.lieuVoteId"  name="lieuVoteId" placeholder="Lieu de vote" required>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <!-- <label><small>Numéro électeur</small></label> -->
                        <div class="form-group">
                          <!-- <input name="libelle" type="text" class="form-control" placeholder="Numéro élécteur" required> -->
                          <mat-form-field class="example-full-width">
                            <mat-label>Numéro électeur</mat-label>
                            <input matInput [(ngModel)]="newMembre.numeroElecteur" name="numeroElecteur" placeholder="Numéro électeur" required>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                  <div class="card">
                    <div class="header">
                        <h2><strong>Parents</strong></h2>
                    </div>
                    <div class="body">
                      <div class="row">
                        <div class="col-lg-12">
                          <fieldset>
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <mat-form-field class="example-full-width">
                                    <mat-label>Nom du père</mat-label>
                                    <input matInput [(ngModel)]="newMembre.nomPere" name="nomPere" placeholder="Nom du père" required>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <mat-form-field class="example-full-width">
                                    <mat-label>Prénom(s) du père</mat-label>
                                    <input matInput [(ngModel)]="newMembre.prenomPere" name="prenomPere" placeholder="Prénom(s) du père" required>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <mat-form-field class="example-full-width">
                                    <mat-label>Date de naissance du père</mat-label>
                                    <input matInput [(ngModel)]="newMembre.dateNaissancePere" name="dateNaissancePere" placeholder="Date de naissance du père" required>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <mat-form-field class="example-full-width">
                                    <mat-label>Lieu de naissance du père</mat-label>
                                    <input matInput [(ngModel)]="newMembre.lieuNaissancePere" name="lieuNaissancePere" placeholder="Lieu de naissance du père" required>
                                  </mat-form-field>
                                </div>
                              </div>

                              <div class="col-sm-6">
                                <div class="form-group">
                                  <mat-form-field class="example-full-width">
                                    <mat-label>Nom de la mère</mat-label>
                                    <input matInput [(ngModel)]="newMembre.nomMere" name="nomMere" placeholder="Nom de la mère" required>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <mat-form-field class="example-full-width">
                                    <mat-label>Prénom(s) de la mère</mat-label>
                                    <input matInput [(ngModel)]="newMembre.prenomMere" name="prenomMere" placeholder="Prénom(s) de la mère" required>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <mat-form-field class="example-full-width">
                                    <mat-label>Date de naissance de la mère</mat-label>
                                    <input matInput [(ngModel)]="newMembre.dateNaissanceMere" name="dateNaissanceMere" placeholder="Date de naissance de la mère" required>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <mat-form-field class="example-full-width">
                                    <mat-label>Lieu de naissance de la mère</mat-label>
                                    <input matInput [(ngModel)]="newMembre.lieuNaissanceMere" name="lieuNaissanceMere" placeholder="Lieu de naissance de la mère" required>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="header">
                <h2><strong>Membre</strong> Information <small>Informations sur les parents</small> </h2>
                <ul class="header-dropdown">
                    <li class="remove">
                        <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                    </li>
                </ul>
            </div>
            <div class="body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <mat-label>Nom</mat-label>
                      <input matInput [(ngModel)]="newMembre.nom" name="nom" placeholder="Nom du millitant" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <mat-label>Prénom(s)</mat-label>
                      <input matInput [(ngModel)]="newMembre.prenom" name="prenom" placeholder="Prénom(s)" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <mat-label>E-mail</mat-label>
                      <input matInput [(ngModel)]="newMembre.email" name="email" placeholder="Adresse E-mail" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <mat-label>Contact</mat-label>
                      <input matInput [(ngModel)]="newMembre.contact" name="Contact" placeholder="Contact téléphonique" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field>
                      <mat-select [(ngModel)]="newMembre.sex" name="sex" class="" required placeholder="Choix du sexe" #singleSelectSexe>
                        <mat-option>
                          <ngx-mat-select-search ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option value="M">Masculin</mat-option>
                        <mat-option value="F">Féminin</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <mat-label>Lieu de naissance</mat-label>
                      <input matInput [(ngModel)]="newMembre.lieuNaissance" name="Contact" placeholder="Lieu de naissance" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <mat-label>Date de naissance</mat-label>
                      <input matInput [(ngModel)]="newMembre.dateNaissance" name="Contact" placeholder="Date de naissance" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <mat-label>Profession</mat-label>
                      <input matInput [(ngModel)]="newMembre.profession" name="Profession" placeholder="Profession" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <mat-label>Adresse physique</mat-label>
                      <input matInput [(ngModel)]="newMembre.adressePhysique" name="adressePhysique" placeholder="Adresse physique" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <mat-label>Adresse postale</mat-label>
                      <input matInput [(ngModel)]="newMembre.adressePostale" name="adressePostale" placeholder="Adresse postale" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <mat-label>Autres:</mat-label>
                      <textarea matInput matInput [(ngModel)]="newMembre.autreInfos" placeholder="Autres informations"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="5"
                      cdkAutosizeMaxRows="10"
                      ></textarea>
                  </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-12 text-left">
                  <div class="footer text-right">
                    <button class="btn btn-default waves-effect m-r-20">ANNULER</button>
                    <button mat-button class="btn btn-primary waves-effect m-r-20">VALIDER</button>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>

      </div>
  </div>
</section>
