<div class="authentication sidebar-collapse">
  <div class="page-header">
    <div class="page-header-image" style="background-image:url('../../../assets/images/login.jpg')"></div>

        <div class="container">
  <div class="col-md-12 content-center">
    <div class="card-plain">
        <form class="form" #formulaireLogin="ngForm" (ngSubmit)="onSubmit(formulaireLogin)" >
            <div class="header">
                <div class="logo-container">
                    <img src="../../../assets/images/logo.svg" alt="">
                </div>
                <h5>Connexion</h5>
            </div>
            <div class="content">
                <div class="input-group input-lg">
                    <input type="text" #inputPseudo="ngModel" ngModel name="Pseudo" class="form-control" required placeholder="Nom utilisateur">
                    <span class="input-group-addon">
                        <i class="zmdi zmdi-account-circle"></i>
                    </span>
                </div>
                <div class="input-group input-lg">
                    <input type="password" #inputPassword="ngModel" ngModel name="Password" required placeholder="Mot de passe" class="form-control" />
                    <span class="input-group-addon">
                        <i class="zmdi zmdi-lock"></i>
                    </span>
                </div>
            </div>
            <div class="footer text-center">
                <button type="submit" [disabled]="!formulaireLogin.form.valid" class="btn btn-primary btn-round btn-lg btn-block ">CONNEXION</button>
                <h5>
                  <span class="col-md-6"><a routerLink="/singup" class="link">Je crée mon compte</a></span>
                  <span class="col-md-6"><a routerLink="/forgotpassword" class="link">Mot de passe oublié ?</a></span>
                </h5>
            </div>
        </form>
    </div>
  </div>
  </div>
  </div>
</div>

