import { SectionService } from './../../Services/section.service';
import { ToastrService } from 'ngx-toastr';
import { ComiteService } from './../../Services/comite.service';
import { SousprefectureService } from './../../Services/sousprefecture.service';
import { DepartementService } from './../../Services/departement.service';
import { RegionService } from './../../Services/region.service';
import { UtilisateursService } from './../../Services/utilisateurs.service';
import { FormControl } from '@angular/forms';
import { Comitecl } from './../../Models/comitecl.model';
import { Sectioncl } from './../../Models/sectioncl.model';
import { Sousprefecturecl } from './../../Models/sousprefecturecl.model';
import { Departementcl } from './../../Models/departementcl.model';
import { Regioncl } from './../../Models/regioncl.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import { ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label, MultiDataSet, PluginServiceGlobalRegistrationAndOptions } from 'ng2-charts';
import {DashboardService} from '../../Services/dashboard.service';
import {Dashboradcl} from '../../Models/dashboradcl.model';
import * as myGlobals from '../../Tools/fonctionsGenerales';
import {Animateur} from '../../Models/animateur.model';
@Component({
  selector: 'app-managementdash',
  templateUrl: './managementdash.component.html',
  styleUrls: ['./managementdash.component.css']
})
export class ManagementdashComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
   /*Diagrame en ligne */
   lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 11, 56, 15, 40], label: 'Evolution Missions' },
    { data: [12, 44, 24, 11, 20, 12, 60], label: 'Evoluttion Militants' },
    { data: [18, 48, 77, 19, 10, 27, 40], label: 'Evolution Sections' },
  ];
  lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  lineChartOptions = {
    responsive: true,
  };
  lineChartColors: Color[] = [
    {
      borderColor: 'rgba(148,159,177,1)',
      backgroundColor: 'rgba(148,159,177,0.2)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
     { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  totalMission: number = 0;
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  /* Fin diagrame en line  */
  /*Pie diagram*/
  doughnutChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  doughnutChartLabels: Label[] = [['Terminées'], ['En Cours'],  ['En Attente']];
  doughnutChartData: number[] = [300, 500, 200];
  doughnutChartType: ChartType = 'doughnut';
  //doughnutChartPlugins = [];
  doughnutChartLegend = false;
  doughnutChartColors = [
    {
      backgroundColor: ['rgba(30, 130, 76, 1)', 'rgba(255, 150, 0, 100)', 'rgba(242, 38, 19, 1)'],
    },
  ];
  doughnutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{
    // tslint:disable-next-line:typedef
    beforeDraw(chart) {
      const ctx = chart.ctx;
      const txt = 'Center Text';

      // Get options from the center object in options
      const sidePadding = 60;
      // const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      const stringWidth = ctx.measureText(txt).width;
      // const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      // const widthRatio = elementWidth / stringWidth;
      // const newFontSize = Math.floor(30 * widthRatio);
      // const elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      // const fontSizeToUse = Math.min(newFontSize, elementHeight);

      // ctx.font = fontSizeToUse + 'px Arial';
      ctx.font = 25 + 'px Arial';
      ctx.fillStyle = 'grey';

      // Draw text in center
      ctx.fillText('Missions', centerX, centerY);
    }
  }];
  /* End Pie fdiagram*/
  /*Jauge charts*/
  gaugeType = 'arch';
  gaugeValue = 0;
  gaugeLabel = 'Evolution';
  gaugeAppendText = '%';
  foregroundColor = ['rgba(0, 150, 136, 1)'];
  /**
   * Prise compte des parametres de filtre
   * @param router
   */
  totalMissionEnCours: number;
  totalMissionNotStart: number;
  totalMissionTerminees: number;
  totalMaxElecteur: number;
  totalMaxSection: number;
  redColor = "#FF0000";
  greenColor = "#008000";
  yellowColor = "#FFFF00";
  gaugeForegroundColorElecteur = this.redColor;
  gaugeValueElecteur: number;
  gaugeForegroundColorSection = this.redColor;
  gaugeValueSection: number;
  gaugeForegroundColorMission = this.redColor;
  gaugeValueMission: number;
  tableDataSource = new MatTableDataSource();
  dashboard = new Dashboradcl();
  lstTopSections: Dashboradcl[];
  lstTopComiteBases: Dashboradcl[];
  lstTopSecretaires: Dashboradcl[];
  lstTopAnimateurs: Dashboradcl[];
  lstMissions: Dashboradcl[];
  maxGood: number = myGlobals.TAUX_CONFORME_GOOD;
  maxNotBad: number = myGlobals.TAUX_CONFORME_NOTBAD;
  private dialogConfig;
  public displayedColumns = ['Login', 'Nom', 'Prenoms', 'Gender', 'Email', 'Phone', 'Commune', 'Comite', 'Role', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  lstRegions = new SelectionModel<Regioncl>(true, []);
  lstRegionTrie = new SelectionModel<Regioncl>(true, []);
  lstDeps = new SelectionModel<Departementcl>(true, []);
  lstDepsTrie = new SelectionModel<Departementcl>(true, []);
  lstCommuneSP = new SelectionModel<Sousprefecturecl>(true, []);
  lstCommuneSPTrie = new SelectionModel<Sousprefecturecl>(true, []);
  lstSections = new SelectionModel<Sectioncl>(true, []);
  lstSectionsTrie = new SelectionModel<Sectioncl>(true, []);
  lstComites = new SelectionModel<Comitecl>(true, []);
  lstComitesTrie = new SelectionModel<Comitecl>(true, []);
  /* Gestion des filtres */
  public selectControlRegion: FormControl = new FormControl();
  public selectFilterCtrlRegion: FormControl = new FormControl();
  /** Départements */
  public selectControlDep: FormControl = new FormControl();
  public selectFilterCtrlDep: FormControl = new FormControl();
  /** Sous prefectures / Commune */
  public selectControlCommune: FormControl = new FormControl();
  public selectFilterCtrlCommune: FormControl = new FormControl();
  /** Sections */
  public selectControlSection: FormControl = new FormControl();
  public selectFilterCtrlSection: FormControl = new FormControl();
  /** Comites */
  public selectControlComite: FormControl = new FormControl();
  public selectFilterCtrlComite: FormControl = new FormControl();
  constructor(private dialog: MatDialog, private svceUser: UtilisateursService, private svceDashboard: DashboardService, private router: Router, private svceRegion: RegionService, private svceDep: DepartementService, private svceSp: SousprefectureService, private svceSection: SectionService, private svceComite: ComiteService, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.getAllNumbers();
    this.getAllRegion();
    this.getAllDep();
    this.getAllComSp();
    this.getAllSec();
    this.getAllComite();
    this.getAllTopSections();
    this.getAllTopComiteBases();
    this.getAllTopSecretaires();
    this.getAllTopAnimateurs();
    this.getAllMissions();
  }
  detailsMission = () => {
    this.router.navigate(['dashboard/detailsMision']) ;
  }
  getAllRegion = () => {
    this.svceRegion.getAllRegion().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstRegions.clear();
          res['donnees'].forEach(element => {
            this.lstRegions.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**Departement */
  getAllDep = () => {
    this.svceDep.getAllDepartement().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstDeps.clear();
          res['donnees'].forEach(element => {
            this.lstDeps.select(element);
            this.lstDepsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieDep = (RegionId: string) => {
    this.lstDepsTrie.clear();
    this.lstDeps.selected.forEach(element => {
      if (element.regionId === RegionId)
      {
        this.lstDepsTrie.select(element);
      }
    });

    if (this.lstDepsTrie.selected.length <= 0)
    {
      this.lstDeps.selected.forEach(element => {
          this.lstDepsTrie.select(element);
      });
    }
    this.getAllNumbersByRegion(RegionId);
    this.getAllTopSectionsByRegion(RegionId);
    this.getAllTopComiteBasesByRegion(RegionId);
    this.getAllTopSecretairesByRegion(RegionId);
    this.getAllTopAnimateursByRegion(RegionId);
    this.getAllMissionsByRegion(RegionId);
  }
  /** Fin departement */
  /** Commune */
  getAllComSp = () => {
    this.svceSp.getAllCommuneSp().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstCommuneSP.clear();
          res['donnees'].forEach(element => {
            this.lstCommuneSP.select(element);
            this.lstCommuneSPTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieCommune = (DepartementId: string) => {
    this.lstCommuneSPTrie.clear();
    this.lstCommuneSP.selected.forEach(element => {
      if (element.departementId === DepartementId)
      {
        this.lstCommuneSPTrie.select(element);
      }
    });

    if (this.lstCommuneSPTrie.selected.length <= 0)
    {
      this.lstCommuneSP.selected.forEach(element => {
          this.lstCommuneSPTrie.select(element);
      });
    }
    this.getAllNumbersByDepartement(DepartementId);
    this.getAllTopSectionsByDepartement(DepartementId);
    this.getAllTopComiteBasesByDepartement(DepartementId);
    this.getAllTopSecretairesByDepartement(DepartementId);
    this.getAllTopAnimateursByDepartement(DepartementId);
    this.getAllMissionsByDepartement(DepartementId);
  }
  /** Fin Commune */
  /** Section */
  getAllSec = () => {
    this.svceSection.getAllSections().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstSections.clear();
          res['donnees'].forEach(element => {
            this.lstSections.select(element);
            this.lstSectionsTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieSection = (sousprefectureId: string) => {
    this.lstSectionsTrie.clear();
    this.lstSections.selected.forEach(element => {
      if (element.communeId === sousprefectureId)
      {
        this.lstSectionsTrie.select(element);
      }
    });

    if (this.lstSectionsTrie.selected.length <= 0)
    {
      this.lstSections.selected.forEach(element => {
          this.lstSectionsTrie.select(element);
      });
    }
    this.getAllNumbersByCommune(sousprefectureId);
    this.getAllTopSectionsByCommune(sousprefectureId);
    this.getAllTopComiteBasesByCommune(sousprefectureId);
    this.getAllTopSecretairesByCommune(sousprefectureId);
    this.getAllTopAnimateursByCommune(sousprefectureId);
    this.getAllMissionsByCommune(sousprefectureId);
  }
  /** Fin Section */
  /** Comité de base */
  getAllComite = () => {
    this.svceComite.getAllComiteBases().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstComites.clear();
          console.log(res['donnees']);
          res['donnees'].forEach(element => {
            this.lstComites.select(element);
            this.lstComitesTrie.select(element);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTrieComite = (sectionId: string) => {
    this.lstComitesTrie.clear();
    this.lstComites.selected.forEach(element => {
      if (element.sectionId === sectionId)
      {
        this.lstComitesTrie.select(element);
      }
    });
    console.log(this.lstComitesTrie.selected);
    if (this.lstComitesTrie.selected.length <= 0)
    {
      this.lstComites.selected.forEach(element => {
          this.lstComitesTrie.select(element);
      });
    }
  }
  getgaugeColorElecteur = (value: number) => {
    // tslint:disable-next-line:triple-equals
    if (!isNaN(value)){
      if (value >= myGlobals.TAUX_CONFORME_NOTBAD && value <= myGlobals.TAUX_CONFORME_GOOD){
        this.gaugeForegroundColorElecteur = this.yellowColor;
      }
      if (value >= myGlobals.TAUX_CONFORME_GOOD){
        this.gaugeForegroundColorElecteur = this.greenColor;
      }
      if (value < myGlobals.TAUX_CONFORME_NOTBAD){
        this.gaugeForegroundColorElecteur = this.redColor;
      }
    } else {
      this.gaugeValueElecteur = 0;
    }
  }
  getgaugeColorSection = (value: number) => {
    if (!isNaN(value)) {
      if (value >= myGlobals.TAUX_CONFORME_NOTBAD && value <= myGlobals.TAUX_CONFORME_GOOD) {
        this.gaugeForegroundColorSection = this.yellowColor;
      }
      if (value >= myGlobals.TAUX_CONFORME_GOOD) {
        this.gaugeForegroundColorSection = this.greenColor;
      }
      if (value < myGlobals.TAUX_CONFORME_NOTBAD) {
        this.gaugeForegroundColorSection = this.redColor;
      }
    } else {
      this.gaugeValueSection = 0;
    }
  }
  getgaugeColorMission = (value: number) => {
    if (!isNaN(value)) {
      if (value >= myGlobals.TAUX_CONFORME_NOTBAD && value <= myGlobals.TAUX_CONFORME_GOOD) {
        this.gaugeForegroundColorMission = this.yellowColor;
      }
      if (value >= myGlobals.TAUX_CONFORME_GOOD) {
        this.gaugeForegroundColorMission = this.greenColor;
      }
      if (value < myGlobals.TAUX_CONFORME_NOTBAD) {
        this.gaugeForegroundColorMission = this.redColor;
      }
    } else {
      this.gaugeValueMission = 0;
    }
  }
  loadCharts = () => {
    this.doughnutChartData = [this.totalMissionTerminees, this.totalMissionEnCours, this.totalMissionNotStart];
  }
  /** Fin Comites */
  getAllNumbers = () => {
    this.svceDashboard.getAllNumbers().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.dashboard = res['donnees'][0] as Dashboradcl;
          this.totalMaxElecteur = this.dashboard.nbreML;
          this.totalMaxSection = this.dashboard.nbreS;
          this.totalMission = this.dashboard.totalMission;
          this.totalMissionEnCours = this.dashboard.totalMissionEnCours;
          this.totalMissionNotStart = this.dashboard.totalMissionNotStart;
          this.totalMissionTerminees = this.dashboard.totalMissionTerminees;
          this.loadCharts();
          this.gaugeValueElecteur = Number(((this.dashboard.nbreML / this.dashboard.objectifElecteur) * 100).toFixed(2));
          this.getgaugeColorElecteur(this.gaugeValueElecteur);
          this.gaugeValueSection = Number(((this.dashboard.nbreS / this.dashboard.objectifSection) * 100).toFixed(2));
          this.getgaugeColorSection(this.gaugeValueSection);
          this.gaugeValueMission = Number(((this.dashboard.totalMissionEffectuee / this.dashboard.totalMission) * 100).toFixed(2));
          this.getgaugeColorMission(this.gaugeValueMission);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllNumbersByRegion = (regionId: string) => {
    this.svceDashboard.getAllNumbersByRegion(regionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.dashboard = res['donnees'][0] as Dashboradcl;
          this.totalMission = this.dashboard.totalMission;
          this.totalMissionEnCours = this.dashboard.totalMissionEnCours;
          this.totalMissionNotStart = this.dashboard.totalMissionNotStart;
          this.totalMissionTerminees = this.dashboard.totalMissionTerminees;
          this.loadCharts();
          this.gaugeValueElecteur = Number(((this.dashboard.nbreML / this.dashboard.objectifElecteur) * 100).toFixed(2));
          this.getgaugeColorElecteur(this.gaugeValueElecteur);
          this.gaugeValueSection = Number(((this.dashboard.nbreS / this.dashboard.objectifSection) * 100).toFixed(2));
          this.getgaugeColorSection(this.gaugeValueSection);
          this.gaugeValueMission = Number(((this.dashboard.totalMissionEffectuee / this.dashboard.totalMission) * 100).toFixed(2));
          this.getgaugeColorMission(this.gaugeValueMission);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllNumbersByDepartement = (departementId: string) => {
    this.svceDashboard.getAllNumbersByDepartement(departementId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.dashboard = res['donnees'][0] as Dashboradcl;
          this.totalMission = this.dashboard.totalMission;
          this.totalMissionEnCours = this.dashboard.totalMissionEnCours;
          this.totalMissionNotStart = this.dashboard.totalMissionNotStart;
          this.totalMissionTerminees = this.dashboard.totalMissionTerminees;
          this.loadCharts();
          this.gaugeValueElecteur = Number(((this.dashboard.nbreML / this.dashboard.objectifElecteur) * 100).toFixed(2));
          this.getgaugeColorElecteur(this.gaugeValueElecteur);
          this.gaugeValueSection = Number(((this.dashboard.nbreS / this.dashboard.objectifSection) * 100).toFixed(2));
          this.getgaugeColorSection(this.gaugeValueSection);
          this.gaugeValueMission = Number(((this.dashboard.totalMissionEffectuee / this.dashboard.totalMission) * 100).toFixed(2));
          this.getgaugeColorMission(this.gaugeValueMission);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllNumbersByCommune = (communeId: string) => {
    this.svceDashboard.getAllNumbersByCommune(communeId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.dashboard = res['donnees'][0] as Dashboradcl;
          this.dashboard.objectifElecteur = this.totalMaxElecteur;
          this.dashboard.objectifSection = this.totalMaxSection;
          this.totalMission = this.dashboard.totalMission;
          this.totalMissionEnCours = this.dashboard.totalMissionEnCours;
          this.totalMissionNotStart = this.dashboard.totalMissionNotStart;
          this.totalMissionTerminees = this.dashboard.totalMissionTerminees;
          this.loadCharts();
          this.gaugeValueElecteur = Number(((this.dashboard.nbreML / this.totalMaxElecteur) * 100).toFixed(2));
          this.getgaugeColorElecteur(this.gaugeValueElecteur);
          this.gaugeValueSection = Number(((this.dashboard.nbreS / this.totalMaxSection) * 100).toFixed(2));
          this.getgaugeColorSection(this.gaugeValueSection);
          this.gaugeValueMission = Number(((this.dashboard.totalMissionEffectuee / this.dashboard.totalMission) * 100).toFixed(2));
          this.getgaugeColorMission(this.gaugeValueMission);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopSections = () => {
    this.svceDashboard.getAllTopSection().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopSections = res['donnees'];
        } else {
          this.lstTopSections = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopSectionsByRegion = (regionId: string) => {
    this.svceDashboard.getAllTopSectionsByRegion(regionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopSections = res['donnees'];
        } else {
          this.lstTopSections = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopSectionsByDepartement = (departementId: string) => {
    this.svceDashboard.getAllTopSectionsByDepartement(departementId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopSections = res['donnees'];
        } else {
          this.lstTopSections = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopSectionsByCommune = (communeId: string) => {
    this.svceDashboard.getAllTopSectionsByCommune(communeId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopSections = res['donnees'];
        } else {
          this.lstTopSections = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopComiteBases = () => {
    this.svceDashboard.getAllTopComiteBase().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopComiteBases = res['donnees'];
        } else {
          this.lstTopComiteBases = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopComiteBasesByRegion = (regionId: string) => {
    this.svceDashboard.getAllTopComiteBasesByRegion(regionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopComiteBases = res['donnees'];
        } else {
          this.lstTopComiteBases = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopComiteBasesByDepartement = (departementId: string) => {
    this.svceDashboard.getAllTopComiteBasesByDepartement(departementId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopComiteBases = res['donnees'];
        } else {
          this.lstTopComiteBases = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopComiteBasesByCommune = (communeId: string) => {
    this.svceDashboard.getAllTopComiteBasesByCommune(communeId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopComiteBases = res['donnees'];
        } else {
          this.lstTopComiteBases = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopSecretaires = () => {
    this.svceDashboard.getAllTopSecretaire().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopSecretaires = res['donnees'];
        } else {
          this.lstTopSecretaires = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopSecretairesByRegion = (regionId: string) => {
    this.svceDashboard.getAllTopSecretairesByRegion(regionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopSecretaires = res['donnees'];
        } else {
          this.lstTopSecretaires = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopSecretairesByDepartement = (departementId: string) => {
    this.svceDashboard.getAllTopSecretairesByDepartement(departementId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopSecretaires = res['donnees'];
        } else {
          this.lstTopSecretaires = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopSecretairesByCommune = (communeId: string) => {
    this.svceDashboard.getAllTopSecretairesByCommune(communeId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopSecretaires = res['donnees'];
        } else {
          this.lstTopSecretaires = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopAnimateurs = () => {
    this.svceDashboard.getAllTopAnimateur().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopAnimateurs = res['donnees'];
        } else {
          this.lstTopAnimateurs = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopAnimateursByRegion = (regionId: string) => {
    this.svceDashboard.getAllTopAnimateursByRegion(regionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopAnimateurs = res['donnees'];
        } else {
          this.lstTopAnimateurs = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopAnimateursByDepartement = (departementId: string) => {
    this.svceDashboard.getAllTopAnimateursByDepartement(departementId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopAnimateurs = res['donnees'];
        } else {
          this.lstTopAnimateurs = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllTopAnimateursByCommune = (communeId: string) => {
    this.svceDashboard.getAllTopAnimateursByCommune(communeId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstTopAnimateurs = res['donnees'];
        } else {
          this.lstTopAnimateurs = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMissions = () => {
    this.svceDashboard.getAllMissions().subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMissions = res['donnees'];
        } else {
          this.lstMissions = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMissionsByRegion = (regionId: string) => {
    this.svceDashboard.getAllMissionsByRegion(regionId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMissions = res['donnees'];
        } else {
          this.lstMissions = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMissionsByDepartement = (departementId: string) => {
    this.svceDashboard.getAllMissionsByDepartement(departementId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMissions = res['donnees'];
        } else {
          this.lstMissions = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllMissionsByCommune = (communeId: string) => {
    this.svceDashboard.getAllMissionsByCommune(communeId).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.lstMissions = res['donnees'];
        } else {
          this.lstMissions = res['donnees'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
