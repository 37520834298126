import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../Tools/fonctionsGenerales';

@Component({
  selector: 'app-prestations',
  templateUrl: './prestations.component.html',
  styleUrls: ['./prestations.component.css']
})
export class PrestationsComponent implements OnInit {
  appName: string = myGlobals.APP_NAME;
  constructor() { }

  ngOnInit(): void {
  }

}
