<div class="row clearfix">
  <div class="col-md-12">
    <div class="card patients-list">
      <div class="header">
        <h2><strong>Liste Departement qui ont reçu l'objectif : </strong> {{this.data['affectationObjectif'].libelle}}</h2>
        <ul class="header-dropdown">
          <li class="remove">
            <a role="button" mat-dialog-close class="boxs-close"><i class="zmdi zmdi-close"></i></a>
          </li>
        </ul>
      </div>
      <form id="detailsaffectationObjectifForm" #detailsaffectationObjectifForm="ngForm" action="post">
        <div class="body">
          <div class="table-responsive">
            <div class="col-sm-12 col-lg-12">
              <div class="input-group search">
                <input type="text" (keyup)="doFilter($event.target.value)"  class="form-control" placeholder="Rechercher...">
                <!-- <span class="input-group-addon">
                    <i class="zmdi zmdi-search"></i>
                </span> -->
              </div>
            </div>
            <div class="col-sm-12 col-lg-12">
              <table mat-table class="table table-bordered table-striped table-hover js-basic-example dataTable"  [dataSource]="tableDataSource" matSort>
                <ng-container matColumnDef="Libelle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Departement </th>
                  <td mat-cell *matCellDef="let element"> {{element.LibelleDepartement}} </td>
                </ng-container>
                <ng-container matColumnDef="Valeur">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header># Objectif </th>
                  <td mat-cell *matCellDef="let element"> {{element.valeur  | mask: 'separator':' '}} </td>
                </ng-container>
                <ng-container matColumnDef="Electeur">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header># Obtenu </th>
                  <td mat-cell *matCellDef="let element"> {{element.NbreELEC  | mask: 'separator':' ' }} </td>
                </ng-container>
                <ng-container matColumnDef="Pourcent">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Taux (%) </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.Taux >= this.maxGood" class="badge badge-success">{{element.Taux | mask: 'separator.2'}} %</span>
                    <span *ngIf="element.Taux < this.maxNotBad" class="badge badge-danger">{{element.Taux  | mask: 'separator.2' }} %</span>
                    <span *ngIf="element.Taux >= this.maxNotBad && element.Taux <this.maxGood" class="badge badge-warning">{{element.Taux  | mask: 'separator.2' }} %</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Delai">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Délai </th>
                  <td mat-cell *matCellDef="let element"> {{ element.deadline }} </td>
                </ng-container>
                <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef style="width: 140px;text-align: center"> Actions </th>
                  <td mat-cell *matCellDef=" let element">
                    <button class="btn btn-icon btn-neutral  btn-icon-mini" (click)="redirectToUpdateAffectationObjectif(element, this.data['affectationObjectif'].libelle)" >
                      <i class="material-icons">edit</i>
                    </button>
                    <button  class="btn btn-icon btn-neutral btn-icon-mini" >
                      <i class="material-icons delete">delete_outline</i>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Loading">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Chargement en cours....
                  </th>
                </ng-container>
                <ng-container matColumnDef="NoData">
                  <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9">
                    Aucunes données trouvées....
                  </th>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
                <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10"  showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- <mat-dialog-actions align="end">
  <button class="btn btn-default waves-effect m-r-20" mat-button mat-dialog-close>Cancel</button>
  <button mat-button class="btn btn-primary waves-effect m-r-20" [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
