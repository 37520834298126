<div *ngIf="this.loaderService.isLoading | async" class="loader-container"><mat-spinner style="top: 50%; left:50%"></mat-spinner> </div>
<!-- Page Loader
<div class="page-loader-wrapper">pro
  <div class="loader">
      <div class="m-t-30"><img class="zmdi-hc-spin" src="../assets/images/logo.svg" width="48" height="48" alt="Oreo"></div>
      <p>Please wait...</p>
  </div>
</div>-->
<!-- Overlay For Sidebars -->
<div class="overlay"></div>
<app-nav></app-nav>
<!--<app-leftnav *ngIf="userConnected['typemembre'] !== 'Animateur' && userConnected['typemembre'] !== 'Secrétaire'" ></app-leftnav>-->
<app-leftnav-refont *ngIf="userConnected['typemembre'] !== 'Animateur' && userConnected['typemembre'] !== 'Secrétaire'" ></app-leftnav-refont>
<app-animateurleftnav *ngIf="userConnected['typemembre'] == 'Animateur'" ></app-animateurleftnav>
<app-cordinateurleftbar *ngIf="userConnected['typemembre'] == 'Secrétaire'"></app-cordinateurleftbar>
<router-outlet></router-outlet>
