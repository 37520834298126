<div class="container-fluid">
    <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="header">
              <h2><strong>Details</strong> Animateur</h2>
              <ul class="header-dropdown">
                  <li class="remove">
                      <a role="button" mat-button [mat-dialog-close]="true" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                  </li>
              </ul>
          </div>
            <div class="body-1">
              <!-- Nav tabs -->
              <ul class="nav nav-tabs">
                <!-- <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#home">PROFILE</a></li> -->
                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#profile">MISSIONS</a></li>
                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#messages">MILLITANTS</a></li>
            </ul>
            <!-- Tab panes
            <hr/>-->
            <div class="tab-content">
                <!-- <div role="tabpanel" class="tab-pane in active" id="home"> <b>Home Content</b>
                    <p> Lorem ipsum dolor sit amet, ut duo atqui exerci dicunt, ius impedit mediocritatem an. Pri ut tation electram moderatius.
                        Per te suavitate democritum. Duis nemore probatus ne quo, ad liber essent aliquid
                        pro. Et eos nusquam accumsan, vide mentitum fabellas ne est, eu munere gubergren
                        sadipscing mel. </p>
                </div> -->
                <div role="tabpanel" class="tab-pane in active" id="profile">
                  <table class="table m-b-0 table-hover">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Address</th>
                            <th>Last Visit</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span class="list-name">KU 00598</span></td>
                            <td>71 Pilgrim Avenue Chevy Chase, MD 20815</td>
                            <td>11 Jan 2018</td>
                            <td><span class="badge badge-success">Oui</span></td>
                        </tr>
                        <tr>
                            <td><span class="list-name">KU 00258</span></td>
                            <td>123 6th St. Melbourne, FL 32904</td>
                            <td>15 Jan 2018</td>
                            <td><span class="badge badge-success">Oui</span></td>
                        </tr>
                        <tr>
                            <td><span class="list-name">KU 00789</span></td>
                            <td>123 6th St. Melbourne, FL 32904</td>
                            <td>19 Jan 2018</td>
                            <td><span class="badge badge-warning">Non</span></td>
                        </tr>
                        <tr>
                            <td><span class="list-name">KU 00987</span></td>
                            <td>123 6th St. Melbourne, FL 32904</td>
                            <td>20 Jan 2018</td>
                            <td><span class="badge badge-success">Oui</span></td>
                        </tr>
                        <tr>
                            <td><span class="list-name">KU 00951</span></td>
                            <td>44 Shirley Ave. West Chicago, IL 60185</td>
                            <td>404-447-6013</td>
                            <td><span class="badge badge-warning">Non</span></td>
                        </tr>
                        <tr>
                            <td><span class="list-name">KU 00953</span></td>
                            <td>123 6th St. Melbourne, FL 32904</td>
                            <td>22 Jan 2018</td>
                            <td><span class="badge badge-warning">Non</span></td>
                        </tr>
                        <tr>
                            <td><span class="list-name">KU 00984</span></td>
                            <td>123 6th St. Melbourne, FL 32904</td>
                            <td>22 Jan 2018</td>
                            <td><span class="badge badge-success">Oui</span></td>
                        </tr>
                        <tr>
                            <td><span class="list-name">KU 00934</span></td>
                            <td>123 6th St. Melbourne, FL 32904</td>
                            <td>29 Jan 2018</td>
                            <td><span class="badge badge-warning">Non</span></td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <div role="tabpanel" class="tab-pane" id="messages"> <b>Message Content</b>
                  <table class="table m-b-0 table-hover">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Appel</th>
                            <th>Visite</th>
                            <th>Vote</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span class="list-name">KU 00598</span></td>
                            <td>Ouattara Sefon Dramane</td>
                            <td><span class="badge badge-success">Oui</span></td>
                            <td><span class="badge badge-success">Oui</span></td>
                            <td><span class="badge badge-warning">Non</span></td>
                        </tr>
                        <tr>
                          <td><span class="list-name">KU 00598</span></td>
                          <td>Bamba Aboubacar</td>
                          <td><span class="badge badge-success">Oui</span></td>
                          <td><span class="badge badge-warning">Non</span></td>
                          <td><span class="badge badge-warning">Non</span></td>
                        </tr>
                        <tr>
                          <td><span class="list-name">KU 00598</span></td>
                          <td>Mocktar Diomande</td>
                          <td><span class="badge badge-success">Oui</span></td>
                          <td><span class="badge badge-success">Oui</span></td>
                          <td><span class="badge badge-success">Oui</span></td>
                        </tr>
                        <tr>
                          <td><span class="list-name">KU 00598</span></td>
                          <td>Niamkey Adde Ahou Hermance</td>
                          <td><span class="badge badge-warning">Non</span></td>
                          <td><span class="badge badge-warning">Non</span></td>
                          <td><span class="badge badge-warning">Non</span></td>
                        </tr>

                    </tbody>
              </table>
                </div>
            </div>
            </div>
          </div>
        </div>
    </div>
</div>
