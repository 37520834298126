<section class="content home">
  <div class="block-header">
    <div class="row">
      <div class="col-lg-5 col-md-5 col-sm-12">
        <h2>Tableau de bord<small class="text-muted">Bienvenue sur {{this.appName}}</small></h2>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-12 text-right">
        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> {{this.appName}}</a></li>
          <li class="breadcrumb-item">Accueil</li>
          <li class="breadcrumb-item active">Tableau de bords</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div *ngIf="this.searchBarActivated" class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="row filtrepan">
            <div class="col-lg-3">
            </div>
            <div class="col-lg-3">
            </div>
            <div class="col-lg-3">
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <mat-form-field>
                  <mat-select  placeholder="Comités de base" #singleComiteSelect>
                    <mat-option>
                      <ngx-mat-select-search ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of lstComitesTrie.selected" [value]="item.comiteBaseId">
                      {{item.libelle}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row clearfix">

      <div class="col-lg-6 col-md-12">
        <div id="detailMilitant"  class="card tasks_report detailMission" style="min-height: 410px;">
          <div class="header">
            <h2><strong>Objectif</strong> Nombre Electeurs <small>Détails.</small></h2>
          </div>
          <div class="body text-center">
            <h6 class="m-t-30">Total Electeurs</h6>
            <h6 class="m-b-20"><strong><h4>{{this.dashboard.nbreML  | mask: 'separator':' '}}</h4></strong></h6>
            <ngx-gauge [type]="gaugeType"
                       [thick]="6"
                       [value]="gaugeValueElecteur"
                       [label]="gaugeLabel"
                       [append]="gaugeAppendText"
                       [foregroundColor]="gaugeForegroundColorElecteur">
            </ngx-gauge>
            <h6 class="m-t-30">Objectif à atteindre</h6>
            <small class="displayblock"><strong><h4>{{this.paramMaxMembreNumber  | mask: 'separator':' '}}</h4></strong></small>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div id="detailMission" (click)="detailsMission()"  class="card tasks_report detailMission" style="min-height: 410px;">
          <div class="header">
            <h2><strong>Taux</strong> Avancement missions <small>Détails.</small></h2>
          </div>
          <div class="body text-center">
            <h6 class="m-t-30">Missions Effectuées</h6>
            <h6 class="m-b-20"><strong><h4>{{this.dashboard.totalMissionEffectuee  | mask: 'separator':' '}}</h4></strong></h6>
            <ngx-gauge [type]="gaugeType"
                       [thick]="6"
                       [value]="this.gaugeValueMission"
                       [label]="gaugeLabel"
                       [append]="gaugeAppendText"
                       [foregroundColor]="this.gaugeForegroundColorMission">
            </ngx-gauge>
            <h6 class="m-t-30">Total Misions</h6>
            <small class="displayblock"><strong><h4>{{this.dashboard.totalMission  | mask: 'separator':' '}}</h4></strong></small>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card tasks_report">
          <div class="header">
            <h2><strong>Missions</strong> Stats <small>Détails.</small></h2>
          </div>
          <div class="body text-center">
            <div class="table-responsive">
              <table class="mat-table cdk-table mat-sort table table-bordered table-striped table-hover js-basic-example dataTable">
                <thead>
                <tr>
                  <th class="text-left">Libelle</th>
                  <th>Statut</th>
                  <th>Taux</th>
                  <th>Evolution</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of lstMissions">
                  <td class="text-left">{{item.libelle}}</td>
                  <td><span *ngIf="item.Taux >= this.maxGood" class="badge badge-success">Terminer</span><span *ngIf="item.Taux < this.maxNotBad" class="badge badge-danger">En cours</span><span *ngIf="item.Taux >= this.maxNotBad && item.Taux <this.maxGood" class="badge badge-warning">En cours</span></td>
                  <td><span *ngIf="item.Taux >= this.maxGood" class="badge badge-success">{{item.Taux}}%</span><span *ngIf="item.Taux < this.maxNotBad" class="badge badge-danger">{{item.Taux}}%</span><span *ngIf="item.Taux >= this.maxNotBad && item.Taux <this.maxGood" class="badge badge-warning">{{item.Taux}}%</span></td>
                  <td>
                    <div class="progress-container progress-containerMission">
                      <div class="progress progressMission">
                        <div *ngIf="item.Taux >= this.maxGood" class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="{{item.Taux}}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': item.Taux + '%'}" ></div>
                        <div *ngIf="item.Taux < this.maxNotBad" class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="{{item.Taux}}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': item.Taux + '%'}" ></div>
                        <div *ngIf="item.Taux >= this.maxNotBad && item.Taux <this.maxGood" class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="{{item.Taux}}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': item.Taux + '%'}" ></div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card tasks_report">
          <div class="header">
            <h2><strong>Missions</strong> Stats <small>Détails.</small></h2>
          </div>
          <div class="body text-center">
            <div class="table-responsive">
              <div class="chart-wrapper">
                <canvas baseChart height="135"
                        [data]="doughnutChartData"
                        [labels]="doughnutChartLabels"
                        [chartType]="doughnutChartType"
                        [legend]="doughnutChartLegend"
                        [options]="doughnutChartOptions"
                        [plugins]="doughnutChartPlugins"
                        [colors]="doughnutChartColors"
                >
                </canvas>
              </div>
            </div>
          </div>
          <div class="footer text-center" style="padding: 24px;">
            <div class="row">
              <div class="col-sm-2">
              </div>
              <div class="col-sm-2">
                <div class="progress">
                  <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                </div>
                <h3 class="number m-b-0" > {{this.totalMission  | mask: 'separator':' '}}</h3>
                <p class="text-muted">Total</p>
                <!-- <small>Change 23%</small> -->
              </div>
              <div class="col-sm-2">
                <div class="progress">
                  <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                </div>
                <h3 class="number m-b-0" > {{this.totalMissionTerminees  | mask: 'separator':' '}}</h3>
                <p class="text-muted">Terminées</p>
                <!-- <small>Change 23%</small> -->
              </div>
              <div class="col-sm-2">
                <div class="progress">
                  <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                </div>
                <h3 class="number m-b-0" > {{this.totalMissionEnCours  | mask: 'separator':' '}}</h3>
                <p class="text-muted">En Cours</p>
                <!-- <small class="text-left">Change 15%</small> -->
              </div>
              <div class="col-sm-2">
                <div class="progress">
                  <div class="progress-bar  progress-bar-danger" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                </div>
                <h3 class="number m-b-0" >
                  {{this.totalMissionNotStart  | mask: 'separator':' '}}</h3>
                <p class="text-muted">En Attentes</p>
                <!-- <small>Change 23%</small> -->
              </div>
              <div class="col-sm-2">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
